const serviceworldTeaserStyle = {
  centeredText: {
    textAlign: "center",
  },
  serviceworldButton: {
    marginTop: "3rem",
    width: 220,
  },
};

export default serviceworldTeaserStyle;
