import React from "react";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import CaseDetailsItem from "../../../Common/CaseDetailsItem/CaseDetailsItem";

const VehicleSpecificCaseDetails = ({ contractLawCase }: any) => {
  return (
    <Grid container>
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"buyerOrSeller"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"vehicleMake"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"model"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"vehicleId"} />
      {contractLawCase.objectiveClient === "withdrawal" && (
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"mileageAtPurchase"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"currentMileage"} />
        </>
      )}
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"purchasePrice"} fieldType={"money"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"buyerPaidStatus"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"locationOfGood"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"hasDefects"} />

      {contractLawCase.hasDefects && (
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"isAccidentCar"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"defectsRecognitionDate"} fieldType={"date"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"defectsDescriptionClient"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"numberRepairAttempts"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"numberReplacementAttempts"} />
        </>
      )}
    </Grid>
  );
};

VehicleSpecificCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

export default VehicleSpecificCaseDetails;
