const multipleFileUploadStyle = {
  section: {
    marginBottom: "3rem",
  },
  headline: {
    fontWeight: 500,
  },
  file: {
    fontWeight: 400,
    textAlign: "right",
    padding: "0.5rem 0",
  },
  icon: {
    verticalAlign: "text-bottom",
    margin: "0 0.5rem",
  },
  deleteIcon: {
    verticalAlign: "text-bottom",
    margin: "0 0.5rem",
    cursor: "pointer",
  },
};
export default multipleFileUploadStyle;
