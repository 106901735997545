const cookiePolicyStyle = {
  tableHeadCell: {
    backgroundColor: "primary.main",
    color: "#fff",
  },
  tableBodyCell: {
    verticalAlign: "top",
  },
  tableBodyCellContent: {
    verticalAlign: "top",
    width: "50%",
  },
  preferenceLink: {
    textAlign: "right",
    margin: "2rem 0",
  },
};

export default cookiePolicyStyle;
