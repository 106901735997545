import React from "react";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { dateFieldDefault, isNumber, isRequired, minNumber, pastDateDefault, requiredValidator } from "../../../../services/validationRules";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";
import ValidatorDateField from "../../../FormFields/ValidatorDateField";
import FormSubmitProps from "../../../../types/FormSubmitProps";
import wizardStyle from "../../wizardStyle";
import { Box } from "@mui/material";

type AlimonyProps = { submitProps: FormSubmitProps };

export default function Alimony({ submitProps }: AlimonyProps) {
  const { values, handleSubmit } = useForm();

  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Typography variant={"h5"} sx={wizardStyle.headline}>
            {values.basicChoice === "complete" ? "Heirat und Trennung" : "Familiäre Situation"}
          </Typography>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
              <FormLegend>Wie ist die aktuelle Situation zwischen Ihnen und Ihrem Ex-Partner?</FormLegend>
            </Grid>
            {values.basicChoice === "complete" && (
              <Grid item xs={12}>
                <ValidatorDateField
                  name="dateMarriage"
                  label="Datum standesamtliche Heirat"
                  validators={[
                    { validator: isRequired, message: "Bitte geben Sie das Datum der standesamtlichen Heirat an" },
                    dateFieldDefault,
                    pastDateDefault,
                  ]}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ValidatorSelectField
                label="Sind Sie getrennt?"
                name="areSeparated"
                validators={[requiredValidator("Bitte geben Sie an, ob Sind Sie getrennt sind.")]}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                <MenuItem value={"yes"}>Ja</MenuItem>
                <MenuItem value={"no"}>Nein</MenuItem>
              </ValidatorSelectField>
            </Grid>
            {values.areSeparated === "yes" && (
              <Grid item xs={12}>
                <ValidatorDateField
                  name="dateSeparation"
                  label="Datum der Trennung"
                  validators={[requiredValidator("Bitte geben Sie das Datum der Trennung an"), dateFieldDefault, pastDateDefault]}
                />
              </Grid>
            )}
            {values.basicChoice === "complete" && (
              <>
                <Grid item xs={12}>
                  <ValidatorSelectField
                    label="Sind Sie geschieden?"
                    name="areDivorced"
                    validators={[requiredValidator("Bitte geben Sie an, ob Sind Sie geschieden sind.")]}
                    MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                  >
                    <MenuItem value={"yes"}>Ja</MenuItem>
                    <MenuItem value={"no"}>Nein</MenuItem>
                  </ValidatorSelectField>
                </Grid>
                {values.areDivorced === "yes" && (
                  <Grid item xs={12}>
                    <ValidatorDateField
                      name="dateDivorce"
                      label="Datum der Scheidung"
                      validators={[requiredValidator("Bitte geben Sie das Datum der Scheidung an"), dateFieldDefault, pastDateDefault]}
                      helperText={"Geben Sie hier an, wann Ihre Scheidung rechtskräftig wurde"}
                    />
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <ValidatorTextField
                name="numberOfChildren"
                label="Anzahl aller Kinder"
                helperText="Geben Sie hier die Anzahl aller unterhaltsberechtigten Kinder von Ihnen und Ihrem Partner an. Egal, ob dies gemeinsame Kinder sind oder aus anderen Beziehungen. "
                validators={[
                  requiredValidator("Bitte geben Sie die Anzahl aller Kinder an"),
                  { validator: isNumber, message: "Bitte geben Sie die Anzahl aller Kinder als Zahl an, z.B. 2" },
                  {
                    validator: minNumber,
                    params: [1],
                    message: "Da Sie den Kindesunterhalt berechnen möchten, müssen Sie hier mindestens ein Kind angeben",
                  },
                ]}
              />
            </Grid>
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
