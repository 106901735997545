/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import {isRequired} from "../../../services/validationRules";
import React from "react";
import ValidatorAutocomplete from "../../FormFields/ValidatorAutocomplete";
import mobileProviders from "../../../services/mobileProviders";

type AutocompleteSelectFieldConfiguredProps = {
  name: string;
  required?: boolean | undefined;
  label?: string;
  requiredMessage?: string;
};
export default function MobileProvidersAutocomplete({
  name,
  required = true,
  label = "Mobilfunkanbieter",
  requiredMessage = "Bitte geben Sie Ihren aktuellen Mobilfunkanbieter an",
}: AutocompleteSelectFieldConfiguredProps) {
  return (
    <ValidatorAutocomplete
      label={label}
      name={name}
      options={mobileProviders}
      valueOptionAttribute="value"
      validators={required ? [{ validator: isRequired, message: requiredMessage }] : []}
    />
  );
}
