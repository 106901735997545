import React from "react";
import summaryStyle from "./summaryStyle";
import Grid from "@mui/material/Grid";
import GridEditRow from "../../Common/GridEditRow/GridEditRow";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";

type ContractualPartnerDataProps = {
  formData: Record<string, any>;
  formPath: string;
  title: string;
};

const ContractualPartnerData = ({ formData, formPath, title }: ContractualPartnerDataProps) => {
  const section = "contractualPartnerData";

  const contractualPartnerType = formData.contractualPartnerType;
  const contractualPartner =
    contractualPartnerType === "contractualPartnerLegalEntity" ? formData.contractualPartnerLegalEntity : formData.contractualPartnerPerson;
  const contractualPartnerAddress =
    contractualPartnerType === "contractualPartnerLegalEntity"
      ? formData.contractualPartnerLegalEntity.address
      : formData.contractualPartnerPerson.residenceAddress;

  return (
    <>
      <Grid item xs={12} sx={summaryStyle.subHeadline}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>
      {contractualPartnerType === "contractualPartnerLegalEntity" && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"name"} sx={summaryStyle.scrollToAnchor} />
            Name Unternehmen
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "name",
              },
            }}
          >
            {formData.contractualPartnerLegalEntity.legalName}
          </GridEditRow>
        </>
      )}
      {contractualPartnerType === "contractualPartnerPerson" && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"gender"} sx={summaryStyle.scrollToAnchor} />
            Anrede
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "gender",
              },
            }}
          >
            {formData.contractualPartnerPerson.gender === "male" ? "Herr" : "Frau"}
          </GridEditRow>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"givenName"} sx={summaryStyle.scrollToAnchor} />
            Vorname
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "givenName",
              },
            }}
          >
            {formData.contractualPartnerPerson.givenName}
          </GridEditRow>

          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"familyName"} sx={summaryStyle.scrollToAnchor} />
            Nachname
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "familyName",
              },
            }}
          >
            {formData.contractualPartnerPerson.familyName}
          </GridEditRow>
        </>
      )}
      {contractualPartnerAddress.streetAddress && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"streetAddress"} sx={summaryStyle.scrollToAnchor} />
            Straße und Hausnummer
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "streetAddress",
              },
            }}
          >
            {contractualPartnerAddress.streetAddress}
          </GridEditRow>
        </>
      )}
      {contractualPartnerAddress.postalCode && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"postalCode"} sx={summaryStyle.scrollToAnchor} />
            Postleitzahl
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "postalCode",
              },
            }}
          >
            {contractualPartnerAddress.postalCode}
          </GridEditRow>
        </>
      )}
      {contractualPartnerAddress.addressLocality && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"addressLocality"} sx={summaryStyle.scrollToAnchor} />
            Ort
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "addressLocality",
              },
            }}
          >
            {contractualPartnerAddress.addressLocality}
          </GridEditRow>
        </>
      )}
      {contractualPartnerAddress.postOfficeBoxNumber && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"postOfficeBoxNumber"} sx={summaryStyle.scrollToAnchor} />
            Postfach
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "postOfficeBoxNumber",
              },
            }}
          >
            {contractualPartnerAddress.postOfficeBoxNumber}
          </GridEditRow>
        </>
      )}
      {contractualPartnerAddress.postOfficeBoxPostalCode && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"postOfficeBoxPostalCode"} sx={summaryStyle.scrollToAnchor} />
            Postfach PLZ
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "postOfficeBoxPostalCode",
              },
            }}
          >
            {contractualPartnerAddress.postOfficeBoxPostalCode}
          </GridEditRow>
        </>
      )}
      {contractualPartnerAddress.postOfficeBoxAddressLocality && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"postOfficeBoxAddressLocality"} sx={summaryStyle.scrollToAnchor} />
            Postfach Ort
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "postOfficeBoxAddressLocality",
              },
            }}
          >
            {contractualPartnerAddress.postOfficeBoxAddressLocality}
          </GridEditRow>
        </>
      )}
      {contractualPartner.email && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"email"} sx={summaryStyle.scrollToAnchor} />
            E-Mail-Adresse
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "email",
              },
            }}
          >
            {contractualPartner.email}
          </GridEditRow>
        </>
      )}
      {contractualPartner.telephone && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"telephone"} sx={summaryStyle.scrollToAnchor} />
            Telefonnummer
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "telephone",
              },
            }}
          >
            {contractualPartner.telephone}
          </GridEditRow>
        </>
      )}
    </>
  );
};

export default ContractualPartnerData;
