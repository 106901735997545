import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Paper from "@mui/material/Paper";
import SignaturePad from "../../../SignaturePad/SignaturePad";
import ButtonLoading from "../../../Button/ButtonLoading";
import Button from "@mui/material/Button";
import { Replay as ReplayIcon } from "@mui/icons-material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { API_HOST, API_PATH } from "../../../../config/_entrypoint";
import { urltoFile } from "../../../../services/mediaObjects";
import signatureStyle from "./signatureStyle";
import Typography from "@mui/material/Typography";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import { createMediaObject } from "../../../../services/reactQuery/reactQueryMediaObjectService";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import { Person } from "../../../../types/Entities/Person";
import { getProductData } from "../../../../services/productService";
import { Box } from "@mui/material";

type SignatureProps = {
  product: AbstractCase;
  person: Person;
  signatureIsDisabled?: boolean;
};

export default function Signature({ product, person, signatureIsDisabled = false }: SignatureProps) {
  const [signatureImage, setSignatureImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const signaturePadRef = useRef<SignaturePad>(null);
  const createMediaObjectMutation = useMutation(createMediaObject);
  const queryClient = useQueryClient();
  const [minimumSignatureSizeReached, setMinimumSignatureSizeReached] = useState(false);

  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data),
  });

  const loadSignature = useCallback(async () => {
    if (!person.signature) {
      return;
    }
    const downloadLink = API_HOST + API_PATH + "/download/" + person.signature.id + "?token=" + localStorage.getItem("token") + "&base64=1";
    const signatureImageResponse = await fetch(downloadLink);
    const imageSrc = await signatureImageResponse.text();
    setSignatureImage(imageSrc);
  }, [person.signature]);

  useEffect(() => {
    loadSignature();
  }, [loadSignature]);

  const clear = () => {
    setSignatureImage("");
    signaturePadRef.current?.clear();
  };

  const saveSignature = async () => {
    if (!signaturePadRef.current || signaturePadRef.current?.signaturePadInstance?.isEmpty()) {
      return;
    }
    setIsLoading(true);
    const file = await urltoFile(signaturePadRef.current?.signaturePadInstance?.toDataURL("image/svg+xml"), "signature.svg", "image/svg+xml");

    const result = await createMediaObjectMutation.mutateAsync({
      data: {
        file: file,
        description: "Unterschrift",
        fieldname: "signature",
        product: product.name,
        productId: product.id,
      },
    });

    await updateMutation.mutateAsync({
      uri: "/people",
      id: person.id!,
      data: {
        signature: `media_objects/${result.id}`,
      },
    });
    await queryClient.invalidateQueries(queryKeys.item(getProductData(product.name, "apiUri"), product.id));
    setIsLoading(false);
  };

  const removeSignature = async () => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "/people",
      id: person.id!,
      data: {
        signature: null,
      },
    });
    await queryClient.invalidateQueries(queryKeys.item(getProductData(product.name, "apiUri"), product.id));
    clear();
    setIsLoading(false);
  };
  return (
    <Grid container justifyContent={"center"} alignItems={"baseline"} spacing={2} sx={signatureStyle.signatureContainer}>
      <Grid item xs={12} sx={signatureStyle.signatureCaption}>
        <Hidden smUp>
          <Typography sx={signatureStyle.primary}>
            <strong>TIPP:</strong> Drehen Sie Ihr Handy, damit Sie mehr Platz zum Unterzeichnen haben.
          </Typography>
        </Hidden>
      </Grid>
      <Grid item>
        <Typography variant={"h4"}>Unterschrift {person.fullName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={4} sx={{ backgroundColor: "#ffffff" }}>
          {signatureImage !== "" ? (
            <Box component={"img"} src={signatureImage} alt={"Unterschrift"} sx={signatureStyle.signatureImage} />
          ) : (
            <SignaturePad height={200} ref={signaturePadRef} setMinimumSignatureSizeReached={setMinimumSignatureSizeReached} disabled={signatureIsDisabled} />
          )}
        </Paper>
      </Grid>
      {person.signature ? (
        <Grid item xs={12}>
          <ButtonLoading color="primary" fullWidth={true} isLoading={isLoading} onClick={removeSignature}>
            Neu unterschreiben
          </ButtonLoading>
        </Grid>
      ) : (
        <>
          <Grid item>
            <ButtonLoading
              disabled={!minimumSignatureSizeReached}
              isLoading={isLoading}
              sx={signatureStyle.signatureButton}
              variant={"contained"}
              color={"primary"}
              onClick={saveSignature}
            >
              Unterschrift speichern
            </ButtonLoading>
          </Grid>
          <Grid item container xs={12} justifyContent={"center"}>
            <Button color={"primary"} onClick={() => clear()}>
              <ReplayIcon sx={signatureStyle.leftIcon} /> Erneut versuchen
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}
