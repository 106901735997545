const mainTeaserStyle = {
  mainTeaserContainer: {
    margin: { xs: "5rem 1rem", md: "5rem" },
  },
  subText: (theme) => ({
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    margin: "1rem 0",
  }),
  button: (theme) => ({
    [theme.breakpoints.down("md")]: {
      display: "block",
      textAlign: "center",
    },
    margin: "0 auto",
    minWidth: "300px",
  }),
  headline: (theme) => ({
    marginTop: "12rem",
    [theme.breakpoints.down("lg")]: {
      marginTop: "3rem",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      textAlign: "center",
    },
  }),
};

export default mainTeaserStyle;
