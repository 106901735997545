import Grid from "@mui/material/Grid";
import React from "react";
import { useForm } from "../../../../../provider/Form/FormProvider";
import FormLegend from "../../../Common/FormLegend/FormLegend";
import ValidatorTextField from "../../../../FormFields/ValidatorTextField";

const AdditionalFeedbackMessage = () => {
  const { values } = useForm();

  return (
    <>
      <Grid item xs={12}>
        <FormLegend>Möchten Sie uns noch etwas zu Ihrer Entscheidung mitteilen?</FormLegend>
      </Grid>
      <Grid item xs={12}>
        <ValidatorTextField
          name="additionalCustomerNote"
          label="Ihr Anliegen"
          placeholder="Ihre Nachricht an uns (optional)"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rows={10}
          inputProps={{ maxLength: 750 }}
          helperText={"[" + values.additionalCustomerNote.length + "/750]"}
        />
      </Grid>
    </>
  );
};
export default AdditionalFeedbackMessage;
