import React, { useContext, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/reactQuery/reactQueryService";
import _head from "lodash/head";
import { Customer } from "../../types/Entities/Customer";

interface CustomerProviderValue {
  customer: Customer | null;
  isLoading: boolean;
  isError: boolean;
  isLoggedIn: boolean;
  refreshCustomer: Function;
  setRefetchInterval: Function;
  isInitialized: boolean;
}

const customerContextDefaults: CustomerProviderValue = {
  customer: null,
  isLoading: false,
  isError: false,
  isLoggedIn: false,
  refreshCustomer: () => {},
  setRefetchInterval: () => {},
  isInitialized: false,
};
const CustomerContext = React.createContext(customerContextDefaults);

function CustomerProvider({ children }: { children: React.ReactElement }) {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const queryClient = useQueryClient();
  const {
    data: customerCollection,
    isLoading,
    isError,
  } = useQuery(
    queryKeys.collection("/customers"),
    () => {
      if (!localStorage.getItem("token")) {
        return [];
      }
      return fetchCollection("/customers");
    },
    {
      // Refetch the data every second
      refetchInterval: refetchInterval,
      retry: false,
    }
  );
  const collectedCustomer = _head(customerCollection);
  if (!!collectedCustomer) {
    localStorage.setItem("customer", JSON.stringify(collectedCustomer));
  }
  if (!localStorage.getItem("token") || isError) {
    localStorage.setItem("customer", JSON.stringify(null));
  }
  const customer: Customer | null = JSON.parse(localStorage.getItem("customer") || "null");
  const refreshCustomer = async () => {
    await queryClient.invalidateQueries(queryKeys.collection("/customers"));
  };

  const data: CustomerProviderValue = {
    customer,
    isLoading,
    isError,
    isLoggedIn: !!customer,
    refreshCustomer,
    isInitialized: true,
    setRefetchInterval,
  };

  return <CustomerContext.Provider value={data}>{children}</CustomerContext.Provider>;
}

const useCustomer = (): CustomerProviderValue => {
  const customerContext = useContext(CustomerContext);
  if (!customerContext.isInitialized) {
    throw new Error("useCustomer can only be used inside CustomerProvider");
  }
  return customerContext;
};

export { CustomerProvider, useCustomer };
