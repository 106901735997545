import React from "react";
import InquiryToCustomer from "../../../components/Serviceworld/InquiryToCustomer/InquiryToCustomer";
import Layout from "../../Layouts/Layout";

export default function InquiryToCustomerPage() {

  return (
    <Layout backgroundType={"serviceworld"}>
      <InquiryToCustomer />
    </Layout>
  );
}
