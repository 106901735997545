import { Theme } from "@mui/material";

const gradientPaperStyle = {
  background: (theme: Theme) => ({
    ...(theme.palette.mode === "dark"
      ? {
          background: "text.contrast",
        }
      : {
          background: "linear-gradient(#FFFFFF, #F4F7FF) !important",
        }),
  }),
};

export default gradientPaperStyle;
