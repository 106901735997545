const saskiaSchlemmerTheme = {
  palette: {
    primary: {
      main: "#B99B5F",
      dark: "#F254E5",
    },
    secondary: {
      main: "#F254E5",
    },
  }
};

export default saskiaSchlemmerTheme;
