/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Box, Grid } from "@mui/material";
import { translate, translateCaseValue } from "../../../../services/translations/translations";
import React from "react";
import { convertValueDependingOnFieldType, fieldPathValueCanBeDisplayed } from "../../../../services/formatService";
import GridEditRow from "../GridEditRow/GridEditRow";
import { scrollToAnchor } from "../../../../theme/commonStyle";
import { FieldType } from "../../../../types/formatServiceTypes/FieldType";

type SummaryDetailsItemProps = {
  caseData: Record<string, any>;
  formUrl: string;
  fieldPath: string;
  fieldType?: FieldType;
  labelPostfix?: string | null;
  anchorId?: string;
};

const SummaryDetailsItem = ({ caseData, formUrl, fieldPath, fieldType = null, labelPostfix = null, anchorId = "contractDetails" }: SummaryDetailsItemProps) => {
  if (!fieldPathValueCanBeDisplayed(caseData, fieldPath)) {
    return null;
  }

  let convertedValue = fieldType === null ? translateCaseValue(caseData, fieldPath) : convertValueDependingOnFieldType(caseData, fieldPath, fieldType);

  let label = translate(`${caseData.name}.${fieldPath}.label`, caseData);
  label = labelPostfix ? label + " " + labelPostfix : label;

  return (
    <>
      <Grid item xs={12} sx={{ marginTop: "1rem", fontWeight: 400 }}>
        <Box id={fieldPath} sx={scrollToAnchor} />
        {label}
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formUrl,
          state: {
            section: anchorId,
            field: fieldPath,
          },
        }}
      >
        {convertedValue}
      </GridEditRow>
    </>
  );
};

export default SummaryDetailsItem;
