/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import React from "react";
import wizardStyle from "../../wizardStyle";
import Grid from "@mui/material/Grid";
import { useForm } from "../../../../provider/Form/FormProvider";
import { translate } from "../../../../services/translations/translations";
import CheckboxGroup, { CheckboxGroupOptionProps } from "../../../Wizard/CheckboxGroup";
import { atLeastOneTrueProperty } from "../../../../services/validationRules";
import FormLegend from "../../Common/FormLegend/FormLegend";

export default function EmotionalState() {
  const { values } = useForm();

  const availableOptions: Array<string> = [
    "lossOfControl",
    "anxiety",
    "uncomfortableAboutScoring",
    "qualityOfSleep",
    "worryAboutFutureFinancialObligations",
    "helpless",
    "lossOfTrust",
    "violationOfHonor",
    "lossOfConfidence",
  ];

  const getOptions = () => {
    let options: Array<CheckboxGroupOptionProps> = [];

    availableOptions.map((option) => {
      options.push({
        value: option,
        label: translate(`dataLeakContract.emotionalState.options.${option}.label`, values),
      });
    });

    return options;
  };

  return (
    <>
      <Grid item xs={12} pt={5}>
        <FormLegend>{translate("dataLeakContract.emotionalState.question", values)}</FormLegend>
      </Grid>

      <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
        <CheckboxGroup
          name={"personalAffection.emotionalState"}
          options={getOptions()}
          validators={[
            {
              validator: atLeastOneTrueProperty,
              message: "Bitte wählen Sie mindestens eine Option aus",
            },
          ]}
        />
      </Grid>
    </>
  );
}
