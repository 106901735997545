import { scrollToAnchor } from "../../../../theme/commonStyle";

const myAccountPageStyle = {
  scrollToAnchor: {
    ...scrollToAnchor,
    marginBottom: "1rem",
  },
  paper: {
    padding: "1rem",
    margin: "25px 0",
  },
  headline: {
    color: "text.primary",
    fontWeight: 500,
    textAlign: "center",
    marginBottom: "2rem",
  },
  verticalSpace: {
    marginBottom: "1rem",
  },
  container: {
    maxWidth: 500,
    padding: "25px",
    margin: "0 auto",
  },
};

export default myAccountPageStyle;
