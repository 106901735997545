const contactSectionStyle = {
  centerText: {
    textAlign: "center",
  },
  button: {
    width: 300,
    margin: "1.5rem",
  },
};

export default contactSectionStyle;
