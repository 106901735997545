import React from "react";
import { Route, Routes } from "react-router-dom";
import ServiceRAFA from "../../components/Jobs/Job/ServiceRAFA";
import ServiceRAFATeamlead from "../../components/Jobs/Job/ServiceRAFATeamlead";
import JobOverviewPage from "../../containers/Pages/Generic/JobsPage/JobOverviewPage";
import Error404Page from "../../containers/Pages/Generic/Error404/Error404Page";
import Attorney from "../../components/Jobs/Job/Attorney";
import LegalTrainee from "../../components/Jobs/Job/LegalTrainee";
import ProductManager from "../../components/Jobs/Job/ProductManager";
import useTracker from "../../services/useTracker";
import SeniorDeveloper from "../../components/Jobs/Job/SeniorDeveloper";

export default function Jobs() {
  useTracker();

  return (
    <Routes>
      <Route path={`rechtsanwaltsfachangestellte-teamleitung`} element={<ServiceRAFATeamlead />} />
      <Route path={`rechtsanwaltsfachangestellter`} element={<ServiceRAFA />} />
      <Route path={`rechtsanwalt`} element={<Attorney />} />
      <Route path={`rechtsreferendar-in`} element={<LegalTrainee />} />
      <Route path={`produktmanager`} element={<ProductManager />} />
      <Route path={`developer`} element={<SeniorDeveloper />} />
      <Route path={``} element={<JobOverviewPage />} />
      <Route path={`*`} element={<Error404Page />} />
    </Routes>
  );
}
