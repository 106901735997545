import React from "react";
import * as PropTypes from "prop-types";
import GradientPaper from "../../../../../../components/GradientComponents/GradientPaper";
import _map from "lodash/map";
import TeaserWbs from "./TeaserWbs";
import { Box } from "@mui/material";

export default function ZipperTeaserWbs({ teaserRows }) {
  return (
    <Box sx={{ padding: "2rem" }}>
      <GradientPaper>
        {_map(teaserRows, (teaserRow, index) => (
          <TeaserWbs
            key={index}
            subText={teaserRow.subText}
            headline={teaserRow.headline}
            imageLeftHandSide={index % 2 === 0}
            image={teaserRow.image}
            imageAlt={teaserRow.headline}
            variant={"compact"}
          />
        ))}
      </GradientPaper>
    </Box>
  );
}

ZipperTeaserWbs.propTypes = {
  teaserRows: PropTypes.array.isRequired,
};
