import commonJobsStyles from "../../../Jobs/commonJobsStyles";

const teamSectionStyle = {
  ...commonJobsStyles,
  headline: {
    textAlign: "center",
  },
  carouselItemContainer: {
    padding: { xs: "2rem 1rem", md: "2rem" },
  },
};

export default teamSectionStyle;
