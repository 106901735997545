import { FormControl, FormLabel } from "@mui/material";
import formLegendStyle from "./formLegendStyle";
import { ReactNode } from "react";

type FormLegendProps = {
  children: ReactNode;
};

export default function FormLegend({ children, ...rest }: FormLegendProps) {
  return (
    <FormControl sx={formLegendStyle.fieldset} component="fieldset" {...rest}>
      <FormLabel sx={formLegendStyle.label} component="legend">
        {children}
      </FormLabel>
    </FormControl>
  );
}
