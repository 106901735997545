/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React, { useState } from "react";
import InquiryToCustomer, { InquiryToCustomerStatus } from "../../../types/Entities/InquiryToCustomer";
import { Box, Grid, Typography } from "@mui/material";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { FormProvider, useForm } from "../../../provider/Form/FormProvider";
import { apiPut } from "../../../services/apiClient";
import ButtonLoading from "../../Button/ButtonLoading";
import Group from "../../Wizard/Group";
import Paper from "@mui/material/Paper";
import TextContainer from "../../Container/TextContainer";
import wizardStyle from "../../Form/wizardStyle";
import { useInvalidateOpenInquiry } from "./useInvalidateOpenInquiry";
import CheckIcon from "../../../assets/icons/common/toggleable/IconHaken";
import CrossIcon from "../../../assets/icons/common/toggleable/IconKreuz";

type InquiryToCustomerDecisionBoxProps = {
  inquiryToCustomer: InquiryToCustomer;
  product: AbstractCase;
};

export default function InquiryToCustomerDecisionBox({ inquiryToCustomer, product }: InquiryToCustomerDecisionBoxProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const invalidateOpenInquiry = useInvalidateOpenInquiry();

  let initialValues = {
    inquiryDecisionAnswer: null,
  };

  const handleSubmit = async ({ values }: { values: Record<string, any> }) => {
    setIsLoading(true);
    let payload: Record<string, any> = {
      status: InquiryToCustomerStatus.STATUS_DONE,
      doneBy: "customer",
      inquiryDecisionAnswer: values.inquiryDecisionAnswer,
    };

    await apiPut("/inquiry_to_customers/" + inquiryToCustomer.id, payload);
    setIsLoading(false);

    await invalidateOpenInquiry(product);
  };

  return (
    <Paper sx={{ padding: "1rem" }}>
      <TextContainer>
        <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
          <InquiryToCustomerDecisionForm inquiryToCustomer={inquiryToCustomer} isLoading={isLoading} />
        </FormProvider>
      </TextContainer>
    </Paper>
  );
}

type InquiryToCustomerDecisionFormProps = {
  inquiryToCustomer: InquiryToCustomer;
  isLoading: boolean;
};

const InquiryToCustomerDecisionForm = ({ inquiryToCustomer, isLoading }: InquiryToCustomerDecisionFormProps) => {
  const { handleSubmit, values } = useForm();

  return (
    <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
      <Typography variant={"h4"} sx={{ textAlign: "center" }}>
        Benötigte Informationen
      </Typography>
      <Grid container alignItems={"baseline"} justifyContent={"center"}>
        <Grid item xs={12}>
          <Group
            type="radio"
            name="inquiryDecisionAnswer"
            question={inquiryToCustomer.inquiryDecisionQuestion ?? ""}
            options={[
              {
                labelText: "Ja",
                labelIcon: <CheckIcon />,
                labelIconActive: <CheckIcon active />,
                value: "yes",
              },
              {
                labelText: "Nein",
                labelIcon: <CrossIcon />,
                labelIconActive: <CrossIcon active />,
                value: "no",
              },
            ]}
          />
        </Grid>
      </Grid>

      <Box sx={{ marginTop: "2rem" }}>
        <ButtonLoading fullWidth variant={"contained"} color={"primary"} type={"submit"} isLoading={isLoading} disabled={values.inquiryDecisionAnswer === null}>
          Rückmeldung abschicken
        </ButtonLoading>
      </Box>
    </Box>
  );
};
