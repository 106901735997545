import CaseDetailsItem from "../../../Serviceworld/Common/CaseDetailsItem/CaseDetailsItem";
import Typography from "@mui/material/Typography";
import doneBoxStyle from "./doneBoxStyle";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import { Divider } from "@mui/material";

type PersonSectionProps = {
  product: AbstractCase;
  personType: "client" | "partner";
};

const PersonSection = ({ product, personType }: PersonSectionProps) => {
  return (
    <>
      <Typography variant={"h6"} sx={doneBoxStyle.subheader}>
        {personType === "partner" ? "Angaben zum Ehepartner" : "Ihre Angaben"}
      </Typography>
      <Divider sx={doneBoxStyle.divider} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.givenName`} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.familyName`} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.nationality`} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.residenceAddress.streetAddress`} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.residenceAddress.postalCode`} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.residenceAddress.addressLocality`} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.givenName`} />
    </>
  );
};
export default PersonSection;
