/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import React, { useEffect, useState } from "react";
import FormFader from "../../Common/Fader/FormFader";
import wizardStyle from "../../wizardStyle";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import { useForm } from "../../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import FormSubmitProps from "../../../../types/FormSubmitProps";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { translate } from "../../../../services/translations/translations";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button } from "@mui/material";
import { requiredValidator } from "../../../../services/validationRules";
import { queryKeys } from "../../../../services/reactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../../../services/productService";

type PersonalAffectionProps = {
  submitProps: FormSubmitProps;
  product: AbstractCase;
};

export default function PersonalAffectionSummary({ submitProps, product }: PersonalAffectionProps) {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [retries, setRetries] = useState(0);
  const { values, handleSubmit, updateValue } = useForm();
  const queryClient = useQueryClient();
  const MAX_RETRIES = 15;

  useEffect(() => {
    if (isContentLoaded || retries > MAX_RETRIES) {
      return;
    }

    const intervalId = setInterval(async () => {
      await queryClient.invalidateQueries(queryKeys.item("/data_leak_contracts", product.id));
      setRetries((prevRetries) => prevRetries + 1);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isContentLoaded, retries]);

  useEffect(() => {
    if (product.personalAffection.personalAffectionClient !== null) {
      setIsContentLoaded(true);

      updateValue("personalAffection.personalAffectionClient", product.personalAffection.personalAffectionClient);
    }
  }, [product.personalAffection.personalAffectionClient]);

  const charCount =
    values.personalAffection !== null && typeof values.personalAffection.personalAffectionClient === "string"
      ? values.personalAffection.personalAffectionClient.length
      : 0;
  const personalAffectionClientTooShort = values.personalAffection.personalAffectionClient?.length < 250;

  const tooManyRetries = retries > MAX_RETRIES;

  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Persönliche Betroffenheit - Zusammenfassung</strong>
              </FormLegend>

              {tooManyRetries && (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={3} py={10}>
                  <Typography sx={{ textAlign: "center" }}>
                    Wegen aktuell unerwartet hoher Systemlast konnte Ihr Betroffenheitstext leider nicht erstellt werden. Bitte versuchen Sie es zu einem
                    späteren Zeitpunkt erneut. Wir entschuldigen uns für die Unannehmlichkeiten und danken Ihnen für Ihr Verständnis.
                  </Typography>

                  <Button fullWidth={false} component={Link} to={getCaseLink(product)} variant={"contained"}>
                    Zurück zur Servicewelt
                  </Button>
                </Box>
              )}

              {!isContentLoaded && !tooManyRetries && (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={3} py={10}>
                  <CircularProgress size={45} />
                  <Typography sx={{ textAlign: "center" }}>Bitte warten Sie, während wir Ihre persönliche Betroffenheitserklärung erstellen.</Typography>
                </Box>
              )}

              {isContentLoaded && !tooManyRetries && (
                <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
                  <Typography sx={{ textAlign: "center", marginBottom: "2rem" }}>
                    Bitte überprüfen/ergänzen Sie die untenstehende Aussage zu Ihrer persönlichen Betroffenheit. Wenn Sie mit dem Ergebnis zufrieden sind und
                    der Text korrekt ihre Betroffenheit wiedergibt, klicken Sie bitte auf “Weiter”.
                  </Typography>

                  <Typography sx={{ textAlign: "center", marginBottom: "2rem" }}>
                    <strong>Wichtig:</strong> Vor Gericht wird sich sehr wahrscheinlich auf diese Ausführungen von Ihnen bezogen, füllen Sie dieses Feld also
                    bitte mit Bedacht.
                  </Typography>
                  <ValidatorTextField
                    name="personalAffection.personalAffectionClient"
                    label={translate("dataLeakContract.personalAffectionClient.label", values)}
                    placeholder={translate("dataLeakContract.personalAffectionClient.placeholder", values)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={25}
                    inputProps={{ minLength: 250, maxLength: 20000 }}
                    error={personalAffectionClientTooShort}
                    helperText={"[" + charCount + "/20000]" + (personalAffectionClientTooShort ? " Bitte geben Sie mindestens 250 Buchstaben ein" : "")}
                    validators={[requiredValidator("Bitte geben Sie einen Text ein")]}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {!tooManyRetries && <FormSubmit {...submitProps} disabled={!isContentLoaded} hideBackButton={!isContentLoaded} />}
        </Box>
      </FormFader>
    </Paper>
  );
}
