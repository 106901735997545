import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import * as loginActions from "../../../../store/login/actions";
import { getLpByLocation } from "../../../../services/landingPagesList";
import Typography from "@mui/material/Typography";
import { FormProvider } from "../../../../provider/Form/FormProvider";
import { useLocation, useNavigate } from "react-router-dom";
import FamilyLawCheckinForm from "./FamilyLawCheckinForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createResource, queryKeys } from "../../../../services/reactQuery/reactQueryService";
import { register } from "../../../../store/login/actions";
import alimonyCheckinStyle from "./alimonyCheckinStyle";
import { Box } from "@mui/material";

type AlimonyCheckinProps = {
  idPrefix: string;
  color?: "default" | "grey";
  checkinHeading?: string;
  target?: string;
  partner: string;
};

export default function AlimonyCheckin({
  color = "default",
  target = "/servicewelt",
  idPrefix,
  checkinHeading = "Persönliches Dokument erstellen und Erstberatung vereinbaren",
  partner,
}: AlimonyCheckinProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const initialValues = {
    email: "",
    gender: "",
    familyName: "",
    givenName: "",
    telephone: "",
  };
  const queryClient = useQueryClient();
  const createMutation = useMutation(createResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data),
  });

  const handleSubmit = async ({ values }: { values: Record<string, any> }) => {
    setIsLoading(true);

    const { from } = location.state || { from: { pathname: target } };
    const emailCheck = await loginActions.checkEmail(values.email);
    if (emailCheck.hasError) {
      setIsLoading(false);
      setEmailErrorText(emailCheck.errorText);
      return;
    }

    const landingPage = getLpByLocation(location.pathname) || { keyword: location.pathname };
    const data = {
      email: values.email.toLowerCase(),
      gclid: localStorage.getItem("gclid"),
      telephone: values.telephone,
      person: {
        gender: values.gender,
        givenName: values.givenName,
        familyName: values.familyName,
      },
      prelead: {
        initialProduct: "alimony",
        landingPage: landingPage.keyword,
        partner: partner,
      },
    };

    await register(data);
    await createMutation.mutateAsync({
      uri: "/alimonies",
      data: {
        acquisitionPartner: partner,
        affiliateCode: localStorage.getItem("gclid"),
        landingPage: landingPage.keyword,
      },
    });
    await queryClient.invalidateQueries(queryKeys.collection("/customers"));
    navigate(from.pathname);
  };

  return (
    <Paper elevation={4} sx={[alimonyCheckinStyle.formPaper, alimonyCheckinStyle[color]]}>
      <Box id={idPrefix + "-checkin"} sx={alimonyCheckinStyle.scrollToAnchor} />
      <Typography variant={"h4"} sx={alimonyCheckinStyle.heading}>
        {checkinHeading}
      </Typography>
      <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
        <FamilyLawCheckinForm isLoading={isLoading} emailErrorText={emailErrorText} setEmailErrorText={setEmailErrorText} />
      </FormProvider>
    </Paper>
  );
}
