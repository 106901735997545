import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useForm } from "../../provider/Form/FormProvider";
import { FormHelperText } from "@mui/material";
import _get from "lodash/get";
import { formValue } from "../../services/formServiceFunctions";
import { DatePicker } from "@mui/x-date-pickers";
import { ValidatorType } from "../../types/ValidatorType";

interface ValidatorDateFieldTypes {
  name: string;
  label: string;
  helperText?: string | null;
  validators?: Array<ValidatorType>;
  dependentValidationFields?: Array<any>;
}

export default function ValidatorDateField({ name, label, helperText, validators, dependentValidationFields, ...rest }: ValidatorDateFieldTypes) {
  const { values, errors, setError, handleChange, registerValidators }: any = useForm();

  const error = !!_get(errors, name);
  useEffect(() => {
    registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators(name, [], []);
      setError(name, undefined);
    };
  }, [name]);

  return (
    <FormControl fullWidth sx={{ margin: "1rem 0 .3rem 0" }}>
      <DatePicker
        value={formValue(values, name)}
        renderInput={(props) => (
          <TextField
            name={name}
            {...props}
            error={error}
            inputProps={{
              ...props.inputProps,
              placeholder: "tt.mm.jjjj",
            }}
          />
        )}
        mask={"__.__.____"}
        onChange={(date) => handleChange({ target: { name: name, value: date } })}
        label={label}
        {...rest}
      />
      <FormHelperText sx={error ? { color: "error.main" } : {}}>{_get(errors, name) || helperText}</FormHelperText>
    </FormControl>
  );
}

ValidatorDateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validators: PropTypes.array.isRequired,
  dependentValidationFields: PropTypes.array.isRequired,
  helperText: PropTypes.string,
};

ValidatorDateField.defaultProps = {
  validators: [],
  dependentValidationFields: [],
};
