/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import React from "react";
import FormFader from "../../Common/Fader/FormFader";
import wizardStyle from "../../wizardStyle";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import { useForm } from "../../../../provider/Form/FormProvider";
import FormSubmitProps from "../../../../types/FormSubmitProps";
import PurchasesPlannedInTheNearFuture from "./PurchasesPlannedInTheNearFuture";
import EmotionalState from "./EmotionalState";
import CreditRatingProblems from "./CreditRatingProblems";
import PersonalStatement from "./PersonalStatement";
import PersonalAffectionIntrotext from "./PersonalAffectionIntrotext";
import { Box } from "@mui/material";
import WorriesFrequency from "./WorriesFrequency";

type PersonalAffectionProps = {
  submitProps: FormSubmitProps;
};

export default function PersonalAffection({ submitProps }: PersonalAffectionProps) {
  const { handleSubmit } = useForm();

  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <PersonalAffectionIntrotext />
            <CreditRatingProblems />
            <PurchasesPlannedInTheNearFuture />
            <EmotionalState />
            <WorriesFrequency />
            <PersonalStatement />
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
