const headlineSectionStyle = {
  centeredText: {
    textAlign: "center",
  },
  image: {
    width: { xs: 280, md: 500 },
    height: "auto",
    margin: "2rem auto 0 auto",
    display: "block",
  },
  smallImage: {
    width: 280,
    height: "auto",
    margin: "2rem auto 0 auto",
    display: "block",
  },
};

export default headlineSectionStyle;
