const uploadFineNoticeStyle = {
  info: {
    height: "1.25rem",
    cursor: "help",
  },
  container: {
    padding: "1rem",
  },
  question: {
    textAlign: "center",
    fontSize: "1.25rem",
  },
  textBlock: {
    textAlign: "center",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  button: {
    marginTop: "1rem",
  },
};

export default uploadFineNoticeStyle;
