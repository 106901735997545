import React from "react";
import gradientPaperStyle from "./gradientPaperStyle";
import { Card } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function GradientCard({ sx = {}, children, ...rest }) {
  const theme = useTheme();

  return (
    <Card sx={{ ...gradientPaperStyle.background(theme), ...sx }} {...rest}>
      {children}
    </Card>
  );
}
