import ReactGA from "react-ga4";
import { googleAnalyticsConfig } from "./universalAnalytics";
import { hasConsent } from "./cookieService";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { gclidPersister } from "./gclidPersister";

const initialize = () => {
  if (!hasConsent("analytics")) {
    return;
  }
  const { trackingId } = googleAnalyticsConfig();
  ReactGA.initialize(trackingId);
  ReactGA.set({ anonymizeIp: false });
};

initialize();

export default function useTracker(options = {}) {
  const location = useLocation();
  const page = location.pathname + location.search;

  useEffect(() => {
    if (!hasConsent("analytics")) {
      return;
    }

    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.send({ hitType: "pageview", page: page });
  }, [page]);

  const withGclid = !!options.withGclid;
  useEffect(() => {
    if (withGclid) {
      gclidPersister(page);
    }
  }, [withGclid, page]);
}

export const modalview = (modal) => {
  pageview("/modal/" + modal);
}

export const pageview = (page) => {
  ReactGA.send({ hitType: "pageview", page: page });
}