import React, { ReactElement } from "react";
import tripleImageProgressionTeaserStyle from "./tripleImageProgressionTeaserStyle";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import IconWrapper from "../IconWrapper/IconWrapper";

//no map because we decided it should be exactly 3 steps
type TripleImageProgressionTeaserProps = {
  step1: Step;
  step2: Step;
  step3: Step;
};
export default function TripleImageProgressionTeaser({ step1, step2, step3 }: TripleImageProgressionTeaserProps) {
  return (
    <Box sx={tripleImageProgressionTeaserStyle.backgroundContainer}>
      <Box sx={tripleImageProgressionTeaserStyle.stepsContainer}>
        <Box sx={tripleImageProgressionTeaserStyle.step}>
          <IconWrapper icon={step1.image} sx={tripleImageProgressionTeaserStyle.image} alt={"Schritt 1"} />
          <Typography variant={"subtitle2"}>
            <strong>{step1.headline}</strong>
          </Typography>
          <Typography variant={"body1"}>{step1.subHeadline}</Typography>
        </Box>
        <Box sx={tripleImageProgressionTeaserStyle.spaceBetween} />
        <Box sx={tripleImageProgressionTeaserStyle.step}>
          <IconWrapper icon={step2.image} sx={tripleImageProgressionTeaserStyle.image} alt={"Schritt 2"} />
          <Typography variant={"subtitle2"}>
            <strong>{step2.headline}</strong>
          </Typography>
          <Typography variant={"body1"}>{step2.subHeadline}</Typography>
        </Box>
        <Box sx={tripleImageProgressionTeaserStyle.spaceBetween} />
        <Box sx={tripleImageProgressionTeaserStyle.step}>
          <IconWrapper icon={step3.image} sx={tripleImageProgressionTeaserStyle.image} alt={"Schritt 3"} />
          <Typography variant={"subtitle2"}>
            <strong>{step3.headline}</strong>
          </Typography>
          <Typography variant={"body1"}>{step3.subHeadline}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

type Step = {
  image: string | ReactElement;
  headline: string;
  subHeadline: string;
};
