import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export const editorStateToHTML = (editorState, emptyToNullConversion = false) => {
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  const convertedContent = draftToHtml(rawContentState);

  if (emptyToNullConversion && !editorState.getCurrentContent().hasText()) {
    return null;
  }
  return convertedContent;
};

export const htmlToEditorState = (html) => {
  if (!html) {
    return EditorState.createEmpty();
  }
  const contentState = htmlToContentState(html);
  return EditorState.createWithContent(contentState);
};


const htmlToContentState = (html) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  return ContentState.createFromBlockArray(contentBlocks, entityMap);
};
