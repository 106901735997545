import React from "react";
import { getCaseLink, getProductData, getProductNameBy } from "../../../services/productService";
import InquiryToCustomerOverviewBox from "./InquiryToCustomerOverviewBox";
import Container from "../../Container/Container";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../hooks/useProductParams";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { InquiryToCustomerStatus } from "../../../types/Entities/InquiryToCustomer";
import InquiryToCustomerAppointmentBox from "./InquiryToCustomerAppointmentBox";
import InquiryToCustomerInformationAndDocuments from "./InquiryToCustomerInformationAndDocuments";
import InquiryToCustomerLinkBox from "./InquiryToCustomerLinkBox";
import InquiryToCustomerDecisionBox from "./InquiryToCustomerDecisionBox";
import { Navigate, useParams } from "react-router-dom";

export default function InquiryToCustomer() {
  const { productName, productId } = useProductParams();
  const { inquiryId } = useParams();
  const convertedInquiryId = parseInt(inquiryId || "0");
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");

  const { data: product } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const inquiryApiUri = "/inquiry_to_customers";
  const { data: inquiry, isFetching } = useQuery(queryKeys.item(inquiryApiUri, convertedInquiryId), () => fetchResource(inquiryApiUri, convertedInquiryId), {
    enabled: !!(product && convertedInquiryId),
  });

  const renderInquiryBox = () => {
    switch (inquiry.inquiryType) {
      case "appointment":
        return <InquiryToCustomerAppointmentBox inquiryToCustomer={inquiry} product={product} />;
      case "decision":
        return <InquiryToCustomerDecisionBox inquiryToCustomer={inquiry} product={product} />;
      case "link":
        return <InquiryToCustomerLinkBox inquiryToCustomer={inquiry} />;
      default:
        return <InquiryToCustomerInformationAndDocuments inquiryToCustomer={inquiry} product={product} />;
    }
  };

  if (!product || isFetching) {
    return <LegalbirdLoader centered />;
  }

  if (!inquiry || ![InquiryToCustomerStatus.STATUS_OPEN, InquiryToCustomerStatus.STATUS_OVERDUE].includes(inquiry.status)) {
    return <Navigate to={getCaseLink(product)} />;
  }

  return (
    <Container>
      {[InquiryToCustomerStatus.STATUS_OPEN, InquiryToCustomerStatus.STATUS_OVERDUE].includes(inquiry.status) && (
        <InquiryToCustomerOverviewBox inquiryToCustomer={inquiry} />
      )}
      {renderInquiryBox()}
    </Container>
  );
}
