export const buttonTextTermsOfServicePrivacyWbsStyles = {
  termsOfServiceText: {
    fontSize: "12px",
    textAlign: "center",
    marginTop: "10px",
    "& a": {
      color: "#FF3C1F",
    },
  },
};
