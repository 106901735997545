/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Grid } from "@mui/material";
import { translate } from "../../../../services/translations/translations";
import { requiredValidator } from "../../../../services/validationRules";
import React from "react";
import { getFormDefinitions } from "../../../../services/formDefinitions/formDefinitions";
import Group from "../../../Wizard/Group";

type RadioGroupConfiguredProps = {
  caseData: Record<string, any>;
  fieldPath: string;
  required?: boolean | undefined;
};
export default function RadioGroupConfigured(
  {
    caseData,
    fieldPath,
    required,
  }: RadioGroupConfiguredProps) {
    const question = translate(`${caseData.name}.${fieldPath}.question`, caseData);
  const validators = [];
  if (required) {
    validators.push(requiredValidator("Bitte wählen Sie eine der angegebenen Antworten aus"));
  }

  return (
    <Grid item xs={12}>
      <Group
        type="radio"
        name={fieldPath}
        question={question}
        options={getFormDefinitions(caseData, fieldPath)}
        validators={validators}
      />
    </Grid>
  );
}
