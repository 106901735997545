import React, { useEffect, useState } from "react";
import Container from "../../../../components/Container/Container";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import Layout from "../../../Layouts/Layout";
import { Link } from "react-router-dom";
import { getCaseLink, getProductData, getProductNameBy } from "../../../../services/productService";
import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import HintBox from "../../../../components/Box/HintBox/HintBox";
import ButtonLoading from "../../../../components/Button/ButtonLoading";
import TextContainer from "../../../../components/Container/TextContainer";
import { STATUS_COMPLETED, STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT } from "../../../../services/contractLaw/leadStatus";
import { useNavigate } from "react-router";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";

export default function SetCaseCompletedPageTenancyLaw() {
  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");
  const { data: product, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data),
  });
  const [additionalMessage, setAdditionalMessage] = useState("");
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (product && !_.includes([STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT, STATUS_COMPLETED], product.leadStatus)) {
      navigate(getCaseLink(product));
    }
  }, [product]);

  if (isLoading) {
    return (
      <Layout backgroundType={"serviceworld"}>
        <Container>
          <LegalbirdLoader centered />
        </Container>
      </Layout>
    );
  }

  const handleSubmit = async () => {
    setIsLoadingInternal(true);

    let payload: { setCaseCompleted: boolean; additionalMessage?: string } = {
      setCaseCompleted: true,
    };

    if (additionalMessage) {
      payload.additionalMessage = additionalMessage;
    }

    await updateMutation.mutateAsync({
      uri: productApiUri,
      id: product.id,
      data: payload,
    });
    setIsLoadingInternal(false);
  };

  if (product.leadStatus === STATUS_COMPLETED) {
    return (
      <Layout backgroundType={"serviceworld"}>
        <Container>
          <HintBox headline={"Fall abgeschlossen"}>
            <Typography sx={{ fontWeight: 500, marginTop: 1 }}>
              Vielen Dank für Ihre Rückmeldung. Ihr Fall ist nun abgeschlossen. Wir wünschen Ihnen alles Gute!
            </Typography>
            <Button component={Link} to={"/servicewelt"} sx={{ marginTop: 2 }}>
              Zurück zur Übersicht
            </Button>
          </HintBox>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout backgroundType={"serviceworld"}>
      <Container>
        <HintBox headline={"Fall abschließen"}>
          <Typography sx={{ fontWeight: 500 }}>
            Es kam zu einer Einigung mit dem Vermieter und der Fall kann abgeschlossen werden? Dann bestätigen Sie uns dies bitte kurz. Wir schließen den Fall
            dann gerne entsprechend ab.
          </Typography>
        </HintBox>
        <Paper>
          <TextContainer>
            <Stack alignItems={"center"} spacing={3}>
              <Typography variant={"h5"}>Möchten Sie uns noch etwas Ergänzendes mitteilen?</Typography>
              <TextField
                label={"Ihr Anliegen"}
                placeholder={"Ihre Nachricht an uns (optional)"}
                multiline
                value={additionalMessage}
                onChange={(e) => setAdditionalMessage(e.target.value.substring(0, 750))}
                rows={10}
                fullWidth
                helperText={"[" + additionalMessage.length + "/750]"}
              />
              <ButtonLoading variant={"contained"} onClick={handleSubmit} isLoading={isLoadingInternal} fullWidth>
                Fall abschließen
              </ButtonLoading>
            </Stack>
          </TextContainer>
        </Paper>
      </Container>
    </Layout>
  );
}
