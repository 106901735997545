import React, { useEffect } from "react";
import summaryStyle from "./summaryStyle";
import {Box, Paper} from "@mui/material";
import TextContainer from "../../../Container/TextContainer";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import Grid from "@mui/material/Grid";
import ContractData from "./ContractData";
import ContractualPartnerData from "./ContractualPartnerData";
import ContractDescription from "./ContractDescription";
import InsuranceData from "./InsuranceData";
import { useLocation } from "react-router-dom";
import SummaryContractDetails from "./SummaryContractDetails";
import DebtCollectionAgencyData from "./DebtCollectionAgencyData";
import { getCaseLink } from "../../../../services/productService";
import { useForm } from "../../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";
import EmailVerificationModal from "../../../FormInfoModal/EmailVerificationModal";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import PersonalAffection from "./PersonalAffection";

type SummaryProps = {
  product: AbstractCase;
  submitProps: Record<string, any>;
};

const Summary = ({ product, submitProps }: SummaryProps) => {
  const location = useLocation();
  const stateField = location.state?.field;
  const { values, handleSubmit } = useForm();

  useEffect(() => {
    const el = document.getElementById(stateField);
    el && el.scrollIntoView();
  }, [stateField]);

  const basePath = getCaseLink(product) + "/antrag";

  return (
    <Paper elevation={4}>
      <TextContainer>
        <Typography variant={"h4"} sx={summaryStyle.headline}>
          Ihre Angaben
        </Typography>
        <Grid container>
          <Box id={"contractData"} sx={summaryStyle.scrollToAnchor} />
          <ContractData caseData={product} formUrl={basePath + "/vertrag"} />
          {product.caseCategory !== "advisory" && (
            <>
              <SummaryContractDetails caseData={product} formUrl={basePath + "/vertragsdetails"} />
              {product.name !== "dataLeakContract" && (
                <>
                  <Box id={"contractualPartnerData"} sx={summaryStyle.scrollToAnchor} />
                  <ContractualPartnerData title={"Angaben zum Vertragspartner"} formPath={basePath + "/vertragspartner"} formData={values} />
                </>
              )}
            </>
          )}
          {product.caseCategory === "defendClaim" && product.defenseProcessStatus === "debtCollection" && (
            <>
              <Box id={"debtCollectionAgencyData"} sx={summaryStyle.scrollToAnchor} />
              <DebtCollectionAgencyData title={"Angaben zum Inkassobüro"} formPath={basePath + "/inkasso"} formData={values} />
            </>
          )}
          <Box id={"contractDescription"} sx={summaryStyle.scrollToAnchor} />
          {product.name !== "dataLeakContract" && (
            <ContractDescription title={"Beschreibung der Situation"} formPath={basePath + "/beschreibung"} formData={values} />
          )}
          {product.name === "dataLeakContract" && (
            <PersonalAffection title={"Persönliche Betroffenheit"} formPath={basePath + "/betroffenheit"} product={product} />
          )}
          <Box id={"insuranceData"} sx={summaryStyle.scrollToAnchor} />
          {product.insurance.specialTermsTag !== "advocardFlex" && (
            <InsuranceData title={"Versicherung"} formPath={basePath + "/versicherung"} formData={values} product={product} />
          )}
        </Grid>
        <form onSubmit={handleSubmit}>
          <FormSubmit {...submitProps} hideBackButton={false} backLink={basePath + "/vertrag"} backText={"Alles bearbeiten"} nextText={"Angaben speichern"} />
        </form>
      </TextContainer>
      <EmailVerificationModal />
    </Paper>
  );
};

export default Summary;
