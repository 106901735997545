import { Properties } from "csstype";

const scrollToAnchor: Properties<string | number> = {
  position: "relative",
  top: "-77px",
};

const modalContainer = {
  width: "23rem",
  margin: "25vh auto",
};

const pageContainerWithNavigation = {
  paddingTop: 77,
};

const containerStyles = {
  paddingTop: "2rem",
  paddingRight: "1rem",
  paddingBottom: "2rem",
  paddingLeft: "1rem",
  margin: "0 auto",
  width: "95%",
};

const leftIcon = {
  marginRight: "1rem",
};

const rightIcon = {
  marginLeft: "1rem",
};

const divider = {
  height: "1rem",
};

const submit = {
  paddingTop: "1rem",
};

export { leftIcon, rightIcon, scrollToAnchor, modalContainer, pageContainerWithNavigation, containerStyles, divider, submit };
