import React from "react";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import CaseDetailsItem from "../../../Common/CaseDetailsItem/CaseDetailsItem";

const EventSpecificCaseDetails = ({ contractLawCase }: any) => {
  return (
    <Grid container>
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"reasonForDispute"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"eventType"} />
      {contractLawCase.eventType === "public" &&
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractNumber"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"numberOfTickets"} />
        </>
      }
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"eventTitle"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"eventCity"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"eventDate"} fieldType={"date"} />
    </Grid>
  );
};

EventSpecificCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

export default EventSpecificCaseDetails;
