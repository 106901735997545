import React from "react";
import Grid from "@mui/material/Grid";
import _includes from "lodash/includes";
import Typography from "@mui/material/Typography";
import SummaryDetailsItem from "../../Common/SummaryDetailsItem/SummaryDetailsItem";
import { scrollToAnchor } from "../../../../theme/commonStyle";
import { Box } from "@mui/material";
import { Person } from "../../../../types/Entities/Person";

interface ContractDataProps {
  caseData: any;
  formUrl: string;
}

const ContractData = ({ caseData, formUrl }: ContractDataProps) => {
  const caseCategory = caseData.caseCategory;
  const anchorId = "contractData";

  return (
    <>
      <Box id={anchorId} sx={scrollToAnchor} />
      <Grid item xs={12} sx={{ marginTop: "2rem", borderBottom: "1px solid #333", "& h5": { lineHeight: "1rem" } }}>
        <Typography variant={"h5"}>Ihr Vertrag</Typography>
      </Grid>
      <SummaryDetailsItem fieldPath={"customer.person.gender"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
      <SummaryDetailsItem fieldPath={"customer.person.givenName"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
      <SummaryDetailsItem fieldPath={"customer.person.familyName"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />

      {caseCategory !== "advisory" && caseData.clientIsCustomer === true && (
        <>
          <SummaryDetailsItem fieldPath={"customer.person.residenceAddress.streetAddress"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
          <SummaryDetailsItem fieldPath={"customer.person.residenceAddress.postalCode"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
          <SummaryDetailsItem fieldPath={"customer.person.residenceAddress.addressLocality"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
        </>
      )}
      <SummaryDetailsItem fieldPath={"customer.telephone"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />

      {caseCategory !== "advisory" && caseData.clientIsCustomer !== true && (
        <>
          <SummaryDetailsItem fieldPath={"client.gender"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
          <SummaryDetailsItem fieldPath={"client.givenName"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
          <SummaryDetailsItem fieldPath={"client.familyName"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
          <SummaryDetailsItem fieldPath={"client.residenceAddress.streetAddress"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
          <SummaryDetailsItem fieldPath={"client.residenceAddress.postalCode"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
          <SummaryDetailsItem fieldPath={"client.residenceAddress.addressLocality"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
          {caseData.clientWasMinorAtContractDate && (
            <>
              <SummaryDetailsItem fieldPath={"clientLegalRepresentative.gender"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
              <SummaryDetailsItem fieldPath={"clientLegalRepresentative.givenName"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
              <SummaryDetailsItem fieldPath={"clientLegalRepresentative.familyName"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
              <SummaryDetailsItem
                fieldPath={"clientLegalRepresentative.residenceAddress.streetAddress"}
                formUrl={formUrl}
                caseData={caseData}
                anchorId={anchorId}
              />
              <SummaryDetailsItem
                fieldPath={"clientLegalRepresentative.residenceAddress.postalCode"}
                formUrl={formUrl}
                caseData={caseData}
                anchorId={anchorId}
              />
              <SummaryDetailsItem
                fieldPath={"clientLegalRepresentative.residenceAddress.addressLocality"}
                formUrl={formUrl}
                caseData={caseData}
                anchorId={anchorId}
              />

              {caseData.clientLegalRepresentativeAmount === 2 && (
                <>
                  <SummaryDetailsItem fieldPath={"secondClientLegalRepresentative.gender"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
                  <SummaryDetailsItem fieldPath={"secondClientLegalRepresentative.givenName"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
                  <SummaryDetailsItem fieldPath={"secondClientLegalRepresentative.familyName"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
                  <SummaryDetailsItem
                    fieldPath={"secondClientLegalRepresentative.residenceAddress.streetAddress"}
                    formUrl={formUrl}
                    caseData={caseData}
                    anchorId={anchorId}
                  />
                  <SummaryDetailsItem
                    fieldPath={"secondClientLegalRepresentative.residenceAddress.postalCode"}
                    formUrl={formUrl}
                    caseData={caseData}
                    anchorId={anchorId}
                  />
                  <SummaryDetailsItem
                    fieldPath={"secondClientLegalRepresentative.residenceAddress.addressLocality"}
                    formUrl={formUrl}
                    caseData={caseData}
                    anchorId={anchorId}
                  />
                  <SummaryDetailsItem fieldPath={"clientLegalRepresentativesAuthorizationType"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
                </>
              )}
            </>
          )}
        </>
      )}
      {caseCategory !== "advisory" && (
        <SummaryDetailsItem fieldPath={"contractDate"} formUrl={formUrl} caseData={caseData} fieldType={"date"} anchorId={anchorId} />
      )}

      {caseData.additionalClients?.map((client: Person, index: number) => (
        <React.Fragment key={index}>
          <SummaryDetailsItem
            fieldPath={`additionalClients[${index}].gender`}
            formUrl={formUrl}
            caseData={caseData}
            labelPostfix={`${index + 2}. Vertragsabschließender`}
          />
          <SummaryDetailsItem
            fieldPath={`additionalClients[${index}].givenName`}
            formUrl={formUrl}
            caseData={caseData}
            labelPostfix={`${index + 2}. Vertragsabschließender`}
          />
          <SummaryDetailsItem
            fieldPath={`additionalClients[${index}].familyName`}
            formUrl={formUrl}
            caseData={caseData}
            labelPostfix={`${index + 2}. Vertragsabschließender`}
          />
        </React.Fragment>
      ))}
      {caseCategory === "enforceClaim" && (
        <>
          {caseData.name !== "dataLeakContract" && (
            <>
              <SummaryDetailsItem fieldPath={"objectiveClient"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
              <SummaryDetailsItem fieldPath={"clientHasAlreadyClaimed"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
            </>
          )}

          {caseData.clientHasAlreadyClaimed === "yes" && (
            <SummaryDetailsItem fieldPath={"claimedDateClient"} formUrl={formUrl} caseData={caseData} fieldType={"date"} anchorId={anchorId} />
          )}

          {caseData.name !== "dataLeakContract" && (
            <SummaryDetailsItem fieldPath={"subjectOfDispute"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
          )}

          {caseData.subjectOfDispute === "money" ||
            (caseData.name !== "dataLeakContract" && (
              <>
                <SummaryDetailsItem fieldPath={"claimedAmountClient"} formUrl={formUrl} caseData={caseData} fieldType={"money"} anchorId={anchorId} />
                <SummaryDetailsItem fieldPath={"clientBankData.iban"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
              </>
            ))}
        </>
      )}

      {caseCategory === "defendClaim" && (
        <>
          <SummaryDetailsItem fieldPath={"defenseProcessStatus"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />

          {caseData.defenseProcessStatus === "paymentOrder" && (
            <SummaryDetailsItem fieldPath={"paymentOrderDate"} formUrl={formUrl} caseData={caseData} fieldType={"date"} anchorId={anchorId} />
          )}
          {caseData.defenseProcessStatus === "enforcementOrder" && (
            <SummaryDetailsItem fieldPath={"enforcementOrderDate"} formUrl={formUrl} caseData={caseData} fieldType={"date"} anchorId={anchorId} />
          )}
          {caseData.defenseProcessStatus === "lawsuit" && (
            <SummaryDetailsItem fieldPath={"statementOfClaimFiledDate"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
          )}

          {_includes(["paymentOrder", "reminder", "lawsuit"], caseData.defenseProcessStatus) && (
            <SummaryDetailsItem fieldPath={"contractualPartnerHasLawyer"} formUrl={formUrl} caseData={caseData} anchorId={anchorId} />
          )}
          {caseData.contractualPartnerHasLawyer === true && (
            <>
              <SummaryDetailsItem fieldPath={"opponentLawyer.gender"} formUrl={formUrl} caseData={caseData} labelPostfix={"Anwalt"} anchorId={anchorId} />
              <SummaryDetailsItem fieldPath={"opponentLawyer.givenName"} formUrl={formUrl} caseData={caseData} labelPostfix={"Anwalt"} anchorId={anchorId} />
              <SummaryDetailsItem fieldPath={"opponentLawyer.familyName"} formUrl={formUrl} caseData={caseData} labelPostfix={"Anwalt"} anchorId={anchorId} />
              <SummaryDetailsItem fieldPath={"opponentLawFirm.name"} formUrl={formUrl} caseData={caseData} labelPostfix={"Kanzlei"} anchorId={anchorId} />
              <SummaryDetailsItem
                fieldPath={"opponentLawFirm.address.streetAddress"}
                formUrl={formUrl}
                caseData={caseData}
                labelPostfix={"Kanzlei"}
                anchorId={anchorId}
              />
              <SummaryDetailsItem
                fieldPath={"opponentLawFirm.address.postalCode"}
                formUrl={formUrl}
                caseData={caseData}
                labelPostfix={"Kanzlei"}
                anchorId={anchorId}
              />
              <SummaryDetailsItem
                fieldPath={"opponentLawFirm.address.addressLocality"}
                formUrl={formUrl}
                caseData={caseData}
                labelPostfix={"Kanzlei"}
                anchorId={anchorId}
              />
              <SummaryDetailsItem fieldPath={"opponentLawFirm.email"} formUrl={formUrl} caseData={caseData} labelPostfix={"Kanzlei"} anchorId={anchorId} />
              <SummaryDetailsItem fieldPath={"opponentLawFirm.telephone"} formUrl={formUrl} caseData={caseData} labelPostfix={"Kanzlei"} anchorId={anchorId} />
            </>
          )}
          <SummaryDetailsItem fieldPath={"subjectOfDispute"} formUrl={formUrl} caseData={caseData} labelPostfix={"Gegenseite"} anchorId={anchorId} />

          {caseData.subjectOfDispute === "money" && (
            <>
              <SummaryDetailsItem
                fieldPath={"claimedAmountClient"}
                formUrl={formUrl}
                caseData={caseData}
                fieldType={"money"}
                labelPostfix={"Gegenseite"}
                anchorId={anchorId}
              />
              {_includes(["reminder", "debtCollection"], caseData.defenseProcessStatus) && (
                <SummaryDetailsItem fieldPath={"overdueFees"} formUrl={formUrl} caseData={caseData} fieldType={"money"} anchorId={anchorId} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ContractData;
