import React from "react";
import DocumentUploadForm from "../../../components/DocumentUpload/DocumentUploadForm/DocumentUploadForm";
import Layout from "../../Layouts/Layout";
import { getProductData, getProductNameBy } from "../../../services/productService";
import LegalbirdLoader from "../../../components/ContentLoader/LegalbirdLoader";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../hooks/useProductParams";

export default function DocumentUploadPage() {
  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");
  const { data: product, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));

  return <Layout>{isLoading ? <LegalbirdLoader centered /> : <DocumentUploadForm product={product} />}</Layout>;
}
