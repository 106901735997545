const documentsStyle ={
  placeholderText: {
    fontSize: "1rem",
    marginBottom: "1rem",
  },
  gridSpacing: {
    marginBottom: "1rem",
  },
};

export default documentsStyle;
