import React from "react";
import { Grid } from "@mui/material";
import CaseDetailsItem from "../../../Common/CaseDetailsItem/CaseDetailsItem";

const DataLeakSpecificCaseDetails = ({ contractLawCase }: any) => {
  return (
    <Grid container>
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractNumber"} />
    </Grid>
  );
};
export default DataLeakSpecificCaseDetails;
