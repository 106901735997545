import React, { ReactNode } from "react";
import { layoutWbsStyles } from "./layoutWbsStyles";
import Box from "@mui/material/Box";
import Footer from "../../../../../../components/Footer/Footer";
import ReleaseInfo from "../../../../../../components/ReleaseInfo/ReleaseInfo";
import ScrollTopButton from "../../../../../../components/Button/ScrollTopButton";

export interface LayoutWbsProps {
  children?: ReactNode;
  withScrollToTopButton?: boolean;
}

export default function LayoutWbs({ children, withScrollToTopButton = true }: LayoutWbsProps) {
  return (
    <>
      <ReleaseInfo />
      <Box sx={layoutWbsStyles.pageContainer}>
        <Box sx={layoutWbsStyles.content}>
          <Box>{children}</Box>
          <Footer />
        </Box>
      </Box>
      {withScrollToTopButton && <ScrollTopButton />}
    </>
  );
}
