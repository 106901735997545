import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconGeldUndLeistungEinfordern = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 1077.29 512.08">
      <g style={{ fill: getIconColor(active, theme) }} transform="translate(0 -0.24)">
        <path d="M473.5,259.4a37.14,37.14,0,0,0-15.5,3.5,38.05,38.05,0,0,0-47.5-10.6l-.5.3-15.7,9.3a37.91,37.91,0,0,0-46.6-9.6l-.6.4-60.2,36.9c-13.6-4-28.7-8.4-43.3-12.6-15.4-4.4-30.2-8.4-42-11.4-5.9-1.5-11.1-2.7-15.4-3.6-2.1-.4-4-.8-5.7-1a25.58,25.58,0,0,0-4.7-.4,20.68,20.68,0,0,0-3.5.3c-4.4.6-10.7,1.9-18.2,3.7-15.2,3.6-34.6,8.8-45.3,11.7V258.2a23,23,0,0,0-22.9-22.9h-63A22.52,22.52,0,0,0,0,257.9V435.2a23,23,0,0,0,22.9,22.9H85.8a23,23,0,0,0,22.9-22.9V421.4c13.6,3,43.7,9.5,75.5,16,21.5,4.4,43.7,8.8,62.1,12.2,9.2,1.7,17.4,3.1,24.1,4a116.21,116.21,0,0,0,15.7,1.6,35.06,35.06,0,0,0,8.9-1.4,121.47,121.47,0,0,0,19.6-7.9c25.9-12.6,63-36.2,98-59.6s67.7-46.4,83.7-57.4c10-6.9,15.7-18.6,15.7-31A38.45,38.45,0,0,0,473.5,259.4ZM91.5,435.2a5.76,5.76,0,0,1-5.7,5.7H22.9a5.76,5.76,0,0,1-5.7-5.7V257.9a5.76,5.76,0,0,1,5.7-5.7H85.8a5.76,5.76,0,0,1,5.7,5.7ZM486.6,314.7c-19.7,13.6-63.8,44.7-106.5,72.5-21.3,13.9-42.3,26.8-59.5,36.3-8.6,4.7-16.3,8.5-22.4,11.1a64.66,64.66,0,0,1-7.9,2.8,18.79,18.79,0,0,1-4.3.8,130.49,130.49,0,0,1-13.2-1.4c-22.5-3.3-64.7-11.7-101.1-19.3-18.2-3.8-34.9-7.4-47.1-10-7.1-1.5-12.6-2.7-16-3.5V293.8c1.7-.5,3.9-1.1,6.5-1.8,9-2.5,22.8-6.1,35.2-9.2,6.2-1.5,12-2.9,16.7-3.9,2.3-.5,4.3-.9,5.9-1.1a15.31,15.31,0,0,1,2.7-.3s.9.1,2.2.2c4.7.7,14.4,3,26.3,6.1,35.6,9.3,91.8,26.1,114.5,33,7.9,2.4,15,5.9,19.6,10.3a24.45,24.45,0,0,1,5.3,7,19.13,19.13,0,0,1,1.9,8.6A24.24,24.24,0,0,1,321.3,367l-22.1-2.2-75.6-9.7a8.57,8.57,0,1,0-2.2,17l75.8,9.7,23.5,2.3h.4a41.59,41.59,0,0,0,41.5-41.5,37.46,37.46,0,0,0-3.5-15.9A43.34,43.34,0,0,0,344,309.8a75.67,75.67,0,0,0-20.4-9.4c-4-1.2-9-2.7-14.7-4.5l46.6-28.5a20.79,20.79,0,0,1,23.7,3.3l-23,13.6a8.61,8.61,0,1,0,8.8,14.8l53.4-31.7a20.82,20.82,0,0,1,9.4-2.4,21.33,21.33,0,0,1,15.1,6.4l-62.2,36.9a8.61,8.61,0,1,0,8.8,14.8l67.2-39.9a14.62,14.62,0,0,0,2-.5,8.84,8.84,0,0,0,3.4-2.6l2.1-1.2a20.82,20.82,0,0,1,9.4-2.4,21.57,21.57,0,0,1,15.1,6.2,21.15,21.15,0,0,1,6.2,15.1C494.8,305.1,491.5,311.3,486.6,314.7Z" />
        <path d="M314.1,183.9a59.65,59.65,0,0,1-30.2,8.2,60.65,60.65,0,0,1-42.8-17.7,59.9,59.9,0,0,1-11.4-16h65.1a8.6,8.6,0,0,0,0-17.2H224.2a58,58,0,0,1,0-19.2h70.6a8.6,8.6,0,0,0,0-17.2H229.7a60.11,60.11,0,0,1,54.2-33.7,59.65,59.65,0,0,1,30.2,8.2,8.56,8.56,0,0,0,8.6-14.8,77.35,77.35,0,0,0-111.6,40.4H200.3a8.6,8.6,0,0,0,0,17.2h6.6a76.1,76.1,0,0,0-.7,9.6,76.1,76.1,0,0,0,.7,9.6h-6.6a8.6,8.6,0,0,0,0,17.2H211a77.35,77.35,0,0,0,111.6,40.4,8.53,8.53,0,0,0,3.1-11.7A8.29,8.29,0,0,0,314.1,183.9Z" />
        <path d="M721.31,370.24a10,10,0,1,0,10,10A10,10,0,0,0,721.31,370.24Z" />
        <path d="M1015.41,190.24l58.9-58.9a10,10,0,0,0-4.2-16.6l-143.3-44.3a100.07,100.07,0,0,0-188.3-7.6l-166.2,51.9a10,10,0,0,0-6.7,7.2,10.35,10.35,0,0,0,2.6,9.5l60.5,60.5-38.1,63.6a9.69,9.69,0,0,0-.8,8.6,10,10,0,0,0,6.2,6l55.3,18.3v153.8a10.07,10.07,0,0,0,6.5,9.4c33.8,12.7,159.5,59.8,160.2,60.1a10,10,0,0,0,6.4.1h.1l180-60a10,10,0,0,0,6.8-9.5v-158l59-18.5a10,10,0,0,0,6.7-7.2,10.35,10.35,0,0,0-2.6-9.5Zm-84.1-90c0-2.5-.1-5.1-.3-7.6l117.5,36.3-49.9,49.9-81.5-27.2A100.4,100.4,0,0,0,931.31,100.24Zm-175.7-26a80.17,80.17,0,1,1-4.3,26A81.47,81.47,0,0,1,755.61,74.24Zm-161.5,54.6,138.3-43.1a99.1,99.1,0,0,0,10,60.3L644,178.84Zm20,126.1,31.6-52.7,160.6,53.5-31.6,52.7C757.51,302.74,632.41,261,614.11,254.94Zm197.2,232.9-140-52.5V295.14c110.1,36.5,104.8,35.4,107.8,35.4a10.15,10.15,0,0,0,8.6-4.9l23.6-39.3v201.5Zm10-248.1-148.4-49.5,80.8-26.9a100,100,0,0,0,150.7,5.1l65.3,21.8Zm170,195.3-160,53.3V278.74l35.5,44.3a10.13,10.13,0,0,0,10.8,3.3l113.7-35.7Zm-113.2-129.8-40-50,160.5-53.5,50,50Z" />
        <path d="M784.81,392.44l-22.2-8.3a10,10,0,1,0-7,18.8l22.2,8.3a10,10,0,0,0,7-18.8Z" />
        <path d="M784.81,435.14l-60-22.5a10,10,0,1,0-7,18.8l60,22.5a10.73,10.73,0,0,0,3.5.6,10,10,0,0,0,3.5-19.4Z" />
        <path d="M888.41,63.14a10,10,0,0,0-14.1,0l-53,53-12.9-12.9a10,10,0,1,0-14.1,14.1l20,20a10,10,0,0,0,14.1,0l60-60A10.08,10.08,0,0,0,888.41,63.14Z" />
      </g>
    </SvgIcon>
  );
};

export default IconGeldUndLeistungEinfordern;
