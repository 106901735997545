import Grid from "@mui/material/Grid";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import MenuItem from "@mui/material/MenuItem";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import EmailCheck from "../../../EmailCheck/EmailCheck";
import ButtonLoading from "../../../Button/ButtonLoading";
import ButtonTextTermsOfServicePrivacy from "../../Common/ButtonTextTermsOfServicePrivacy/ButtonTextTermsOfServicePrivacy";
import React from "react";
import { useForm } from "../../../../provider/Form/FormProvider";
import { emailDefault, isPhoneNumberDefault, minStringLength, requiredValidator } from "../../../../services/validationRules";

type FamilyLawCheckinFormProps = {
  isLoading: boolean;
  emailErrorText: string;
  setEmailErrorText: (emailErrorText: string) => void;
};

export default function FamilyLawCheckinForm({ isLoading, emailErrorText, setEmailErrorText }: FamilyLawCheckinFormProps) {
  const { handleSubmit } = useForm();
  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems={"baseline"} justifyContent={"center"}>
        <Grid item xs={12} md={4} sx={{ paddingRight: { md: "3rem" } }}>
          <ValidatorSelectField label="Anrede" name={`gender`} validators={[requiredValidator("Bitte geben Sie Ihre Anrede an.")]}>
            <MenuItem value={"female"}>Frau</MenuItem>
            <MenuItem value={"male"}>Herr</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ paddingRight: { sm: "3rem" } }}>
          <ValidatorTextField
            name={`givenName`}
            label="Vorname"
            validators={[
              requiredValidator("Bitte geben Sie den Vornamen an"),
              { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ValidatorTextField
            name={`familyName`}
            label="Nachname"
            validators={[
              requiredValidator("Bitte geben Sie den Nachnamen an"),
              { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ paddingRight: { sm: "3rem" } }}>
          <EmailCheck
            name="email"
            label="Ihre E-Mail-Adresse"
            autoComplete="email"
            emailErrorText={emailErrorText}
            setEmailErrorText={setEmailErrorText}
            validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
            redirectProps={null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ValidatorTextField
            name="telephone"
            label="Ihre Telefonnummer"
            autoComplete="tel"
            validators={[requiredValidator("Bitte geben Sie Ihre Telefonnummer an"), ...isPhoneNumberDefault]}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ paddingTop: "1rem" }}>
          <ButtonLoading variant="contained" color="primary" sx={{ marginTop: { md: "25px" } }} type="submit" fullWidth isLoading={isLoading}>
            jetzt kostenlos starten
          </ButtonLoading>
          <ButtonTextTermsOfServicePrivacy />
        </Grid>
      </Grid>
    </form>
  );
}
