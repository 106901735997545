/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";

type DoAndDontProps = {
  doText: string;
  doHeadline: string;
  dontHeadline: string;
  dontText: string;
};

export default function DoAndDont({ doHeadline, doText, dontHeadline, dontText}: DoAndDontProps) {
  return (
    <Stack spacing={2}>
      <Typography variant={"h6"}>
        DO: {doHeadline}!
      </Typography>
      <Typography>{doText}</Typography>
      <Typography variant={"h6"}>
        DON'T: {dontHeadline}!
      </Typography>
      <Typography>{dontText}</Typography>
    </Stack>
  );
};
