import React from "react";
import * as PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import contractLawDetailsStyles from "./contractLawDetailsStyles";
import ContractSpecificDetailsSection from "./ContractSpecificDetailsSection";
import _includes from "lodash/includes";
import Typography from "@mui/material/Typography";
import CaseDetailsItem from "../../Common/CaseDetailsItem/CaseDetailsItem";

const ExpandedGeneralContractLawCaseDetails = ({ contractLawCase }: any) => {
  return (
    <>
      {contractLawCase.caseCategory === "enforceClaim" && (
        <Grid container>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"objectiveClient"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"clientHasAlreadyClaimed"} />

          {contractLawCase.clientHasAlreadyClaimed && <CaseDetailsItem caseData={contractLawCase} fieldPath={"claimedDateClient"} fieldType={"date"} />}
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"subjectOfDispute"} />
          {contractLawCase.subjectOfDispute === "money" && (
            <>
              <CaseDetailsItem caseData={contractLawCase} fieldPath={"claimedAmountClient"} fieldType={"money"} />
              <CaseDetailsItem caseData={contractLawCase} fieldPath={"clientBankData.iban"} />
            </>
          )}
        </Grid>
      )}
      {contractLawCase.caseCategory === "defendClaim" && (
        <>
          <Grid container>
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"defenseProcessStatus"} />
            {contractLawCase.defenseProcessStatus === "paymentOrder" && (
              <CaseDetailsItem caseData={contractLawCase} fieldPath={"paymentOrderDate"} fieldType={"date"} />
            )}
            {contractLawCase.defenseProcessStatus === "enforcementOrder" && (
              <CaseDetailsItem caseData={contractLawCase} fieldPath={"enforcementOrderDate"} fieldType={"date"} />
            )}
            {contractLawCase.defenseProcessStatus === "lawsuit" && (
              <CaseDetailsItem caseData={contractLawCase} fieldPath={"statementOfClaimFiledDate"} fieldType={"date"} />
            )}
            {_includes(["paymentOrder", "enforcementOrder", "reminder", "lawsuit"], contractLawCase.defenseProcessStatus) && (
              <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerHasLawyer"} />
            )}
            {contractLawCase.contractualPartnerHasLawyer && (
              <>
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"opponentLawyer.gender"} labelPostfix={"Anwalt"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"opponentLawyer.givenName"} labelPostfix={"Anwalt"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"opponentLawyer.familyName"} labelPostfix={"Anwalt"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"opponentLawFirm.name"} labelPostfix={"Kanzlei"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"opponentLawFirm.address.streetAddress"} labelPostfix={"Kanzlei"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"opponentLawFirm.address.postalCode"} labelPostfix={"Kanzlei"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"opponentLawFirm.address.addressLocality"} labelPostfix={"Kanzlei"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"opponentLawFirm.email"} labelPostfix={"Kanzlei"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"opponentLawFirm.telephone"} labelPostfix={"Kanzlei"} />
              </>
            )}
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"subjectOfDispute"} />
            {contractLawCase.subjectOfDispute === "money" && (
              <CaseDetailsItem caseData={contractLawCase} fieldPath={"claimedAmountClient"} fieldType={"money"} />
            )}
          </Grid>
        </>
      )}

      <ContractSpecificDetailsSection contractLawCase={contractLawCase} />

      {contractLawCase.caseCategory !== "advisory" && (
        <>
          <Typography variant={"h5"} sx={contractLawDetailsStyles.subheader}>
            Angaben zum Vertragspartner
          </Typography>
          <Box sx={contractLawDetailsStyles.divider} />
          <Grid container>
            {contractLawCase.contractualPartnerType === "contractualPartnerLegalEntity" && (
              <>
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerLegalEntity.legalName"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerLegalEntity.address.streetAddress"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerLegalEntity.address.postalCode"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerLegalEntity.address.addressLocality"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerLegalEntity.address.postOfficeBoxNumber"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerLegalEntity.address.postOfficeBoxPostalCode"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerLegalEntity.address.postOfficeBoxAddressLocality"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerLegalEntity.email"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerLegalEntity.telephone"} />
              </>
            )}
            {contractLawCase.contractualPartnerType === "contractualPartnerPerson" && (
              <>
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerPerson.gender"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerPerson.givenName"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerPerson.familyName"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerPerson.residenceAddress.streetAddress"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerPerson.residenceAddress.postalCode"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerPerson.residenceAddress.addressLocality"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerPerson.email"} />
                <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerPerson.telephone"} />
              </>
            )}
          </Grid>
        </>
      )}
      {contractLawCase.caseCategory === "defendClaim" && contractLawCase.defenseProcessStatus === "debtCollection" && (
        <>
          <Typography variant={"h6"} sx={contractLawDetailsStyles.subheader}>
            Angaben zum Inkassobüro
          </Typography>
          <Box sx={contractLawDetailsStyles.divider} />
          <Grid container>
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"debtCollectionAgency.name"} />
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"debtCollectionAgencyReference"} />
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"debtCollectionAgency.address.streetAddress"} />
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"debtCollectionAgency.address.postalCode"} />
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"debtCollectionAgency.address.addressLocality"} />
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"debtCollectionAgency.email"} />
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"debtCollectionAgency.telephone"} />
          </Grid>
        </>
      )}
      <Typography variant={"h6"} sx={contractLawDetailsStyles.subheader}>
        Beschreibung der Situation
      </Typography>
      <Box sx={contractLawDetailsStyles.divider} />
      <Grid container>
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"personalTextMessage"} />
      </Grid>
      <Typography variant={"h6"} sx={contractLawDetailsStyles.subheader}>
        Versicherung
      </Typography>
      <Box sx={contractLawDetailsStyles.divider} />
      <Grid container>
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"insurance.insurance.name"} />
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"insurance.insurancePolicyNumber"} />
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"insurance.caseReferenceNumber"} />
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"insurance.policyHolderType"} />

        {contractLawCase.insurance.policyHolderType === "familyMember" && (
          <>
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"insurance.policyHolder.gender"} labelPostfix={"Versicherungsnehmer"} />
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"insurance.policyHolder.givenName"} labelPostfix={"Versicherungsnehmer"} />
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"insurance.policyHolder.familyName"} labelPostfix={"Versicherungsnehmer"} />
          </>
        )}
      </Grid>
    </>
  );
};

ExpandedGeneralContractLawCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

export default ExpandedGeneralContractLawCaseDetails;
