import React from "react";
import Insurance from "../../../types/Entities/Insurance";
import { PARTNER_INSURANCE } from "../../../services/insurancesList";
import { Box } from "@mui/material";

type LegalExpenseInsuranceDisclaimerProps = {
  insurance: Insurance | null | undefined;
}

export default function LegalExpenseInsuranceDisclaimer({insurance}: LegalExpenseInsuranceDisclaimerProps) {
  if (!insurance) {
    return null;
  }

  let disclaimerText;
  switch (insurance.id) {
    case PARTNER_INSURANCE.ARAG:
      disclaimerText = "Sie sind bei der ARAG rechtsschutzversichert - daher ist das behördliche Verfahren für Sie komplett kostenlos!"
      break;
    case PARTNER_INSURANCE.ADVOCARD:
      disclaimerText = "Sie sind bei der Advocard rechtsschutzversichert, daher ist das weitere Vorgehen für Sie komplett kostenlos!";
      break;
    default:
      disclaimerText = "Sie sind rechtsschutzversichert, daher ist das weitere Vorgehen für Sie komplett kostenlos!";
      break;
  }

  return <Box>{disclaimerText}</Box>;
}
