import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import legalbirdLogo from "../../assets/img/logo/legalbird-logo-white.svg";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import footerTransition from "../../assets/img/background/Footer_overlay.svg";
import moment from "moment";
import footerStyle from "./footerStyle";

type FooterProps = {
  hideProductLinks?: boolean;
};

export default function Footer({ hideProductLinks = false }: FooterProps) {
  return (
    <Box sx={footerStyle.footer}>
      <Box component={"img"} sx={footerStyle.footerTransition} src={footerTransition} alt={"footertransition"} />
      <footer>
        <Grid container sx={footerStyle.footerContainer}>
          <Grid item xs={12} md={3} lg={2}>
            <Box component={"img"} src={legalbirdLogo} alt={"Legalbird"} sx={footerStyle.logo} />
          </Grid>
          <Grid item xs={12} md={9} lg={8}>
            <Grid container spacing={4}>
              <Grid item xs={6} md={3} lg={2}>
                <Typography variant={"h6"} sx={footerStyle.categoryHeadline}>
                  Legalbird
                </Typography>
                <Stack spacing={2}>
                  <Typography component={Link} color={"inherit"} to={"/ueber-uns"}>
                    Über uns
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/kontakt"}>
                    Kontakt
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/jobs"}>
                    Karriere
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/lp/partneranwalt-werden"}>
                    Partneranwalt werden
                  </Typography>
                </Stack>
              </Grid>
              {!hideProductLinks && (
                <>
                  <Grid item xs={6} md={3} lg={2}>
                    <Typography variant={"h6"} sx={footerStyle.categoryHeadline}>
                      Rechtsbereiche
                    </Typography>
                    <Stack spacing={2}>
                      <Typography component={Link} color={"inherit"} to={"/familienrecht"}>
                        Familienrecht
                      </Typography>
                      <Typography component={Link} color={"inherit"} to={"/arbeitsrecht"}>
                        Arbeitsrecht
                      </Typography>
                      <Typography component={Link} color={"inherit"} to={"/verkehrsrecht"}>
                        Verkehrsrecht
                      </Typography>
                      <Typography component={Link} color={"inherit"} to={"/vertragsrecht"}>
                        Vertragsrecht
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={3} lg={2}>
                    <Typography variant={"h6"} sx={footerStyle.categoryHeadline}>
                      Ratgeber
                    </Typography>
                    <Stack spacing={2}>
                      <Typography component={Link} color={"inherit"} to={"/ratgeber/familienrecht"}>
                        Familienrecht
                      </Typography>
                      <Typography component={Link} color={"inherit"} to={"/ratgeber/arbeitsrecht"}>
                        Arbeitsrecht
                      </Typography>
                      <Typography component={Link} color={"inherit"} to={"/ratgeber/verkehrsrecht"}>
                        Verkehrsrecht
                      </Typography>
                    </Stack>
                  </Grid>
                </>
              )}
              <Grid item xs={6} md={3} lg={2}>
                <Typography variant={"h6"} sx={footerStyle.categoryHeadline}>
                  Rechtliches
                </Typography>
                <Stack spacing={2}>
                  <Typography component={Link} color={"inherit"} to={"/agb"}>
                    AGB
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/datenschutz"}>
                    Datenschutz
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/impressum"}>
                    Impressum
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography color={"inherit"} sx={footerStyle.trademark}>
              © Legalbird {moment().format("YYYY")}
            </Typography>
          </Grid>
        </Grid>
      </footer>
    </Box>
  );
}
