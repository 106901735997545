const divorceHighlightBoxStyle = {
  paper: {
    textAlign: "center",
    padding: "3rem",
    border: "1px solid ",
    borderColor: "primary.main",
  },
};

export default divorceHighlightBoxStyle;
