import { styled } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";

const StyledToastContainer = styled(ToastContainer)`
  --toastify-icon-color-success: #137f7b;
  --toastify-color-progress-success: #137f7b;
  --toastify-toast-width:450px;
  text-align: center;
`;

export default StyledToastContainer;
