/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import Typography from "@mui/material/Typography";

type AreYouInterestedSectionProps = {
  jobTitle: string;
};

export default function AreYouInterestedSection({ jobTitle }: AreYouInterestedSectionProps) {

  return (
    <>
      <Typography paragraph>Du findest das alles spannend?</Typography>
      <Typography paragraph>
        Wir bieten dir mit einer Karriere als {jobTitle} in einer Legal-Tech-Kanzlei eine einzigartige Möglichkeit, Teil einer Branche zu sein, die sich schnell
        entwickelt und sich auf Innovation und Technologie konzentriert. Wenn dich ein kollaboratives Arbeitsumfeld, mehr Verantwortung, kollegiales Arbeiten
        auf Augenhöhe und Freiheit durch eine bessere Work-Life-Balance ansprechen, dann freuen wir uns über deine Nachricht!
      </Typography>
      <Typography paragraph>
        Du hast noch Fragen oder bist dir unsicher, ob “legal-tech” überhaupt zu dir passt? Scheu dich nicht, uns noch Rückfragen zu stellen. Wir haben die
        Erfahrung gemacht, dass es nichts gibt, was du nicht lernen könntest!
      </Typography>
    </>
  );
};
