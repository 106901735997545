import { fileCheck, saveMediaObject } from "../../../../services/formServiceFunctions";
import { addMessage } from "../../../../store/messages/actions";
import _head from "lodash/head";
import React, { ChangeEvent, useState } from "react";
import UploadField from "../../ProcessCostSupport/Receipts/UploadField";
import { useMutation } from "@tanstack/react-query";
import { createMediaObject } from "../../../../services/reactQuery/reactQueryMediaObjectService";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

type SingleFileUploadProps = {
  product: AbstractCase;
  fieldname: string;
  description: string;
  buttonText: string;
  fetchMediaObjects: () => void;
  electronicFileFolderPath?: string;
};

const SingleFileUpload = ({
  product,
  fieldname,
  description,
  buttonText = "Datei Hochladen",
  fetchMediaObjects,
  electronicFileFolderPath,
}: SingleFileUploadProps) => {
  const [filePath, setFilePath] = useState("");
  const createMediaObjectMutation = useMutation(createMediaObject);

  const handleChangeFile = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    const fileCheckResult = fileCheck(target);
    if (fileCheckResult.hasError) {
      addMessage({ type: "error", text: fileCheckResult.errorMsg });
      return;
    }
    const file = _head(target.files);
    setFilePath(file!.name);

    let payload: Record<string, any> = {
      file: file,
      fieldname: fieldname,
      description: description,
      caseProduct: product["@id"],
      product: product.name,
      productId: product.id,
    };

    if (electronicFileFolderPath) {
      payload.electronicFileFolderPath = electronicFileFolderPath;
    }
    await saveMediaObject(createMediaObjectMutation, payload);
    setFilePath("");
    await fetchMediaObjects();
  };

  return <UploadField fieldname={fieldname} filePath={filePath} handleChange={handleChangeFile} buttonText={buttonText} />;
};

export default SingleFileUpload;
