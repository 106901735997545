import React from "react";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import CaseDetailsItem from "../../../Common/CaseDetailsItem/CaseDetailsItem";

const MembershipSpecificCaseDetails = ({ contractLawCase }: any) => {
  return (
    <Grid container>
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"membershipType"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"reasonForDispute"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractNumber"} />
      {contractLawCase.reasonForDispute === "priceIncrease" && contractLawCase.priceIncreaseAnnouncementDate && (
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"priceIncreaseAnnouncementDate"} fieldType={"date"} />
      )}
      {(contractLawCase.reasonForDispute === "cancellationContractExtension" || contractLawCase.reasonForDispute === "cancellationRefused") && (
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"cancellationDate"} fieldType={"date"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"endOfContractDate"} fieldType={"date"} />
        </>
      )}
      {contractLawCase.reasonForDispute === "cancellationRefused" && (
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"opponentReactionToCancellation"} />
          {contractLawCase.opponentReactionToCancellation === "differentDate" && (
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"endOfContractDateOpponent"} fieldType={"date"} />
          )}
        </>
      )}

      <CaseDetailsItem caseData={contractLawCase} fieldPath={"feePaymentFrequency"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"fee"} fieldType={"money"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"numberUnpaidPeriods"} />
    </Grid>
  );
};

MembershipSpecificCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

export default MembershipSpecificCaseDetails;
