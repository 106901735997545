import React from "react";
import ServiceWorld from "../../../components/Serviceworld/ServiceWorld";
import AdvocardFlexServiceWorld from "../../../components/Serviceworld/AdvocardFlexService/ServiceWorld";
import Layout from "../../Layouts/Layout";

import { Helmet } from "react-helmet-async";
import { gaSet } from "../../../services/cookieService";
import { useCustomer } from "../../../provider/Customer/CustomerProvider";

const ServiceWorldPage = () => {
  const title = "Servicewelt | Legalbird";
  gaSet({ title: title });
  const { customer } = useCustomer();
  const isAdvocardFlexServiceWorld = (customer?.roles && customer.roles.includes("ROLE_ADVOCARD_FLEX_SERVICE")) || false;

  return (
    <Layout active={"serviceworld"} displayBackgroundImage={true} backgroundType={"serviceworld"}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isAdvocardFlexServiceWorld ? <AdvocardFlexServiceWorld /> : <ServiceWorld />}
    </Layout>
  );
};

export default ServiceWorldPage;
