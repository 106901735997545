/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Theme } from "@mui/material/styles";

const scrollTopButtonStyle = {
  button: (theme: Theme) => ({
    cursor: "pointer",
    color: "primary.main",
    position: "fixed",
    bottom: "70px",
    right: "25%",
    [theme.breakpoints.down("sm")]: {
      right: "10px",
    },
  }),
  imageCircle: (theme: Theme) => ({
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    border: `1px solid ${theme.palette.primary.main}`,
  }),
  centered: {
    margin: "auto",
    width: "24px",
    height: "24px",
  },
};

export default scrollTopButtonStyle;
