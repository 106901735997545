import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import UploadBox from "./UploadBox";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import _get from "lodash/get";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { handleDocumentUploadDescriptionSave } from "../../../services/documentUploadFormService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";
import { MediaObjectCombinationRequest } from "../../../types/Entities/MediaObject/MediaObjectCombinationRequest";
import {getProductData} from "../../../services/productService";

type MainUploadFormStepProps = {
  setCurrentStep: Function;
  product: AbstractCase;
  setIsLoading: Function;
  mediaObjectCombinationRequest: MediaObjectCombinationRequest;
};

export default function MainUploadFormStep({ mediaObjectCombinationRequest, product, setCurrentStep, setIsLoading }: MainUploadFormStepProps) {
  const [description, setDescription] = useState(_get(mediaObjectCombinationRequest, ["filesToCombine", 0, "description"], ""));
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: () => queryClient.invalidateQueries(queryKeys.item(getProductData(product.name, "apiUri"), product.id)),
  });

  if (!mediaObjectCombinationRequest) {
    return <LegalbirdLoader centered />;
  }

  const hasDocument = _get(mediaObjectCombinationRequest, ["filesToCombine", 0, "files"], []).length > 0;

  const handleNextStepClick = async () => {
    setIsLoading(true);
    await handleDocumentUploadDescriptionSave(mediaObjectCombinationRequest, 0, description, updateMutation);
    setIsLoading(false);
    setCurrentStep((currentStep: number) => currentStep + 1);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h4"} sx={{ textAlign: "center" }}>
          Datei auswählen
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
          Laden Sie hier Dokumente und Dateien hoch, die uns helfen Ihren Fall zu bearbeiten.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <UploadBox product={product} description={description} setDescription={setDescription} mediaObjectCombinationRequest={mediaObjectCombinationRequest} />
      </Grid>
      <Grid item xs={12} md={6} />
      <Grid item xs={12} md={6}>
        <Button disabled={!hasDocument || description.length < 5} fullWidth variant={"contained"} onClick={handleNextStepClick}>
          Weiter
        </Button>
      </Grid>
    </Grid>
  );
}
