import React from "react";
import { MinimalCase } from "../../../types/Entities/MinimalCase";
import { getProductData } from "../../../services/productService";
import { Stack } from "@mui/material";
import UnreadCaseMessage from "../../Serviceworld/MyCases/UnreadCaseMessage";
import Typography from "@mui/material/Typography";

type MessageCasePickerMenuItemProps = {
  minimalCase: MinimalCase;
};

export default function MessageCasePickerMenuItemContent({ minimalCase }: MessageCasePickerMenuItemProps) {
  return (
    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{width: "100%"}}>
      <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
        {getProductData(minimalCase.name, "productCategory.label") + " - " + minimalCase.reference}
      </Typography>
      <UnreadCaseMessage minimalCase={minimalCase} />
    </Stack>
  );
}
