/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React, {ReactNode, useCallback, useContext, useEffect, useRef, useState} from "react";
import _set from "lodash/set";
import _pick from "lodash/pick";
import { formValidate } from "./formFunctions";
import _pickBy from "lodash/pickBy";
import _isArray from "lodash/isArray";
import _cloneDeep from "lodash/cloneDeep";
import _isEmpty from "lodash/isEmpty";
import { FormChangeEvent, FormDataTypes } from "../../types/FormDataTypes";

const defaultFormContextValues: FormDataTypes = {
  initialValues: {},
  values: {},
  updateValue: () => {},
  touchedValues: {},
  errors: {},
  clearForm: () => {},
  handleBlur: () => {},
  handleChange: () => {},
  handleSubmit: () => {},
  registerValidators: () => {},
  setErrors: () => {},
  setError: () => {},
  isInitialized: false,
};

const FormContext = React.createContext(defaultFormContextValues);

interface FormProviderProps {
  children: ReactNode;
  initialValues: Object;
  submitAction?: Function;
  externalErrors?: Object;
}

const FormProvider = ({ children, initialValues, submitAction = () => {}, externalErrors = {} }: FormProviderProps) => {
  const [values, setValues] = useState(_cloneDeep(initialValues));
  const [touchedValues, setTouchedValues] = useState({});
  const [errors, setErrors] = useState({});
  const validators: { [fieldName: string]: any } = useRef({});
  const dependentValidationFields: { [fieldName: string]: any } = useRef({});

  const externalErrorSerialized = JSON.stringify(externalErrors);
  useEffect(() => {
    setErrors(_pickBy({ ...errors, ...externalErrors }));
  }, [externalErrorSerialized]);

  const clearForm = useCallback(() => {
    setValues(_cloneDeep(initialValues));
    setErrors({});
    setTouchedValues({});
  }, []);

  const updateValue = useCallback((name: string, value: any) => {
    setValues((prevData: any) => {
      return _set(_cloneDeep(prevData), name, value);
    });
    setTouchedValues((prevData) => {
      return _set(_cloneDeep(prevData), name, true);
    });
  }, []);

  const handleChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement> | FormChangeEvent) => {
    const { name, type } = target;
    const value = type === "checkbox" ? target.checked : target.value;

    updateValue(name, value);
  }, []);

  const validateField = useCallback(
    (name: string) => {
      let fieldNamesToValidate = [name];
      if (_isArray(dependentValidationFields.current[name])) {
        fieldNamesToValidate.push(dependentValidationFields.current[name]);
      }

      const valuesToValidate = _pick(values, fieldNamesToValidate);

      formValidate(valuesToValidate, validators.current).then((currentFieldError) => {
        setErrors(_pickBy({ ...errors, ...currentFieldError }));
      });
    },
    [values, errors]
  );

  const handleBlur = useCallback(
    ({ target }: React.FocusEvent<HTMLInputElement>) => {
      const { name } = target;
      setTouchedValues((prevData) => {
        return _set(_cloneDeep(prevData), name, true);
      });
      validateField(name);
    },
    [values, errors]
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent, options = {}) => {
      event.preventDefault();
      formValidate(values, validators.current).then((currentErrors) => {
        const pickedErrors = _pickBy(currentErrors);
        setErrors(pickedErrors);
        if (_isEmpty(pickedErrors)) {
          submitAction({ values, options });
        }
      });
    },
    [submitAction, values]
  );

  const registerValidators = useCallback((name: string, registerValidators: any, dependentFields: Array<any> = []) => {
    validators.current[name] = registerValidators;
    dependentValidationFields.current[name] = dependentFields;
  }, []);

  const setError = useCallback(
    (name: string, value: string | number | boolean) => {
      setErrors(_pickBy({ ...errors, [name]: value }));
    },
    [errors]
  );

  const data: FormDataTypes = {
    initialValues: _cloneDeep(initialValues),
    values,
    updateValue,
    touchedValues,
    errors,
    clearForm,
    handleBlur,
    handleChange,
    handleSubmit,
    registerValidators,
    setErrors,
    setError,
    isInitialized: true,
  };

  return <FormContext.Provider value={data}>{children}</FormContext.Provider>;
};

const useForm = (): FormDataTypes => {
  const formContext = useContext(FormContext);
  if (!formContext.isInitialized) {
    throw new Error("useForm can only be used inside FormProvider");
  }
  return formContext;
};

export { FormProvider, useForm };
