const documentsStyle = {
  inlineHintBox: {
    margin: "0 auto 40px auto",
    maxWidth: "700px",
    textAlign: "center",
    "& h4": {
      marginBottom: "20px",
    },
  },
  headline: {
    fontWeight: 500,
  },
};

export default documentsStyle;
