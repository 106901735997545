/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Theme } from "@mui/material";

const advantagesBoxStyle = {
  imageCircleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    maxWidth: "100px",
    maxHeight: "100px",
    padding: "36px",
    borderRadius: "50% !important",
    "& > img": {
      height: "64px",
    },
  },
  box: {
    textAlign: "center",
    padding: "1rem 2rem 2rem",
  },
  boxHeading: {
    color: "primary.main",
    fontWeight: 500,
    marginBottom: "2rem",
  },
  gridItem: (theme: Theme) => ({
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem",
    },
  }),
};

export default advantagesBoxStyle;
