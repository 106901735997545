import React, { ReactNode } from "react";
import layoutStyle from "../../../../Layouts/layoutStyle";
import Footer from "../../../../../components/Footer/Footer";
import ReleaseInfo from "../../../../../components/ReleaseInfo/ReleaseInfo";
import ScrollTopButton from "../../../../../components/Button/ScrollTopButton";
import { AppBar, Box, Grid } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import advocardLegalbirdCooperationLogo from "../../../../../assets/img/logo/advocard_legalbird_cooperation_logo.png";
import advocardLegalbirdCooperationLogoDark from "../../../../../assets/img/logo/advocard_legalbird_cooperation_logo_dark.png";
import withScroll from "../../../../../services/withScroll";
import ThemedIllustration from "../../../../../components/ThemedIconSwitch/ThemedIllustration";

type LayoutAdvocardFlexProps = {
  children: ReactNode;
  scrollPosition: number;
  withScrollToTopButton: boolean;
};

function LayoutAdvocardFlex({ children, scrollPosition, withScrollToTopButton = true }: LayoutAdvocardFlexProps) {
  const scrolledStyle = scrollPosition > 1;

  return (
    <>
      <ReleaseInfo />
      <AppBar position={"fixed"} color={scrolledStyle ? "inherit" : "transparent"} elevation={scrolledStyle ? 4 : 0}>
        <Toolbar>
          <Grid container alignItems={"center"} sx={layoutStyle.navigationContainer} columnSpacing={5}>
            <Grid item margin={2}>
              <ThemedIllustration
                lightIllustration={advocardLegalbirdCooperationLogo}
                darkIllustration={advocardLegalbirdCooperationLogoDark}
                alt={"Advocard Legalbird Kooperation"}
                sx={{ width: "200px" }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box sx={layoutStyle.pageContainer}>
        <Box sx={layoutStyle.content}>
          <Box sx={layoutStyle.childrenContainer}>{children}</Box>
          <Footer hideProductLinks={true} />
        </Box>
      </Box>
      {withScrollToTopButton && <ScrollTopButton />}
    </>
  );
}

export default withScroll(LayoutAdvocardFlex);
