import { SxProps } from "@mui/system";
import React from "react";
import { List, ListItem } from "@mui/material";
import { MuiListNativeStyledStyle } from "./MuiListNativeStyledStyle";

type MuiListNativeStyledProps = {
  sx?: SxProps;
  listItems: Array<String | React.ReactNode>;
};

export default function MuiListNativeStyled({ sx = {}, listItems }: MuiListNativeStyledProps) {
  return (
    <List sx={{ ...MuiListNativeStyledStyle.list, ...sx }} disablePadding>
      {listItems.map((item, index) => (
        <ListItem key={index} sx={MuiListNativeStyledStyle.listItem} disablePadding>
          {item}
        </ListItem>
      ))}
    </List>
  );
}
