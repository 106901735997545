import React from "react";
import { Route, Routes } from "react-router-dom";
import GuidebookDivorceCosts from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookDivorceCosts";
import GuidebookDivorceRequirements from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookDivorceRequirements";
import GuidebookDivorceImportantTopics from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookDivorceImportantTopics";
import GuidebookDivorceDuration from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookDivorceDuration";
import GuidebookDivorcePensions from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookDivorcePensions";
import GuidebookDivorceProcessCostSupport from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookDivorceProcessCostSupport";
import GuidebookDivorceAlimony from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookDivorceAlimony";
import GuidebookDivorceChildren from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookDivorceChildren";
import GuidebookDivorcePropertyScheme from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookDivorcePropertyScheme";
import GuidebookDivorceCourtHearing from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookDivorceCourtHearing";
import GuidebookHardshipDivorce from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookHardshipDivorce";
import GuidebookAgreedDivorce from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookAgreedDivorce";
import GuidebookAttorneyDuty from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookAttorneyDuty";
import GuidebookContentiousDivorce from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookContentiousDivorce";
import GuidebookDivorceThreeYearsSeparation from "../../components/GuidebookContent/GuidebookDivorceContent/GuidebookDivorceThreeYearsSeparation";
import GuidebookFamilyLawOverview from "../../containers/Pages/Divorce/GuidebookDivorceOverview/GuidebookFamilyLawOverview";

export default function GuidebookFamilyLaw() {
  return (
    <Routes>
      <Route path={`scheidungskosten`} element={<GuidebookDivorceCosts />} />
      <Route path={`scheidungsvoraussetzungen`} element={<GuidebookDivorceRequirements />} />
      <Route path={`wichtige-themen-ueberblick`} element={<GuidebookDivorceImportantTopics />} />
      <Route path={`scheidungsdauer`} element={<GuidebookDivorceDuration />} />
      <Route path={`versorgungsausgleich`} element={<GuidebookDivorcePensions />} />
      <Route path={`verfahrenskostenhilfe`} element={<GuidebookDivorceProcessCostSupport />} />
      <Route path={`unterhalt`} element={<GuidebookDivorceAlimony />} />
      <Route path={`scheidung-mit-kindern`} element={<GuidebookDivorceChildren />} />
      <Route path={`gueterstand`} element={<GuidebookDivorcePropertyScheme />} />
      <Route path={`gerichtstermin`} element={<GuidebookDivorceCourtHearing />} />
      <Route path={`haertefallscheidung`} element={<GuidebookHardshipDivorce />} />
      <Route path={`einvernehmliche-scheidung`} element={<GuidebookAgreedDivorce />} />
      <Route path={`anwaltspflicht`} element={<GuidebookAttorneyDuty />} />
      <Route path={`streitige-scheidung`} element={<GuidebookContentiousDivorce />} />
      <Route path={`scheidung-drei-jahre-trennung`} element={<GuidebookDivorceThreeYearsSeparation />} />
      <Route path={""} element={<GuidebookFamilyLawOverview />} />
    </Routes>
  );
}
