import {
  STATUS_CHECKED,
  STATUS_COMPLETED,
  STATUS_COURT_PAID,
  STATUS_CREATED,
  STATUS_DATA_COMPLETED,
  STATUS_DATE_SET,
  STATUS_DIVORCE_DONE,
  STATUS_DOCUMENTS_COMPLETE,
  STATUS_HEARING_PAID,
  STATUS_JUDICIAL_PROCESS,
  STATUS_POWER_OF_ATTORNEY_GRANTED,
  STATUS_PROCESS_PAID,
  STATUS_SUBMITTED,
} from "./leadStatus";
import { APPLICATION_STATUS_DOCUMENTS } from "./applicationStatus";
import moment from "moment";
import ApiClient from "../apiClient";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { getCaseLink, getProductData } from "../productService";
import BankDataContainer from "../../components/Serviceworld/Divorce/BankDataContainer";
import DialogModal from "../../components/DialogModal/DialogModal";
import _get from "lodash/get";
import { modalview, pageview } from "../useTracker";
import { useQueryClient } from "@tanstack/react-query";
import { APPLICATION_STATUS_CHECKING, APPLICATION_STATUS_RECEIPTS } from "../processCostSupport/applicationStatus";

const getContent = (divorce) => {
  let nextStepContent = {
    header: "",
    text: "",
  };

  const hasAlternateCostsHandling = divorce.hasProcessCostSupport || divorce.paymentType === "installment";

  switch (divorce.leadStatus) {
    case STATUS_CREATED:
      nextStepContent.header = "Angaben zu Ihrer Trennung";
      nextStepContent.text =
        "Damit wir Sie bestmöglich beraten können, müssen Sie nun kurz die Angaben zu Ihrer Trennung vervollständigen. Bei Fragen unterstützen wir Sie jederzeit!";
      break;
    case STATUS_DATA_COMPLETED:
      nextStepContent.header = "Ihre Vollmacht wird benötigt";
      nextStepContent.text = "Zum Einreichen des Antrags bei Gericht benötigen wir noch Ihre Einwilligung per Vollmacht.";
      break;
    case STATUS_POWER_OF_ATTORNEY_GRANTED:
      // Following condition is to guarantee that a customer does not see requirement for uploading missing documents
      if (divorce.applicationStatus === APPLICATION_STATUS_DOCUMENTS) {
        nextStepContent.header = "Ihre Angaben sind fast vollständig!";
        nextStepContent.text =
          "Zum Einreichen des Antrags bei Gericht " +
          (divorce.numberChildren === 0
            ? "fehlt noch die Heiratsurkunde."
            : "fehlen noch: Kopie der Heiratsurkunde | Kopie der" +
              (divorce.numberChildren === 1 ? " Geburtsurkunde Ihres Kindes." : " Geburtsurkunden Ihrer Kinder."));
        break;
      }
    // fallthrough
    case STATUS_DOCUMENTS_COMPLETE:
      if (divorce.hasProcessCostSupport) {
        switch (true) {
          case !divorce.processCostSupport:
          case divorce.processCostSupport.applicationStatus < APPLICATION_STATUS_RECEIPTS:
            nextStepContent.header = "Jetzt unkompliziert Verfahrenskostenhilfe beantragen!";
            nextStepContent.text =
              "Füllen Sie Ihren Antrag auf Verfahrenskostenhilfe aus, damit Ihre Scheidung bestenfalls komplett kostenlos eingereicht werden kann.";
            break;
          case divorce.processCostSupport.applicationStatus === APPLICATION_STATUS_RECEIPTS:
            nextStepContent.header = "Belege unvollständig";
            nextStepContent.text =
              "Vervollständigen Sie Ihren Antrag auf Verfahrenskostenhilfe, damit Ihre Scheidung bestenfalls komplett kostenlos eingereicht werden kann.";
            break;
          case divorce.processCostSupport.applicationStatus === APPLICATION_STATUS_CHECKING:
            nextStepContent.header = "Ihr Antrag wird geprüft";
            nextStepContent.text =
              "Ihr Antrag wird aktuell geprüft. Sie erhalten zeitnah Rückmeldung, ob der Antrag eingereicht werden kann, oder noch etwas angepasst werden muss.";
            break;
          case !!divorce.processCostSupportVerified:
            nextStepContent.header = "Ihr Antrag auf Verfahrenskostenhilfe ist vollständig!";
            nextStepContent.text = "Wir haben Ihren Antrag geprüft und werden ihn nun zeitnah zusammen mit Ihrem Scheidungsantrag beim Gericht einreichen.";
            break;
          default:
            break;
        }
        break;
      }
      nextStepContent.header = "Ihr Antrag ist eingegangen und wird direkt von uns geprüft!";
      nextStepContent.text = "Wir melden uns nun umgehend bei Ihnen, um den Antrag zu finalisieren und offene Fragen zu klären.";
      break;
    case STATUS_CHECKED:
      if (hasAlternateCostsHandling) {
        nextStepContent.header = "Ihre Unterlagen sind vollständig!";
        nextStepContent.text = "Wir übermitteln Ihren Scheidungsantrag und den Antrag auf Verfahrenskostenhilfe nun an das zuständige Gericht.";
      } else {
        nextStepContent.header = "Ihr Antrag wurde geprüft und kann nun eingereicht werden!";
        nextStepContent.text = "Sobald Sie die Verfahrensgebühr bezahlt haben, reichen wir den Antrag umgehend bei Gericht für Sie ein.";
      }
      break;
    case STATUS_PROCESS_PAID:
      if (!hasAlternateCostsHandling) {
        nextStepContent.header = "Vielen Dank für die Zahlung der Verfahrensgebühr - Ihr Antrag wird nun eingereicht!";
        nextStepContent.text = "Wir übermitteln Ihren Scheidungsantrag nun an das zuständige Gericht.";
      } else {
        nextStepContent.header = "Ihre Unterlagen sind vollständig!";
        nextStepContent.text = "Wir übermitteln Ihren Scheidungsantrag und den Antrag auf Verfahrenskostenhilfe nun an das zuständige Gericht.";
      }
      break;
    case STATUS_SUBMITTED:
      if (divorce.hasProcessCostSupport) {
        nextStepContent.header = "Ihr Antrag wurde bei Gericht eingereicht!";
        nextStepContent.text =
          "Sie erhalten nun vom Gericht Informationen zum weiteren Vorgehen. Nach Klärung aller Sachverhalte (z.B. Versorgungsausgleich) erhalten Sie ein Schreiben zum Gerichtstermin.";
      } else if (divorce.courtFeeInvoiceReceivedDate) {
        nextStepContent.header = "Gerichtsgebühr bezahlt?";
        nextStepContent.text =
          "Damit das Gericht Ihre Scheidung weiter bearbeitet, muss die Gerichtsgebühr gezahlt werden. Sie finden die Rechnung in Ihrer Servicewelt. Sie haben bereits bezahlt? Dann klicken Sie bitte kurz hier";
      } else {
        nextStepContent.header = "Ihr Antrag wurde bei Gericht eingereicht!";
        nextStepContent.text =
          "Sie erhalten nun vom Gericht ein Schreiben zu den Gerichtskosten. Diese müssen Sie " +
          "schnellstmöglich bezahlen, damit das Gericht Ihren Antrag prüft und Ihnen dann einen Scheidungstermin zuweist.";
      }
      break;
    case STATUS_COURT_PAID:
      nextStepContent.header = "Vielen Dank für die Zahlung der Gerichtsgebühr!";
      nextStepContent.text =
        "Sie erhalten nun vom Gericht Informationen zum weiteren Vorgehen. Nach Klärung aller Sachverhalte (z.B. Versorgungsausgleich) erhalten Sie ein Schreiben zum Gerichtstermin.";
      break;
    case STATUS_JUDICIAL_PROCESS:
      nextStepContent.header = "Gerichtliches Verfahren";
      nextStepContent.text = "Ihr Fall befindet sich im gerichtlichen Verfahren. Sobald ein Scheidungstermin vorliegt erhalten Sie hier weitere Informationen.";
      break;
    case STATUS_DATE_SET:
      switch (true) {
        case !_get(divorce, "divorceAppointment.courtDate.appointmentDateTime"):
          nextStepContent.header = "Warten auf Scheidungstermin";
          nextStepContent.text =
            "Es wird auf ein gerichtliches Ladungsschreiben für Ihren Scheidungstermin gewartet. Sobald dieses eingeht, werden Sie es hier in der Servicewelt finden können.";
          break;
        case !hasAlternateCostsHandling:
          nextStepContent.header = "Ihr Scheidungstermin steht fest!";
          nextStepContent.text =
            "Das Gericht hat Ihnen den " +
            moment(divorce.divorceAppointment.courtDate.appointmentDateTime, moment.ISO_8601, true).format("DD.MM.YYYY [um] HH:mm [Uhr]") +
            " als Scheidungstermin zugeteilt." +
            " Bitte denken Sie an die Zahlung der Terminsgebühr.";
          break;
        default:
          nextStepContent.header =
            "Ihre Scheidung findet am " +
            moment(divorce.divorceAppointment.courtDate.appointmentDateTime, moment.ISO_8601, true).format("DD.MM.YYYY [um] HH:mm [Uhr]") +
            " statt!";
          nextStepContent.text = "Bitte denken Sie daran, zum Scheidungstermin Ihren Ausweis und die Heiratsurkunde im Original mitzubringen.";
          break;
      }
      break;
    case STATUS_HEARING_PAID:
      if (!!divorce.divorceAppointment) {
        nextStepContent.header =
          "Ihre Scheidung findet am " +
          moment(divorce.divorceAppointment.courtDate.appointmentDateTime, moment.ISO_8601, true).format("DD.MM.YYYY [um] HH:mm [Uhr]") +
          " statt!";
        nextStepContent.text = hasAlternateCostsHandling
          ? "Vielen Dank für die Zahlung der Terminsgebühr! Bitte denken Sie daran, zum Scheidungstermin Ihren Ausweis und die Heiratsurkunde im Original mitzubringen."
          : "Bitte denken Sie daran, zum Scheidungstermin Ihren Ausweis und die Heiratsurkunde im Original mitzubringen.";
        break;
      }
      nextStepContent.header = "Warten auf Scheidungstermin";
      nextStepContent.text =
        "Es wird auf ein gerichtliches Ladungsschreiben für Ihren Scheidungstermin gewartet. Sobald dieses eingeht, werden Sie es hier in der Servicewelt finden können.";
      break;
    case STATUS_DIVORCE_DONE:
      if (!divorce.hasProcessCostSupport) {
        nextStepContent.header = "Ihre Scheidung wurde erfolgreich durchgeführt!";
        nextStepContent.text =
          "Sie erhalten nun von uns die Abschluss-Rechnung. Sollten Sie hierzu noch Fragen haben, melden Sie sich gerne jederzeit bei uns!";
      } else {
        nextStepContent.header = "Ihre Scheidung wurde erfolgreich durchgeführt!";
        nextStepContent.text =
          "Vielen Dank, dass wir Sie bei Ihrer Scheidung unterstützen durften. Wir wünschen Ihnen alles Gute und freuen uns, wenn wir Ihnen auch in Zukunft bei Rechtsfragen weiter helfen dürfen!";
      }
      break;
    case STATUS_COMPLETED:
      nextStepContent.header = "Ihre Scheidung wurde erfolgreich durchgeführt!";
      nextStepContent.text =
        "Vielen Dank, dass wir Sie bei Ihrer Scheidung unterstützen durften. Wir wünschen Ihnen alles Gute und freuen uns, wenn wir Ihnen auch in Zukunft bei Rechtsfragen weiter helfen dürfen!";
      break;

    default:
      break;
  }

  return nextStepContent;
};

const StatusBoxButton = ({ divorce }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const refreshDivorce = () => queryClient.invalidateQueries([getProductData(divorce.name, "apiUri"), divorce.id]);

  let target = "";
  let text = "";
  let onClick = null;

  switch (divorce.leadStatus) {
    case STATUS_CREATED:
      target = getCaseLink(divorce) + "/antrag";
      text = "Jetzt ausfüllen";
      break;
    case STATUS_DATA_COMPLETED:
      target = getCaseLink(divorce) + "/antrag/vollmacht";
      text = "Zur Vollmacht";
      break;
    case STATUS_POWER_OF_ATTORNEY_GRANTED:
      if (divorce.applicationStatus === APPLICATION_STATUS_DOCUMENTS) {
        target = getCaseLink(divorce) + "/antrag/dokumente-hochladen";
        text = "Dokumente hochladen";
        break;
      }
      return null;
    case STATUS_DOCUMENTS_COMPLETE:
      if (divorce.hasProcessCostSupport) {
        switch (true) {
          case !divorce.processCostSupport:
          case divorce.processCostSupport.applicationStatus < APPLICATION_STATUS_RECEIPTS:
            target = getCaseLink(divorce) + "/vkh-assistent/";
            text = "Jetzt beantragen";
            break;
          case divorce.processCostSupport.applicationStatus === APPLICATION_STATUS_RECEIPTS:
            target = getCaseLink(divorce) + "/vkh-assistent/";
            text = "Jetzt Antrag fertigstellen";
            break;
          default:
            return null;
        }
        break;
      }
      return null;
    case STATUS_SUBMITTED:
    case STATUS_JUDICIAL_PROCESS:
      if (!divorce.hasProcessCostSupport && divorce.courtFeeInvoiceReceivedDate) {
        text = "Ich habe gezahlt";
        onClick = async (event) => {
          event.preventDefault();
          await ApiClient.post("/divorce_court_paid?id=" + divorce.id);
          await refreshDivorce();
        };
        break;
      }
      return null;
    case STATUS_CHECKED:
    // falls through
    case STATUS_DATE_SET:
      if (divorce.hasProcessCostSupport) {
        return null;
      }

      const handleOpenBanklayer = (event) => {
        event.preventDefault();
        navigate(pathname, { state: { bankModalOpen: "statusBox" } });
        modalview("Bankdata Modal");
      };

      text = "Bankdaten anzeigen";
      onClick = handleOpenBanklayer;
      break;

    default:
      return null;
  }

  return (
    <Button component={Link} to={target} variant="contained" color="primary" fullWidth onClick={onClick}>
      {text}
    </Button>
  );
};

const getPercentage = (divorce) => {
  return (Math.max(1, divorce.leadStatus) / 11) * 10;
};

const ExtraContent = ({ divorce }) => {
  const navigate = useNavigate();
  const location = useLocation();

  switch (divorce.leadStatus) {
    case STATUS_CREATED:
    case STATUS_DATA_COMPLETED:
    case STATUS_POWER_OF_ATTORNEY_GRANTED:
    case STATUS_DOCUMENTS_COMPLETE:
    case STATUS_SUBMITTED:
    case STATUS_JUDICIAL_PROCESS:
      return null;
    case STATUS_CHECKED:
    case STATUS_DATE_SET:
      if (divorce.hasProcessCostSupport) {
        return null;
      }

      const handleCloseBanklayer = (event) => {
        event.preventDefault();
        navigate(-1);
        pageview(location.pathname);
      };

      return (
        <DialogModal open={!!(location.state && location.state.bankModalOpen === "statusBox")} onClose={handleCloseBanklayer} title={"Bankdaten"}>
          <BankDataContainer
            divorce={divorce}
            hearing={divorce.leadStatus === STATUS_DATE_SET}
            process={divorce.leadStatus === STATUS_CHECKED}
            handleClose={handleCloseBanklayer}
          />
        </DialogModal>
      );
    default:
      return null;
  }
};

export const getStatusBoxConfigDivorce = (divorce) => {
  let statusBoxConfig = {
    header: "Es ist ein Fehler aufgetreten",
    text: "",
    ctaButton: null,
    productName: "",
    percentage: 100,
    extraContent: null,
  };

  const content = getContent(divorce);

  statusBoxConfig.header = content.header;
  statusBoxConfig.text = content.text;
  statusBoxConfig.ctaButton = <StatusBoxButton divorce={divorce} />;
  statusBoxConfig.productName = getProductData(divorce.name, "label");
  statusBoxConfig.percentage = getPercentage(divorce);
  statusBoxConfig.extraContent = <ExtraContent divorce={divorce} />;

  return statusBoxConfig;
};
