import Layout from "../../../../containers/Layouts/Layout";
import Container from "../../../Container/Container";
import HintBox from "../../../Box/HintBox/HintBox";
import React from "react";
import { getCaseLink, getProductData, getProductNameBy } from "../../../../services/productService";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import useProductParams from "../../../../hooks/useProductParams";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../../services/reactQuery/reactQueryService";
import LegalbirdLoader from "../../../ContentLoader/LegalbirdLoader";
import { Typography } from "@mui/material";

const FlexServiceFeedbackConfirm = () => {
  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");
  const { data: product, isLoading: isLoadingProduct } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const { state } = useLocation();

  if (isLoadingProduct) {
    return (
      <Layout>
        <Container>
          <Container sx={{ width: 250 }}>
            <LegalbirdLoader />
          </Container>
        </Container>
      </Layout>
    );
  }

  if (state !== "sendForm") {
    return (
      <Layout>
        <Container>
          <HintBox headline={"Keine Berechtigung"}>
            Sie haben aktuell nicht die Berechtigung diese Seite aufzurufen.
            <Button component={Link} to={getCaseLink(product)} variant={"text"} color={"primary"}>
              Zurück zum Fall
            </Button>
          </HintBox>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container>
        <HintBox headline={"Vielen Dank für Ihre Rückmeldung!"}>
          {!!product.finalClaimStatusClient ? (
            <Typography>Wir haben Ihre Rückmeldung erhalten. Vielen Dank für die Nutzung unseres Services</Typography>
          ) : (
            <Typography>
              Damit wir weiter für Sie tätig werden können, müssen Sie bitte zunächst in der Servicewelt weitere Angaben zu Ihrem Fall ergänzen und uns die
              Vollmacht erteilen. Keine Sorge: Ihre bisherigen Angaben und Dokumente sind nach wie vor vorhanden. Sie können diese also gerne weiter benutzen
              oder gegebenenfalls um neue Informationen ergänzen.
            </Typography>
          )}
          <Button component={Link} to={getCaseLink(product) + (!!product.finalClaimStatusClient ? "" : "/antrag")} variant={"text"} color={"primary"}>
            Zurück zum Fall
          </Button>
        </HintBox>
      </Container>
    </Layout>
  );
};
export default FlexServiceFeedbackConfirm;
