import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { GlobalStyles } from "@mui/system";
import { Theme } from "@mui/material";
import globalStyles from "../../theme/globalStyles";

const CssGlobal = () => {
  return (
    <>
      <CssBaseline />
      <GlobalStyles styles={(theme: Theme) => globalStyles(theme)} />
    </>
  );
};

export default CssGlobal;
