import React from "react";
import { Route, Routes } from "react-router-dom";
import useTracker from "../../services/useTracker";
import CheckoutConfirmation from "../../containers/HigherOrderContainers/CheckoutConfirmation";
import StripeCheckout from "../../components/Checkout/StripeCheckout";
import DocumentGenerationFailed from "../../components/Payment/DocumentGenerationFailed/DocumentGenerationFailed";
import GeneralCheck from "../../components/Payment/PaymentCheck/GeneralCheck";
import PaidPage from "../../containers/Pages/Payment/PaidPage/PaidPage";
import Error404Page from "../../containers/Pages/Generic/Error404/Error404Page";

export default function Checkout() {
  useTracker();

  return (
    <Routes>
      <Route path={""} element={<StripeCheckout />} />
      <Route path={"/bestaetigung"} element={<CheckoutConfirmation />} />
      <Route path={`dokument/erstellen`} element={<GeneralCheck />} />
      <Route path={`dokumentenfehler`} element={<DocumentGenerationFailed />} />
      <Route path={`erfolgreich`} element={<PaidPage />} />
      <Route path={`*`} element={<Error404Page />} />
    </Routes>
  );
}
