/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import { Radio, RadioGroup, FormControl, FormControlLabel, Typography } from "@mui/material";

import React, { useEffect } from "react";
import { useForm } from "../../provider/Form/FormProvider";
import { formValue } from "../../services/formServiceFunctions";
import _get from "lodash/get";
import { FormChangeEvent } from "../../types/FormDataTypes";

export type RadioGroupOptionProps = {
  label: string;
  value: string;
};

type RadioGroupProps = {
  name: string;
  options: Array<RadioGroupOptionProps>;
  validators?: Array<any>;
  dependentValidationFields?: Array<string>;
};

export default function RadioButtonGroup({ name, options, validators = [], dependentValidationFields = [] }: RadioGroupProps) {
  const { values, errors, setError, handleChange, registerValidators } = useForm();
  const isValid = !_get(errors, name);
  const value = formValue(values, name);

  useEffect(() => {
    registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators(name, [], []);
      setError(name, undefined);
    };
  }, [name]);

  const handleChangeInternal = (event: FormChangeEvent) => {
    setError(name, undefined);
    handleChange(event);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup name={name} value={value} onChange={handleChangeInternal}>
        {options.map((option) => (
          <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
        ))}
      </RadioGroup>
      {!isValid && <Typography color="error">{_get(errors, name)}</Typography>}
    </FormControl>
  );
}
