import React from "react";
import SummaryDetailsItem from "../../../Common/SummaryDetailsItem/SummaryDetailsItem";
import SummaryDetailsProps from "../../../../../types/SummaryDetailsProps";

const SummaryDetailsEvent = ({ caseData, formUrl }: SummaryDetailsProps) => {
  return (
    <>
      <SummaryDetailsItem fieldPath={"reasonForDispute"} formUrl={formUrl} caseData={caseData}/>
      <SummaryDetailsItem fieldPath={"eventType"} formUrl={formUrl} caseData={caseData}/>

      {caseData.eventType === "public" && (
        <>
          <SummaryDetailsItem fieldPath={"contractNumber"} formUrl={formUrl} caseData={caseData}/>
          <SummaryDetailsItem fieldPath={"numberOfTickets"} formUrl={formUrl} caseData={caseData}/>
        </>
      )}
      <SummaryDetailsItem fieldPath={"eventTitle"} formUrl={formUrl} caseData={caseData}/>
      <SummaryDetailsItem fieldPath={"eventCity"} formUrl={formUrl} caseData={caseData}/>
      <SummaryDetailsItem fieldPath={"eventDate"} formUrl={formUrl} caseData={caseData} fieldType={"date"}/>
    </>
  );
};

export default SummaryDetailsEvent;
