const serviceWorldContentBoxStyle = {
  headerIcon: {
    minWidth: "40px",
    minHeight: "40px",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  contentBoxHeadline: {
    marginBottom: "25px",
  },
  imageCircleContainer: {
    alignItems: "stretch",
    maxWidth: "70px",
    height: "70px",
    padding: "14px",
    marginRight: ".5rem",
  },
  contentBoxContainer: {
    paddingTop: "25px",
  },
};

export default serviceWorldContentBoxStyle;
