import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { checkToken } from "../../services/localStorage";
import { logout } from "../../store/login/actions";
import { Navigate, useLocation } from "react-router";
import { useCustomer } from "../../provider/Customer/CustomerProvider";

export default function RequireAuth({ children, redirectTo }) {
  const { customer, isLoading } = useCustomer();
  const location = useLocation();
  const isAuthenticated = checkToken(localStorage.getItem("token"), customer);
  useEffect(() => {
    if (!!customer && !isAuthenticated) {
      logout();
    }
  }, [customer, isAuthenticated]);

  if (!customer && isLoading) {
    return null;
  }

  return isAuthenticated ? children : <Navigate to={redirectTo} state={{ from: location }} />;
}

RequireAuth.propTypes = {
  redirectTo: PropTypes.string.isRequired,
};

RequireAuth.defaultProps = {
  redirectTo: "/login",
};