import React, { useState } from "react";
import Layout from "../../../Layouts/Layout";
import HintBox from "../../../../components/Box/HintBox/HintBox";
import FeedbackForm from "../../../../components/Form/ContractLaw/FeedbackForm/FeedbackForm";
import Container from "../../../../components/Container/Container";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { STATUS_EXTRAJUDICIAL_REACTION_RECEIVED } from "../../../../services/contractLaw/leadStatus";
import { getCaseLink, getProductData, getProductNameBy } from "../../../../services/productService";
import { FormProvider } from "../../../../provider/Form/FormProvider";
import _cloneDeep from "lodash/cloneDeep";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

const FeedbackPage = () => {
  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");
  const { data: product, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data),
  });

  if (isLoading) {
    return (
      <Layout>
        <Container>
          <Container sx={{ width: 250 }}>
            <LegalbirdLoader />
          </Container>
        </Container>
      </Layout>
    );
  }

  const getHintText = (product: AbstractCase) => {
    switch (product.caseCategory) {
      case "enforceClaim":
        switch (product.extrajudicialReaction) {
          case "noReaction":
            return (
              <p>
                <strong>Die Gegenseite hat leider nicht auf unser Schreiben reagiert.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, ob Sie zwischenzeitlich etwas von der Gegenseite gehört haben und wie weiter vorgegangen
                werden soll.
              </p>
            );
          case "fullyAgreed":
            return (
              <p>
                <strong>Die Gegenseite hat uns mitgeteilt, dass sie Ihrer Forderung vollständig nachkommen wird.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, ob die Forderung erfüllt wurde und der Fall für Sie somit abgeschlossen ist.
              </p>
            );
          case "partiallyAgreed":
            return (
              <p>
                <strong>Die Gegenseite hat uns mitgeteilt, dass sie Ihrer Forderung teilweise nachkommen wird.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, ob Sie damit einverstanden sind und der Fall für Sie somit abgeschlossen ist.
              </p>
            );
          case "disagreed":
            return (
              <p>
                <strong>Leider hat uns die Gegenseite mitgeteilt, dass sie Ihrer Forderung nicht nachkommen wird.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, wie Sie weiter vorgehen möchten.
              </p>
            );
          default:
            return "";
        }
      case "defendClaim":
        switch (product.extrajudicialReaction) {
          case "noReaction":
            return (
              <p>
                <strong>Die Gegenseite hat bisher nicht auf unser Schreiben reagiert.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, ob Sie zwischenzeitlich etwas von der Gegenseite gehört haben und wie weiter vorgegangen
                werden soll.
              </p>
            );
          case "fullyAgreed":
            return (
              <p>
                <strong>Die Gegenseite hat uns mitgeteilt, dass die Forderung gegen Sie vollständig aufgegeben wird.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, ob der Fall für Sie somit abgeschlossen ist.
              </p>
            );
          case "partiallyAgreed":
            return (
              <p>
                <strong>Die Gegenseite hat uns mitgeteilt, dass die Forderung gegen Sie teilweise aufgegeben wird.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, ob Sie damit einverstanden sind und Sie der teilweisen Forderung nachkommen.
              </p>
            );
          case "disagreed":
            return (
              <p>
                <strong>Leider hat uns die Gegenseite mitgeteilt, dass die Forderung gegen Sie aufrecht erhalten wird.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, wie Sie weiter vorgehen möchten.
              </p>
            );
          default:
            return "";
        }
      default:
        return "";
    }
  };

  if (!!product.finalClaimStatusClient || product.leadStatus !== STATUS_EXTRAJUDICIAL_REACTION_RECEIVED) {
    return (
      <Layout>
        <Container>
          <HintBox headline={"Vielen Dank für Ihre Rückmeldung!"}>
            Wir haben Ihre Rückmeldung erhalten und werden Ihren Fall entsprechend weiter bearbeiten. Sie werden über das weitere Vorgehen wie gewohnt per
            Nachricht und Ihre Servicewelt informiert.
            <Button component={Link} to={getCaseLink(product)} variant={"text"} color={"primary"}>
              Zurück zum Fall
            </Button>
          </HintBox>
        </Container>
      </Layout>
    );
  }

  const initialValues = {
    reactionReceived: null,
    clientAgrees: null,
    finalClaimStatusClient: null,
    finalProcessFeedbackClient: null,
    additionalCustomerNote: "",
  };

  const handleSubmit = async ({ values }: { values: AbstractCase }) => {
    setIsLoadingInternal(true);
    const updateData = _cloneDeep(values);
    if (updateData.reactionReceived === "no") {
      updateData.finalClaimStatusClient = "noReaction";
    }
    if (updateData.clientAgrees === "yes") {
      updateData.finalClaimStatusClient = "fullyAgreed";
      updateData.finalProcessFeedbackClient = null;
    }
    if (updateData.additionalCustomerNote === "") {
      updateData.additionalCustomerNote = null;
    }
    await updateMutation.mutateAsync({
      uri: getProductData(product.name, "apiUri"),
      id: product.id,
      data: updateData,
    });
    setIsLoadingInternal(false);
  };

  return (
    <Layout backgroundType={"serviceworld"}>
      <Container>
        <HintBox headline={"Ihre Rückmeldung wird benötigt"}>{getHintText(product)}</HintBox>
        <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
          <FeedbackForm product={product} isLoading={isLoadingInternal} />
        </FormProvider>
      </Container>
    </Layout>
  );
};

export default FeedbackPage;
