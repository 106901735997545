import { scrollToAnchor } from "../../theme/commonStyle";

const serviceWorldCaseStyle = {
  contentContainer: {
    padding: "25px",
    maxWidth: "500px",
    margin: "0 auto",
    "& > div": {
      marginBottom: "25px",
    },
  },
  loaderContainer: {
    marginTop: "50px",
  },
  scrollToAnchor,
};

export default serviceWorldCaseStyle;
