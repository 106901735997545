import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CookieModal from "../CookieBanner/CookieModal";
import cookiePolicyStyle from "./cookiePolicyStyle";
import { getConsent } from "../../services/cookieService";
import moment from "moment";
import Typography from "@mui/material/Typography";

const CookiePolicy = () => {
  const [isConfigureOpen, setIsConfigureOpen] = useState(false);
  const cookieConsent = getConsent();
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={"h1"}>Cookierichtlinie</Typography>
          <p>
            Unsere Cookierichtlinie soll Ihnen korrespondierend zu unseren Datenschutzhinweisen (zu finden <Link to={"/datenschutz"}>hier</Link>) einen
            vereinfachten Überblick über die auf legalbird.de eingesetzten Cookies geben. Ihre Cookie-Präferenzen können Sie{" "}
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setIsConfigureOpen(true);
              }}
            >
              hier
            </a>{" "}
            anpassen.
          </p>
          <p>Bitte geben Sie Ihre Einwilligungs-ID und das Datum an, wenn Sie uns bezüglich Ihrer Einwilligung kontaktieren möchten.</p>
          <p>Ihre Einwilligungs-ID: {cookieConsent?.hash}</p>
          <p>Datum der Einwilligung: {cookieConsent && moment.unix(cookieConsent.timestamp / 1000).format("DD.MM.YYYY HH:mm")}</p>
        </Grid>
        <Grid item xs={12}>
          <p>Cookies werden bei Legalbird in zwei Kategorien eingeteilt:</p>
          <Typography variant={"h3"}>1. Notwendige Cookies</Typography>
          <p>
            Der ersten Kategorie „Notwendig“ werden Cookies zugeordnet, die für die Nutzung unserer Services erforderlich sind. Durch sie wird sichergestellt,
            dass die Services von Legalbird sicher und so wie von Ihnen gewünscht funktionieren. Diese Cookies können nicht abgewählt werden.
          </p>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={cookiePolicyStyle.tableHeadCell}>Unternehmen</TableCell>
                  <TableCell sx={cookiePolicyStyle.tableHeadCell}>Ausstellende&nbsp;Domain</TableCell>
                  <TableCell sx={cookiePolicyStyle.tableHeadCell}>Cookiebeschreibung</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={cookiePolicyStyle.tableBodyCell}>Legalbird</TableCell>
                  <TableCell sx={cookiePolicyStyle.tableBodyCell}>.legalbird.de</TableCell>
                  <TableCell sx={cookiePolicyStyle.tableBodyCellContent}>
                    Es handelt sich um Cookies, die in unmittelbarem Zusammenhang mit der Nutzung der Services von Legalbird stehen. Beispiel dafür sind
                    Session-Cookies oder die Speicherung der Cookiepräferenzen.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={cookiePolicyStyle.tableBodyCell}>Stripe</TableCell>
                  <TableCell sx={cookiePolicyStyle.tableBodyCell}>
                    .stripe.com
                    <br />
                    .js.stripe.com
                    <br />
                    .m.stripe.com
                    <br />
                    .m.stripe.network
                  </TableCell>
                  <TableCell sx={cookiePolicyStyle.tableBodyCell}>
                    Abwicklung von Kundenzahlungen
                    <br />
                    <a href={"https://stripe.com/de/privacy"} target="_blank" rel="noopener noreferrer nofollow">
                      Datenschutzerklärung
                    </a>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h3"}>2. Analytische Cookies</Typography>
          <p>
            In der Kategorie „Analytisch“ finden sich Cookies wieder, die es uns zusammen mit den dazugehörigen Technologien erlauben das Nutzungsverhalten von
            Benutzern auf legalbird.de zu analysieren und auszuwerten. Die daraus gewonnenen Erkenntnisse dienen dazu unsere Services nutzerorientiert
            weiterzuentwickeln und entsprechend zu verbessern.
          </p>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={cookiePolicyStyle.tableHeadCell}>Unternehmen</TableCell>
                  <TableCell sx={cookiePolicyStyle.tableHeadCell}>Ausstellende&nbsp;Domain</TableCell>
                  <TableCell sx={cookiePolicyStyle.tableHeadCell}>Cookiebeschreibung</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={cookiePolicyStyle.tableBodyCell}>Google Inc.</TableCell>
                  <TableCell sx={cookiePolicyStyle.tableBodyCell}>
                    .google.com
                    <br />
                    .google.de
                    <br />
                    .googleadservices.com
                    <br />
                    .google-analytics.com
                    <br />
                    .doubleclick.net
                  </TableCell>
                  <TableCell sx={cookiePolicyStyle.tableBodyCellContent}>
                    Google Analytics - Erhebung von Daten zur Webseitennutzung
                    <br />
                    <a href={"https://policies.google.com/terms"} target="_blank" rel="noopener noreferrer nofollow">
                      Nutzungsbedingungen
                    </a>
                    <br />
                    <a href={"https://policies.google.com/privacy"} target="_blank" rel="noopener noreferrer nofollow">
                      Datenschutzerklärung
                    </a>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box sx={cookiePolicyStyle.preferenceLink}>
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            setIsConfigureOpen(true);
          }}
        >
          Cookie-Präferenzen anpassen
        </a>
      </Box>
      <CookieModal
        open={isConfigureOpen}
        setIsConfigureOpen={setIsConfigureOpen}
        setIsSelectionMade={(isSelectionMade) => setIsConfigureOpen(!isSelectionMade)}
        isSelectionMade
      />
    </>
  );
};

export default CookiePolicy;
