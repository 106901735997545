/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import { Grid, MenuItem } from "@mui/material";
import FormLegend from "../../Common/FormLegend/FormLegend";
import { translate, translateHelperTextIfExists } from "../../../../services/translations/translations";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import { requiredValidator } from "../../../../services/validationRules";
import { getFormDefinitions } from "../../../../services/formDefinitions/formDefinitions";

type SingleSelectFieldConfiguredProps = {
  caseData: any;
  fieldPath: string;
  required?: boolean | undefined;
};
export default function SingleSelectFieldConfigured(
  {
    caseData,
    fieldPath,
    required,
  }: SingleSelectFieldConfiguredProps) {

  const helperText = translateHelperTextIfExists(caseData, fieldPath);
    const question = translate(`${caseData.name}.${fieldPath}.question`, caseData);
    const label = translate(`${caseData.name}.${fieldPath}.label`, caseData);
  const validators = [];
  if (required) {
    validators.push(requiredValidator());
  }
  const menuItems = getFormDefinitions(caseData, fieldPath).options;

  return (
    <>
      <Grid item xs={12}>
        <FormLegend>{question}</FormLegend>
      </Grid>
      <Grid item xs={12}>
        <ValidatorSelectField label={label} name={fieldPath} validators={validators} helperText={helperText}>
          {menuItems.map((item: string, index: number) => (
            <MenuItem key={index} value={item}>
              {translate(`${caseData.name}.${fieldPath}.options.${item}.label`, caseData)}
            </MenuItem>
          ))}
        </ValidatorSelectField>
      </Grid>
    </>
  );
}
