/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import React from "react";
import wizardStyle from "../../wizardStyle";
import Grid from "@mui/material/Grid";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { translate } from "../../../../services/translations/translations";
import { RadioGroupOptionProps } from "../../../Wizard/RadioButtonGroup";
import { isRequired, requiredValidator } from "../../../../services/validationRules";
import FormLegend from "../../Common/FormLegend/FormLegend";
import RadioButtonGroup from "../../../Wizard/RadioButtonGroup";

export default function WorriesFrequency() {
  const { values, errors } = useForm();

  const availableOptions = ["daily", "weekly", "monthly", "other"];

  const getOptions = () => {
    let options: Array<RadioGroupOptionProps> = [];

    availableOptions.map((option) => {
      options.push({
        value: option,
        label: translate(`dataLeakContract.worriesFrequency.options.${option}.label`, values),
      });
    });

    return options;
  };

  const charCount = values.personalAffection?.worriesFrequencyOther?.length || 0;
  const otherHasError = (charCount > 0 && charCount < 50) || typeof errors["personalAffection.worriesFrequencyOther"] !== "undefined";

  return (
    <>
      <Grid item xs={12} pt={5}>
        <FormLegend>{translate("dataLeakContract.worriesFrequency.question", values)}</FormLegend>
      </Grid>

      <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
        <RadioButtonGroup
          name={"personalAffection.worriesFrequency"}
          options={getOptions()}
          validators={[
            {
              validator: isRequired,
              message: translate("dataLeakContract.worriesFrequency.errors.required", values),
            },
          ]}
        />
        {values.personalAffection.worriesFrequency === "other" && (
          <ValidatorTextField
            name="personalAffection.worriesFrequencyOther"
            label={translate("dataLeakContract.worriesFrequencyOther.label", values)}
            placeholder={translate("dataLeakContract.worriesFrequencyOther.placeholder", values)}
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={10}
            inputProps={{ minLength: 50, maxLength: 500 }}
            error={otherHasError}
            helperText={
              "[" +
              (typeof values.personalAffection.worriesFrequencyOther === "string" ? values.personalAffection.worriesFrequencyOther.length : 0) +
              "/500]" +
              (otherHasError ? " Bitte geben Sie mindestens 50 Buchstaben ein" : "")
            }
            validators={[requiredValidator("Bitte geben Sie einen Text ein")]}
          />
        )}
      </Grid>
    </>
  );
}
