import React, { useEffect, useState } from "react";
import { isCookieFetched, mercureFetchCookie, mercureSubscribe } from "../../services/mercure";
import BlockPage from "../../containers/Layouts/BlockPage/BlockPage";
import { apiGet } from "../../services/apiClient";
import { Release } from "../../types/Entities/Release";

const ReleaseInfo = () => {
  const [notification, setNotification] = useState<{ release: Release | null }>({
    release: null,
  });
  const cookieFetched = isCookieFetched();

  useEffect(() => {
    if (cookieFetched) {
      const eventSource = mercureSubscribe("/tech_status/release", setNotification);
      return () => {
        eventSource.close();
      };
    } else {
      mercureFetchCookie();
    }
  }, [cookieFetched]);

  useEffect(() => {
    apiGet("/releases", { isRunning: true }).then((releases) => {
      if (releases && releases.length > 0) {
        setNotification({ release: releases[0] });
      }
    });
  }, []);

  if (notification && notification.release && notification.release.resultMessage === "Pending") {
    return <BlockPage />;
  }
  return null;
};

export default ReleaseInfo;
