/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const BrowserNotice = () => {
  return (
    <Paper sx={{ margin: "5rem auto", maxWidth: "23rem", padding: "1rem" }} elevation={4}>
      <Typography variant={"h4"}>Wichtiger Hinweis</Typography>
      <p>
        <strong>
          Sie nutzen einen veralteten Internet-Browser der nicht den aktuellen Sicherheitsstandards entspricht! Aus Datenschutzgründen wird Ihnen die Seite
          daher leider nicht richtig angezeigt.
        </strong>
      </p>
      <p>Zum Schutz Ihrer Daten und um alle Websites gut nutzen zu können, empfehlen wir Ihnen die Nutzung von Google Chrome, Firefox oder Microsoft Edge.</p>
      <p>
        Über die folgenden Links können Sie die Browser herunterladen:
        <ul>
          <li>
            <a href={"https://www.google.com/chrome/"} target="_blank" rel="noopener noreferrer">
              Google Chrome
            </a>
          </li>
          <li>
            <a href={"https://www.mozilla.org/de/firefox/new/"} target="_blank" rel="noopener noreferrer">
              Firefox
            </a>
          </li>
          <li>
            <a href={"https://www.microsoft.com/de-de/windows/microsoft-edge"} target="_blank" rel="noopener noreferrer">
              Edge
            </a>
          </li>
        </ul>
      </p>
    </Paper>
  );
};

export default BrowserNotice;
