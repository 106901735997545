import React from "react";
import summaryStyle from "./summaryStyle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SummaryDetailsItem from "../../Common/SummaryDetailsItem/SummaryDetailsItem";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

type InsuranceDataProps = {
  product: AbstractCase;
  formPath: string;
  title: string;
};

const InsuranceData = ({ product, formPath, title }: InsuranceDataProps) => {
  const section = "insuranceData";

  if (!product.insurance.insurance) {
    return null;
  }
  return (
    <>
      <Grid item xs={12} sx={summaryStyle.subHeadline}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>
      <SummaryDetailsItem caseData={product} formUrl={formPath} fieldPath={"legalExpenseInsurance"} anchorId={section} />
      {product.legalExpenseInsurance && (
        <>
          <SummaryDetailsItem caseData={product} formUrl={formPath} fieldPath={"insurance.insurance.name"} anchorId={section} />
          <SummaryDetailsItem caseData={product} formUrl={formPath} fieldPath={"insurance.insurancePolicyNumber"} anchorId={section} />
          <SummaryDetailsItem caseData={product} formUrl={formPath} fieldPath={"insurance.caseReferenceNumber"} anchorId={section} />
          <SummaryDetailsItem caseData={product} formUrl={formPath} fieldPath={"insurance.policyHolderType"} anchorId={section} />
          <SummaryDetailsItem caseData={product} formUrl={formPath} fieldPath={"insurance.deductible"} anchorId={section} />

          {product.insurance.policyHolderType === "familyMember" && (
            <>
              <SummaryDetailsItem
                caseData={product}
                formUrl={formPath}
                fieldPath={"insurance.policyHolder.gender"}
                anchorId={section}
                labelPostfix={"Versicherter"}
              />
              <SummaryDetailsItem
                caseData={product}
                formUrl={formPath}
                fieldPath={"insurance.policyHolder.givenName"}
                anchorId={section}
                labelPostfix={"Versicherter"}
              />
              <SummaryDetailsItem
                caseData={product}
                formUrl={formPath}
                fieldPath={"insurance.policyHolder.familyName"}
                anchorId={section}
                labelPostfix={"Versicherter"}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default InsuranceData;
