import formSubmitStyle from "../../Common/FormSubmit/formSubmitStyle";
import { scrollToAnchor } from "../../../../theme/commonStyle";

const summaryStyle = {
  headline: {
    color: "primary.main",
    fontWeight: 500,
  },
  subHeadline: {
    marginTop: "2rem",
    borderBottom: "1px solid #333",
    "& h5": {
      lineHeight: "1rem",
    },
  },
  question: {
    marginTop: "1rem",
    fontWeight: 400,
  },
  ...formSubmitStyle,
  questionsTextArea: {
    margin: "2rem 0",
  },
  scrollToAnchor,
};

export default summaryStyle;
