import React, { useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { getProductData } from "../../services/productService";
import { useQueryClient } from "@tanstack/react-query";
import { Customer } from "../../types/Entities/Customer";
import { AbstractCase } from "../../types/Entities/AbstractCase";
import ButtonLoading from "../Button/ButtonLoading";
import { queryKeys } from "../../services/reactQuery/reactQueryService";

type CalendlyBookingWidgetProps = {
  responsibleParty: string;
  appointmentType: string;
  customer: Customer;
  product: AbstractCase;
  utmSource: string;
};

const CalendlyBookingWidget = ({ responsibleParty, appointmentType, customer, product, utmSource }: CalendlyBookingWidgetProps) => {
  const [serviceWorldButtonLoading, setServiceWorldButtonLoading] = useState(false);
  const productApiUri = getProductData(product.name, "apiUri");

  const queryClient = useQueryClient();

  useCalendlyEventListener({
    onEventScheduled: () => {
      setServiceWorldButtonLoading(true);
      setTimeout(async () => {
        await queryClient.invalidateQueries(queryKeys.item(productApiUri, product.id));
        setServiceWorldButtonLoading(false);
      }, 3000);
    },
  });

  return (
    <>
      <InlineWidget
        styles={{ height: "660px" }}
        url={`https://calendly.com/${responsibleParty}/${appointmentType}`}
        prefill={{ email: customer.email, name: customer?.person?.fullName || undefined }}
        utm={{ utmSource: utmSource }}
      />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <ButtonLoading
          loadingText={"Bitte warten..."}
          color={"buttonGrey"}
          component={Link}
          to={"/servicewelt"}
          variant={"contained"}
          isLoading={serviceWorldButtonLoading}
        >
          Zurück zur Servicewelt
        </ButtonLoading>
      </Box>
    </>
  );
};

export default CalendlyBookingWidget;
