import React from "react";
import summaryStyle from "./summaryStyle";
import Grid from "@mui/material/Grid";
import GridEditRow from "../../Common/GridEditRow/GridEditRow";
import { translate } from "../../../../services/translations/translations";
import Typography from "@mui/material/Typography";
import { isInsurancePartnered } from "../../../../services/productService";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import {Box} from "@mui/material";

type InsuranceDataProps = {
  formData: Record<string, any>;
  product: AbstractCase;
  formPath: string;
  title: string;
};

const InsuranceData = ({ formData, product, formPath, title }: InsuranceDataProps) => {
  const section = "insuranceData";

  return (
    <>
      <Grid item xs={12} sx={summaryStyle.subHeadline}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"name"} sx={summaryStyle.scrollToAnchor} />
        Versicherung
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "name",
          },
        }}
      >
        {product.insurance.insurance.name}
      </GridEditRow>
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"insurancePolicyNumber"} sx={summaryStyle.scrollToAnchor} />
        Versicherungsnummer
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "insurancePolicyNumber",
          },
        }}
      >
        {formData.insurance.insurancePolicyNumber}
      </GridEditRow>
      {!isInsurancePartnered(product) && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"caseReferenceNumber"} sx={summaryStyle.scrollToAnchor} />
            Schadennummer
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "caseReferenceNumber",
              },
            }}
          >
            {formData.insurance.caseReferenceNumber}
          </GridEditRow>
        </>
      )}
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"policyHolderType"} sx={summaryStyle.scrollToAnchor} />
        Selbst oder mitversichert
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "policyHolderType",
          },
        }}
      >
        {translate(`${product.name}.insurance.policyHolderType.options.${formData.insurance.policyHolderType}.label`, formData)}
      </GridEditRow>
      {formData.insurance.policyHolderType === "familyMember" && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"gender"} sx={summaryStyle.scrollToAnchor} />
            Anrede Versicherter
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "gender",
              },
            }}
          >
            {formData.insurance.policyHolder.gender === "male" ? "Herr" : "Frau"}
          </GridEditRow>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"givenName"} sx={summaryStyle.scrollToAnchor} />
            Vorname Versicherter
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "givenName",
              },
            }}
          >
            {formData.insurance.policyHolder.givenName}
          </GridEditRow>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"familyName"} sx={summaryStyle.scrollToAnchor} />
            Nachname Versicherter
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "familyName",
              },
            }}
          >
            {formData.insurance.policyHolder.familyName}
          </GridEditRow>
        </>
      )}
    </>
  );
};

export default InsuranceData;
