import React from "react";
import InquiryToCustomer from "../../../types/Entities/InquiryToCustomer";
import HintBox from "../../Box/HintBox/HintBox";
import { Box, Stack, Typography } from "@mui/material";
import { translate } from "../../../services/translations/translations";
import moment from "moment";

type InquiryToCustomerOverviewBoxProps = {
  inquiryToCustomer: InquiryToCustomer;
};

export default function InquiryToCustomerOverviewBox({ inquiryToCustomer }: InquiryToCustomerOverviewBoxProps) {
  if (inquiryToCustomer.inquiryType === "appointment") {
    return (
      <HintBox headline={"Termin vereinbaren"}>
        <Stack sx={{ marginTop: 4 }} spacing={2}>
          <Typography>
            In Ihrem Fall ist eine telefonische Rücksprache nötig. Bitte vereinbaren Sie sich einen Beratungstermin{" "}
            <strong>bis zum {moment(inquiryToCustomer.deadline).format("DD.MM.YYYY")}</strong>, damit Ihr Fall weiter bearbeitet werden kann.
          </Typography>
          {inquiryToCustomer.inquiryTextAppointment && (
            <>
              <Typography>
                <strong>Wichtige Hinweise zu Ihrem Termin:</strong>
              </Typography>
              <Box sx={{ all: "initial" }}>
                <Typography sx={{ color: "text.primary" }} dangerouslySetInnerHTML={{ __html: inquiryToCustomer.inquiryTextAppointment }} />
              </Box>
            </>
          )}
        </Stack>
      </HintBox>
    );
  }

  return (
    <HintBox headline={"Ihre Rückmeldung wird benötigt"}>
      <Typography sx={{ marginTop: 4 }}>
        Es werden {translate("inquiryToCustomer.inquiryType.options." + inquiryToCustomer.inquiryType + ".label", {})} von Ihnen benötigt. Bitte stellen Sie
        diese <strong>bis zum {moment(inquiryToCustomer.deadline).format("DD.MM.YYYY")}</strong> zur Verfügung, damit Ihr Fall weiter bearbeitet werden kann.
      </Typography>
    </HintBox>
  );
}
