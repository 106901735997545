import { Box, Grid, MenuItem, Paper } from "@mui/material";
import { useForm } from "../../../provider/Form/FormProvider";
import Hidden from "@mui/material/Hidden";
import React from "react";
import FormFader from "../Common/Fader/FormFader";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import { emailDefault, isRequired } from "../../../services/validationRules";
import _times from "lodash/times";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import _toString from "lodash/toString";
import Typography from "@mui/material/Typography";
import FormLegend from "../Common/FormLegend/FormLegend";
import { translate } from "../../../services/translations/translations";
import NumberFieldConfigured from "../ConfiguredFormElements/NumberFieldConfigured/NumberFieldConfigured";
import Address from "../Common/Address/Address";
import FormSubmitProps from "../../../types/FormSubmitProps";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import wizardStyle from "../wizardStyle";

type ContractProps = {
  submitProps: FormSubmitProps;
  product: AbstractCase;
};

export default function Contract({ submitProps, product }: ContractProps) {
  const currentYear = new Date().getFullYear();
  const { handleSubmit, values } = useForm();

  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Arbeitsverhältnis
            </Typography>
          </Hidden>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12}>
              <FormLegend>Seit wann arbeiten Sie im Unternehmen?</FormLegend>
            </Grid>
            <Grid item xs={12} sm={6} sx={[wizardStyle.gridSpace, wizardStyle.gridMargin]}>
              <ValidatorSelectField
                label={translate("settlement.employedSinceMonth.label", values)}
                name="employedSinceMonth"
                validators={[
                  {
                    validator: isRequired,
                    message: "Bitte geben Sie den Monat an, seitdem Sie im Unternehmen arbeiten.",
                  },
                ]}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                <MenuItem value={"01"}>{translate("settlement.employedSinceMonth.options.01.label", values)}</MenuItem>
                <MenuItem value={"02"}>{translate("settlement.employedSinceMonth.options.02.label", values)}</MenuItem>
                <MenuItem value={"03"}>{translate("settlement.employedSinceMonth.options.03.label", values)}</MenuItem>
                <MenuItem value={"04"}>{translate("settlement.employedSinceMonth.options.04.label", values)}</MenuItem>
                <MenuItem value={"05"}>{translate("settlement.employedSinceMonth.options.05.label", values)}</MenuItem>
                <MenuItem value={"06"}>{translate("settlement.employedSinceMonth.options.06.label", values)}</MenuItem>
                <MenuItem value={"07"}>{translate("settlement.employedSinceMonth.options.07.label", values)}</MenuItem>
                <MenuItem value={"08"}>{translate("settlement.employedSinceMonth.options.08.label", values)}</MenuItem>
                <MenuItem value={"09"}>{translate("settlement.employedSinceMonth.options.09.label", values)}</MenuItem>
                <MenuItem value={"10"}>{translate("settlement.employedSinceMonth.options.10.label", values)}</MenuItem>
                <MenuItem value={"11"}>{translate("settlement.employedSinceMonth.options.11.label", values)}</MenuItem>
                <MenuItem value={"12"}>{translate("settlement.employedSinceMonth.options.12.label", values)}</MenuItem>
              </ValidatorSelectField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ValidatorSelectField
                label={translate("settlement.employedSinceYear.label", values)}
                name="employedSinceYear"
                validators={[
                  {
                    validator: isRequired,
                    message: "Bitte geben Sie das Jahr an, seitdem Sie im Unternehmen arbeiten.",
                  },
                ]}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                {_times(currentYear - 1970 + 1, (i) => (
                  <MenuItem key={i} value={_toString(currentYear - i)}>
                    {currentYear - i}
                  </MenuItem>
                ))}
              </ValidatorSelectField>
            </Grid>
            <NumberFieldConfigured caseData={product} fieldPath={"grossSalary"} fieldType={"money"} required={true} />

            <Grid item xs={12}>
              <FormLegend>{translate("settlement.jobDescription.question", values)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                label={translate("settlement.jobDescription.label", values)}
                name="jobDescription"
                validators={[{ validator: isRequired, message: "Bitte geben Sie Ihre Berufsbezeichnung an." }]}
              />
            </Grid>

            <Grid item xs={12}>
              <FormLegend>{translate("settlement.numberOfEmployees.question", values)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorSelectField
                label={translate("settlement.numberOfEmployees.label", values)}
                name="numberOfEmployees"
                validators={[{ validator: isRequired, message: "Bitte geben Sie die Anzahl Mitarbeiter an" }]}
              >
                <MenuItem value={"10"}>{translate("settlement.numberOfEmployees.options.10.label", values)}</MenuItem>
                <MenuItem value={"50"}>{translate("settlement.numberOfEmployees.options.50.label", values)}</MenuItem>
                <MenuItem value={"500"}>{translate("settlement.numberOfEmployees.options.500.label", values)}</MenuItem>
                <MenuItem value={"1000"}>{translate("settlement.numberOfEmployees.options.1000.label", values)}</MenuItem>
              </ValidatorSelectField>
            </Grid>

            <Grid item xs={12}>
              <FormLegend>{translate("settlement.worksCouncil.question", values)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorSelectField
                label={translate("settlement.worksCouncil.label", values)}
                name="worksCouncil"
                validators={[{ validator: isRequired, message: "Bitte geben Sie an, ob ein Betriebsrat vorhanden ist." }]}
              >
                <MenuItem value={"yes"}>{translate("settlement.worksCouncil.options.yes.label", values)}</MenuItem>
                <MenuItem value={"no"}>{translate("settlement.worksCouncil.options.no.label", values)}</MenuItem>
              </ValidatorSelectField>
            </Grid>

            <Grid item xs={12}>
              <FormLegend>
                Bitte geben Sie möglichst alle Kontaktdaten Ihres Arbeitgebers an, die Ihnen vorliegen. Sie finden die Adressdaten zum Beispiel auf Ihrem
                Arbeitsvertrag.
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField label={translate("settlement.employerOrganization.name.label", values)} name="employerOrganization.name" />
            </Grid>
            <Address path={"employerOrganization.address"} headline={""} fieldsAreRequired={false} />
            <Grid item xs={12}>
              <ValidatorTextField name={"employerOrganization.address.postOfficeBoxNumber"} label={translate("address.postOfficeBoxNumber.label", values)} />
            </Grid>
            <Grid item xs={12} sm={6} sx={wizardStyle.gridSpace}>
              <ValidatorTextField
                name={"employerOrganization.address.postOfficeBoxPostalCode"}
                label={translate("address.postOfficeBoxPostalCode.label", values)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ValidatorTextField
                name={"employerOrganization.address.postOfficeBoxAddressLocality"}
                label={translate("address.postOfficeBoxAddressLocality.label", values)}
              />
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                name={"employerOrganization.email"}
                label={translate("settlement.employerOrganization.email.label", values)}
                validators={[emailDefault]}
              />
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField name={"employerOrganization.telephone"} label={translate("settlement.employerOrganization.telephone.label", values)} />
            </Grid>
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
