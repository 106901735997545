import React from "react";
import ContractualPartnerData from "../../ContractLaw/Summary/ContractualPartnerData";
import Grid from "@mui/material/Grid";
import GridEditRow from "../../Common/GridEditRow/GridEditRow";
import summaryStyle from "../../Common/Style/summaryStyle";
import {Box} from "@mui/material";

type LandLordDataProps = {
  formData: Record<string, any>;
  formPath: string;
};

export const LandLordData = ({ formData, formPath }: LandLordDataProps) => {
  const section = "contractualPartnerData";

  return (
    <>
      <ContractualPartnerData formPath={formPath} formData={formData} title={"Angaben zum Vermieter"} />
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"name"} sx={summaryStyle.scrollToAnchor} />
        Hausverwaltung vorhanden
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "hasPropertyManager",
          },
        }}
      >
        {formData.hasPropertyManager === "yes" ? "Ja" : "Nein"}
      </GridEditRow>
      {formData.hasPropertyManager === "yes" && (
        <>
          <Grid item xs={12} sx={summaryStyle.question}>
            <Box id={"name"} sx={summaryStyle.scrollToAnchor} />
            Name Hausverwaltung
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "legalName",
              },
            }}
          >
            {formData.propertyManager.legalName}
          </GridEditRow>
          {formData.propertyManager.address.streetAddress && (
            <>
              <Grid item xs={12} sx={summaryStyle.question}>
                <Box id={"streetAddress"} sx={summaryStyle.scrollToAnchor} />
                Straße und Hausnummer Hausverwaltung
              </Grid>
              <GridEditRow
                item
                xs={12}
                to={{
                  pathname: formPath,
                  state: {
                    section: section,
                    field: "streetAddress",
                  },
                }}
              >
                {formData.propertyManager.address.streetAddress}
              </GridEditRow>
            </>
          )}
          {formData.propertyManager.address.postalCode && (
            <>
              <Grid item xs={12} sx={summaryStyle.question}>
                <Box id={"postalCode"} sx={summaryStyle.scrollToAnchor} />
                PLZ Hausverwaltung
              </Grid>
              <GridEditRow
                item
                xs={12}
                to={{
                  pathname: formPath,
                  state: {
                    section: section,
                    field: "postalCode",
                  },
                }}
              >
                {formData.propertyManager.address.postalCode}
              </GridEditRow>
            </>
          )}
          {formData.propertyManager.address.addressLocality && (
            <>
              <Grid item xs={12} sx={summaryStyle.question}>
                <Box id={"addressLocality"} sx={summaryStyle.scrollToAnchor} />
                Ort Hausverwaltung
              </Grid>
              <GridEditRow
                item
                xs={12}
                to={{
                  pathname: formPath,
                  state: {
                    section: section,
                    field: "addressLocality",
                  },
                }}
              >
                {formData.propertyManager.address.addressLocality}
              </GridEditRow>
            </>
          )}
          {formData.propertyManager.email && (
            <>
              <Grid item xs={12} sx={summaryStyle.question}>
                <Box id={"email"} sx={summaryStyle.scrollToAnchor} />
                E-Mail Hausverwaltung
              </Grid>
              <GridEditRow
                item
                xs={12}
                to={{
                  pathname: formPath,
                  state: {
                    section: section,
                    field: "email",
                  },
                }}
              >
                {formData.propertyManager.email}
              </GridEditRow>
            </>
          )}
          {formData.propertyManager.telephone && (
            <>
              <Grid item xs={12} sx={summaryStyle.question}>
                <Box id={"telephone"} sx={summaryStyle.scrollToAnchor} />
                Telefonnummer Hausverwaltung
              </Grid>
              <GridEditRow
                item
                xs={12}
                to={{
                  pathname: formPath,
                  state: {
                    section: section,
                    field: "telephone",
                  },
                }}
              >
                {formData.propertyManager.telephone}
              </GridEditRow>
            </>
          )}
          {formData.propertyManager.fax && (
            <>
              <Grid item xs={12} sx={summaryStyle.question}>
                <Box id={"telephone"} sx={summaryStyle.scrollToAnchor} />
                Faxnummer Hausverwaltung
              </Grid>
              <GridEditRow
                item
                xs={12}
                to={{
                  pathname: formPath,
                  state: {
                    section: section,
                    field: "fax",
                  },
                }}
              >
                {formData.propertyManager.fax}
              </GridEditRow>
            </>
          )}
          {formData.propertyManagerReference && (
            <>
              <Grid item xs={12} sx={summaryStyle.question}>
                <Box id={"telephone"} sx={summaryStyle.scrollToAnchor} />
                Aktenzeichen Hausverwaltung
              </Grid>
              <GridEditRow
                item
                xs={12}
                to={{
                  pathname: formPath,
                  state: {
                    section: section,
                    field: "propertyManagerReference",
                  },
                }}
              >
                {formData.propertyManagerReference}
              </GridEditRow>
            </>
          )}
        </>
      )}
    </>
  );
};
