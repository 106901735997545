import React from "react";
import { Box, Paper, Typography } from "@mui/material";

interface HintBoxProps {
  children: React.ReactNode;
  headline: string;
}

export default function HintBox({children, headline}: HintBoxProps) {
  return (
    <Paper sx={{padding: "2rem", textAlign: "center", marginBottom: "1rem"}}>
      <Typography variant={"h4"} sx={{color: (theme) => theme.palette.primary.main}}>{headline}</Typography>
      <Box maxWidth={"700px"} margin={"0 auto"}>{children}</Box>
    </Paper>
  );
};