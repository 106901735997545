/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Grid } from "@mui/material";
import FormLegend from "../../Common/FormLegend/FormLegend";
import { translate, translateHelperTextIfExists } from "../../../../services/translations/translations";
import { requiredValidator } from "../../../../services/validationRules";
import React, { useEffect } from "react";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { getFormDefinitions } from "../../../../services/formDefinitions/formDefinitions";
import { ValidatorType } from "../../../../types/ValidatorType";

type TextFieldConfiguredProps = {
  caseData: Record<string, any>;
  fieldPath: string;
  required?: boolean | undefined;
  multiline?: boolean;
};
export default function TextFieldConfigured({ caseData, fieldPath, required, multiline = false }: TextFieldConfiguredProps) {
  const helperText = translateHelperTextIfExists(caseData, fieldPath);
  const question = translate(`${caseData.name}.${fieldPath}.question`, caseData);
  const label = translate(`${caseData.name}.${fieldPath}.label`, caseData);
  const validators: Array<ValidatorType> = [];

  useEffect(() => {
    if (required) {
      validators.push(requiredValidator());
    }
    if (getFormDefinitions(caseData, fieldPath) && getFormDefinitions(caseData, fieldPath).validators) {
      const definedValidators: Array<ValidatorType> = getFormDefinitions(caseData, fieldPath).validators;
      definedValidators.forEach((definedValidator) => {
        validators.push(definedValidator);
      });
    }
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <FormLegend>{question}</FormLegend>
      </Grid>
      <Grid item xs={12}>
        <ValidatorTextField name={fieldPath} label={label} multiline={multiline} rows={multiline ? 6 : 1} helperText={helperText} validators={validators} />
      </Grid>
    </>
  );
}
