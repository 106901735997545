import _get from "lodash/get";
import _isString from "lodash/isString";

import { API_HOST, API_PATH } from "../config/_entrypoint";
import SubmissionError from "../error/SubmissionError";
const apiGet = (iri, filters, options = {}) => {
  const searchParams = new URLSearchParams(filters);
  return apiCall("GET", iri + '?' + searchParams, options);
};

const apiPut = (iri, data, options = {}) => {
  options.body = JSON.stringify(data);
  return apiCall("PUT", iri, options);
};

const apiPost = (iri, data, options = {}) => {
  options.body = JSON.stringify(data);
  return apiCall("POST", iri, options);
};

const apiDelete = (iri, options = {}) => {
  return apiCall("DELETE", iri, options);
};

const apiCall = async (method, iri, options) => {
  const url = API_HOST + API_PATH + iri;

  options.method = method;
  options = defaultOptions(options);

  const response = await fetch(url, options);
  if (response.ok === true && response.status === 204) {
    return null;
  }

  const json = await response.json();
  if (!response.ok) {
    throw handleError(json, response);
  }

  return json;
};

const getToken = async (credentials, options = {persist: true, throwError: true}) => {
  const json = await apiPost("/login_check", credentials);
  const token = _get(await json, "token");

  if (!_isString(token)) {
    if (options.throwError) {
      throw new Error("Der Login ist fehlgeschlagen. Bitte überprüfen Sie Ihre Daten.");
    }
  }

  if (options.persist) {
    localStorage.setItem('token', token);
  }
  return token;
}

const defaultOptions = (options) => {
  if ("undefined" === typeof options.headers) {
    options.headers = new Headers();
  }

  if (null === options.headers.get("Accept")) {
    options.headers.set("Accept", "application/json");
  }

  if (
    "undefined" !== options.body &&
    !(options.body instanceof FormData) &&
    null === options.headers.get("Content-Type")
  ) {
    options.headers.set("Content-Type", "application/ld+json");
  }

  if (options.headers.get("Content-Type") === "multipart/form-data") {
    options.headers.delete("Content-Type");
  }

  const token = localStorage.getItem("token");
  if (null !== token && null === options.headers.get("Authorization")) {
    options.headers.set("Authorization", "Bearer " + token);
  }

  if (localStorage.getItem("impersonate")) {
    options.headers.set("X-Switch-User", localStorage.getItem("impersonate"));
  }

  return options;
};

const handleError = (json, response) => {
  const error = json["hydra:description"] || response.statusText;
  if (!json.violations) {
    return new Error(error);
  }
  let errors = { _error: error };
  json.violations.map((violation) => (errors[violation.propertyPath] = violation.message));
  return new SubmissionError(errors);
};

class ApiClient {
  async getToken(credentials) {
    return getToken(credentials);
  }

  async get(iri, options = {}) {
    return apiCall("GET", iri, options);
  }

  async post(iri, options = {}) {
    return apiCall("POST", iri, options);
  }

  async put(iri, options = {}) {
    return apiCall("PUT", iri, options);
  }

  async delete(iri, options = {}) {
    return apiCall("DELETE", iri, options);
  }
}

export default new ApiClient();

export {
  apiGet,
  apiPut,
  apiPost,
  apiDelete,
  apiCall,
  getToken
}
