import Paper from "@mui/material/Paper";
import React, { useEffect } from "react";
import TextContainer from "../../../Container/TextContainer";
import { formValue } from "../../../../services/formServiceFunctions";
import _get from "lodash/get";
import alimonyTranslations from "../../../../services/alimony/alimonyTranslations";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import PersonData from "./Summary/PersonData";
import AlimonyData from "./Summary/AlimonyData";
import AlimonyChildren from "./Summary/AlimonyChildren";
import EmailVerificationModal from "../../../FormInfoModal/EmailVerificationModal";
import Button from "@mui/material/Button";
import ButtonLoading from "../../../Button/ButtonLoading";
import { toCheckout } from "../../../../services/checkoutService";
import ContactData from "./Summary/ContactData";
import { getCaseLink } from "../../../../services/productService";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import Typography from "@mui/material/Typography";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import summaryStyle from "../../Common/Style/summaryStyle";
import { Box } from "@mui/material";

type SummaryProps = { product: AbstractCase };

export default function Summary({ product }: SummaryProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const stateField = location.state?.field;
  const { values, handleSubmit } = useForm();
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data),
  });

  useEffect(() => {
    const el = document.getElementById(stateField);
    el && el.scrollIntoView();
  }, [stateField]);

  const basePath = getCaseLink(product) + "/antrag";

  const translate = (path: string) => {
    return _get(alimonyTranslations, path);
  };

  const handleCheckout = async () => {
    navigate("/checkout/dokument/erstellen");
    await updateMutation.mutateAsync({
      uri: "/alimonies",
      id: product.id,
      data: { personalTextMessage: formValue(values, "personalTextMessage") },
    });
    await toCheckout(product.checkoutItem.id + "_" + product.id, navigate);
  };

  return (
    <Paper elevation={4}>
      <TextContainer>
        <Typography variant={"h4"} sx={summaryStyle.headline}>
          Ihre Angaben
        </Typography>
        <Grid container>
          {values.basicChoice !== "other" && (
            <>
              <Box id={"alimonyPetitionerData"} sx={summaryStyle.scrollToAnchor} />
              <PersonData
                title={"Ihre finanzielle Situation"}
                formData={values}
                personType={"alimonyPetitioner"}
                formPath={getCaseLink(product) + "/antrag/finanzielle-situation"}
                section={"alimonyPetitionerData"}
              />

              <Box id={"alimonyPartnerData"} sx={summaryStyle.scrollToAnchor} />
              <PersonData
                title={"Finanzielle Situation Ihres Ex-Partners"}
                formData={values}
                personType={"alimonyPartner"}
                formPath={getCaseLink(product) + "/antrag/finanzielle-situation-partner"}
                section={"alimonyPartnerData"}
              />

              <Box id={"alimony"} sx={summaryStyle.scrollToAnchor} />
              <AlimonyData basePath={basePath} formData={values} />

              <Box id={"alimonyChildren"} sx={summaryStyle.scrollToAnchor} />
              <AlimonyChildren basePath={basePath} formData={values} translate={translate} />
            </>
          )}

          <Box id={"contactData"} sx={summaryStyle.scrollToAnchor} />
          <ContactData basePath={basePath} formData={values} />
        </Grid>

        <form onSubmit={handleSubmit}>
          <ValidatorTextField
            sx={summaryStyle.questionsTextArea}
            label="Ihr Anliegen"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Sie haben Fragen/Anmerkungen? Dann beschreiben Sie diese hier gerne!"
            name="personalTextMessage"
            multiline
            rows={12}
            maxRows={12}
          />
          <Grid container justifyContent={"center"} alignItems={"baseline"} sx={summaryStyle.applicationActions}>
            <Grid item xs={12} sm={6} sx={summaryStyle.backButtonContainer}>
              <Button fullWidth variant={"text"} component={Link} to={basePath + "/ihre-analyse"}>
                Angaben bearbeiten
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} sx={summaryStyle.submitButtonContainer}>
              <ButtonLoading fullWidth color={"primary"} variant={"contained"} onClick={handleCheckout}>
                Analyse erstellen
              </ButtonLoading>
            </Grid>
          </Grid>
        </form>
      </TextContainer>
      <EmailVerificationModal />
    </Paper>
  );
}
