const serviceWorldNewCaseStyle = {
  newCaseContainer: {
    margin: "25px 0",
  },
  bottomPadding: {
    paddingBottom: "1.6rem",
  },
  productCategoryHeadline: {
    display: "block",
    textAlign: "center",
    padding: "10px",
    backgroundColor: "background.light",
  },
  icon: {
    width: "30px",
    height: "30px",
    marginTop: "5px",
  },
  modalContent: {
    padding: "2rem 0",
  },
  infoIcon: {
    color: "grey",
    margin: "0 0 0 auto",
  },
  overwriteLinkColor: {
    color: "buttonText.main",
  },
  productItem: {
    cursor: "pointer",
    padding: "15px 25px",
    "&:hover": {
      backgroundColor: "text.contrast",
    },
    color: "text.primary",
  },
};

export default serviceWorldNewCaseStyle;
