/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Grid } from "@mui/material";
import { translate, translateCaseValue } from "../../../../services/translations/translations";
import React from "react";
import caseDetailsItemStyle from "./caseDetailsItemStyle";
import { convertValueDependingOnFieldType, fieldPathValueCanBeDisplayed } from "../../../../services/formatService";
import { FieldType } from "../../../../types/formatServiceTypes/FieldType";

type CaseDetailsItemProps = {
  caseData: any;
  fieldPath: string;
  fieldType?: FieldType;
  labelPostfix?: string | null;
};

const CaseDetailsItem = ({ caseData, fieldPath, fieldType = null, labelPostfix = null }: CaseDetailsItemProps) => {
  if (!fieldPathValueCanBeDisplayed(caseData, fieldPath)) {
    return null;
  }

  let convertedValue = fieldType === null ? translateCaseValue(caseData, fieldPath) : convertValueDependingOnFieldType(caseData, fieldPath, fieldType);

  let label = translate(`${caseData.name}.${fieldPath}.label`, caseData);
  label = labelPostfix ? label + " " + labelPostfix : label;

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} sx={caseDetailsItemStyle.label}>
          {label}
        </Grid>
        <Grid item xs={12}>
          {convertedValue}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CaseDetailsItem;
