const guidebookHomeSectionStyle = {
  heading: {
    textAlign: "center",
  },
  hintBox: {
    paddingBottom: 0,
  },
};

export default guidebookHomeSectionStyle;
