const serviceworldStepSectionStyle = {
  marginTop: {
    marginTop: "3rem",
  },
  centerText: {
    textAlign: "center",
  },
  button: {
    width: 300,
  },
};

export default serviceworldStepSectionStyle;
