import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import React from "react";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import AlimonyChild from "./AlimonyChild/AlimonyChild";
import { useForm } from "../../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";
import FormSubmitProps from "../../../../types/FormSubmitProps";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import { Child } from "../../../../types/Entities/Child";
import wizardStyle from "../../wizardStyle";
import { Box } from "@mui/material";

type AlimonyChildrenProps = { submitProps: FormSubmitProps; product: AbstractCase };
export default function AlimonyChildren({ submitProps, product }: AlimonyChildrenProps) {
  const { handleSubmit } = useForm();
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Typography variant={"h5"} sx={wizardStyle.headline}>
            Angaben der Kinder
          </Typography>
          {product.children.map((child: Child, index: number) => (
            <AlimonyChild key={index} childIndex={index} />
          ))}
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
