import React from "react";
import _filter from "lodash/filter";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import MultipleFileUpload from "../../Common/FileUpload/MultipleFileUpload";
import moment from "moment";
import FormSubmit from "../FormSubmit/FormSubmit";
import { useForm } from "../../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";
import FormLegend from "../FormLegend/FormLegend";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import LegalbirdLoader from "../../../ContentLoader/LegalbirdLoader";
import { fetchCollection, queryKeys } from "../../../../services/reactQuery/reactQueryService";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import FormSubmitProps from "../../../../types/FormSubmitProps";
import wizardStyle from "../../wizardStyle";
import { Box } from "@mui/material";

type DocumentsProps = {
  product: AbstractCase;
  submitProps: FormSubmitProps;
  headline?: string;
  mainDescription?: string;
  subDescription?: string;
};

export default function Documents({
  product,
  submitProps,
  headline = "Unterlagen",
  mainDescription = "Sie können hier gerne Unterlagen zu Ihrem Fall hochladen. Wenn Sie keine Unterlagen hochladen möchten, klicken Sie unten einfach auf “WEITER”.",
  subDescription = "Möchten Sie uns Unterlagen zukommen lassen, damit wir Ihr Anliegen besser einschätzen können? Dann laden Sie diese bitte hier hoch.",
}: DocumentsProps) {
  const queryClient = useQueryClient();
  const urlParams = {
    product: product.name,
    productId: product.id,
  };
  const { data: mediaObjectCollection, isLoading } = useQuery(queryKeys.collection("/media_objects", urlParams), () =>
    fetchCollection("/media_objects", urlParams)
  );
  const { handleSubmit } = useForm();

  const refreshMediaObjectCollection = () => queryClient.invalidateQueries(queryKeys.collection("/media_objects", urlParams));

  if (isLoading) {
    return <LegalbirdLoader centered />;
  }

  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              {headline}
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
              <FormLegend>{mainDescription}</FormLegend>
            </Grid>
            <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
              <Typography variant={"h5"}>{headline}</Typography>
              <p>{subDescription}</p>
              <MultipleFileUpload
                product={product}
                fieldname="applicationDocument"
                description={"Dokument vom " + moment().format("DD.MM.YYYY")}
                mediaObjectCollection={_filter(mediaObjectCollection, ["fieldname", "applicationDocument"]) || []}
                fetchMediaObjects={refreshMediaObjectCollection}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
