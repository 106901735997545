/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import Layout from "../../../Layouts/Layout";
import {  Stack } from "@mui/material";
import { useEffect, useState } from "react";
import RequestAccountDeletion from "./RequestAccountDeletion";
import ConfirmAccountDeletion from "./ConfirmAccountDeletion";

export default function AccountDeletionPage() {
  const [action, setAction] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    setAction(params.get("action"));
    setToken(params.get("token"));
  }, []);
  if (!action || !token) {
    return null;
  }
  return (
    <Layout minimal={true}>
      <Stack minHeight={"600px"} alignItems={"center"} justifyContent={"center"}>
        {action === "request" && <RequestAccountDeletion token={token} />}
        {action === "confirm" && <ConfirmAccountDeletion token={token} />}
      </Stack>
    </Layout>
  );
}
