import React, { PropsWithChildren, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme, { darkModePalette, lightModePalette } from "../../theme/LegacyTheme";
import { useCustomer } from "../../provider/Customer/CustomerProvider";

// @ts-ignore
const darkModeTheme = createTheme({
  ...theme,
  ...darkModePalette,
});
// @ts-ignore
const lightModeTheme = createTheme({
  ...theme,
  ...lightModePalette,
});

export default function ThemeProviderWrapper({ children }: PropsWithChildren) {
  const { customer } = useCustomer();

  const [selectedTheme, setSelectedTheme] = useState("light");

  useEffect(() => {
    if (selectedTheme === customer?.userConfig?.theme) {
      return;
    }
    setSelectedTheme(customer?.userConfig?.theme || "light");
  }, [customer?.userConfig?.theme]);

  // ? in userConfig? is only temporary since there might be old customers in users localstorage
  return <ThemeProvider theme={selectedTheme === "light" ? lightModeTheme : darkModeTheme}>{children}</ThemeProvider>;
}
