import React from "react";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import CaseDetailsItem from "../../../Common/CaseDetailsItem/CaseDetailsItem";

const TravelSpecificCaseDetails = ({ contractLawCase }: any) => {
  return (
    <Grid container>
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"reasonForDispute"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractNumber"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"bookingDate"} fieldType={"date"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"journeyStart"} fieldType={"date"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"journeyEnd"} fieldType={"date"} />
      {contractLawCase.reasonForDispute === "cancelled" && (
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerHasCancelled"} />
          {contractLawCase.contractualPartnerHasCancelled && (
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractualPartnerCancellationDate"} fieldType={"date"} />
          )}

          <CaseDetailsItem caseData={contractLawCase} fieldPath={"buyerPaidStatus"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"buyerPaidDate"} fieldType={"date"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"buyerPaidAmount"} fieldType={"money"} />
        </>
      )}
    </Grid>
  );
};

TravelSpecificCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

export default TravelSpecificCaseDetails;
