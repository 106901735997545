import { useParams } from "react-router-dom";

const useProductParams = () => {
  const { productName, productId } = useParams();

  return {
    productName: productName,
    productId: productId ? parseInt(productId) : 0,
  };
}

export default useProductParams;
