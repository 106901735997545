/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

const preleadTranslations = {
  grossSalary: {
    label: "Mtl. Bruttogehalt",
    question: "Wie hoch ist Ihr monatliches Bruttogehalt?",
  },
};

export default preleadTranslations;
