import { toast } from "react-toastify";

type addMessageProps = {
  type: 'success' | 'error' | 'info' | 'warning';
  text: string;
};

export function addMessage({ type, text }: addMessageProps) {
  toast[type](text);
}
