/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import Layout from "../../../Layouts/Layout";
import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";
import { FormProvider } from "../../../../provider/Form/FormProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, fetchResource, queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import PersonalAffection from "../../../../components/Form/ContractLaw/DataLeakContract/PersonalAffection";
import Container from "../../../../components/Container/Container";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import { fromBoolean, handleSimpleArray, reduceSimpleArray, toBoolean } from "../../../../services/formServiceFunctions";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import _cloneDeep from "lodash/cloneDeep";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import PersonalAffectionSummary from "../../../../components/Form/ContractLaw/DataLeakContract/PersonalAffectionSummary";
import { InquiryToCustomerStatus } from "../../../../types/Entities/InquiryToCustomer";
import { getCaseLink } from "../../../../services/productService";

export default function DataLeakPersonalAffectionPage() {
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [isApproval, setIsApproval] = useState(false);
  const [externalErrors] = useState({});
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const productApiUri = "/data_leak_contracts";
  const getProductId = () => {
    const { productId } = useParams();

    return productId ? parseInt(productId) : 0;
  };
  const productId = getProductId();
  const { data: product, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data),
  });

  const inquiryFilters = {
    product: "dataLeakContract",
    identifier: "dataLeakPersonalInvolvementInquiry",
    productId: productId,
    "status[0]": InquiryToCustomerStatus.STATUS_OPEN,
    "status[1]": InquiryToCustomerStatus.STATUS_OVERDUE,
    "status[2]": InquiryToCustomerStatus.STATUS_IN_PROGRESS,
  };
  const inquiryApiUri = "/inquiry_to_customers";
  const { data: inquiries } = useQuery(queryKeys.collection(inquiryApiUri, inquiryFilters), () => fetchCollection(inquiryApiUri, inquiryFilters), {
    enabled: !!product,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isApproval]);

  if (isLoading || !product || !inquiries) {
    return (
      <Layout>
        <Container>
          {/*@ts-ignore*/}
          <Container style={{ width: 250 }}>
            <LegalbirdLoader />
          </Container>
        </Container>
      </Layout>
    );
  }

  const currentInquiryToCustomer = inquiries[0];

  if (!currentInquiryToCustomer || currentInquiryToCustomer.identifier !== "dataLeakPersonalInvolvementInquiry") {
    return <Navigate to={getCaseLink(product)} />;
  }

  let personalAffection;

  if (product.personalAffection !== null) {
    personalAffection = {
      ...product.personalAffection,
      hadProblemsDueToCreditRating: fromBoolean(product.personalAffection.hadProblemsDueToCreditRating),
      emotionalState: reduceSimpleArray(product.personalAffection.emotionalState),
      purchasesPlannedInTheNearFuture: reduceSimpleArray(product.personalAffection.purchasesPlannedInTheNearFuture),
    };
  } else {
    personalAffection = {
      hadProblemsDueToCreditRating: {},
      creditRatingProblemsDescription: "",
      emotionalState: {},
      purchasesPlannedInTheNearFuture: {},
      purchasesPlannedInTheNearFutureOther: "",
      worriesFrequency: "",
      worriesFrequencyOther: "",
      personalStatement: "",
    };
  }

  const initialValues = {
    ...product,
    personalAffection: personalAffection,
  };

  const getMutationData = (values: AbstractCase) => {
    const updateData = _cloneDeep(values);

    if (!isApproval) {
      return {
        ...updateData.personalAffection,
        hadProblemsDueToCreditRating: toBoolean(updateData.personalAffection.hadProblemsDueToCreditRating),
        emotionalState: handleSimpleArray(updateData.personalAffection.emotionalState),
        purchasesPlannedInTheNearFuture: handleSimpleArray(updateData.personalAffection.purchasesPlannedInTheNearFuture),
      };
    } else {
      return {
        id: updateData.personalAffection.id,
        personalAffectionClient: updateData.personalAffection.personalAffectionClient,
        clearInquiry: true,
      };
    }
  };

  const handleSubmit = async ({ values }: { values: AbstractCase }) => {
    setIsLoadingInternal(true);

    const mutationData = getMutationData(values);

    await updateMutation.mutateAsync({
      uri: "/data_leak_personal_affections",
      id: mutationData.id,
      data: mutationData,
    });

    await queryClient.invalidateQueries(queryKeys.item("/data_leak_contracts", product.id));

    setIsLoadingInternal(false);

    if (!isApproval) {
      setIsApproval(true);
    } else {
      navigate("/servicewelt");
    }
  };

  return (
    <Layout backgroundType={"serviceworld"}>
      <Helmet>
        <title>Datenweitergabe - Persönliche Betroffenheit | Legalbird</title>
      </Helmet>
      <Container style={{ paddingTop: "4rem" }}>
        <FormProvider initialValues={initialValues} submitAction={handleSubmit} externalErrors={externalErrors}>
          {!isApproval && <PersonalAffection submitProps={{ isLoading: isLoadingInternal, isMinimal: true, hideBackButton: true }} />}
          {isApproval && (
            <PersonalAffectionSummary
              product={product}
              submitProps={{
                nextText: "Rückmeldung übermitteln",
                isLoading: isLoadingInternal,
                isMinimal: true,
                onBackClick: (e) => {
                  e.preventDefault();
                  setIsApproval(false);
                  return false;
                },
              }}
            />
          )}
        </FormProvider>
      </Container>
    </Layout>
  );
}
