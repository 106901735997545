import { useEffect, useRef, useState } from "react";
import { AbstractCase } from "../types/Entities/AbstractCase";
import { MediaObjectCombinationRequest } from "../types/Entities/MediaObject/MediaObjectCombinationRequest";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { createResource, CreateResourceParams, queryKeys, updateResource } from "../services/reactQuery/reactQueryService";
import { getProductData } from "../services/productService";

const useMediaObjectCombinationRequest = (product: AbstractCase, types: string[]) => {
  const queryClient = useQueryClient();
  const productQueryKey = queryKeys.item(getProductData(product.name, "apiUri"), product.id);
  const refreshCase = async () => queryClient.invalidateQueries(productQueryKey, { refetchType: "all" });
  const createMutation = useMutation(createResource, {
    onSuccess: refreshCase,
  });
  const updateMutation = useMutation(updateResource, {
    onSuccess: refreshCase,
  });
  const updateAction = async (id: number, data: Partial<MediaObjectCombinationRequest>) =>
    await updateMutation.mutateAsync({
      uri: "/media_object_combination_requests",
      id: id,
      data: data,
    });
  const [isLoading, setIsLoading] = useState(false);
  const initialized = useRef(false);
  let result: {
    mediaObjectCombinationRequests: Record<string, MediaObjectCombinationRequest>;
    isLoadingMediaObjectCombinationRequest: boolean;
    updateAction: (id: number, data: Partial<MediaObjectCombinationRequest>) => Promise<void>;
    hasAtLeastOneFileOfType: (mediaObjectCombinationRequests: Record<string, MediaObjectCombinationRequest>, type: string) => boolean;
  } = {
    mediaObjectCombinationRequests: {},
    isLoadingMediaObjectCombinationRequest: isLoading,
    updateAction: updateAction,
    hasAtLeastOneFileOfType: hasAtLeastOneFileOfType,
  };

  const initializeCombinationRequests = async () => {
    setIsLoading(true);
    await refreshCase();
    const currentProduct = queryClient.getQueryData<AbstractCase>(productQueryKey);
    if (!currentProduct) {
      return;
    }
    await Promise.all(ensureCombinationRequests(types, currentProduct, createMutation));
    setIsLoading(false);
  };

  useEffect(() => {
    if (!initialized.current) {
      initializeCombinationRequests();
      initialized.current = true;
    }
  }, []);

  types.forEach((type) => {
    result.mediaObjectCombinationRequests[type] = getMediaObjectCombinationRequestByType(product, type);
  });
  return result;
};

const hasAtLeastOneFileOfType = (mediaObjectCombinationRequests: Record<string, MediaObjectCombinationRequest>, type: string) => {
  return (mediaObjectCombinationRequests[type]?.filesToCombine[0]?.files.length || 0) > 0;
};

const ensureCombinationRequests = (types: string[], product: AbstractCase, createMutation: UseMutationResult<any, unknown, CreateResourceParams>) => {
  let promises: Promise<void>[] = [];
  types.forEach((type) => {
    if (getMediaObjectCombinationRequestByType(product, type)) {
      return;
    }
    promises.push(
      createMutation.mutateAsync({
        uri: "/media_object_combination_requests",
        data: {
          caseId: product.id,
          type: type,
        },
      })
    );
  });
  return promises;
};

const getMediaObjectCombinationRequestByType = (product: AbstractCase, type: string) => {
  const filteredMediaObjectCombinationRequests = product.mediaObjectCombinationRequests.filter(
    (mediaObjectCombination: MediaObjectCombinationRequest) => mediaObjectCombination.type === type
  );
  return filteredMediaObjectCombinationRequests.length > 0 ? filteredMediaObjectCombinationRequests[0] : null;
};

export default useMediaObjectCombinationRequest;
