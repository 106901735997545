import React from "react";
import { useForm } from "../../../../provider/Form/FormProvider";
import DateFieldConfigured from "../../ConfiguredFormElements/DateFieldConfigured/DateFieldConfigured";
import { FormHeaderWrapper } from "../../Common/FormHeaderWrapper/FormHeaderWrapper";
import TextFieldConfigured from "../../ConfiguredFormElements/TextFieldConfigured/TextFieldConfigured";
import RadioGroupConfigured from "../../ConfiguredFormElements/RadioGroupConfigured/RadioGroupConfigured";
import { ContractDetailsProps } from "../../../../types/ContractDetailsProps";
import Grid from "@mui/material/Grid";
import FormLegend from "../../Common/FormLegend/FormLegend";
import MobileProvidersAutocomplete from "../../MobileProvidersAutoComplete/MobileProvidersAutocomplete";

export default function DataLeakContractDetails({ product, submitProps }: ContractDetailsProps) {
  const { values, handleSubmit }: any = useForm();
  return (
    <FormHeaderWrapper submitProps={submitProps} handleSubmit={handleSubmit}>
      <Grid item xs={12}>
        <FormLegend>Bei welchem Mobilfunkanbieter sind Sie?</FormLegend>
        <MobileProvidersAutocomplete name={"mobileProviderOrganizationId"} requiredMessage={"Bitte gib deinen aktuellen Mobilfunkanbieter an"} />
      </Grid>
      <TextFieldConfigured caseData={product} fieldPath={"contractNumber"} required />
      <RadioGroupConfigured caseData={values} fieldPath={"salesChannel"} required />
      <Grid item xs={12}>
        <FormLegend>Für die SCHUFA / CRIF-Anfrage benötigen wir zudem noch Ihr Geburtsdatum.</FormLegend>
      </Grid>
      <DateFieldConfigured caseData={values} fieldPath={"customer.person.birthDate"} dateValidation={"past"} required />
    </FormHeaderWrapper>
  );
}
