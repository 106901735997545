import { useForm } from "../../provider/Form/FormProvider";
import React, { ReactNode, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectProps } from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { formValue } from "../../services/formServiceFunctions";
import _get from "lodash/get";
import { FormControlProps } from "@mui/material/FormControl/FormControl";
import { ValidatorType } from "../../types/ValidatorType";

type ValidatorSelectFieldProps<T> = Omit<SelectProps<T>, "margin"> &
  Pick<FormControlProps, "margin" | "fullWidth" | "disabled"> & {
    children: ReactNode;
    name: string;
    label: string;
    validators?: Array<ValidatorType>;
    dependentValidationFields?: Array<string>;
    helperText?: string | null;
  };
export default function ValidatorSelectField<T = unknown>({
  children,
  name,
  label,
  helperText,
  validators = [],
  dependentValidationFields = [],
  fullWidth = true,
  margin = "normal",
  disabled = false,
  ...rest
}: ValidatorSelectFieldProps<T>) {
  const { values, errors, setError, handleChange, handleBlur, registerValidators }: any = useForm();
  const error = !!_get(errors, name);
  useEffect(() => {
    registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators(name, [], []);
      setError(name, undefined);
    };
  }, [name]);

  return (
    <FormControl fullWidth={fullWidth} margin={margin} error={error} disabled={disabled}>
      <InputLabel>{label}</InputLabel>
      <Select<T> name={name} label={label} value={formValue(values, name)} onChange={handleChange} onBlur={handleBlur} {...rest}>
        {children}
      </Select>
      <FormHelperText>{_get(errors, name) || helperText}</FormHelperText>
    </FormControl>
  );
}
