import { leftIcon } from "../../../../theme/commonStyle";

const signatureStyle = {
  primary: {
    color: "primary.main",
  },
  signatureCaption: {
    textAlign: "center",
  },
  signatureImage: {
    maxWidth: "100%",
  },
  signatureButton: {
    width: "280px",
  },
  signatureContainer: {
    marginBottom: "1rem",
  },
  leftIcon,
};
export default signatureStyle;
