import React from "react";
import { getProductData, getProductNameBy } from "../../../services/productService";
import Layout from "../../Layouts/Layout";
import Container from "../../../components/Container/Container";
import LegalbirdLoader from "../../../components/ContentLoader/LegalbirdLoader";
import ApplicationForm from "../../../components/ApplicationForm/ApplicationForm";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../hooks/useProductParams";

export default function ApplicationFormPage() {
  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");
  const { data: product, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));

  if (isLoading) {
    return (
      <Layout active={"serviceworld"} withScrollToTopButton={false}>
        <Container>
          <Container sx={{ width: 250 }}>
            <LegalbirdLoader />
          </Container>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout active={"serviceworld"} withScrollToTopButton={false}>
      <ApplicationForm product={product} />
    </Layout>
  );
}
