import React from "react";
import SummaryDetailsTravel from "./ContractSpecificDetails/SummaryDetailsTravel";
import SummaryDetailsPurchase from "./ContractSpecificDetails/SummaryDetailsPurchase";
import SummaryDetailsVehicle from "./ContractSpecificDetails/SummaryDetailsVehicle";
import SummaryDetailsSubscription from "./ContractSpecificDetails/SummaryDetailsSubscription";
import SummaryDetailsMembership from "./ContractSpecificDetails/SummaryDetailsMembership";
import SummaryDetailsService from "./ContractSpecificDetails/SummaryDetailsService";
import SummaryDetailsEvent from "./ContractSpecificDetails/SummaryDetailsEvent";
import SummaryDetailsProvider from "./ContractSpecificDetails/SummaryDetailsProvider";
import { Box } from "@mui/material";
import { scrollToAnchor } from "../../../../theme/commonStyle";
import SummaryDetailsDataLeak from "./ContractSpecificDetails/SummaryDetailsDataLeak";

interface SummaryContractDetailsProps {
  caseData: {
    name: string;
  };
  formUrl: string;
  summarySectionAnchorId?: string;
}

const summaryContractDetailsAnchorId = "contractDetails";

const SummaryContractDetails = ({ caseData, formUrl}: SummaryContractDetailsProps) => {
  function getContractDetails(caseData: any) {
    switch (caseData.name) {
      case "travelContract":
        return <SummaryDetailsTravel formUrl={formUrl} caseData={caseData} />;
      case "purchaseContract":
        return <SummaryDetailsPurchase formUrl={formUrl} caseData={caseData} />;
      case "vehicleContract":
        return <SummaryDetailsVehicle formUrl={formUrl} caseData={caseData} />;
      case "subscriptionContract":
        return <SummaryDetailsSubscription formUrl={formUrl} caseData={caseData} />;
      case "membershipContract":
        return <SummaryDetailsMembership formUrl={formUrl} caseData={caseData} />;
      case "serviceContract":
        return <SummaryDetailsService formUrl={formUrl} caseData={caseData} />;
      case "eventContract":
        return <SummaryDetailsEvent formUrl={formUrl} caseData={caseData} />;
      case "providerContract":
        return <SummaryDetailsProvider formUrl={formUrl} caseData={caseData} />;
      case "dataLeakContract":
        return <SummaryDetailsDataLeak formUrl={formUrl} caseData={caseData} />;
      default:
        return null;
    }
  }

  return (
    <>
      <Box id={summaryContractDetailsAnchorId} sx={scrollToAnchor} />
      {getContractDetails(caseData)}
    </>
  );
};

export default SummaryContractDetails;
