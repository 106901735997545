/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React, {ReactNode} from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

type ButtonLoadingProps = {
  isLoading?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  loadingText?: string;
} & ButtonProps<React.ElementType>;

const ButtonLoading = ({ isLoading = false, children, disabled = false, loadingText = "", ...rest }: ButtonLoadingProps) => {
  return (
    <Button disabled={disabled || isLoading} {...rest}>
      {isLoading ? (
        <>
          <CircularProgress size={28} />
          {loadingText && <Typography sx={{ paddingLeft: "10px" }}>{loadingText}</Typography>}
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export default ButtonLoading;
