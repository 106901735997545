import React from "react";
import quotationCardStyle from "./quotationCardStyle";
import { Box, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";

type QuotationCardProps = {
  text: string;
  sx?: SxProps;
};

export default function QuotationCard({ text, sx = {} }: QuotationCardProps) {
  return (
    <Paper sx={quotationCardStyle.card}>
      <Box sx={quotationCardStyle.textContainer}>
        <Typography sx={sx} variant={"body2"}>
          {text}
        </Typography>
      </Box>
    </Paper>
  );
}
