import React from "react";
import { useForm } from "../../../../provider/Form/FormProvider";
import RadioGroupConfigured from "../../ConfiguredFormElements/RadioGroupConfigured/RadioGroupConfigured";
import { ContractDetailsProps } from "../../../../types/ContractDetailsProps";
import DateFieldConfigured from "../../ConfiguredFormElements/DateFieldConfigured/DateFieldConfigured";
import TextFieldConfigured from "../../ConfiguredFormElements/TextFieldConfigured/TextFieldConfigured";
import SingleSelectFieldConfigured from "../../ConfiguredFormElements/SingleSelectFieldConfigured/SingleSelectFieldConfigured";
import NumberFieldConfigured from "../../ConfiguredFormElements/NumberFieldConfigured/NumberFieldConfigured";
import { FormHeaderWrapper } from "../../Common/FormHeaderWrapper/FormHeaderWrapper";

export default function MembershipContractDetails({ product, submitProps }: ContractDetailsProps) {
  const { values, handleSubmit } = useForm();
  return (
    <FormHeaderWrapper submitProps={submitProps} handleSubmit={handleSubmit}>
      <RadioGroupConfigured caseData={values} fieldPath={"membershipType"} required />
      <RadioGroupConfigured caseData={values} fieldPath={"reasonForDispute"} required />
      <TextFieldConfigured caseData={values} fieldPath={"contractNumber"} required />
      {values.reasonForDispute === "priceIncrease" && (
        <DateFieldConfigured caseData={values} fieldPath={"priceIncreaseAnnouncementDate"} dateValidation={"pastOrToday"} required />
      )}
      {(values.reasonForDispute === "cancellationRefused" || values.reasonForDispute === "cancellationContractExtension") && (
        <>
          <DateFieldConfigured caseData={values} fieldPath={"cancellationDate"} dateValidation={"pastOrToday"} required />
          <DateFieldConfigured caseData={values} fieldPath={"endOfContractDate"} />
        </>
      )}
      {values.reasonForDispute === "cancellationRefused" && (
        <>
          <RadioGroupConfigured caseData={product} fieldPath={"opponentReactionToCancellation"} required />
          {values.opponentReactionToCancellation === "differentDate" && <DateFieldConfigured caseData={values} fieldPath={"endOfContractDateOpponent"} />}
        </>
      )}
      <SingleSelectFieldConfigured caseData={values} fieldPath={"feePaymentFrequency"} />
      {values.feePaymentFrequency && (
        <>
          <NumberFieldConfigured caseData={values} fieldPath={"fee"} fieldType={"money"} numberType={"float"} />
          <NumberFieldConfigured caseData={values} fieldPath={"numberUnpaidPeriods"} />
        </>
      )}
    </FormHeaderWrapper>
  );
}
