import React from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ErrorOutline } from "@mui/icons-material";

export default function MessageCenterNoCasesMessage() {
  return (
    <>
      <Typography textAlign={"center"} sx={{ margin: "2rem" }}>
        <Stack direction={"row"} spacing={1}>
          <ErrorOutline />
          <Typography>
            Sobald Sie einen Fall bei uns haben, können Sie von hier aus ganz bequem direkt mit Ihrem Anwalt kommunizieren. Sie haben vorab z.B. eine Frage zum
            Ablauf? Dann schreiben Sie uns eine E-Mail an <a href={"mailto:service@legalbird.de"}>service@legalbird.de</a>
          </Typography>
        </Stack>
      </Typography>
    </>
  );
}
