import React from "react";
import SummaryDetailsItem from "../../../Common/SummaryDetailsItem/SummaryDetailsItem";
import SummaryDetailsProps from "../../../../../types/SummaryDetailsProps";

const SummaryDetailsProvider = ({ caseData, formUrl }: SummaryDetailsProps) => {
  return (
    <>
      <SummaryDetailsItem fieldPath={"membershipType"} formUrl={formUrl} caseData={caseData} />

      <SummaryDetailsItem caseData={caseData} formUrl={formUrl} fieldPath={"providerType"} />
      <SummaryDetailsItem caseData={caseData} formUrl={formUrl} fieldPath={"reasonForDispute"} />

      {caseData.reasonForDispute === "cancellationByProvider" && (
        <SummaryDetailsItem caseData={caseData} formUrl={formUrl} fieldPath={"cancellationReceivedDate"} fieldType={"date"} />
      )}
      {caseData.reasonForDispute === "priceIncrease" && caseData.priceIncreaseAnnouncementDate && (
        <SummaryDetailsItem caseData={caseData} formUrl={formUrl} fieldPath={"priceIncreaseAnnouncementDate"} fieldType={"date"} />
      )}
      {caseData.reasonForDispute === "cancellationRefused" && (
        <>
          <SummaryDetailsItem caseData={caseData} formUrl={formUrl} fieldPath={"cancellationDate"} fieldType={"date"} />
          <SummaryDetailsItem caseData={caseData} formUrl={formUrl} fieldPath={"endOfContractDate"} fieldType={"date"} />
          <SummaryDetailsItem caseData={caseData} formUrl={formUrl} fieldPath={"opponentReactionToCancellation"} />
          {caseData.opponentReactionToCancellation === "differentDate" && (
            <SummaryDetailsItem caseData={caseData} formUrl={formUrl} fieldPath={"endOfContractDateOpponent"} fieldType={"date"} />
          )}
        </>
      )}
      {!!caseData.contractNumber && <SummaryDetailsItem caseData={caseData} formUrl={formUrl} fieldPath={"contractNumber"} />}
    </>
  );
};

export default SummaryDetailsProvider;
