import React from "react";
import { Grid } from "@mui/material";
import { translate } from "../../../services/translations/translations";
import { requiredValidator } from "../../../services/validationRules";
import NumberFieldConfigured from "../ConfiguredFormElements/NumberFieldConfigured/NumberFieldConfigured";
import DateFieldConfigured from "../ConfiguredFormElements/DateFieldConfigured/DateFieldConfigured";
import ValidatorTextField from "../../FormFields/ValidatorTextField";

type RentalContractReasonForDisputeDetailsProps = {
  values: Record<string, any>;
};
export default function RentalContractReasonForDisputeDetails({ values }: RentalContractReasonForDisputeDetailsProps) {
  switch (values.reasonForDispute) {
    case "housingDeficiencies":
      return (
        <>
          <Grid item xs={12}>
            <ValidatorTextField
              label={translate(`rentalContract.housingDeficienciesDescriptionClient.label`, values)}
              name="housingDeficienciesDescriptionClient"
              helperText={translate(`rentalContract.housingDeficienciesDescriptionClient.helperText`, values)}
              validators={[requiredValidator()]}
              multiline
              rows={6}
            />
          </Grid>
        </>
      );
    case "utilitiesStatement":
      return (
        <>
          <DateFieldConfigured caseData={values} fieldPath="rentalContractStartDate" required />
          <DateFieldConfigured caseData={values} fieldPath="rentalContractEndDate" />
        </>
      );
    case "rentIncrease":
      return (
        <>
          <NumberFieldConfigured caseData={values} fieldPath={"previousBasicRent"} fieldType={"money"} />
          <NumberFieldConfigured caseData={values} fieldPath={"previousRentIncludingHeating"} fieldType={"money"} />
          <NumberFieldConfigured caseData={values} fieldPath={"futureBasicRent"} fieldType={"money"} />
          <NumberFieldConfigured caseData={values} fieldPath={"futureRentIncludingHeating"} fieldType={"money"} />
        </>
      );
    case "deposit":
      return (
        <>
          <NumberFieldConfigured caseData={values} fieldPath={"depositAmount"} fieldType={"money"} required />
          <DateFieldConfigured caseData={values} fieldPath="rentalContractStartDate" required />
          <DateFieldConfigured caseData={values} fieldPath="rentalContractEndDate" required />
        </>
      );
    case "landlordHasCancelled":
      return (
        <>
          <DateFieldConfigured caseData={values} fieldPath="cancellationReceivedDate" required />
          <DateFieldConfigured caseData={values} fieldPath="rentalContractStartDate" required />
          <DateFieldConfigured caseData={values} fieldPath="rentalContractEndDate" required />
        </>
      );
    default:
      return null;
  }
}
