const emailConfirmationBoxStyle = {
  container: {
    padding: "0.25rem 1rem 1rem",
    marginBottom: "25px",
  },
  headline: {
    color: "primary.main",
    fontWeight: 500,
    textAlign: "center",
    margin: "3rem 0",
  },
  content: {
    maxWidth: "700px",
    margin: "0 auto",
    textAlign: "center",
  },
  divider: {
    margin: "1rem 0",
  },
};

export default emailConfirmationBoxStyle;
