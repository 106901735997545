import React from "react";
import Container from "../../../components/Container/Container";
import LegalbirdLoader from "../../../components/ContentLoader/LegalbirdLoader";
import Layout from "../../Layouts/Layout";

export default function LegalbirdLoaderPage() {
  return (
    <Layout>
      <Container>
        <LegalbirdLoader />
      </Container>
    </Layout>
  );
}
