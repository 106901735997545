/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import * as PropTypes from "prop-types";
import summaryStyle from "./summaryStyle";
import Grid from "@mui/material/Grid";
import GridEditRow from "../../Common/GridEditRow/GridEditRow";
import Typography from "@mui/material/Typography";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import { Box } from "@mui/material";

type PersonalAffectionProps = {
  product: AbstractCase;
  formPath: string;
  title: string;
};
const PersonalAffection = ({ product, formPath, title }: PersonalAffectionProps) => {
  return (
    <>
      <Grid item xs={12} sx={summaryStyle.subHeadline}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"name"} sx={summaryStyle.scrollToAnchor} />
        Aussage zur persönlichen Betroffenheit
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
        }}
      >
        <div style={{ whiteSpace: "pre-line" }}>{product.personalAffection.personalAffectionClient}</div>
      </GridEditRow>
    </>
  );
};

PersonalAffection.propTypes = {
  formPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PersonalAffection;
