import React from "react";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import CaseDetailsItem from "../../../Common/CaseDetailsItem/CaseDetailsItem";
import SpecificCaseDetailsProps from "../../../../../types/SpecificCaseDetailsProps";

const PurchaseSpecificCaseDetails = ({ contractLawCase }: SpecificCaseDetailsProps) => {
  return (
    <Grid container>
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"buyerOrSeller"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"channel"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractNumber"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"itemTitle"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"articleNumber"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"purchasePrice"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"buyerPaidStatus"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"goodsOrServiceProvidedStatus"} />

      {contractLawCase.goodsOrServiceProvidedStatus !== "none" && (
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"locationOfGood"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"hasDefects"} />
        </>
      )}

      {contractLawCase.hasDefects && (
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"defectsDescriptionClient"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"numberRepairAttempts"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"numberReplacementAttempts"} />
        </>
      )}
    </Grid>
  );
};

PurchaseSpecificCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

export default PurchaseSpecificCaseDetails;
