import { AbstractCase } from "../../types/Entities/AbstractCase";

const defaultAuthorizationItems = [
  "außergerichtlichen Interessensvertretung,",
  "Prozessführung einschließlich Einlegung und Rücknahme von Rechtsmitteln,",
  "Entgegennahme von Zustellungen,",
  "Empfangnahme von Geldern,",
  "Begründung und Aufhebung von Vertragsverhältnissen und",
  "Abgabe von einseitigen Willenserklärungen, insbesondere zum Ausspruch von ordentlichen und außerordentlichen Kündigungen.",
];

const defaultOverviewSteps = [
  "Sie erteilen uns die Vollmacht, damit wir Sie vertreten dürfen.",
  "Wir prüfen Ihre rechtlichen Möglichkeiten und melden uns dann umgehend bei Ihnen zurück.",
  "Wir setzen uns mit der Gegenseite in Verbindung und versuchen Ihren Fall außergerichtlich zu klären.",
  "Sollte eine außergerichtliche Klärung nicht möglich sein, vertreten wir Sie selbstverständlich auch im gerichtlichen Verfahren.",
];

export function getPowerOfAttorneyOverviewSteps(product: AbstractCase): string[] {
  switch (product.name) {
    case "dataLeakContract":
      return [
        defaultOverviewSteps[0],
        "Wir fordern für Sie einen Daten-Auszug bei den Auskunfteien an und stellen Deckungsanfrage bei Ihrer Versicherung.",
        "Nachdem ein Datenauszug vorliegt, prüfen wir Ihre rechtliche Grundlage.",
        "Wenn es zu einer unrechtmäßigen Datenweitergabe kam, setzen wir uns umgehend mit der Gegenseite in Verbindung und versuchen, Ihren Fall zunächst außergerichtlich zu klären.",
        defaultOverviewSteps[3],
      ];
    default:
      return defaultOverviewSteps;
  }
}

export function getPowerOfAttorneyAuthorizationItems(product: AbstractCase): string[] {
  switch (product.name) {
    case "dataLeakContract":
      return [
        "Geltendmachung von umfassenden datenschutzrechtlichen Auskunftsansprüchen gem. Art. 15 DSGVO - insbesondere die Einholung der Auskunft und auch der Erhalt derselben,",
        ...defaultAuthorizationItems,
      ];
    default:
      return defaultAuthorizationItems;
  }
}
