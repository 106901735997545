import React from "react";
import Layout from "../../Layouts/Layout";
import ServiceWorldInitialCase from "../../../components/Serviceworld/ServiceWorldInitialCase";

const ServiceWorldInitialCasePage = () => {
  return (
    <Layout active={"serviceworld"} backgroundType={"serviceworld"}>
      <ServiceWorldInitialCase />
    </Layout>
  );
};

export default ServiceWorldInitialCasePage;
