import React from "react";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import ContentBox from "../../Box/ContentBox/ContentBox";
import InquiryToCustomer from "../../../types/Entities/InquiryToCustomer";
import {Box, Button, Stack, Typography} from "@mui/material";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../../services/productService";
import { getInquiriesBoxHeadingContent } from "../../../services/inquiryToCustomerService";
import moment from "moment";

type InquiriesToCustomerCaseBoxProps = {
  product: AbstractCase;
  inquiries: InquiryToCustomer[];
};

export default function InquiriesToCustomerCaseBox({ product, inquiries }: InquiriesToCustomerCaseBoxProps) {
  const { headline, icon } = getInquiriesBoxHeadingContent(inquiries);

  return (
    <ContentBox headline={headline} icon={icon}>
      <Stack spacing={4}>
        {inquiries
          .sort((a, b) => (moment(a.deadline).isAfter(moment(b.deadline)) ? 1 : -1))
          .map((inquiry) => (
            <InquiryToCustomerEntry key={inquiry.id} product={product} inquiryToCustomer={inquiry} />
          ))}
      </Stack>
    </ContentBox>
  );
}

type InquiryToCustomerEntryProps = {
  product: AbstractCase;
  inquiryToCustomer: InquiryToCustomer;
};
const InquiryToCustomerEntry = ({ inquiryToCustomer, product }: InquiryToCustomerEntryProps) => {
  if (!inquiryToCustomer) {
    return null;
  }

  let text;
  let buttonText;

  switch (inquiryToCustomer.inquiryType) {
    case "appointment":
      text = "In Ihrem Fall ist eine telefonische Rücksprache notwendig. Bitte vereinbaren Sie einen Beratungstermin.";
      buttonText = "Termin vereinbaren";
      break;
    case "informationAndDocuments":
      text = "Bitte melden Sie sich mit den angefragten Informationen und Dokumenten bei uns zurück, damit Ihr Fall bearbeitet werden kann.";
      buttonText = "Rückmeldung geben";
      break;
    case "documents":
      text = "Bitte melden Sie sich mit den angefragten Dokumenten bei uns zurück, damit Ihr Fall bearbeitet werden kann.";
      buttonText = "Rückmeldung geben";
      break;
    case "link":
      text = "Bitte geben Sie uns Rückmeldung, damit Ihr Fall weiter bearbeitet werden kann.";
      buttonText = "Rückmeldung geben";
      break;
    case "information":
    default:
      text = "Bitte melden Sie sich mit den angefragten Informationen bei uns zurück, damit Ihr Fall bearbeitet werden kann.";
      buttonText = "Rückmeldung geben";
      break;
  }

  return (
    <Box>
      <Typography>{text}</Typography>
      <Button sx={{ marginTop: "1rem" }} fullWidth variant={"contained"} component={Link} to={getCaseLink(product) + "/anfrage/" + inquiryToCustomer.id}>
        {buttonText}
      </Button>
    </Box>
  );
};
