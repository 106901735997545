import { Theme } from "@mui/material";

const partnerLogoSectionStyle = {
  divorceContainer: {
    textAlign: "center",
    paddingTop: "3rem",
  },
  settlementContainer: {
    textAlign: "center",
    paddingBottom: "2rem",
  },
  smallPaddingContainer: {
    textAlign: "center",
    paddingBottom: "0.5rem",
    paddingTop: "0.5rem",
  },
  logo: {
    maxWidth: "300px",
    maxHeight: "120px",
  },
  logoText: {
    margin: 0,
  },
  logoGridContainer: (theme: Theme) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  }),
  logoGridItem: {
    margin: ".5rem",
  },
};

export default partnerLogoSectionStyle;
