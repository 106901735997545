import React from "react";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import _includes from "lodash/includes";
import CaseDetailsItem from "../../../Common/CaseDetailsItem/CaseDetailsItem";

const ServiceSpecificCaseDetails = ({ contractLawCase }: any) => {
  return (
    <Grid container>
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"reasonForDispute"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"serviceTitle"} />
      {contractLawCase.reasonForDispute === "costEstimateExceeded" && (
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"costEstimate"} fieldType={"money"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"costEstimateAdjustment"} />

          {_includes(["asap", "tooLate"], contractLawCase.costEstimateAdjustment) && (
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"costEstimateAdjustmentDate"} fieldType={"date"} />
          )}
        </>
      )}
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"costReal"} fieldType={"money"} />
      {_includes(["hasDefects", "unfinishedWork"], contractLawCase.reasonForDispute) && (
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"defectsDescriptionClient"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"numberRepairAttempts"} />
        </>
      )}
    </Grid>
  );
};

ServiceSpecificCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

export default ServiceSpecificCaseDetails;
