/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

export const refaDosAndDonts = [
  {
    doHeadline: "Wir Duzen uns",
    doText: "Und das von Anfang an - und nicht erst seit eine Karriereberatung es uns empfohlen hat.",
    dontHeadline: "Strenge hierarchische Strukturen",
    dontText: "Uns ist wichtig, dass du dich wohl und wertgeschätzt fühlst. Nur so kann eine langfristig angenehme Arbeitsatmosphäre bewahrt werden.",
  },
  {
    doHeadline: "Wir bieten flexible Arbeitszeit",
    doText: "Du möchtest morgens später anfangen oder deine Mittagspause verlängern? Kein Problem!",
    dontHeadline: "Feste Arbeitszeiten",
    dontText: "Denn du entscheidest, unter welchen Umständen du am besten deine Leistung erbringst.",
  },
  {
    doHeadline: "Mobiles Arbeiten",
    doText: "Heute im Büro, aber morgen lieber von Zuhause? Du weißt, wo du dich am besten konzentrieren kannst! Und wir freuen uns, dich hin und wieder auch im real-life zu sehen und persönlich kennen zu lernen!",
    dontHeadline: "Anwesenheitspflicht",
    dontText: "Du definierst dich nicht über deine Anwesenheit im Büro? Perfekt, wir dich auch nicht!",
  },
  {
    doHeadline: "Ein junges dynamisches Team",
    doText: "Unsere RechtsanwältInnen sind ein motiviertes Team, die deinen Input zu schätzen wissen!",
    dontHeadline: "Alte “Diktatoren”",
    dontText: "Hier wird nicht nach Diktat getippt! Denn wir wissen, dass du viel mehr kannst!",
  },
  {
    doHeadline: "Kollegialer Austausch",
    doText: "Ganz wie es dir passt, kannst du dich mit deinen KollegInnen unterhalten oder auch mal während der Arbeit Musik hören!",
    dontHeadline: "Telefondienste",
    dontText: "Wir wollen nicht, dass du ständig telefonieren musst!",
  },
  {
    doHeadline: "Echte Benefits",
    doText: "Bei uns erhältst du nicht nur eine überdurchschnittliche Bezahlung, sondern auch ein Deutschlandticket sowie eine Urban-Sports-Mitgliedschaft bezuschusst.",
    dontHeadline: "Nur Obstkörbe",
    dontText: "Wir wissen, dass es mehr braucht, um gute MitarbeiterInnen glücklich zu machen! Trotzdem haben wir einen Obstkorb - der ist schließlich obligatorisch.",
  },
  {
    doHeadline: "Karrieremöglichkeiten",
    doText: "Du magst deinen Job und du willst, dass es so bleibt? Wir auch! Deswegen kannst du dich bei uns intern und extern fortbilden und im Zweifel wird der Job dir angepasst - nicht umgekehrt!",
    dontHeadline: "Den immer gleichen Job",
    dontText: "“Das haben wir schon immer so gemacht” hältst du für eine unkluge Arbeitsanweisung? Wir auch! Die Arbeitswelt ist im stetigen Wandel. Dem sollte sich deine Stellenbeschreibung anpassen können!",
  },
  {
    doHeadline: "Eigene Legal-Tech Software",
    doText: "Wir haben unsere eigene IT! Nur so ist der Austausch gewährleistet und das Programm zum Bearbeiten von Mandaten wird täglich an unsere Anforderungen angepasst",
    dontHeadline: "Papierakten",
    dontText: "Last but (definitely) not least: Das Suchen von Akten gibt es bei uns nicht!",
  },
];

export const attorneyDosAndDonts = [
  {
    doHeadline: "Wir Duzen uns",
    doText: "Und das von Anfang an - und nicht erst seit eine Karriereberatung es uns empfohlen hat.",
    dontHeadline: "Strenge hierarchische Strukturen",
    dontText: "Uns ist wichtig, dass du dich wohl und wertgeschätzt fühlst. Nur so kann eine langfristig angenehme Arbeitsatmosphäre bewahrt werden.",
  },
  {
    doHeadline: "Wir bieten flexible Arbeitszeit",
    doText: "Du möchtest morgens später anfangen oder deine Mittagspause verlängern? Kein Problem!",
    dontHeadline: "Unzählige Überstunden",
    dontText: "Denn du entscheidest, unter welchen Umständen du am besten deine Leistung erbringst. Insbesondere hast du bei uns tatsächlich faire Arbeitszeiten!",
  },
  {
    doHeadline: "Mobiles Arbeiten",
    doText: "Heute im Büro, aber morgen lieber von Zuhause? Du weißt, wo du dich am besten konzentrieren kannst! Und wir freuen uns, dich hin und wieder auch im real-life zu sehen und persönlich kennen zu lernen!",
    dontHeadline: "Anwesenheitspflicht",
    dontText: "Du definierst dich nicht über deine Anwesenheit im Büro? Perfekt, wir dich auch nicht!",
  },
  {
    doHeadline: "Ein junges dynamisches Team",
    doText: "Du arbeitest gemeinsam mit einem hochmotivierten Team von jungen und sympathischen Anwälten zusammen und profitierst von einer hohen Dynamik sowie kreativen, zeitgemäßen Lösungsansätzen!",
    dontHeadline: "Veraltete Arbeitsweisen",
    dontText: "Hier hortet niemand eigene Vorlagen. Du musst nicht in dicken Papierwälzern die Lösung zu deinem Rechtsproblem finden. Wir arbeiten digital und gemeinsam!",
  },
  {
    doHeadline: "Direkter Mandantenkontakt",
    doText: "Von Anfang an hast du eigenen Mandantenkontakt - ganz einfach digital und telefonisch. So ist eine zielgerichtete schnelle Kommunikation sichergestellt.",
    dontHeadline: "Arbeit im Hinterzimmer",
    dontText: "Alleine im Einzelbüro ohne Kontakt zu Mandanten oder Kollegen? Nicht bei uns!",
  },
  {
    doHeadline: "Echte Benefits",
    doText: "Bei uns erhältst du nicht nur eine überdurchschnittliche Bezahlung, sondern auch ein Deutschlandticket sowie eine Urban-Sports-Mitgliedschaft bezuschusst",
    dontHeadline: "Nur Obstkörbe",
    dontText: "Wir wissen, dass es mehr braucht, um gute MitarbeiterInnen glücklich zu machen! Trotzdem haben wir einen Obstkorb - der ist schließlich obligatorisch.",
  },
  {
    doHeadline: "Karrieremöglichkeiten",
    doText: "Du magst deinen Job und du willst, dass es so bleibt? Wir auch! Deswegen kannst du dich bei uns intern und extern fortbilden. Gerne kannst du auch einen Fachanwaltstitel anstreben!",
    dontHeadline: "Den immer gleichen Job",
    dontText: "“Das haben wir schon immer so gemacht” hältst du für eine unkluge Arbeitsanweisung? Wir auch! Die Arbeitswelt ist im stetigen Wandel. Dem sollte sich deine Stellenbeschreibung anpassen können!",
  },
  {
    doHeadline: "Eigene Legal-Tech Software",
    doText: "Wir haben unsere eigene IT! Nur so ist der Austausch gewährleistet und das Programm zum Bearbeiten von Mandaten wird täglich an unsere Anforderungen angepasst",
    dontHeadline: "Keine Papierakten",
    dontText: "Last but (definitely) not least: Dicke Papierakten gibt es bei uns nicht!",
  },
];
