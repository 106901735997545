import React, { useEffect, useState } from "react";
import CalendlyBookingWidget from "../../Calendly/CalendlyBookingWidget";
import { useCustomer } from "../../../provider/Customer/CustomerProvider";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import ApiClient, { apiPut } from "../../../services/apiClient";
import { getCalendlyDataFromServiceWorldCalendlyUrl } from "../../../services/calendlyService";
import { Navigate } from "react-router-dom";
import { getCaseLink } from "../../../services/productService";
import { Box, Paper, TextField, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ButtonLoading from "../../Button/ButtonLoading";
import InquiryToCustomer, { InquiryToCustomerStatus } from "../../../types/Entities/InquiryToCustomer";
import { queryKeys } from "../../../services/reactQuery/reactQueryService";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";

type InquiryToCustomerAppointmentBoxProps = {
  product: AbstractCase;
  inquiryToCustomer: InquiryToCustomer
};

export default function InquiryToCustomerAppointmentBox({ product, inquiryToCustomer }: InquiryToCustomerAppointmentBoxProps) {
  const { customer } = useCustomer();
  const [calendlyLink, setCalendlyLink] = useState<string>("");
  const [denyAppointment, setDenyAppointment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkError, setCheckError] = useState<boolean>(false);
  const [inquiryTextAppointmentCustomer, setInquiryTextAppointmentCustomer] = useState<string>("");
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    ApiClient.get(`/get_inquiry_calendly_link/${inquiryToCustomer.id}`).then((result) => setCalendlyLink(result));
  }, []);

  if (!customer || !calendlyLink) {
    return null;
  }

  const { appointmentType, responsibleParty, utmSource } = getCalendlyDataFromServiceWorldCalendlyUrl(calendlyLink);

  if (!appointmentType || !responsibleParty || !utmSource) {
    return <Navigate to={getCaseLink(product)} />;
  }

  const handleSubmitDeny = async () => {
    if (inquiryTextAppointmentCustomer.length < 5) {
      setCheckError(true);
      return;
    }
    setIsLoading(true);
    await apiPut("/inquiry_to_customers/" + inquiryToCustomer.id, {
      status: InquiryToCustomerStatus.STATUS_DONE,
      doneBy: "customer",
      inquiryTextAppointmentCustomer: inquiryTextAppointmentCustomer,
    });
    setIsLoading(false);

    await queryClient.invalidateQueries(queryKeys.all("/inquiry_to_customers"));
    navigate(getCaseLink(product) + "/anfrage/beantwortet");
  };

  return (
    <Paper sx={{ padding: "1rem" }}>
      <Box sx={{maxWidth: "800px", margin: "1rem auto", textAlign: "center"}}>
        <Typography variant={"h4"}>Ihre Terminbuchung</Typography>
        <Checkbox sx={{ marginRight: "1rem" }} checked={denyAppointment} onChange={() => setDenyAppointment(!denyAppointment)} />
        Das Anliegen hat sich erledigt oder der Termin ist aus anderen Gründen nicht (mehr) notwendig
      </Box>
      {!denyAppointment ? (
        <CalendlyBookingWidget
          appointmentType={appointmentType}
          responsibleParty={responsibleParty}
          customer={customer}
          product={product}
          utmSource={utmSource}
        />
      ) : (
        <Box sx={{maxWidth: "800px", margin: "auto", padding: "1rem"}}>
          <TextField
            fullWidth={true}
            label="Ihre Anmerkungen"
            name="inquiryTextAppointmentCustomer"
            value={inquiryTextAppointmentCustomer}
            onChange={({ target }) => {setCheckError(false); setInquiryTextAppointmentCustomer(target.value)}}
            onBlur={() => setCheckError(true)}
            placeholder={"Bitte teilen Sie hier kurz mit, warum der Termin Ihrer Meinung nach nicht (mehr) notwendig ist."}
            error={checkError && inquiryTextAppointmentCustomer.length < 5}
            helperText={(checkError && inquiryTextAppointmentCustomer.length < 5) && "Bitte teilen Sie uns mit, warum der Termin nicht nötig ist"}
            multiline
            inputProps={{
              maxLength: 1000,
            }}
            rows={5}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <ButtonLoading
            sx={{marginTop: "1rem"}}
            fullWidth={true}
            variant={"contained"}
            onClick={handleSubmitDeny}
            isLoading={isLoading}
            >
            Terminanfrage ablehnen
          </ButtonLoading>
        </Box>
      )}
    </Paper>
  );
}
