import React from "react";
import Grid from "@mui/material/Grid";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { isRequired, postalCodeDefault, requiredValidator } from "../../../../services/validationRules";
import FormLegend from "../FormLegend/FormLegend";
import wizardStyle from "../../wizardStyle";

type PostOfficeBoxAddressProps = {
  path: string;
  headline?: string;
  fieldsAreRequired?: boolean;
  disabled?: boolean;
};
const PostOfficeBoxAddress = ({ path, headline = "Wo wohnen Sie?", fieldsAreRequired = true, disabled = false }: PostOfficeBoxAddressProps) => {
  return (
    <>
      {headline && (
        <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
          <FormLegend>{headline}</FormLegend>
        </Grid>
      )}
      <Grid item xs={12}>
        <ValidatorTextField
          name={`${path}.postOfficeBoxNumber`}
          label="Postfach"
          disabled={disabled}
          validators={fieldsAreRequired ? [requiredValidator("Bitte geben Sie das Postfach an")] : []}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={wizardStyle.gridSpace}>
        <ValidatorTextField
          name={`${path}.postOfficeBoxPostalCode`}
          label="Postfach PLZ"
          disabled={disabled}
          validators={fieldsAreRequired ? [requiredValidator("Bitte geben Sie die Postleitzahl an"), postalCodeDefault] : [postalCodeDefault]}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ValidatorTextField
          name={`${path}.postOfficeBoxAddressLocality`}
          label="Postfach Ort"
          disabled={disabled}
          validators={fieldsAreRequired ? [{ validator: isRequired, message: "Bitte geben Sie den Ort an" }] : []}
        />
      </Grid>
    </>
  );
};

export default PostOfficeBoxAddress;
