import React from "react";
import pictureTeaserCardStyle from "./pictureTeaserCardStyle";
import Typography from "@mui/material/Typography";
import GradientPaper from "../../GradientComponents/GradientPaper";
import {Box} from "@mui/material";

type PictureTeaserCardProps = {
  image: string;
  title: string;
  subTitle: string;
};

export default function PictureTeaserCard({ image, title, subTitle }: PictureTeaserCardProps) {
  return (
    <GradientPaper sx={pictureTeaserCardStyle.card}>
      <Box component={"img"} src={image} alt={title} sx={pictureTeaserCardStyle.image} />
      <Typography variant={"h4"} sx={pictureTeaserCardStyle.text}>
        {title}
      </Typography>
      <Typography variant={"body1"} sx={pictureTeaserCardStyle.text}>
        {subTitle}
      </Typography>
    </GradientPaper>
  );
}
