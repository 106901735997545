import React, { useCallback, useEffect, useMemo, useState } from "react";
import RedirectService from "../../services/RedirectService";
import { getProductData } from "../../services/productService";
import { useLocation, useNavigate } from "react-router-dom";
import { gaSet } from "../../services/cookieService";
import { Helmet } from "react-helmet-async";
import Container from "../Container/Container";
import Layout from "../../containers/Layouts/Layout";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import _isEmpty from "lodash/isEmpty";
import * as messagesActions from "../../store/messages/actions";
import { FormProvider } from "../../provider/Form/FormProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createResource, deleteResource, queryKeys, updateResource } from "../../services/reactQuery/reactQueryService";
import { useCustomer } from "../../provider/Customer/CustomerProvider";
import { AbstractCase } from "../../types/Entities/AbstractCase";
import formSteps from "../../types/FormSteps";

type ApplicationFormProps = {
  product: AbstractCase;
};

export default function ApplicationForm({ product }: ApplicationFormProps) {
  const redirectService = RedirectService;
  const formService = getProductData(product.name, "formService");
  const applicationStatusSummary = getProductData(product.name, "summaryStatus");
  const { customer, refreshCustomer } = useCustomer();
  const location = useLocation();
  const navigate = useNavigate();

  const [apiErrors, setApiErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const applicationStatus = product.applicationStatus;
  const pathName = location.pathname;
  const [currentStatus, setCurrentStatus] = useState<number | null>(null);
  const [formStep, setFormStep] = useState<formSteps>();

  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data),
  });
  const createMutation = useMutation(createResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data),
  });
  const deleteMutation = useMutation(deleteResource, {
    onSuccess: (data, variables) => queryClient.invalidateQueries(queryKeys.item(variables.uri, variables.id)),
  });

  useEffect(() => {
    const targetPath = redirectService.getApplicationLocation(product, pathName);
    if (targetPath !== pathName) {
      navigate(targetPath, { replace: true });
    }
    setCurrentStatus(redirectService.getStatusByLocation(pathName, product) || applicationStatus);
  }, [pathName, product, applicationStatus]);

  useEffect(() => {
    setInitialValues(formService.prepareFormData(product, customer));
  }, [product]);

  useEffect(() => {
    if (initialValues) {
      const currentFormStep = formService.getStep(currentStatus, initialValues, product);
      setFormStep(currentFormStep);
      gaSet({ title: currentFormStep.title });
    }
  }, [initialValues, currentStatus]);

  const formKey = useMemo(() => {
    return new Date().getTime();
  }, [initialValues]);

  const isSummaryEdit = useCallback(() => {
    const redirectServiceStatus = redirectService.getStatusByLocation(pathName, product);
    return (
      !!location.state && !!location.state.field && product.applicationStatus === applicationStatusSummary && redirectServiceStatus !== applicationStatusSummary
    );
  }, [pathName, location, applicationStatusSummary]);

  const getSubmitProps = useCallback(() => {
    if (isSummaryEdit()) {
      return {
        hideBackButton: true,
        nextText: "Speichern und zur Übersicht",
        isLoading: isLoading,
      };
    }

    const previousStep = formService.getStep(currentStatus, initialValues, product, -1);
    return {
      backLink: previousStep ? redirectService.getApplicationStep(previousStep.applicationStatus, product) : undefined,
      hideBackButton: !previousStep,
      isLoading: isLoading,
    };
  }, [isLoading, formStep]);

  const submitAction = useCallback(
    async ({ values, options }: { values: Record<string, any>; options: { preventRedirect: boolean } }) => {
      setIsLoading(true);
      const nextStep = formService.getStep(currentStatus, values, product, 1);

      try {
        await formService.save({
          formData: values,
          applicationStatus: currentStatus,
          nextApplicationStatus: nextStep.applicationStatus,
          product,
          customer,
          updateMutation,
          queryClient,
          createMutation,
          refreshCustomer,
          deleteMutation,
        });

        if (!options.preventRedirect) {
          isSummaryEdit()
            ? navigate(redirectService.getApplicationStep(applicationStatusSummary, product), { state: location.state })
            : navigate(redirectService.getApplicationStep(nextStep.applicationStatus, product));
        }
        setApiErrors({});
      } catch (error: any) {
        if (!_isEmpty(error.errors)) {
          setIsLoading(false);
          setApiErrors(error.errors);
          return;
        }
        messagesActions.addMessage({
          type: "error",
          text:
            "Es ist ein technischer Fehler aufgetreten. Bitte laden Sie die Seite einmal neu. Wenn der Fehler bestehen bleibt, melden Sie sich bei unserem Kundenservice. Nennen Sie uns dann die Stelle, an der der Fehler aufgetreten ist und die Fehlermeldung: " +
            error.message,
        });
        console.error(error);
      }
      setIsLoading(false);
    },
    [currentStatus, applicationStatusSummary, product]
  );
  if (!initialValues || !formStep) {
    return (
      <Layout>
        <Container>
          <Container sx={{ width: 250 }}>
            <LegalbirdLoader />
          </Container>
        </Container>
      </Layout>
    );
  }

  return (
    <>
      <Helmet>
        <title>{formStep.title} </title>
      </Helmet>
      <Container>
        <FormProvider key={formKey} initialValues={initialValues} submitAction={submitAction} externalErrors={apiErrors}>
          {formStep.render({
            product,
            submitProps: getSubmitProps(),
          })}
        </FormProvider>
      </Container>
    </>
  );
}
