import React from "react";
import wizardStyle from "../wizardStyle";
import Paper from "@mui/material/Paper";
import FormFader from "../Common/Fader/FormFader";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import { useForm } from "../../../provider/Form/FormProvider";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import { requiredValidator } from "../../../services/validationRules";
import Typography from "@mui/material/Typography";
import FormLegend from "../Common/FormLegend/FormLegend";
import { Box } from "@mui/material";

export default function ContractDescription({ submitProps }: any) {
  const { handleSubmit } = useForm();
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Beschreibung der Situation</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
              <FormLegend>
                Bitte schildern Sie uns hier Ihre Situation und Fragen. Jede Information hilft uns dabei, Ihren Fall besser einschätzen zu können. Im Anschluss
                können Sie ergänzend noch Vertrags-Dokumente oder Schriftverkehr mit dem Vermieter hochladen, um Ihre Schilderungen zu ergänzen.
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                name="personalTextMessage"
                label="Ihre Situation und Fragen"
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={10}
                validators={[requiredValidator("Bitte schildern Sie uns hier kurz Ihre Situation und Ihre Fragen.")]}
              />
            </Grid>
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
