const calendlyStyle = {
  backButton: {
    marginTop: "2rem",
  },
  appointmentBoxbutton: {
    margin: ".5rem 0",
  },
  contentContainer: {
    padding: "25px",
    maxWidth: "500px",
    margin: "0 auto",
  },
};

export default calendlyStyle;
