import React from "react";
import { Route, Routes } from "react-router-dom";
import Error404PageService from "../../containers/Pages/Generic/Error404/Error404PageService";
import GenericGuidebookPage from "../../containers/Pages/Generic/GenericGuidebookPage/GenericGuidebookPage";
import GuidebookFamilyLaw from "./GuidebookFamilyLaw";
import GuidebookEmploymentLaw from "./GuidebookEmploymentLaw";
import GuidebookTrafficLaw from "./GuidebookTrafficLaw";
import useTracker from "../../services/useTracker";

export default function Guidebook() {
  useTracker();

  return (
    <Routes>
      <Route path={"familienrecht/*"} element={<GuidebookFamilyLaw />} />
      <Route path={"arbeitsrecht/*"} element={<GuidebookEmploymentLaw />} />
      <Route path={"verkehrsrecht/*"} element={<GuidebookTrafficLaw />} />
      <Route path={""} element={<GenericGuidebookPage />} />
      <Route path={"*"} element={<Error404PageService />} />
    </Routes>
  );
}
