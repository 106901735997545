/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { isRequired } from "../validationRules";

export const alimonyFormDefinitions = {
  alimonyPartner: {
    netIncome: netIncomePartner,
    otherIncome: otherIncomePartner,
    otherExpenses: otherExpensesPartner,
    livingExpenses: livingExpensesPartner,
  },
  alimonyPetitioner: {
    netIncome: netIncomePetitioner,
    otherIncome: otherIncomePetitioner,
    otherExpenses: otherExpensesPetitioner,
    livingExpenses: livingExpensesPetitioner,
  },
};

function netIncomePartner() {
  return {
    validators: [
      {
        validator: isRequired,
        message: "Dies ist ein Pflichtfeld - Sollten Sie dies nicht genau wissen, geben Sie eine Schätzung oder einfach 0 € an.",
      },
    ],
  };
}

function netIncomePetitioner() {
  return {
    validators: [{ validator: isRequired, message: "Dies ist ein Pflichtfeld" }],
  };
}

function otherIncomePartner() {
  return {
    validators: [
      {
        validator: isRequired,
        message: "Dies ist ein Pflichtfeld - Sollten Sie dies nicht genau wissen, geben Sie eine Schätzung oder einfach 0 € an.",
      },
    ],
  };
}

function otherIncomePetitioner() {
  return {
    validators: [{ validator: isRequired, message: "Dies ist ein Pflichtfeld" }],
  };
}

function otherExpensesPartner() {
  return {
    validators: [
      {
        validator: isRequired,
        message: "Dies ist ein Pflichtfeld - Sollten Sie dies nicht genau wissen, geben Sie eine Schätzung oder einfach 0 € an.",
      },
    ],
  };
}

function otherExpensesPetitioner() {
  return {
    validators: [{ validator: isRequired, message: "Dies ist ein Pflichtfeld" }],
  };
}

function livingExpensesPartner() {
  return {
    validators: [
      {
        validator: isRequired,
        message: "Dies ist ein Pflichtfeld - Sollten Sie dies nicht genau wissen, geben Sie eine Schätzung oder einfach 0 € an.",
      },
    ],
  };
}

function livingExpensesPetitioner() {
  return {
    validators: [{ validator: isRequired, message: "Dies ist ein Pflichtfeld" }],
  };
}
