/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import React from "react";
import wizardStyle from "../../wizardStyle";
import Grid from "@mui/material/Grid";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { translate } from "../../../../services/translations/translations";
import CheckboxGroup, { CheckboxGroupOptionProps } from "../../../Wizard/CheckboxGroup";
import { atLeastOneTrueProperty, requiredValidator } from "../../../../services/validationRules";
import FormLegend from "../../Common/FormLegend/FormLegend";

export default function PurchasesPlannedInTheNearFuture() {
  const { values, errors } = useForm();

  const availableOptions = [
    "vehicle",
    "housePurchase",
    "relocation",
    "mobilePhoneContract",
    "electricityProvider",
    "gasProvider",
    "invoicePurchase",
    "borrowing",
    "debtConversion",
    "other",
    "none",
  ];

  const getOptions = () => {
    let options: Array<CheckboxGroupOptionProps> = [];

    availableOptions.map((option) => {
      options.push({
        value: option,
        label: translate(`dataLeakContract.purchasesPlannedInTheNearFuture.options.${option}.label`, values),
      });
    });

    return options;
  };

  const charCount = values.personalAffection?.purchasesPlannedInTheNearFutureOther?.length || 0;
  const otherHasError = (charCount > 0 && charCount < 50) || typeof errors["personalAffection.purchasesPlannedInTheNearFutureOther"] !== "undefined";

  return (
    <>
      <Grid item xs={12} pt={5}>
        <FormLegend>{translate("dataLeakContract.purchasesPlannedInTheNearFuture.question", values)}</FormLegend>
      </Grid>

      <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
        <CheckboxGroup
          name={"personalAffection.purchasesPlannedInTheNearFuture"}
          options={getOptions()}
          resetOption={"none"}
          validators={[
            {
              validator: atLeastOneTrueProperty,
              message: "Bitte wählen Sie mindestens eine Option aus",
            },
          ]}
        />
        {values.personalAffection.purchasesPlannedInTheNearFuture?.other === true && (
          <ValidatorTextField
            name="personalAffection.purchasesPlannedInTheNearFutureOther"
            label="Ergänzende Angaben"
            placeholder="Weitere Angaben zu zukünftigen Käufen, bei denen es durch schlechte Scores zu Problemen kommen könnte"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={10}
            inputProps={{ minLength: 50, maxLength: 500 }}
            error={otherHasError}
            helperText={
              "[" +
              (typeof values.personalAffection.purchasesPlannedInTheNearFutureOther === "string"
                ? values.personalAffection.purchasesPlannedInTheNearFutureOther.length
                : 0) +
              "/500]" +
              (otherHasError ? " Bitte geben Sie mindestens 50 Buchstaben ein" : "")
            }
            validators={[requiredValidator("Bitte geben Sie einen Text ein")]}
          />
        )}
      </Grid>
    </>
  );
}
