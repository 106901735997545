/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import scrollTopButtonStyle from "./scrollTopButtonStyle";
import Paper from "@mui/material/Paper";
import withScroll from "../../services/withScroll";
import { Box, Fade } from "@mui/material";
import * as Scroll from "react-scroll";
import { ArrowUpwardRounded } from "@mui/icons-material";

type ScrollTopButtonProps = {
  scrollPosition: number;
};

const ScrollTopButton = ({ scrollPosition }: ScrollTopButtonProps) => {
  return (
    <Fade in={scrollPosition >= 300} timeout={1000}>
      <Box sx={scrollTopButtonStyle.button}>
        <Paper
          elevation={8}
          sx={scrollTopButtonStyle.imageCircle}
          onClick={() => {
            Scroll.animateScroll.scrollToTop({ smooth: true });
          }}
        >
          <Box sx={scrollTopButtonStyle.centered}>
            <ArrowUpwardRounded color={"primary"} />
          </Box>
        </Paper>
      </Box>
    </Fade>
  );
};

export default withScroll(ScrollTopButton);
