import quotationMarkBackground from "../../../assets/img/background/Anfuehrungszeichen_oben_unten.svg";
import quotationMarkBackgroundDark from "../../../assets/img/background/Anfuehrungszeichen_oben_unten_dark.svg";
import { Theme } from "@mui/material";

const quotationCardStyle = {
  card: (theme: Theme) => ({
    width: "200px",
    height: "200px",
    margin: "auto",
    backgroundRepeat: "no-repeat",
    ...(theme.palette.mode === "dark"
      ? {
          backgroundImage: "url(" + quotationMarkBackgroundDark + "), linear-gradient(#333333, #333333)",
        }
      : {
          backgroundImage: "url(" + quotationMarkBackground + "), linear-gradient(#FFFFFF, #F4F7FF)",
        }),
  }),
  textContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "0 1.5rem",
  },
};

export default quotationCardStyle;
