import React from "react";
import fileUploadSectionStyle from "./fileUploadSectionStyle";
import _map from "lodash/map";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import { formatFilePath } from "../../../../services/mediaObjects";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import UploadField from "../../ProcessCostSupport/Receipts/UploadField";
import Typography from "@mui/material/Typography";
import { MediaObject } from "../../../../types/Entities/MediaObject/MediaObject";
import {Box} from "@mui/material";

export type FileUploadFormProps = {
  noSection?: boolean;
  headline?: string;
  helperText?: string;
  mediaObjectCollection: MediaObject[];
  deleteMediaObject: (mediaObject: MediaObject) => void;
  allowMore?: boolean;
  fieldname: string;
  label?: string;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filePath: string;
  isBlocked: boolean;
  uploadFileLabel?: string;
  addPageLabel?: string;
};

const FileUploadForm = ({
  noSection,
  headline,
  helperText,
  mediaObjectCollection,
  deleteMediaObject,
  allowMore,
  fieldname,
  label,
  handleFileChange,
  filePath,
  isBlocked,
  uploadFileLabel = "Datei hochladen",
  addPageLabel = "Weitere Seite hinzufügen",
}: FileUploadFormProps) => {
  return (
    <Box sx={!noSection ? fileUploadSectionStyle.section : undefined}>
      {headline && (
        <Typography variant={"h5"} sx={fileUploadSectionStyle.headline}>
          {headline}
        </Typography>
      )}
      {helperText && <p>{helperText}</p>}
      <Grid container>
        {_map(mediaObjectCollection, (mediaObject, key) => (
          <Grid key={key} sx={fileUploadSectionStyle.file} item xs={12}>
            <Hidden smUp>{formatFilePath(mediaObject.originalName, 20)}</Hidden>
            <Hidden smDown>{mediaObject.originalName}</Hidden>
            <DeleteIcon onClick={() => deleteMediaObject(mediaObject)} sx={fileUploadSectionStyle.deleteIcon} />
          </Grid>
        ))}
      </Grid>
      {allowMore && (
        <UploadField
          label={label}
          handleChange={handleFileChange}
          fieldname={fieldname}
          filePath={filePath}
          disabled={isBlocked}
          buttonText={mediaObjectCollection.length === 0 ? uploadFileLabel : addPageLabel}
        />
      )}
    </Box>
  );
};

export default FileUploadForm;
