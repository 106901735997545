import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { logout } from "../store/login/actions";

export default function LogoutExpired({ token, children }) {
  const [decodedToken, setDecodedToken] = useState(null);

  const isExpired = (decodedToken) => {
    return decodedToken.exp < Date.now() / 1000;
  };

  useEffect(() => {
    if (!!token) {
      setDecodedToken(jwtDecode(token));
    }
  }, [token]);

  useEffect(() => {
    if (!!decodedToken && isExpired(decodedToken)) {
      logout();
    }
  }, [decodedToken]);

  if (!token || (decodedToken && !isExpired(decodedToken))) {
    return children;
  }
  return null;
}