import React from "react";
import guidebookHomeSectionStyle from "./guidebookHomeSectionStyle";
import AreaSelection from "../../../AreaSelection/AreaSelection";
import Container from "../../../Container/Container";
import Typography from "@mui/material/Typography";

const GuidebookHomeSection = () => {
  return (
    <>
      <Container>
        <Typography variant={"h2"} sx={guidebookHomeSectionStyle.heading}>
          Welchen Ratgeber möchten Sie lesen?
        </Typography>
      </Container>
      <AreaSelection linkedCardList={"guidebook"} cardButtonText={"Zum Ratgeber"} />
    </>
  );
};

export default GuidebookHomeSection;
