/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Layout from "../../../Layouts/Layout";
import Grid from "@mui/material/Grid";
import { loginImpersonate } from "../../../../store/login/actions";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "../../../../provider/Form/FormProvider";
import ValidatorTextField from "../../../../components/FormFields/ValidatorTextField";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../../services/reactQuery/reactQueryService";
import { requiredFieldDefault } from "../../../../services/validationRules";

export default function AdminPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const initialValues = {
    email: "",
    password: "",
    login: "",
  };

  const handleSubmit = async ({ values }) => {
    await loginImpersonate(values);
    await queryClient.invalidateQueries(queryKeys.collection("/customers"));
    navigate("/servicewelt");
  };

  return (
    <Layout minimal={true}>
      <Paper elevation={4} style={{ maxWidth: 500, margin: "2rem auto", padding: "1rem" }}>
        <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
          <AdminForm />
        </FormProvider>
      </Paper>
    </Layout>
  );
}

const AdminForm = () => {
  const { handleSubmit } = useForm();
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <ValidatorTextField name="email" label="E-Mail" type="email" validators={[requiredFieldDefault]} />
        </Grid>
        <Grid item xs={12}>
          <ValidatorTextField name="password" label="Passwort" type="password" validators={[requiredFieldDefault]} />
        </Grid>
        <Grid item xs={12}>
          <ValidatorTextField name="login" label="Login (Mandantschaft)" type="email" validators={[requiredFieldDefault]} />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "1rem" }}>
          <Button color={"primary"} type="submit" variant={"contained"} fullWidth>
            Abschicken
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
