import ExtendableError from "es6-error";

type ValidationError = {
  field: string;
  message: string;
};

class SubmissionError extends ExtendableError {
  public errors: ValidationError[];

  constructor(errors: ValidationError[]) {
    super("Submit Validation Failed");
    this.errors = errors;
  }
}

export default SubmissionError;
