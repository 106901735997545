import { Theme } from "@mui/material";

const greyBackgroundWrapperStyle = {
  greyBackground: (theme: Theme) => ({
    ...(theme.palette.mode === "dark"
      ? {
          backgroundColor: "background.default",
        }
      : {
          backgroundColor: "#F4F4F4",
        }),
  }),
  verticalPadding: {
    padding: "2rem 0",
  },
};

export default greyBackgroundWrapperStyle;
