import React, { useState } from "react";
import { Divider, IconButton, ListItemIcon, Menu, MenuItem, Switch } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import * as messagesActions from "../../store/messages/actions";
import { logout } from "../../store/login/actions";
import { Link, useNavigate } from "react-router-dom";
import { useCustomer } from "../../provider/Customer/CustomerProvider";
import ListItemText from "@mui/material/ListItemText";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/reactQuery/reactQueryService";

export default function LoggedInMenu() {
  const { customer, refreshCustomer } = useCustomer();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateMutation = useMutation(updateResource);

  if (!customer) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (customer.verification === null) {
      messagesActions.addMessage({
        type: "info",
        text: "Bitte bestätigen Sie Ihre E-Mail-Adresse, damit Sie alle Funktionen der Servicewelt nutzen können.",
      });
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    refreshCustomer();
    handleCloseMenu();
    navigate("/");
  };

  const handleThemeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!customer?.userConfig?.id) {
      return;
    }
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "/user_configs",
      id: customer.userConfig.id,
      data: {
        theme: e.target.checked ? "dark" : "light",
      },
    });
    refreshCustomer();
    setIsLoading(false);
  };

  return (
    <>
      <IconButton onClick={handleClick} size="large">
        <MenuIcon sx={{ color: "text.primary" }} />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: { minWidth: 150 },
        }}
      >
        <MenuItem component={Link} to={"/servicewelt"}>
          Meine Fälle
        </MenuItem>
        <MenuItem component={Link} to={"/servicewelt/mein-konto"}>
          Mein Konto
        </MenuItem>
        <MenuItem>
          <ListItemText>Dark Mode</ListItemText>
          <ListItemIcon>
            <Switch onChange={handleThemeChange} checked={customer?.userConfig?.theme === "dark"} disabled={isLoading} />
          </ListItemIcon>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>Abmelden</MenuItem>
      </Menu>
    </>
  );
}
