import recommendationBoxBackground from "../../../../../assets/img/landingpage/saskiaschlemmer/diescheidungsanwaeltin.jpg";

const lpSaskiaSchlemmerStyles = {
  recommendationBox: {
    height: "800px",
    width: "auto",
    backgroundImage: "url(" + recommendationBoxBackground + ")",
    backgroundPositionX: "center",
    backgroundPositionY: "top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    padding: "2rem",
  },
  recommendationText: {
    textAlign: "center",
    color: "primary.contrastText",
    margin: "0",
  },
  recommendationTextBox: {
    padding: "1rem",
    backgroundColor: "primary.main",
  },
  logo: {
    marginTop: "20px",
    backgroundColor: "#fff",
    padding: "16px 20px 10px 20px",
    borderRadius: "5px",
  },
};

export default lpSaskiaSchlemmerStyles;
