/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import React from "react";
import wizardStyle from "../../wizardStyle";
import Grid from "@mui/material/Grid";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { translate } from "../../../../services/translations/translations";
import Typography from "@mui/material/Typography";

export default function PersonalStatement() {
  const { values } = useForm();

  const charCount =
    values.personalAffection !== null && typeof values.personalAffection.personalStatement === "string" ? values.personalAffection.personalStatement.length : 0;

  return (
    <>
      <Grid item xs={12} pt={5}>
        <Typography mb={2}>
          Bitte schildern Sie uns <u>ergänzend</u> zu den vorherigen Angaben Ihre weiteren Erfahrungen im Zusammenhang mit der Datenweitergabe, insbesondere
          Ihre Befürchtungen bezüglich der Übermittlung Ihrer Vertragsdaten an die Schufa/CRIF ohne datenschutzrechtliche Grundlage. Teilen Sie uns auch Ihre
          negativen Erfahrungen mit der Schufa/CRIF mit und wie Sie das Scoring sowie dessen undurchsichtige Berechnungsweise empfinden. Ihre Angaben müssen
          wahrheitsgemäß und umfassend sein, um eine erfolgreiche Vertretung vor Gericht sicherzustellen. Bitte bedenken Sie, dass die Qualität und der Umfang
          Ihrer Angaben die Höhe des Schadensersatzes beeinflussen können.
        </Typography>
        <Typography mb={2}>
          <strong>Beispiel 1:</strong> Das Scoring bestimmt maßgeblich meine finanzielle Zukunft, jedoch habe ich keinen Einfluss auf seine Berechnung. Die
          Undurchschaubarkeit des Systems und fehlende Transparenz verstärken das Gefühl der Ohnmacht. Ein niedriger Score erschwert Kreditvergabe und
          Vertragsabschlüsse, trotz möglicher Verbesserungen im Zahlungsverhalten. Es ist frustrierend, für vergangenes Fehlverhalten ständig bestraft zu
          werden.
        </Typography>
        <Typography mb={2}>
          <strong>Beispiel 2:</strong> Das Scoring verhinderte kürzlich den Abschluss eines Mietvertrags, obwohl ich finanziell stabil bin und die Miete
          problemlos zahlen kann. Ein negativer Eintrag führte zur Ablehnung meines Antrags.
        </Typography>
      </Grid>

      <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
        <ValidatorTextField
          name="personalAffection.personalStatement"
          label={translate("dataLeakContract.personalStatement.label", values)}
          placeholder={translate("dataLeakContract.personalStatement.placeholder", values)}
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rows={10}
          inputProps={{ maxLength: 2000 }}
          helperText={"[" + charCount + "/2000]"}
        />
      </Grid>
    </>
  );
}
