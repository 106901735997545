import wizardStyle from "../../wizardStyle";

const feedbackFormTenancyLawStyle = {
  ...wizardStyle,
  recommendation: {
    marginTop: "2rem",
    textAlign: "center",
    border: "1px solid ",
    boderColor: "primary.main",
    borderRadius: "5px",
    padding: "0.5rem",
  },
  button: {
    margin: "1rem 0",
  },
};
export default feedbackFormTenancyLawStyle;
