const messagingTranslations = {
  conversation: {
    type: {
      label: "Kategorie",
      options: {
        submitCaseInfo: { label: "Informationen/Neuigkeiten zum Fall" },
        other: { label: "Allgemeine Nachricht" },
        questionNextSteps: { label: "Rückfrage zum weiteren Vorgehen" },
        legalQuestion: { label: "Rechtliche Frage an den Anwalt" },
        technicalQuestion: { label: "Frage zur Servicewelt" },
      },
    },
  },
};
export default messagingTranslations;
