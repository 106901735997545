import React from "react";
import SummaryDetailsItem from "../../../Common/SummaryDetailsItem/SummaryDetailsItem";
import SummaryDetailsProps from "../../../../../types/SummaryDetailsProps";

const SummaryDetailsDataLeak = ({ caseData, formUrl }: SummaryDetailsProps) => {
  return (
    <>
      {!!caseData.contractNumber && <SummaryDetailsItem caseData={caseData} formUrl={formUrl} fieldPath={"contractNumber"} />}
    </>
  );
};

export default SummaryDetailsDataLeak;
