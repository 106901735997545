
const familyLawTeaserCardSectionStyle = {
  container: {
    textAlign: "center",
  },
  heading: {
    marginBottom: "50px",
  },
  cardContainer:  (theme) => ({
    [theme.breakpoints.down('lg')]: {
      width: "532px",
      margin: "auto",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  }),
};

export default familyLawTeaserCardSectionStyle;
