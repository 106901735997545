import React from "react";
import { Box, Typography } from "@mui/material";
import { coloredLogoBoxStyles } from "./coloredLogoBoxStyles";

export interface ColoredLogoBoxProps {
  color: string;
  logo: any;
  title: string;
  description: string;
}

export default function ColoredLogoBox({ color, logo, title, description }: ColoredLogoBoxProps) {
  return (
    <Box
      sx={{
        ...coloredLogoBoxStyles.coloredBox,
        backgroundColor: color,
      }}
    >
      <Box sx={coloredLogoBoxStyles.logoBox}>
        <img src={logo} alt={title} height={"100%"} width={"100%"} />
      </Box>
      <Box>
        <Typography sx={coloredLogoBoxStyles.text} variant={"h5"} textAlign={"center"}>
          {title}
        </Typography>
        <Typography sx={coloredLogoBoxStyles.text} variant={"subtitle2"}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
}
