import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import trafficCheckinStyle from "./trafficCheckinStyle";
import * as loginActions from "../../../store/login/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { getLpByLocation } from "../../../services/landingPagesList";
import Typography from "@mui/material/Typography";
import { FormProvider } from "../../../provider/Form/FormProvider";
import TrafficCheckinForm from "./TrafficCheckinForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createResource, queryKeys } from "../../../services/reactQuery/reactQueryService";
import { APPLICATION_STATUS_AUTHORITY_NOTIFICATION } from "../../../services/traffic/applicationStatus";
import {Box} from "@mui/material";

type TrafficCheckinProps = {
  idPrefix: string;
  color?: "default" | "grey";
  checkinHeading?: string;
  target?: string;
  partner: string;
  accusation: AccusationProps;
};

type AccusationProps = {
  name: string;
  detail: {
    name: string;
    label: string;
    options: {
      value: string;
      label: string;
    }[];
  };
};

const defaultAccusation = {
  name: "accusation",
  detail: {
    name: "accusation",
    label: "Vorwurf auswählen",
    options: [
      { value: "speeding", label: "Geschwindigkeitsverstoß" },
      { value: "red_light", label: "Rotlichtverstoß" },
      { value: "distance", label: "Abstandsverstoß" },
      { value: "other", label: "Handy / Drogen / Parken / Sonstiges" },
    ],
  },
};

export default function TrafficCheckin({
  accusation = defaultAccusation,
  color = "default",
  target = "/servicewelt",
  idPrefix,
  checkinHeading = "Wir geben Ihnen eine kostenlose Ersteinschätzung! Was wird Ihnen vorgeworfen?",
  partner,
}: TrafficCheckinProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const initialValues = {
    email: "",
    telephone: "",
    accusationDetail: "",
    familyName: null,
    givenName: null,
    gender: null,
  };

  const queryClient = useQueryClient();
  const createMutation = useMutation(createResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data),
  });
  const handleSubmit = async ({ values }: { values: Record<string, any> }) => {
    setIsLoading(true);

    const { from } = location.state || { from: { pathname: target } };
    const emailCheck = await loginActions.checkEmail(values.email);
    if (emailCheck.hasError) {
      setIsLoading(false);
      setEmailErrorText(emailCheck.errorText);
      return;
    }

    const landingPage = getLpByLocation(location.pathname) || { keyword: location.pathname };
    const accusationBase = accusation.name !== "accusation" ? accusation.name : values.accusationDetail;
    const data = {
      email: values.email.toLowerCase(),
      telephone: values.telephone,
      gclid: localStorage.getItem("gclid"),
      person: {
        givenName: values.givenName,
        familyName: values.familyName,
        gender: values.gender,
      },
      prelead: {
        initialProduct: "traffic",
        accusation: accusationBase,
        accusationDetail: {
          [accusation.detail.name]: values.accusationDetail,
        },
        landingPage: landingPage.keyword,
        partner: partner || null,
      },
    };

    await loginActions.register(data);
    await createMutation.mutateAsync({
      uri: "/traffic",
      data: {
        acquisitionPartner: partner,
        affiliateCode: localStorage.getItem("gclid"),
        accusation: accusationBase,
        applicationStatus: APPLICATION_STATUS_AUTHORITY_NOTIFICATION,
        landingPage: landingPage.keyword,
      },
    });
    await queryClient.invalidateQueries(queryKeys.collection("/customers"));
    navigate(from.pathname);
  };

  return (
    <Paper elevation={4} sx={[trafficCheckinStyle.formPaper, trafficCheckinStyle[color]]}>
      <Box id={idPrefix + "-checkin"} sx={trafficCheckinStyle.scrollToAnchor} />
      <Typography variant={"h4"} sx={trafficCheckinStyle.heading}>
        {checkinHeading}
      </Typography>
      <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
        <TrafficCheckinForm
          isLoading={isLoading}
          emailErrorText={emailErrorText}
          setEmailErrorText={setEmailErrorText}
          accusation={accusation}
          showPersonFields={partner === "wbs"}
        />
      </FormProvider>
    </Paper>
  );
}
