import React from "react";
import { Box } from "@mui/material";
import layoutStyle from "../layoutStyle";
import LegalbirdLoader from "../../../components/ContentLoader/LegalbirdLoader";
import Typography from "@mui/material/Typography";

export default function BlockPage() {
  return (
    <Box sx={layoutStyle.blockPage}>
      <LegalbirdLoader />
      <Typography variant={"h4"}>Neue Software ist gerade auf dem Weg zu Ihnen!</Typography>
      <Typography variant={"h6"}>
        Das Update wird nur wenige Minuten dauern.
        <br />
        Sobald die neue Software einsatzbereit ist, können Sie die Servicewelt wie gewohnt nutzen!
      </Typography>
    </Box>
  );
}
