import React from "react";
import serviceWorldCaseStyle from "./serviceWorldCaseStyle";
import { Helmet } from "react-helmet-async";

import { getProductData, getProductNameBy } from "../../services/productService";
import { Link, useParams } from "react-router-dom";
import InitialStatusBox from "./StatusBox/InitialStatusBox";
import InitialProcessBox from "./ProcessBox/InitialProcessBox";
import ServiceWorldCaseProductBoxes from "./ServiceWorldCase/ServiceWorldCaseProductBoxes";
import {Box, Button} from "@mui/material";
import PageNavigation from "../Navigation/SummaryNavigation/PageNavigation";
import ServiceWorldCaseProductGuidebookBoxes from "./ServiceWorldCase/ServiceWorldCaseProductGuidebookBoxes";
import { gaSet } from "../../services/cookieService";

const ServiceWorldInitialCase = () => {
  const { productName } = useParams();
  const productClassName = getProductNameBy("urlPartShort", productName);

  const title = "Servicewelt Mein Fall | Legalbird";
  gaSet({ title: title });

  const navigationLinks = [
    {
      target: "#status",
      label: "Jetzt starten",
    },
  ];

  return (
    <Box sx={serviceWorldCaseStyle.contentContainer}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageNavigation
        navigationLinks={navigationLinks}
        headerIcon={getProductData(productClassName, "productIcon")}
        headline={getProductData(productClassName, "label")}
      />
      <Box sx={serviceWorldCaseStyle.scrollToAnchor} id={"status"} />
      <InitialStatusBox productClassName={productClassName} />
      <InitialProcessBox productClassName={productClassName} />
      <ServiceWorldCaseProductBoxes
        product={{
          name: productClassName,
          isInitialProxy: true,
        }}
      />
      <ServiceWorldCaseProductGuidebookBoxes
        product={{
          name: productClassName,
          isInitialProxy: true,
        }}
      />
      <Button color="buttonGrey" fullWidth component={Link} variant={"contained"} to={"/servicewelt"}>
        Zurück zur Servicewelt
      </Button>
    </Box>
  );
};

export default ServiceWorldInitialCase;
