const costHintBoxStyle = {
  insuranceBox: {
    textAlign: "center",
    border: "1px solid ",
    borderColor: "primary.main",
    borderRadius: "5px",
    padding: "0.5rem",
    marginTop: "1.5rem",
  },
};

export default costHintBoxStyle;
