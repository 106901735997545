import React from "react";
import summaryStyle from "./summaryStyle";
import Grid from "@mui/material/Grid";
import GridEditRow from "../../Common/GridEditRow/GridEditRow";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";

type DebtCollectionAgencyDataProps = {
  formData: Record<string, any>;
  formPath: string;
  title: string;
};

const DebtCollectionAgencyData = ({ formData, formPath, title }: DebtCollectionAgencyDataProps) => {
  const section = "debtCollectionAgencyData";

  return (
    <>
      <Grid item xs={12} sx={summaryStyle.subHeadline}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"name"} sx={summaryStyle.scrollToAnchor} />
        Name Inkassobüro
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "name",
          },
        }}
      >
        {formData.debtCollectionAgency.name}
      </GridEditRow>
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"debtCollectionAgencyReference"} sx={summaryStyle.scrollToAnchor} />
        Aktenzeichen Inkassobüro
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "debtCollectionAgencyReference",
          },
        }}
      >
        {formData.debtCollectionAgencyReference}
      </GridEditRow>
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"streetAddress"} sx={summaryStyle.scrollToAnchor} />
        Straße und Hausnummer
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "streetAddress",
          },
        }}
      >
        {formData.debtCollectionAgency.address.streetAddress}
      </GridEditRow>
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"postalCode"} sx={summaryStyle.scrollToAnchor} />
        Postleitzahl
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "postalCode",
          },
        }}
      >
        {formData.debtCollectionAgency.address.postalCode}
      </GridEditRow>
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"addressLocality"} sx={summaryStyle.scrollToAnchor} />
        Ort
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "addressLocality",
          },
        }}
      >
        {formData.debtCollectionAgency.address.addressLocality}
      </GridEditRow>
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"email"} sx={summaryStyle.scrollToAnchor} />
        E-Mail-Adresse
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "email",
          },
        }}
      >
        {formData.debtCollectionAgency.email}
      </GridEditRow>
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"telephone"} sx={summaryStyle.scrollToAnchor} />
        Telefonnummer
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "telephone",
          },
        }}
      >
        {formData.debtCollectionAgency.telephone}
      </GridEditRow>
    </>
  );
};

export default DebtCollectionAgencyData;
