import {Box, Button, Divider, Grid, Switch} from "@mui/material";
import { Link } from "react-router-dom";
import DialogModal from "../DialogModal/DialogModal";
import React, { useState } from "react";
import { allowAll, denyAll, hasConsent, setConsent } from "../../services/cookieService";
import cookieBannerStyle from "./cookieBannerStyle";

type CookieModalProps = {
  open: boolean;
  setIsConfigureOpen: (open: boolean) => void;
  isSelectionMade: boolean;
  setIsSelectionMade: (isSelectionMade: boolean) => void;
};

const CookieModal = ({ open, setIsConfigureOpen, isSelectionMade, setIsSelectionMade }: CookieModalProps) => {
  const [analyticsChecked, setAnalyticsChecked] = useState(hasConsent("analytics"));

  const saveSelection = () => {
    setConsent({
      consentType: "configured",
      config: {
        analytics: analyticsChecked,
      },
    });
  };

  return (
    <DialogModal
      open={open && !isSelectionMade}
      onClose={() => setIsConfigureOpen(false)}
      title={"Cookie-Einstellungen anpassen"}
      maxWidth={"md"}
      responsive={true}
      actions={<ModalActions setAnalyticsChecked={setAnalyticsChecked} setIsSelectionMade={setIsSelectionMade} saveSelection={saveSelection} />}
    >
      <Box sx={cookieBannerStyle.modalContent}>
        <p>Wir verwenden Cookies und ähnliche Tools (zusammen "Cookies") für folgende Zwecke:</p>
        <strong>Essenziell</strong>
        <Grid container alignItems={"center"}>
          <Grid item xs={11}>
            <p>
              Diese Cookies sind erforderlich, um unsere Website und Services bereitzustellen und können nicht deaktiviert werden. Sie werden in der Regel als
              Reaktion auf Ihre Aktionen auf der Website festgelegt, z. B. die Festlegung Ihrer Datenschutzeinstellungen, die Anmeldung oder das Ausfüllen von
              Formularen sowie den Bezahlprozess.
            </p>
          </Grid>
          <Grid item xs={1}>
            <Switch color="primary" defaultChecked disabled />
          </Grid>
        </Grid>
        <Divider />
        <br />
        <strong>Analytisch</strong>
        <Grid container alignItems={"center"}>
          <Grid item xs={11}>
            <p>
              In der Kategorie „Analytisch“ finden sich Cookies wieder, die es uns zusammen mit den dazugehörigen Technologien erlauben das Nutzungsverhalten
              von Benutzern auf legalbird.de zu analysieren und auszuwerten. Die daraus gewonnenen Erkenntnisse dienen dazu unsere Services nutzerorientiert
              weiterzuentwickeln und entsprechend zu verbessern.
            </p>
          </Grid>
          <Grid item xs={1}>
            <Switch color="primary" checked={analyticsChecked} onChange={({ target }) => setAnalyticsChecked(target.checked)} />
          </Grid>
        </Grid>
        <Divider />
        <br />
        <p>
          Das Blockieren einiger Arten von Cookies kann sich auf Ihre Erfahrung auf unseren Websites auswirken. Um mehr über die auf unserer Homepage genutzten
          Cookies zu erfahren und Ihre Cookie-Einstellungen jederzeit zu ändern besuchen Sie bitte unsere{" "}
          <Link to={"/cookierichtlinie"} target="__blank">
            Cookierichtlinie
          </Link>
          .
        </p>
      </Box>
    </DialogModal>
  );
};

const ModalActions = ({
  setIsSelectionMade,
  saveSelection,
  setAnalyticsChecked = () => {},
}: {
  setIsSelectionMade: (isSelectionMade: boolean) => void;
  saveSelection: Function;
  setAnalyticsChecked: (analyticsChecked: boolean) => void;
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Button
          fullWidth
          onClick={() => {
            setIsSelectionMade(true);
            setAnalyticsChecked(false);
            denyAll();
          }}
          color={"primary"}
        >
          Ablehnen
        </Button>
      </Grid>
      <Grid item md={4} xs={12}>
        <Button
          fullWidth
          onClick={() => {
            setIsSelectionMade(true);
            saveSelection();
          }}
          color={"primary"}
        >
          Auswahl speichern
        </Button>
      </Grid>
      <Grid item md={4} xs={12}>
        <Button
          fullWidth
          onClick={() => {
            setIsSelectionMade(true);
            setAnalyticsChecked(true);
            allowAll();
          }}
          variant={"contained"}
          color={"primary"}
        >
          Alle Akzeptieren
        </Button>
      </Grid>
    </Grid>
  );
};

export default CookieModal;
