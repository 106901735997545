import React from "react";
import Layout from "../../Layouts/Layout";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getCaseLink, getProductNameBy } from "../../../services/productService";
import HintBox from "../../../components/Box/HintBox/HintBox";
import useProductParams from "../../../hooks/useProductParams";
import TextContainer from "../../../components/Container/TextContainer";

export default function InquiryToCustomerAnsweredPage() {
  const { productName, productId } = useProductParams();

  return (
    <Layout backgroundType={"serviceworld"}>
      <TextContainer>
        <HintBox headline={"Vielen Dank für Ihre Rückmeldung!"}>
          <Typography sx={{ marginTop: "2rem" }}>
            Wir haben Ihre Rückmeldung erhalten und bearbeiten Ihren Fall entsprechend weiter. Bei offenen Fragen oder Neuigkeiten zu Ihrem Fall, melden wir uns
            umgehend bei Ihnen zurück!
          </Typography>
          <Button
            sx={{ marginTop: "2rem" }}
            variant={"contained"}
            component={Link}
            to={getCaseLink({ name: getProductNameBy("urlPartShort", productName), id: productId })}
          >
            Zurück zu meinem Fall
          </Button>
        </HintBox>
      </TextContainer>
    </Layout>
  );
}
