const APPLICATION_STATUS_DESCRIPTION = 10;
const APPLICATION_STATUS_DOCUMENTS = 20;
const APPLICATION_STATUS_PERSONAL = 30;
const APPLICATION_STATUS_INSURANCE = 40;
const APPLICATION_STATUS_DONE = 200;

export {
  APPLICATION_STATUS_DESCRIPTION,
  APPLICATION_STATUS_DOCUMENTS,
  APPLICATION_STATUS_PERSONAL,
  APPLICATION_STATUS_INSURANCE,
  APPLICATION_STATUS_DONE,
};
