import React from "react";
import contactSectionStyle from "./contactSectionStyle";
import HeadlineSection from "../HeadlineSection/HeadlineSection";
import Typography from "@mui/material/Typography";
import { Button, Stack } from "@mui/material";
import Container from "../../../Container/Container";
import messageCenterImage from "../../../../assets/img/teaser/contact/MessageCenter.svg";
import { Link } from "react-router-dom";

export default function ContactSection({}) {
  return (
    <Container>
      <Stack alignItems={"center"}>
        <HeadlineSection headline={"Persönlicher Nachrichtenbereich"} typographyVariant={"h2"} image={messageCenterImage} smallImage={false} />
        <Typography variant={"subtitle1"} sx={contactSectionStyle.centerText}>
          In Ihrem persönlichen Nachrichtenbereich in der Servicewelt haben Sie jederzeit die Möglichkeit, sich mit unseren Experten bezüglich Ihrer Fälle
          auszutauschen. Dadurch bleiben Sie stets auf dem neuesten Stand und sind optimal informiert.
        </Typography>

        <Button variant={"contained"} color={"primary"} component={Link} to={"/servicewelt/nachrichten"} sx={contactSectionStyle.button}>
          Zu meinen Nachrichten
        </Button>
        <Typography fontWeight={"normal"} sx={contactSectionStyle.centerText}>
          Sie haben noch keinen Fall bei uns und allgemeine Fragen zum Ablauf? Dann schicken Sie uns gerne eine E-Mail an{" "}
          <a href={"mailto:service@legalbird.de"}>service@legalbird.de</a>
        </Typography>
      </Stack>
    </Container>
  );
}
