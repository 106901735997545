/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import React from "react";
import wizardStyle from "../../wizardStyle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";

export default function PersonalAffectionIntrotext() {
  return (
    <>
      <Grid item xs={12}>
        <FormLegend>
          <strong>Wir benötigen Ihre Unterstützung!</strong>
        </FormLegend>
      </Grid>

      <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
        <Typography sx={{ textAlign: "center", marginBottom: "2rem" }}>
          Im Zusammenhang mit dem vorliegenden Verfahren ist es notwendig, dass wir Ihre individuelle Betroffenheit vom Datenschutzverstoß der Gegenseite
          darlegen. Dafür benötigen wir von Ihnen weitere Informationen, um die Bedenken hinsichtlich Ihrer Befürchtungen und des Kontrollverlustes über Ihre
          persönlichen Daten und möglichen weiteren Datenmissbrauchs durch die Gegenseite und Wirtschaftsauskunfteien wie die SCHUFA oder CRIF angemessen
          darstellen zu können.
        </Typography>
        <Typography sx={{ textAlign: "center", marginBottom: "2rem" }}>
          Im Verfahren machen wir unter anderem Ihren Anspruch auf immateriellen Schadensersatz geltend. Es geht um den Verlust der Kontrolle über Ihre
          persönlichen Daten und die Befürchtung eines (weiteren) Datenmissbrauchs, die mit Sorgen und Ängsten einhergehen können. Um unseren Vortrag zu
          unterstützen, benötigen wir von Ihnen eine detaillierte Beschreibung Ihrer persönlichen Erfahrungen.
        </Typography>
        <Typography sx={{ textAlign: "center", marginBottom: "2rem" }}>
          <strong>
            Beantworten Sie bitte die nachfolgenden Fragen.
            <br />
            Bitte pro Frage mindestens ein Feld ankreuzen. Mehrfachantworten sind möglich.
          </strong>
        </Typography>
        <Typography sx={{ textAlign: "center", marginBottom: "2rem" }}>
          <strong>
            Hinweis: Je mehr Ausführungen wir von Ihnen haben, desto exakter können wir Ihren persönlichen Schaden durch die Datenweitergabe der Gegenseite an
            die SCHUFA oder CRIF darstellen.
          </strong>
        </Typography>
      </Grid>
    </>
  );
}
