import "url-search-params-polyfill";
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { logout } from "../../../../store/login/actions";
import { apiPost } from "../../../../services/apiClient";

export default function NewEmailPage() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const token = query.get("t");

  useEffect(() => {
    logout();
    apiPost("/confirm_new_email", { id, token }).then(
      () => {
        /*
        addMessage({
            type: "success",
            text: "Sie haben Ihre neue E-Mail-Adresse erfolgreich bestätigt. Bitte melden Sie sich nun mit der neuen E-Mail-Adresse und Ihrem Passwort an.",
        })
        */
      },
      () => {
        /*addMessage({
          type: "error",
          text: "Wir konnten Ihre neue E-Mail-Adresse nicht setzen. Sollte dieses Problem bestehen bleiben wenden Sie sich bitte an unseren Kundenservice.",
        })*/
      },
    )
  }, []);

  return <Navigate to={'/login'} />;
}
