import React, { useEffect } from "react";
import { Box, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import FormSubmitProps from "../../../types/FormSubmitProps";
import { useForm } from "../../../provider/Form/FormProvider";
import { getCaseLink } from "../../../services/productService";
import TextContainer from "../../Container/TextContainer";
import ContractData from "../ContractLaw/Summary/ContractData";
import DebtCollectionAgencyData from "../ContractLaw/Summary/DebtCollectionAgencyData";
import InsuranceData from "../Settlement/Summary/InsuranceData";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import EmailVerificationModal from "../../FormInfoModal/EmailVerificationModal";
import summaryStyle from "../ContractLaw/Summary/summaryStyle";
import SummaryDetailsItem from "../Common/SummaryDetailsItem/SummaryDetailsItem";
import { scrollToAnchor } from "../../../theme/commonStyle";
import ContractDescription from "../ContractLaw/Summary/ContractDescription";
import { LandLordData } from "./Summary/LandlordData";

type SummaryProps = {
  product: AbstractCase;
  submitProps: FormSubmitProps;
};

const Summary = ({ product, submitProps }: SummaryProps) => {
  const location = useLocation();
  const stateField = location.state?.field;
  const { values, handleSubmit } = useForm();

  useEffect(() => {
    const el = document.getElementById(stateField);
    el && el.scrollIntoView();
  }, [stateField]);

  const basePath = getCaseLink(product) + "/antrag";

  return (
    <Paper elevation={4}>
      <TextContainer>
        <Typography variant={"h4"} sx={summaryStyle.headline}>
          Ihre Angaben
        </Typography>
        <Grid container>
          <Box id={"contractData"} sx={summaryStyle.scrollToAnchor} />
          <ContractData caseData={product} formUrl={basePath + "/vertrag"} />
          {product.caseCategory !== "advisory" && (
            <>
              <Box id={"contractDetails"} sx={scrollToAnchor} />
              <SummaryDetailsItem fieldPath={"reasonForDispute"} formUrl={basePath + "/vertragsdetails"} caseData={product} />
              <SummaryDetailsItem fieldPath={"housingDeficienciesDescriptionClient"} formUrl={basePath + "/vertragsdetails"} caseData={product} />
              <SummaryDetailsItem fieldPath={"previousBasicRent"} formUrl={basePath + "/vertragsdetails"} caseData={product} />
              <SummaryDetailsItem fieldPath={"previousRentIncludingHeating"} formUrl={basePath + "/vertragsdetails"} caseData={product} />
              <SummaryDetailsItem fieldPath={"futureBasicRent"} formUrl={basePath + "/vertragsdetails"} caseData={product} />
              <SummaryDetailsItem fieldPath={"futureRentIncludingHeating"} formUrl={basePath + "/vertragsdetails"} caseData={product} />
              <SummaryDetailsItem fieldPath={"depositAmount"} formUrl={basePath + "/vertragsdetails"} caseData={product} />
              <SummaryDetailsItem fieldPath={"cancellationReceivedDate"} formUrl={basePath + "/vertragsdetails"} caseData={product} />

              <Box id={"contractualPartnerData"} sx={summaryStyle.scrollToAnchor} />
              <LandLordData formPath={basePath + "/vertragspartner"} formData={values} />
            </>
          )}
          {product.caseCategory === "defendClaim" && product.defenseProcessStatus === "debtCollection" && (
            <>
              <Box id={"debtCollectionAgencyData"} sx={summaryStyle.scrollToAnchor} />
              <DebtCollectionAgencyData title={"Angaben zum Inkassobüro"} formPath={basePath + "/inkasso"} formData={values} />
            </>
          )}
          <Box id={"contractDescription"} sx={summaryStyle.scrollToAnchor} />
          <ContractDescription title={"Beschreibung der Situation"} formPath={basePath + "/beschreibung"} formData={values} />
          <Box id={"insuranceData"} sx={summaryStyle.scrollToAnchor} />
          <InsuranceData title={"Versicherung"} formPath={basePath + "/versicherung"} product={product} />
        </Grid>
        <form onSubmit={handleSubmit}>
          <FormSubmit {...submitProps} hideBackButton={false} backLink={basePath + "/vertrag"} backText={"Alles bearbeiten"} nextText={"Angaben speichern"} />
        </form>
      </TextContainer>
      <EmailVerificationModal />
    </Paper>
  );
};

export default Summary;
