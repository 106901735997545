import Grid from "@mui/material/Grid";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import _map from "lodash/map";
import MenuItem from "@mui/material/MenuItem";
import EmailCheck from "../../EmailCheck/EmailCheck";
import ButtonLoading from "../../Button/ButtonLoading";
import ButtonTextTermsOfServicePrivacy from "../Common/ButtonTextTermsOfServicePrivacy/ButtonTextTermsOfServicePrivacy";
import React, { useMemo } from "react";
import { useForm } from "../../../provider/Form/FormProvider";
import {
  caseReferenceNumberIsUnique,
  emailDefault,
  isPhoneNumberDefault,
  matchRegexp,
  minStringLength,
  requiredValidator,
} from "../../../services/validationRules";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import trafficCheckinStyle from "./trafficCheckinStyle";

type TrafficCheckinFormProps = {
  isLoading: boolean;
  emailErrorText: string;
  setEmailErrorText: (emailErrorText: string) => void;
  accusation: Record<string, any>;
  partnerInsurer?: string | null;
  showPersonFields?: boolean;
};
export default function TrafficCheckinForm({
  isLoading,
  emailErrorText,
  setEmailErrorText,
  accusation,
  partnerInsurer = null,
  showPersonFields = false,
}: TrafficCheckinFormProps) {
  const { handleSubmit } = useForm();

  const caseReferenceNumberValidators = useMemo(() => {
    let caseReferenceNumberValidators = [requiredValidator("Bitte geben Sie Ihre Schaden-/Leistungsnummer an.")];
    switch (partnerInsurer) {
      case "advocard":
        caseReferenceNumberValidators.push({
          validator: matchRegexp,
          params: ["^\\d{2}-?(33-?0001-?|\\d)\\d{5}-?\\d$"],
          message: "Ihre Schadens-/Leistungsnummer hat das falsche Format",
        });
        break;
      default:
      case "arag":
        caseReferenceNumberValidators.push({
          validator: minStringLength,
          params: [3],
          message: "Bitte kontrollieren Sie Ihre Eingabe",
        });
        break;
    }

    caseReferenceNumberValidators.push({
      validator: caseReferenceNumberIsUnique,
      params: [""],
      message:
        "Diese Schadennummer ist bereits einem anderen Rechtsfall zugeordnet. Bitte überprüfen Sie die Schadennummer. Wenn Sie sich nicht sicher sind, lassen Sie das Feld vorerst leer.",
    });

    return caseReferenceNumberValidators;
  }, [partnerInsurer]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} sm={6} md={showPersonFields ? 6 : 4}>
          <ValidatorSelectField
            label={accusation.detail.label}
            name={"accusationDetail"}
            validators={[requiredValidator("Bitte geben Sie an, was Ihnen vorgeworfen wird.")]}
          >
            {_map(accusation.detail.options, (option, key) => (
              <MenuItem key={key} value={`${option.value}`}>
                {option.label}
              </MenuItem>
            ))}
          </ValidatorSelectField>
        </Grid>
        {showPersonFields && (
          <>
            <Grid item xs={12} sm={6}>
              <ValidatorSelectField label="Anrede" name={`gender`} validators={[requiredValidator("Bitte geben Sie Ihre Anrede an.")]}>
                <MenuItem value={"female"}>Frau</MenuItem>
                <MenuItem value={"male"}>Herr</MenuItem>
              </ValidatorSelectField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ValidatorTextField
                name={`givenName`}
                label="Vorname"
                validators={[
                  requiredValidator("Bitte geben Sie Ihren Vornamen an"),
                  { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ValidatorTextField
                name={`familyName`}
                label="Nachname"
                validators={[
                  requiredValidator("Bitte geben Sie Ihren Nachnamen an"),
                  { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
                ]}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={showPersonFields ? 6 : 4}>
          <EmailCheck
            name="email"
            label="Ihre E-Mail-Adresse"
            autoComplete="email"
            emailErrorText={emailErrorText}
            setEmailErrorText={setEmailErrorText}
            validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
            redirectProps={null}
          />
        </Grid>
        <Grid item xs={12} sm={partnerInsurer || showPersonFields ? 6 : 12} md={showPersonFields ? 6 : 4}>
          <ValidatorTextField
            name="telephone"
            label="Ihre Telefonnummer"
            autoComplete="tel"
            validators={[requiredValidator("Bitte geben Sie Ihre Telefonnummer an"), ...isPhoneNumberDefault]}
          />
        </Grid>
        {partnerInsurer && (
          <Grid item xs={12} sm={showPersonFields ? 12 : 6} md={12}>
            <ValidatorTextField
              name="caseReferenceNumber"
              label="Schadens-/Leistungsnummer"
              helperText={"Dies ist die Nummer, die Sie zu Ihrem aktuellen Anliegen erhalten haben."}
              validators={caseReferenceNumberValidators}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} sx={trafficCheckinStyle.buttonContainer}>
          <ButtonLoading variant="contained" color="primary" sx={trafficCheckinStyle.button} type="submit" fullWidth isLoading={isLoading}>
            Kostenlose Ersteinschätzung
          </ButtonLoading>
          <ButtonTextTermsOfServicePrivacy />
        </Grid>
      </Grid>
    </form>
  );
}
