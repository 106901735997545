import Grid from "@mui/material/Grid";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { isPhoneNumberDefault, isRequired } from "../../../../services/validationRules";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import { MenuItem } from "@mui/material";
import React from "react";
import FormLegend from "../FormLegend/FormLegend";
import { translate } from "../../../../services/translations/translations";
import { serviceTimes } from "../../../../services/formServiceFunctions";
import _map from "lodash/map";
import { FormDataTypes } from "../../../../types/FormDataTypes";
import { useForm } from "../../../../provider/Form/FormProvider";
import wizardStyle from "../../wizardStyle";

type ContactProps = {
  path?: string;
  headline?: string;
  withContactTime?: boolean;
};

export default function Contact({ path = "customer", headline = "Wie können wir Sie telefonisch am besten erreichen?", withContactTime = true }: ContactProps) {
  const { values }: FormDataTypes = useForm();

  return (
    <>
      <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
        <FormLegend>{headline}</FormLegend>
      </Grid>
      <Grid item xs={12} sm={withContactTime ? 6 : 12} sx={withContactTime ? wizardStyle.gridSpace : undefined}>
        <ValidatorTextField
          name={`${path}.telephone`}
          label={translate("person.telephone.label", values)}
          autoComplete="tel"
          validators={[
            {
              validator: isRequired,
              message: "Bitte geben Sie Ihre Telefonnummer an",
            },
            ...isPhoneNumberDefault,
          ]}
        />
      </Grid>
      {withContactTime && (
        <Grid item xs={12} sm={6}>
          <ValidatorSelectField label={translate("person.contactTime.label", values)} name={`${path}.contactTime`}>
            {_map(serviceTimes(), (serviceTimeSlot, value) => (
              <MenuItem key={value} value={value}>
                {translate(`person.contactTime.options.${value}.label`, values)}
              </MenuItem>
            ))}
          </ValidatorSelectField>
        </Grid>
      )}
    </>
  );
}
