import doneBoxStyle from "./doneBoxStyle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import _uniqueId from "lodash/uniqueId";
import React from "react";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import { Child } from "../../../../types/Entities/Child";
import {Divider} from "@mui/material";

type ChildrenSectionProps = {
  divorce: AbstractCase;
};

const ChildrenSection = ({ divorce }: ChildrenSectionProps) => {
  if (divorce.numberChildren === 0) {
    return null;
  }
  return (
    <div>
      <Typography variant={"h6"} sx={doneBoxStyle.subheader}>
        Angaben zu Ihren Kindern
      </Typography>
      <Divider sx={doneBoxStyle.divider} />
      <Grid container>
        {divorce.children.map((child: Child, index: number) => {
          return [
            <Grid container item xs={12} key={_uniqueId()}>
              <Grid item xs={12} sx={doneBoxStyle.label}>
                Vorname Kind {index + 1}
              </Grid>
              <Grid item xs={12}>
                {child.name}
              </Grid>
            </Grid>,
            <Grid container item xs={12} key={_uniqueId()}>
              <Grid item xs={12} sx={doneBoxStyle.label}>
                Wohnort Kind {index + 1}
              </Grid>
              <Grid item xs={12}>
                {child.livesWith === "petitioner" ? "Lebt bei mir" : "Lebt beim Ehepartner"}
              </Grid>
            </Grid>,
          ];
        })}
      </Grid>
    </div>
  );
};

export default ChildrenSection;
