import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import useProductParams from "../../../../hooks/useProductParams";
import Layout from "../../../../containers/Layouts/Layout";
import Container from "../../../Container/Container";
import HintBox from "../../../Box/HintBox/HintBox";
import { FormProvider } from "../../../../provider/Form/FormProvider";
import React, { useState } from "react";
import LegalbirdLoader from "../../../ContentLoader/LegalbirdLoader";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import { getCaseLink, getProductData, getProductNameBy } from "../../../../services/productService";
import FinalProcessFeedbackFlexServiceClient from "./Modules/FinalProcessFeedbackFlexServiceClient";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import _cloneDeep from "lodash/cloneDeep";
import ApiClient from "../../../../services/apiClient";
import { STATUS_COMPLETED } from "../../../../services/contractLaw/leadStatus";

const FlexServiceFeedback = () => {
  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");
  const { data: product, isLoading: isLoadingProduct } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const [isLoading] = useState(false);
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data),
  });
  const navigate = useNavigate();

  if (isLoadingProduct) {
    return (
      <Layout>
        <Container>
          <Container sx={{ width: 250 }}>
            <LegalbirdLoader />
          </Container>
        </Container>
      </Layout>
    );
  }

  if (!!product.finalClaimStatusClient || product.leadStatus !== STATUS_COMPLETED || product.insurance.specialTermsTag !== "advocardFlex") {
    return (
      <Layout>
        <Container>
          <HintBox headline={"Keine Berechtigung"}>
            Sie haben aktuell nicht die Berechtigung diese Seite aufzurufen.
            <Button component={Link} to={getCaseLink(product)} variant={"text"} color={"primary"}>
              Zurück zum Fall
            </Button>
          </HintBox>
        </Container>
      </Layout>
    );
  }

  const initialValues = {
    clientReaction: null,
    finalProcessFeedbackClient: null,
  };

  const handleSubmit = async ({ values }: { values: AbstractCase }) => {
    const updateData = _cloneDeep(values);
    if (updateData.clientReaction === "closeCase") {
      updateData.finalClaimStatusClient = "fullyAgreed";
      await updateMutation.mutateAsync({
        uri: getProductData(product.name, "apiUri"),
        id: product.id,
        data: updateData,
      });
    } else if (updateData.clientReaction === "continueNewCase") {
      await ApiClient.post(`/advocard/flex/reset_case/${product.productCategory}/${product.id}`);
    }

    navigate(getCaseLink(product) + "/flex/rueckmeldung/bestaetigung", { state: "sendForm" });
  };

  return (
    <Layout backgroundType={"serviceworld"}>
      <Container>
        <HintBox headline={"Weiteres Vorgehen notwendig"}>
          <p>
            <strong>Wenn Sie der Meinung sind, dass Sie weiteren rechtlichen Beistand benötigen, dann können Sie uns das hier mitteilen</strong>
            <br />
            <br />
            Bitte geben Sie uns hier eine Rückmeldung darüber, wie Sie weiter vorgehen möchten.
          </p>
        </HintBox>
        <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
          <FinalProcessFeedbackFlexServiceClient isLoading={isLoading} />
        </FormProvider>
      </Container>
    </Layout>
  );
};

export default FlexServiceFeedback;
