import React, { ReactNode } from "react";
import greyBackgroundWrapperStyle from "./greyBackgroundWrapperStyle";
import TransparentToGreyDivider from "./TransparentToGreyDivider";
import GreyToTransparentDivider from "./GreyToTransparentDivider";
import { Box } from "@mui/material";

type GreyBackgroundWrapperProps = {
  hasVerticalPadding?: boolean;
  children?: ReactNode;
};
export default function GreyBackgroundWrapper({ hasVerticalPadding = true, children }: GreyBackgroundWrapperProps) {
  return (
    <>
      <TransparentToGreyDivider />
      <Box
        sx={[
          greyBackgroundWrapperStyle.greyBackground,
          (hasVerticalPadding ? greyBackgroundWrapperStyle.verticalPadding : {}),
        ]}
      >
        {children}
      </Box>
      <GreyToTransparentDivider />
    </>
  );
}
