import Grid from "@mui/material/Grid";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import MenuItem from "@mui/material/MenuItem";
import EmailCheck from "../../EmailCheck/EmailCheck";
import ButtonLoading from "../../Button/ButtonLoading";
import ButtonTextTermsOfServicePrivacy from "../Common/ButtonTextTermsOfServicePrivacy/ButtonTextTermsOfServicePrivacy";
import React from "react";
import { useForm } from "../../../provider/Form/FormProvider";
import { emailDefault, requiredValidator } from "../../../services/validationRules";
import tenancyLawCheckinStyle from "./tenancyLawCheckinStyle";

type TenancyLawCheckinFormProps = {
  emailErrorText: string;
  setEmailErrorText: (emailErrorText: string) => void;
  isLoading: boolean;
};

export default function TenancyLawCheckinForm({ emailErrorText, setEmailErrorText, isLoading }: TenancyLawCheckinFormProps) {
  const { handleSubmit } = useForm();
  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems={"baseline"} justifyContent={"center"}>
        <Grid item xs={12} md={4} sx={tenancyLawCheckinStyle.gridSpaceMd}>
          <ValidatorSelectField label="Ihr Anliegen" name={`reasonForDispute`} validators={[requiredValidator("Bitte geben Sie Ihr Anliegen an.")]}>
            <MenuItem value={"housingDeficiencies"}>Mietmängel</MenuItem>
            <MenuItem value={"rentIncrease"}>Mieterhöhung</MenuItem>
            <MenuItem value={"utilitiesStatement"}>Nebenkostenabrechnung</MenuItem>
            <MenuItem value={"deposit"}>Kaution</MenuItem>
            <MenuItem value={"landlordHasCancelled"}>Kündigung</MenuItem>
            <MenuItem value={"illegalContractClause"}>Rechtswidrige Vertragsklausel</MenuItem>
            <MenuItem value={"other"}>Anderes Anliegen</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} sm={4} sx={tenancyLawCheckinStyle.gridSpace}>
          <EmailCheck
            name="email"
            label="Ihre E-Mail-Adresse"
            autoComplete="email"
            emailErrorText={emailErrorText}
            setEmailErrorText={setEmailErrorText}
            validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
            redirectProps={null}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={[tenancyLawCheckinStyle.submit, tenancyLawCheckinStyle.gridTopSpaced]}>
          <ButtonLoading variant="contained" color="primary" sx={tenancyLawCheckinStyle.button} type="submit" fullWidth isLoading={isLoading}>
            jetzt starten
          </ButtonLoading>
          <ButtonTextTermsOfServicePrivacy />
        </Grid>
      </Grid>
    </form>
  );
}
