import { UseMutationResult } from "@tanstack/react-query";
import { UpdateResourceParams } from "./reactQuery/reactQueryService";
import structuredClone from "@ungap/structured-clone";
import { MediaObjectCombinationRequest } from "../types/Entities/MediaObject/MediaObjectCombinationRequest";

export const handleDocumentUploadDescriptionSave = async (
  mediaObjectCombinationRequest: MediaObjectCombinationRequest,
  currentStep: number,
  description: string,
  updateMutation: UseMutationResult<any, unknown, UpdateResourceParams>
) => {
  let filesToCombineClone = structuredClone(mediaObjectCombinationRequest.filesToCombine);
  filesToCombineClone[currentStep].description = description;

  await updateMutation.mutateAsync({
    uri: "/media_object_combination_requests",
    id: mediaObjectCombinationRequest.id,
    data: {
      filesToCombine: filesToCombineClone,
    },
  });
};

export const getNoteStep = (steps: number) => {
  return steps - 2;
};

export const getFinishedStep = (steps: number) => {
  return steps - 1;
};

export const getMainDocumentStep = () => {
  return 0;
};
