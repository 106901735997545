import React from "react";
import _includes from "lodash/includes";
import SummaryDetailsItem from "../../../Common/SummaryDetailsItem/SummaryDetailsItem";
import SummaryDetailsProps from "../../../../../types/SummaryDetailsProps";

const SummaryDetailsService = ({ caseData, formUrl }: SummaryDetailsProps) => {
  return (
    <>
      <SummaryDetailsItem fieldPath={"reasonForDispute"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem fieldPath={"serviceTitle"} formUrl={formUrl} caseData={caseData} />

      {caseData.reasonForDispute === "costEstimateExceeded" && (
        <>
          <SummaryDetailsItem fieldPath={"costEstimate"} formUrl={formUrl} caseData={caseData} fieldType={"money"} />
          <SummaryDetailsItem fieldPath={"costEstimateAdjustment"} formUrl={formUrl} caseData={caseData} />

          {_includes(["asap", "tooLate"], caseData.costEstimateAdjustment) && (
            <SummaryDetailsItem fieldPath={"costEstimateAdjustmentDate"} formUrl={formUrl} caseData={caseData} fieldType={"date"} />
          )}
        </>
      )}
      <SummaryDetailsItem fieldPath={"costReal"} formUrl={formUrl} caseData={caseData} fieldType={"money"} />

      {_includes(["hasDefects", "unfinishedWork"], caseData.reasonForDispute) && (
        <>
          <SummaryDetailsItem fieldPath={"defectsDescriptionClient"} formUrl={formUrl} caseData={caseData} />
          <SummaryDetailsItem fieldPath={"numberRepairAttempts"} formUrl={formUrl} caseData={caseData} />
        </>
      )}
    </>
  );
};

export default SummaryDetailsService;
