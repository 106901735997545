import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/reactQuery/reactQueryService";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ServiceWorldContentBox from "../../Serviceworld/ServiceWorldContentBox";
import { Box, FormControl, ListSubheader, Stack } from "@mui/material";
import MessageCasePickerMenuItemContent from "./MessageCasePickerMenuItemContent";
import { splitUpByCompletedStatus } from "../../../services/serviceWorldService";

type MessagesCasePickerProps = {
  handleConversationClick: Function;
  selectedCase: any;
};

export default function MessagesCasePicker({ handleConversationClick, selectedCase }: MessagesCasePickerProps) {
  const { data: minimalCases, isLoading } = useQuery(queryKeys.collection("/collection/cases"), () => fetchCollection("/collection/cases"));

  if (isLoading) {
    return null;
  }

  const { cases, closedCases } = splitUpByCompletedStatus(minimalCases);

  return (
    <ServiceWorldContentBox headline={"Fall auswählen"}>
      <Box sx={{ padding: "0 1rem 1rem 1rem" }}>
        <Stack alignItems={"center"}>
          <FormControl fullWidth={true} margin={"none"}>
            <Select value={selectedCase || " "}>
              {cases.map((minimalCase) => (
                <MenuItem key={minimalCase.id} onClick={() => handleConversationClick(minimalCase)} value={minimalCase}>
                  <MessageCasePickerMenuItemContent minimalCase={minimalCase} />
                </MenuItem>
              ))}
              {closedCases.length > 0 && <ListSubheader>Abgeschlossen</ListSubheader>}
              {closedCases.map((minimalCase) => (
                <MenuItem key={minimalCase.id} onClick={() => handleConversationClick(minimalCase)} value={minimalCase}>
                  <MessageCasePickerMenuItemContent minimalCase={minimalCase} />
                </MenuItem>
              ))}
              <MenuItem value={" "} disabled> </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Box>
    </ServiceWorldContentBox>
  );
}
