import React from "react";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import { Box, Grid, Hidden } from "@mui/material";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import Address from "../../Common/Address/Address";
import { emailDefault, isPhoneNumberDefault } from "../../../../services/validationRules";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";
import { ContractDetailsProps } from "../../../../types/ContractDetailsProps";
import wizardStyle from "../../wizardStyle";

export default function DebtCollection({ submitProps }: ContractDetailsProps) {
  const { handleSubmit } = useForm();
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Angaben zum Inkassounternehmen</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>
                Sie haben angegeben, dass Sie von einem Inkassounternehmen zur Zahlung aufgefordert wurden. Bitte prüfen Sie das Schreiben vom
                Inkassounternehmen und befüllen Sie entsprechend die folgenden Angaben so vollständig wie möglich.
              </FormLegend>
            </Grid>
            <Grid item xs={12} md={6} sx={wizardStyle.gridSpaceMd}>
              <ValidatorTextField name="debtCollectionAgency.name" label="Name Inkassobüro" />
            </Grid>
            <Grid item xs={12} md={6}>
              <ValidatorTextField
                name="debtCollectionAgencyReference"
                label="Aktenzeichen Inkassobüro"
                helperText="Dieses Aktenzeichen finden Sie auf dem Schreiben des Inkassobüros. Meist rechts oben im Briefkopf oder in der Betreffzeile."
              />
            </Grid>
            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>Bitte geben Sie möglichst alle weiteren Daten zum Inkassounternehmen an, die Ihnen vorliegen.</FormLegend>
            </Grid>
            <Address path="debtCollectionAgency.address" headline="" fieldsAreRequired={false} />
            <Grid item xs={12} sm={6} sx={wizardStyle.gridSpaceMd}>
              <ValidatorTextField name="debtCollectionAgency.email" label="E-Mail-Adresse" validators={[emailDefault]} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ValidatorTextField name="debtCollectionAgency.telephone" label="Telefonnummer" validators={isPhoneNumberDefault} />
            </Grid>
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
