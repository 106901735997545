import React from "react";
import Signature from "./Signature";
import { Person } from "../../../../types/Entities/Person";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

type MultipleSignaturesProps = {
  product: AbstractCase;
  signatories: Person[];
};

const MultipleSignatures = ({ product, signatories }: MultipleSignaturesProps) => {
  return (
    <>
      {signatories.map((signatory, index) => {
        const previousSignatory = signatories[index - 1];
        const signatureIsDisabled = previousSignatory ? previousSignatory.signature === null : false;

        return <Signature key={index} product={product} person={signatory} signatureIsDisabled={signatureIsDisabled} />;
      })}
    </>
  );
};

export default MultipleSignatures;
