import React from "react";
import * as PropTypes from "prop-types";
import {Box, Grid} from "@mui/material";
import contractLawDetailsStyles from "./contractLawDetailsStyles";
import Typography from "@mui/material/Typography";
import CaseDetailsItem from "../../Common/CaseDetailsItem/CaseDetailsItem";

const GeneralContractLawCaseDetails = ({ contractLawCase }) => {
  return (
    <>
      <Typography variant={"h6"} sx={contractLawDetailsStyles.subheader}>
        Angaben zum Vertrag
      </Typography>

      <Box sx={contractLawDetailsStyles.divider} />
      <Grid container>
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"name"} />
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"caseCategory"} />
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"client.gender"} />
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"client.givenName"} />
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"client.familyName"} />
        {contractLawCase.caseCategory !== "advisory" && (
          <>
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"client.residenceAddress.streetAddress"} />
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"client.residenceAddress.postalCode"} />
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"client.residenceAddress.addressLocality"} />
          </>
        )}
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"customer.telephone"} />

        {contractLawCase.additionalClients?.map((client, index) => (
          <React.Fragment key={index}>
            <CaseDetailsItem
              caseData={contractLawCase}
              fieldPath={`additionalClients[${index}].gender`}
              labelPostfix={`${index + 2}. Vertragsabschließender`}
            />
            <CaseDetailsItem
              caseData={contractLawCase}
              fieldPath={`additionalClients[${index}].givenName`}
              labelPostfix={`${index + 2}. Vertragsabschließender`}
            />
            <CaseDetailsItem
              caseData={contractLawCase}
              fieldPath={`additionalClients[${index}].familyName`}
              labelPostfix={`${index + 2}. Vertragsabschließender`}
            />
          </React.Fragment>
        ))}
        {contractLawCase.caseCategory !== "advisory" && (
          <>
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractDate"} fieldType={"date"} />
          </>
        )}
      </Grid>
    </>
  );
};

GeneralContractLawCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

GeneralContractLawCaseDetails.defaultProps = {};

export default GeneralContractLawCaseDetails;
