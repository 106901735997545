import React from "react";
import TravelContractDetails from "./Travel/TravelContractDetails";
import PurchaseContractDetails from "./PurchaseContractDetails/PurchaseContractDetails";
import VehicleContractDetails from "./VehicleContractDetails/VehicleContractDetails";
import SubscriptionContractDetails from "./SubscriptionContractDetails/SubscriptionContractDetails";
import MembershipContractDetails from "./MembershipContractDetails/MembershipContractDetails";
import ServiceContractDetails from "./ServiceContractDetails/ServiceContractDetails";
import EventContractDetails from "./EventContractDetails/EventContractDetails";
import ProviderContractDetails from "./ProviderContractDetails/ProviderContractDetails";
import DataLeakContractDetails from "./DataLeakContract/DataLeakContractDetails";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import FormSubmitProps from "../../../types/FormSubmitProps";

type ContractDetailsProps = {
  product: AbstractCase;
  submitProps: FormSubmitProps;
};

export default function ContractDetails(props: ContractDetailsProps) {
  const { product } = props;
  switch (product.name) {
    case "travelContract":
      return <TravelContractDetails {...props} />;
    case "purchaseContract":
      return <PurchaseContractDetails {...props} />;
    case "vehicleContract":
      return <VehicleContractDetails {...props} />;
    case "subscriptionContract":
      return <SubscriptionContractDetails {...props} />;
    case "membershipContract":
      return <MembershipContractDetails {...props} />;
    case "serviceContract":
      return <ServiceContractDetails {...props} />;
    case "eventContract":
      return <EventContractDetails {...props} />;
    case "providerContract":
      return <ProviderContractDetails {...props} />;
    case "dataLeakContract":
      return <DataLeakContractDetails {...props} />;
    default:
      return null;
  }
}
