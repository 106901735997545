const birdMapSectionStyle = {
  headline: {
    textAlign: "center",
    paddingBottom: "1rem",
  },
  subheadline: {
    textAlign: "center",
    paddingBottom: "3rem",
  },
  mapIcon: {
    width: "300px",
  },
  container: {},
};

export default birdMapSectionStyle;
