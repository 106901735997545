import React from "react";
import { useTheme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { Box, Theme } from "@mui/material";

type ThemedIllustrationProps = {
  lightIllustration: string;
  darkIllustration: string;
  alt?: string;
  sx?: SxProps<Theme>;
};

const ThemedIllustration = ({ lightIllustration, darkIllustration, alt, sx }: ThemedIllustrationProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return <Box component={"img"} src={isDarkMode ? darkIllustration : lightIllustration} sx={sx} alt={alt} />;
};

export default ThemedIllustration;
