import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ButtonLoading from "../../../Button/ButtonLoading";
import feedbackFormTenancyLawStyle from "./feedbackFormTenancyLawStyle";
import ClientReceivedReaction from "./Modules/ClientReceivedReaction";
import ClientAgrees from "./Modules/ClientAgrees";
import Recommendation from "./Modules/Recommendation";
import LawsuitConsequences from "./Modules/LawsuitConsequences";
import FinalClaimStatusClient from "./Modules/FinalClaimStatusClient";
import FinalProcessFeedbackClient from "./Modules/FinalProcessFeedbackClient";
import { useForm } from "../../../../provider/Form/FormProvider";
import AdditionalFeedbackMessage from "./Modules/AdditionalFeedbackMessage";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import wizardStyle from "../../wizardStyle";
import { Box } from "@mui/material";

type FeedbackFormProps = {
  product: AbstractCase;
  isLoading: boolean;
};

const FeedbackFormTenancyLaw = ({ product, isLoading }: FeedbackFormProps) => {
  const { handleSubmit, values } = useForm();
  const [hasClaimStatusClient, setHasClaimStatusClient] = useState(false);

  const { extrajudicialReaction } = product;
  const { clientAgrees, reactionReceived } = values;
  useEffect(() => {
    setHasClaimStatusClient(
      !((extrajudicialReaction === "fullyAgreed" && clientAgrees !== "no") || (extrajudicialReaction === "noReaction" && reactionReceived !== "yes"))
    );
  }, [setHasClaimStatusClient, extrajudicialReaction, clientAgrees, reactionReceived]);

  return (
    <Paper sx={feedbackFormTenancyLawStyle.paper} elevation={4}>
      <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
        <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
          <ClientReceivedReaction product={product} />
          <ClientAgrees product={product} />
          <FinalClaimStatusClient product={product} hasClaimStatusClient={hasClaimStatusClient} />
          <Recommendation product={product} hasClaimStatusClient={hasClaimStatusClient} />
          <FinalProcessFeedbackClient product={product} hasClaimStatusClient={hasClaimStatusClient} />
          <LawsuitConsequences product={product} />
          <AdditionalFeedbackMessage />
        </Grid>
        <ButtonLoading sx={feedbackFormTenancyLawStyle.button} fullWidth variant="contained" color="primary" type="submit" isLoading={isLoading}>
          Meine Rückmeldung übermitteln
        </ButtonLoading>
      </Box>
    </Paper>
  );
};
export default FeedbackFormTenancyLaw;
