import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../Layouts/Layout";
import Container from "../../../../components/Container/Container";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import PowerOfAttorney from "../../../../components/Form/Traffic/PowerOfAttorney";
import { STATUS_POWER_OF_ATTORNEY_GRANTED } from "../../../../services/traffic/leadStatus";
import { getCaseLink, getProductData } from "../../../../services/productService";
import { Helmet } from "react-helmet-async";
import { apiCall } from "../../../../services/apiClient";
import HintBox from "../../../../components/Box/HintBox/HintBox";
import { fromGender } from "../../../../services/formServiceFunctions";
import Button from "@mui/material/Button";
import { FormProvider } from "../../../../provider/Form/FormProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";
import { useCustomer } from "../../../../provider/Customer/CustomerProvider";

const PowerOfAttorneyPageTraffic = () => {
  const { productId } = useProductParams();
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const productApiUri = getProductData("traffic", "apiUri");
  const { data: traffic, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const { customer } = useCustomer();
  const formService = getProductData("traffic", "formService");
  const queryClient = useQueryClient();

  if (isLoading) {
    return (
      <Layout>
        <Container sx={{ width: 250 }}>
          <LegalbirdLoader />
        </Container>
      </Layout>
    );
  }

  if (isComplete || traffic <= STATUS_POWER_OF_ATTORNEY_GRANTED || traffic.powerOfAttorney !== null) {
    return (
      <Layout>
        <Container>
          <HintBox headline={"Vielen Dank!"}>
            <p>Wir haben Ihre Vollmacht gespeichert und bearbeiten Ihren Fall entsprechend weiter.</p>
            <Button variant={"text"} color={"primary"} component={Link} to={getCaseLink(traffic)} fullWidth>
              Zurück zum Fall
            </Button>
          </HintBox>
        </Container>
      </Layout>
    );
  }

  const handleSubmit = async () => {
    setIsLoadingInternal(true);
    await apiCall("PATCH", "/traffic/" + traffic.id + "/power_of_attorney", {
      body: JSON.stringify({
        powerOfAttorney: null,
      }),
    });
    setIsLoadingInternal(false);
    setIsComplete(true);
    await queryClient.invalidateQueries(["/traffic", traffic.id]);
  };

  return (
    <FormProvider initialValues={formService.prepareFormData(traffic, customer)} submitAction={handleSubmit}>
      <Layout>
        <Helmet>
          <title>Legalbird | Vollmacht </title>
        </Helmet>
        <Container>
          <HintBox headline={"Bitte erteilen Sie Ihre Vollmacht"}>
            <p>
              Achtung: Auf dieser Vollmacht muss{" "}
              <strong>
                {fromGender(traffic.client.gender)} {traffic.client.givenName} {traffic.client.familyName}
              </strong>{" "}
              unterzeichnen damit wir weiter tätig werden können.
            </p>
          </HintBox>
          <PowerOfAttorney product={traffic} submitProps={{ isLoading: isLoadingInternal, isMinimal: true }} />
        </Container>
      </Layout>
    </FormProvider>
  );
};
export default PowerOfAttorneyPageTraffic;
