import React from "react";
import Layout from "../../../Layouts/Layout";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../../services/reactQuery/reactQueryService";
import LegalbirdLoaderPage from "../../Loading/LegalbirdLoaderPage";
import { Conversation } from "../../../../types/Entities/Messaging/Conversation";
import MessageCenter from "../../../../components/Messaging/MessageCenter/MessageCenter";
import Container from "../../../../components/Container/Container";

export default function MessageCenterPage() {

  const conversationFilter = {
    "exists[containedMessages]": true,
  };

  const { data: conversations, isLoading: isLoadingConversations } = useQuery<Array<Conversation>, Error>(queryKeys.collection("/conversations", conversationFilter), () =>
    fetchCollection("/conversations", conversationFilter)
  );

  const { data: minimalCases, isLoading: isLoadingMinimalCases } = useQuery(queryKeys.collection("/collection/cases"), () =>
    fetchCollection("/collection/cases")
  );

  if (isLoadingConversations || isLoadingMinimalCases) {
    return <LegalbirdLoaderPage />;
  }

  return (
    <Layout backgroundType={"serviceworld"}>
      <Container>
        <MessageCenter minimalCases={minimalCases || []} conversations={conversations || []} />
      </Container>
    </Layout>
  );
}
