import React from "react";
import SummaryDetailsItem from "../../../Common/SummaryDetailsItem/SummaryDetailsItem";
import SummaryDetailsProps from "../../../../../types/SummaryDetailsProps";

const SummaryDetailsTravel = ({ formUrl, caseData }: SummaryDetailsProps) => {
  return (
    <>
      <SummaryDetailsItem fieldPath={"reasonForDispute"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem fieldPath={"contractNumber"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem fieldPath={"bookingDate"} formUrl={formUrl} caseData={caseData} fieldType={"date"} />
      <SummaryDetailsItem fieldPath={"journeyStart"} formUrl={formUrl} caseData={caseData} fieldType={"date"} />
      <SummaryDetailsItem fieldPath={"journeyEnd"} formUrl={formUrl} caseData={caseData} fieldType={"date"} />

      {caseData.reasonForDispute === "cancelled" && (
        <>
          <SummaryDetailsItem fieldPath={"contractualPartnerHasCancelled"} formUrl={formUrl} caseData={caseData} />

          {caseData.contractualPartnerHasCancelled === "yes" && (
            <SummaryDetailsItem fieldPath={"contractualPartnerCancellationDate"} formUrl={formUrl} caseData={caseData} fieldType={"date"} />
          )}
          <SummaryDetailsItem fieldPath={"buyerPaidStatus"} formUrl={formUrl} caseData={caseData} />
          <SummaryDetailsItem fieldPath={"buyerPaidDate"} formUrl={formUrl} caseData={caseData} fieldType={"date"}/>
          <SummaryDetailsItem fieldPath={"buyerPaidAmount"} formUrl={formUrl} caseData={caseData} fieldType={"money"}/>
        </>
      )}
    </>
  );
};

export default SummaryDetailsTravel;
