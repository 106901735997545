import React from "react";
import { IMaskInput } from "react-imask";

export const ThousandsNumberInputFloat = React.forwardRef(function ThousandsNumberInput(props, ref) {
  const { onChange, name, numberType, ...other }: any = props;
  return (
    <IMaskInput
      {...other}
      mask={Number}
      scale={2}
      radix={","}
      unmask
      thousandsSeparator={"."}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: name, value } })}
    />
  );
});
