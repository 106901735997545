import { Helmet } from "react-helmet-async";
import Container from "../../../../components/Container/Container";
import Layout from "../../../Layouts/Layout";
import React, { useEffect, useState } from "react";
import HintBox from "../../../../components/Box/HintBox/HintBox";
import FileUploadSection from "../../../../components/Form/Common/FileUploadSection/FileUploadSection";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import TextContainer from "../../../../components/Container/TextContainer";
import ButtonLoading from "../../../../components/Button/ButtonLoading";
import requestDocumentsPageStyle from "./requestDocumentsPageStyle";
import Paper from "@mui/material/Paper";
import ApiClient from "../../../../services/apiClient";
import { useNavigate } from "react-router-dom";
import { getCaseLink, getProductData } from "../../../../services/productService";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";
import useMediaObjectCombinationRequest from "../../../../hooks/useMediaObjectCombinationRequest";

const RequestDocumentsPage = () => {
  const { productId } = useProductParams();
  const productApiUri = getProductData("traffic", "apiUri");
  const { data: traffic, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));

  if (isLoading) {
    return <LegalbirdLoader />;
  }
  return <RequestDocumentsPageInner traffic={traffic} />;
};

const RequestDocumentsPageInner = ({ traffic }) => {
  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);

  let mediaObjectCombinationRequestTypes = [];
  if (traffic.fineNotice === null) {
    mediaObjectCombinationRequestTypes.push("files");
  }

  const { mediaObjectCombinationRequests, isLoadingMediaObjectCombinationRequest, updateAction } = useMediaObjectCombinationRequest(
    traffic,
    mediaObjectCombinationRequestTypes
  );

  const navigate = useNavigate();
  const triggerUpdate = async () => {
    setIsLoadingInternal(true);
    await ApiClient.post(`/generate_requested_mediaobjects/traffic/${traffic.id}`);
    navigate(getCaseLink(traffic));
  };

  useEffect(() => {
    if (traffic) {
      let hasRequestedDocument = traffic.fineNotice === null;
      if (!hasRequestedDocument) {
        navigate(getCaseLink(traffic));
      }
    }
  }, [traffic, navigate]);

  if (isLoading || isLoadingMediaObjectCombinationRequest) {
    return (
      <Container sx={{ width: 250 }}>
        <LegalbirdLoader />
      </Container>
    );
  }

  return (
    <Layout active="serviceworld" product={"traffic"}>
      <Helmet>
        <title>Legalbird - Fehlende Dokumente</title>
      </Helmet>
      <TextContainer>
        <HintBox headline={"Fehlende Dokumente ergänzen"}>Um Ihren Fall weiter bearbeiten zu können, benötigen wir noch folgende Unterlagen von Ihnen.</HintBox>
        <Paper elevation={4} sx={requestDocumentsPageStyle.box}>
          {traffic.fineNotice === null && (
            <FileUploadSection
              mediaObjectCombinationRequest={mediaObjectCombinationRequests.files}
              temporaryMediaObjectsFieldname={"files"}
              headline={"Bußgeldbescheid"}
              helperText={"Bitte laden Sie Ihren Bußgeldbescheid hoch."}
              productEntity={traffic}
              productEntityId={traffic.id}
              product={"traffic"}
              updateAction={updateAction}
              setIsBlocked={setIsBlocked}
              isBlocked={isBlocked}
              fieldName={"fineNotice"}
            />
          )}
          <br />
          <br />
          <ButtonLoading
            onClick={triggerUpdate}
            color="primary"
            variant="contained"
            disabled={false}
            fullWidth={true}
            type={"submit"}
            isLoading={isLoadingInternal}
          >
            Dokumente Hochladen
          </ButtonLoading>
        </Paper>
      </TextContainer>
    </Layout>
  );
};

export default RequestDocumentsPage;
