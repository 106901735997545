import React from "react";
import { IMaskInput } from "react-imask";

export const IbanInput = React.forwardRef(function IbanInput(props: any, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00 0000 0000 0000 0000 00"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});
