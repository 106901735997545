const serviceWorldCaseItemStyle = {
  headline: {
    fontWeight: 500,
  },
  reference: {
    textAlign: "right",
    paddingTop: "5px",
  },
  icon: {
    width: "30px",
    height: "30px",
  },
  textContainer: {
    marginLeft: "25px",
    wordBreak: "break-word",
  },
  innerContainer: {
    marginTop: "5px",
    fontSize: "1rem",
  },
  warningIcon: {
    flexGrow: 1,
  },
  caseItem: {
    padding: "0 25px 29px 25px",
    display: "block",
  },
  divider: {
    backgroundColor: "#e6e6e6",
  },
};

export default serviceWorldCaseItemStyle;
