/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Theme } from "@mui/material/styles";

const doubleButtonStyle = {
  buttonActions: (theme: Theme) => ({
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  }),
  buttonContainer: (theme: Theme) => ({
    paddingTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
  backButton: {
    color: "primary.main",
    paddingRight: "5px",
  },
};

export default doubleButtonStyle;
