import { Conversation } from "../types/Entities/Messaging/Conversation";
import { apiPost } from "./apiClient";
import { Message } from "../types/Entities/Messaging/Message";

export const caseHasUnreadMessage = (caseId: number, conversations: Array<Conversation>) => {
  const caseConversations = conversations.filter((conversation) => conversation.channel.caseId === caseId);
  return !!caseConversations.find((conversation) => conversationHasUnreadMessage(conversation));
};

export const conversationHasUnreadMessage = (conversation: Conversation) => {
  return !!conversation.containedMessages.find((message) => !message.readByMe);
};

export const markMessagesAsRead = async (messages: Array<Message>) => {
  let promises: Array<Promise<any>> = [];
  messages.forEach((message) => {
    if (!message.readByMe) {
      promises.push(
        apiPost("/message_user_read_statuses", {
          message: "/messages/" + message.id,
        })
      );
    }
  });
  await Promise.all(promises);
};

export const getNewMessageLink = (productName: string, productId: string | number, conversationId: number | null = null) => {
  const baseLink = `/servicewelt/nachrichten/neu?product=${productName}&productId=${productId}`;
  return conversationId ? `${baseLink}&conversationId=${conversationId}` : baseLink;
};

export const getMessageStep = () => {
  return 0;
};

export const getFinishedStep = (steps: number) => {
  return steps - 1;
};

export const getMainAttachmentStep = () => {
  return 1;
};
