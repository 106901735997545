import {
  STATUS_CHECKED,
  STATUS_COMPLETED,
  STATUS_CREATED,
  STATUS_DATA_COMPLETED,
} from "./leadStatus";
import moment from "moment";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React from "react";
import { getCaseLink, getProductData } from "../productService";
import { STATUS_IN_PROGRESS } from "../contractLaw/leadStatus";
import _get from "lodash/get";

const getContent = (familyLawCase) => {

  const appointmentStatus = _get(familyLawCase, "advisoryAppointment.status");
  const appointmentDate =
    _get(familyLawCase, "advisoryAppointment.appointment.date") &&
    moment(_get(familyLawCase, "advisoryAppointment.appointment.date"));

  switch (familyLawCase.leadStatus) {
    default:
    case STATUS_CREATED:
      return {
        header: "Ihr Anliegen",
        text: "Beschreiben Sie hier kurz Ihr Anliegen. Danach können Sie direkt einen Termin für Ihr Erstgespräch buchen.",
        buttonTarget: getCaseLink(familyLawCase) + "/antrag",
        buttonLabel: "Fall schildern",
      };
    case STATUS_DATA_COMPLETED:
    case STATUS_CHECKED:
      return {
        header: "Jetzt Termin vereinbaren",
        text: "Vielen Dank für die Schilderung Ihres Falls. Sie können jetzt einen Termin für eine Erstberatung vereinbaren",
        buttonTarget: getCaseLink(familyLawCase) + familyLawCase.calendlyLink,
        buttonLabel: "Termin vereinbaren",
      };
    case STATUS_IN_PROGRESS:
      if (appointmentStatus === "not_processed") {
        return {
          header: "Ihr Termin konnte nicht stattfinden",
          text:
            `Sie hatten einen Termin für ein Erstgespräch am ${
              appointmentDate && appointmentDate.format("DD.MM.YYYY")
            } gebucht. Der Termin konnte nicht stattfinden. Buchen Sie sich gerne einen neuen Termin.`,
          buttonTarget: getCaseLink(familyLawCase) + familyLawCase.calendlyLink,
          buttonLabel: "Neuen Termin buchen",
        };
      }

      return {
        header: "Termin erfolgreich gebucht",
        text:
          `Sie haben einen Termin für ein Erstgespräch am ${
            appointmentDate && appointmentDate.format("DD.MM.YYYY")
          } gebucht.` +
          ` Ihr Rechtsanwalt wird Sie um ${
            appointmentDate && appointmentDate.format("HH:mm")
          } Uhr anrufen.`,
      };
    case STATUS_COMPLETED:
      return {
        header: "Ihr Fall ist abgeschlossen",
        text: "Vielen Dank für Ihr Vertrauen und dafür, dass wir Sie unterstützen durften! Sie sind der Meinung, dass ihr Fall nicht abgeschlossen ist oder es sind im Nachgang doch noch Probleme aufgetreten? Dann schreiben Sie uns gerne jederzeit eine Nachricht und wir werden selbstverständlich umgehend für Sie tätig!",
      };
  }
};

const getButton = (familyLawCase) => {
  const content = getContent(familyLawCase);

  if (!content.buttonLabel) {
    return null;
  }
  return (
    <Button
      component={Link}
      to={
        content.linkType === "external"
          ? { pathname: content.buttonTarget }
          : content.buttonTarget
      }
      target={content.linkType === "external" ? "_blank" : null}
      variant="contained"
      color="primary"
      fullWidth
    >
      {content.buttonLabel}
    </Button>
  );
};

const getPercentage = (familyLawCase) => {
  switch (familyLawCase.leadStatus) {
    case STATUS_CREATED:
      return 50;
    case STATUS_DATA_COMPLETED:
    case STATUS_CHECKED:
      return 66;
    case STATUS_IN_PROGRESS:
      return 75;
    case STATUS_COMPLETED:
      return 100;
    default:
      return 0;
  }
};

export const getStatusBoxConfigFamilyLawCase = (familyLawCase) => {
  let statusBoxConfig = {
    header: "Es ist ein Fehler aufgetreten",
    text: "",
    ctaButton: null,
    productName: "",
    percentage: 100,
  };

  const ctaButton = getButton(familyLawCase);
  const content = getContent(familyLawCase);

  statusBoxConfig.header = content.header;
  statusBoxConfig.text = content.text;
  statusBoxConfig.ctaButton = ctaButton;
  statusBoxConfig.productName = getProductData(familyLawCase.name, "label");
  statusBoxConfig.percentage = getPercentage(familyLawCase);

  return statusBoxConfig;
};
