/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { getStatusBoxConfigContractLawCase } from "../statusBoxSteps";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React from "react";
import { getCaseLink } from "../../productService";
import { STATUS_CREATED } from "../leadStatus";

const getButton = (contractLawCase) => {
  return (
    <Button component={Link} to={getCaseLink(contractLawCase) + "/antrag"} variant="contained" color="primary" fullWidth>
      Weiteres Vorgehen festlegen
    </Button>
  );
};

export const getStatusBoxConfigAdvocardFlexServiceCase = (contractLawCase) => {
  let boxConfig = getStatusBoxConfigContractLawCase(contractLawCase);

  if (contractLawCase.caseCategory === "advisory" && contractLawCase.leadStatus === STATUS_CREATED) {
    return {
      ...boxConfig,
      header: "Jetzt Ihren Fall schildern",
      text: "Beschreiben Sie hier kurz in Anliegen. Danach können Sie direkt einen Termin für Ihr Erstgespräch buchen.",
      ctaButton: getButton(contractLawCase),
    };
  }

  return boxConfig;
};
