// Google Tag Manager Configuration depending on Environment (developmemnt, test, production)

export const googleAnalyticsConfig = () => {
  let universalAnalyticsArgs = {
    trackingId: "G-FH8Z26PM92",
  };

  switch (process.env.REACT_APP_DEPLOYMENT) {
    case "production":
      // production environment
      universalAnalyticsArgs.trackingId = "G-PVLEN2P623";
      break;
    case "development":
      // dev environment
      universalAnalyticsArgs.trackingId = "G-FH8Z26PM92";
      break;
    case "testing":
      // staging environment
      universalAnalyticsArgs.trackingId = "G-FH8Z26PM92";
      break;
    default:
      // dev environment
      universalAnalyticsArgs.trackingId = "G-FH8Z26PM92";
  }
  return universalAnalyticsArgs;
};
