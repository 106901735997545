/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { maxNumber } from "../validationRules";

export const divorceFormDefinitions = {
  netIncomePetitioner: netIncomePetitioner,
  netIncomePartner: netIncomePartner,
};

function netIncomePetitioner() {
  return {
    validators: [
      {
        validator: maxNumber,
        params: [50000],
        message: "Bitte überprüfen Sie Ihre Angabe",
      },
    ],
  };
}

function netIncomePartner() {
  return {
    validators: [
      {
        validator: maxNumber,
        params: [50000],
        message: "Bitte überprüfen Sie Ihre Angabe",
      },
    ],
  };
}
