import React from "react";
import SummaryDetailsItem from "../../../Common/SummaryDetailsItem/SummaryDetailsItem";
import SummaryDetailsProps from "../../../../../types/SummaryDetailsProps";

const SummaryDetailsPurchase = ({caseData: product, formUrl}: SummaryDetailsProps) => {
  return (
    <>
      <SummaryDetailsItem fieldPath={"buyerOrSeller"} formUrl={formUrl} caseData={product} />
      <SummaryDetailsItem fieldPath={"channel"} formUrl={formUrl} caseData={product} />
      {product.buyerOrSeller === "buyer" && product.channel !== "fleaMarket" && product.channel !== "offline" && (
        <SummaryDetailsItem fieldPath={"contractNumber"} formUrl={formUrl} caseData={product} />
      )}
      <SummaryDetailsItem fieldPath={"itemTitle"} formUrl={formUrl} caseData={product} />
      {product.buyerOrSeller === "buyer" && product.articleNumber && <SummaryDetailsItem fieldPath={"articleNumber"} formUrl={formUrl} caseData={product} />}
      <SummaryDetailsItem fieldPath={"purchasePrice"} formUrl={formUrl} caseData={product} />
      <SummaryDetailsItem fieldPath={"buyerPaidStatus"} formUrl={formUrl} caseData={product} />

      <SummaryDetailsItem fieldPath={"goodsOrServiceProvidedStatus"} formUrl={formUrl} caseData={product} />

      {product.goodsOrServiceProvidedStatus !== "none" && (
        <>
          <SummaryDetailsItem fieldPath={"hasDefects"} formUrl={formUrl} caseData={product} />
          <SummaryDetailsItem fieldPath={"locationOfGood"} formUrl={formUrl} caseData={product} />
        </>
      )}

      {product.hasDefects === "yes" && (
        <>
          <SummaryDetailsItem fieldPath={"defectsDescriptionClient"} formUrl={formUrl} caseData={product} />
          <SummaryDetailsItem fieldPath={"numberRepairAttempts"} formUrl={formUrl} caseData={product} />
          <SummaryDetailsItem fieldPath={"numberReplacementAttempts"} formUrl={formUrl} caseData={product} />
        </>
      )}
    </>
  );
};

export default SummaryDetailsPurchase;
