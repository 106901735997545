import { Theme } from "@mui/material";

export const coloredLogoBoxStyles = {
  coloredBox: {
    position: "relative",
    width: "100%",
    padding: "1rem",
    boxSizing: "border-box",
    height: "100%",
  },
  logoBox: {
    height: "4rem",
    width: "4rem",
    position: "absolute",
    top: "-2rem",
  },
  text: (theme: Theme) => ({
    color: theme.palette.mode === "dark" ? "#000" : "inherit",
  }),
};
