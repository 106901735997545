import React from "react";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import FormSubmit from "../FormSubmit/FormSubmit";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { requiredValidator } from "../../../../services/validationRules";
import Typography from "@mui/material/Typography";
import FormLegend from "../FormLegend/FormLegend";
import FormSubmitProps from "../../../../types/FormSubmitProps";
import wizardStyle from "../../wizardStyle";
import { Box } from "@mui/material";

type PersonalTextMessageProps = {
  submitProps: FormSubmitProps;
};

export default function PersonalTextMessage({ submitProps }: PersonalTextMessageProps) {
  const { handleSubmit } = useForm();
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Mein Anliegen
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
              <FormLegend>Bitte schildern Sie hier kurz Ihr Anliegen, damit wir Sie bestmöglich beraten können.</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                name="personalTextMessage"
                label="Ihr Anliegen"
                placeholder="Schildern Sie hier kurz Ihr Anliegen"
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={10}
                validators={[requiredValidator("Bitte schildern Sie uns hier kurz Ihr Anliegen.")]}
              />
            </Grid>
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
