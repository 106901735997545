import React from "react";
import * as PropTypes from "prop-types";
import GradientPaper from "../GradientComponents/GradientPaper";
import _map from "lodash/map";
import Teaser from "./Teaser";
import Container from "../Container/Container";
import zipperTeaserStyle from "./zipperTeaserStyle";

export default function ZipperTeaser({ teaserRows }) {
  return (
    <Container>
      <GradientPaper sx={zipperTeaserStyle.paper}>
        {_map(teaserRows, (teaserRow, index) => (
          <Teaser
            key={index}
            subText={teaserRow.subText}
            headline={teaserRow.headline}
            imageLeftHandSide={index % 2 === 0}
            image={teaserRow.image}
            imageAlt={teaserRow.headline}
            variant={"compact"}
          />
        ))}
      </GradientPaper>
    </Container>
  );
}

ZipperTeaser.propTypes = {
  teaserRows: PropTypes.array.isRequired,
};
