import _isObjectLike from "lodash/isObjectLike";

export const getCaseReferenceNumberRegEx = (insurer) => {
  if (_isObjectLike(insurer)) {
    insurer = "/insurances/" + insurer.id;
  }
  switch (insurer) {
    case "/insurances/2": /* Advocard */
      return "^\\d{2}-?(33-?0001-?|\\d)\\d{5}-?\\d$";
    case "/insurances/6": /* ARAG */
      return "^3100\\d{10}";
    default:
      return "^[a-zA-Z0-9-]{4,30}";
  }
};

export const getInsuranceNumberRegEx = (insurer) => {
  if (_isObjectLike(insurer)) {
    insurer = "/insurances/" + insurer.id;
  }
  switch (insurer) {
    case "/insurances/2": /* Advocard */
      return "^(\\d{2}\\.?)?(\\d\\.?\\d{3}\\.?\\d{3}\\.?\\d)(\\-?\\d{2})?$";
    default:
      return ".*";
  }
}