/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import abstractCaseTranslations from "./abstractCaseTranslations";
import personTranslations from "./personTranslations";

const settlementTranslations = {
  ...abstractCaseTranslations,
  label: "Kündigung",
  contractTerminated: {
    label: "Haben Sie Ihre Kündigung bereits erhalten?",
    options: {
      yes: { label: "Ja" },
      no: { label: "Nein" },
      true: { label: "Ja" },
      false: { label: "Nein" },
    },
  },
  contractTerminationReceivedDate: {
    label: "Kündigung erhalten am",
    question: "Wann haben Sie die Kündigung erhalten?",
  },
  terminationNoticeType: {
    label: "Fristlos oder fristgerecht?",
    question: "Können Sie der Kündigung entnehmen, ob Sie fristlos oder fristgerecht gekündigt wurden?",
    options: {
      withoutNotice: { label: "Fristlose Kündigung" },
      notice: { label: "Fristgerechte Kündigung" },
      unsure: { label: "Bin mir nicht sicher" },
    },
  },
  preferredOutcome: {
    question: "Was möchten Sie mit dem Vorgehen gegen die Kündigung erreichen?",
    label: "Ziel der Klage?",
    options: {
      settlement: { label: "Abfindung" },
      employment: { label: "Rückkehr auf den Arbeitsplatz" },
    },
  },
  clientBankData: {
    iban: {
      question: "Wie lautet Ihre Kontoverbindung, auf die die erwirkte Abfindung überwiesen werden soll?",
      label: "Ihre IBAN",
    },
  },
  terminationObstacles: {
    label: "Genießen Sie besonderen Kündigungsschutz?",
    options: {
      parent: { label: "Ich bin in Elternzeit" },
      pregnant: { label: "Ich bin schwanger bzw. in Mutterschutz" },
      disabled: { label: "Ich bin (zu mindestens 50%) schwerbehindert" },
      council: { label: "Ich bin Betriebsratmitglied" },
      privacyOfficer: { label: "Ich bin Datenschutzbeauftragter" },
      apprentice: { label: "Ich bin Auszubildender" },
    },
  },
  employedSinceMonth: {
    label: "Monat",
    options: {
      "01": { label: "Januar" },
      "02": { label: "Februar" },
      "03": { label: "März" },
      "04": { label: "April" },
      "05": { label: "Mai" },
      "06": { label: "Juni" },
      "07": { label: "Juli" },
      "08": { label: "August" },
      "09": { label: "September" },
      "10": { label: "Oktober" },
      "11": { label: "November" },
      "12": { label: "Dezember" },
    },
  },
  employedSinceYear: {
    label: "Jahr",
  },
  employedSince: { label: "Seit wann arbeiten Sie im Unternehmen?" },
  grossSalary: {
    label: "Mtl. Bruttogehalt",
    question: "Wie hoch ist Ihr monatliches Bruttogehalt?",
  },
  jobDescription: {
    label: "Berufsbezeichnung / Tätigkeit",
    question: "Wie lautet Ihre Berufsbezeichnung laut Vertrag?",
  },
  numberOfEmployees: {
    label: "Anzahl Mitarbeiter",
    question: "Wie viele Mitarbeiter sind in Ihrem Unternehmen beschäftigt?",
    options: {
      "10": { label: "0 - 10" },
      "50": { label: "11 - 50" },
      "500": { label: "51 - 500" },
      "1000": { label: "Mehr als 500" },
    },
  },
  worksCouncil: {
    question: "Gibt es in Ihrem Unternehmen einen Betriebsrat?",
    label: "Betriebsrat vorhanden",
    options: {
      yes: { label: "Ja" },
      no: { label: "Nein" },
      true: { label: "Ja" },
      false: { label: "Nein" },
    },
  },
  maritalStatus: {
    label: "Familienstand",
    question: "Wie ist Ihre familiäre Situation?",
    options: {
      unmarried: { label: "ledig" },
      married: { label: "verheiratet" },
      divorced: { label: "geschieden" },
    },
  },
  partnerIsWorking: {
    label: "Ehepartner berufstätig?",
    options: {
      yes: { label: "Ja" },
      no: { label: "Nein" },
      true: { label: "Ja" },
      false: { label: "Nein" },
    },
  },
  numberChildren: {
    label: "Unterhaltsberechtigte Kinder",
    options: {
      0: { label: "Keine unterhaltsberechtigten Kinder" },
      1: { label: "1 Kind" },
      2: { label: "2 Kinder" },
      3: { label: "3 Kinder" },
      4: { label: "4 Kinder" },
      5: { label: "5 Kinder" },
      6: { label: "6 Kinder" },
    },
  },
  petitioner: personTranslations,
  employerOrganization: {
    name: {
      label: "Name Arbeitgeber / Firmenname",
    },
    telephone: {
      label: "Telefonnummer Personalverantwortlicher",
    },
    email: {
      label: "E-Mail Personalverantwortlicher",
    },
    address: {
      streetAddress: {
        label: "Arbeitgeber Straße",
      },
      postalCode: {
        label: "Arbeitgeber PLZ",
      },
      addressLocality: {
        label: "Arbeitgeber Ort",
      },
      postOfficeBoxNumber: {
        label: "Arbeitgeber Postfach",
      },
      postOfficeBoxPostalCode: {
        label: "Arbeitgeber Postfach PLZ",
      },
      postOfficeBoxAddressLocality: {
        label: "Arbeitgeber Postfach Ort",
      },
    },
  },
};

export default settlementTranslations;
