import React, { ChangeEvent, useState } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import _get from "lodash/get";
import { InfoOutlined } from "@mui/icons-material";
import { isAllowMultiplePagesFileType } from "../../../services/mediaObjects";
import { MediaObjectCombinationRequest } from "../../../types/Entities/MediaObject/MediaObjectCombinationRequest";
import FileUploadSection from "../../Form/Common/FileUploadSection/FileUploadSection";

type UploadBoxFormStepProps = {
  product: AbstractCase;
  currentFileIndex?: number;
  description: string;
  setDescription: Function;
  updateAction: (id: number, data: Partial<MediaObjectCombinationRequest>) => Promise<void>;
  mediaObjectCombinationRequest: MediaObjectCombinationRequest;
};

//mostly duplicate code. only ok because the whole file upload handling needs to be reworked
export default function UploadBox({
  product,
  description,
  setDescription,
  mediaObjectCombinationRequest,
  updateAction,
  currentFileIndex = 0,
}: UploadBoxFormStepProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleNoteChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value.length > 50) {
      return;
    }
    setDescription(target.value);
  };

  const firstMediaObject = _get(mediaObjectCombinationRequest.filesToCombine, [currentFileIndex, "files", 0]);

  const descriptionTooShort = description.length < 5;

  return (
    <Stack spacing={2} sx={{ maxWidth: 650, margin: "0 auto" }}>
      <Typography variant={"h5"}>{"Datei " + (currentFileIndex + 1) + ":"}</Typography>
      <Typography>1. Wählen Sie hier die Datei aus, die an dem Fall hochgeladen werden soll</Typography>
      <FileUploadSection
        description={description}
        productEntity={product}
        temporaryMediaObjectsFieldname={"temporaryFilesToCombine"}
        setIsBlocked={setIsLoading}
        isBlocked={isLoading}
        fileIndex={currentFileIndex}
        product={product.name}
        mediaObjectCombinationRequest={mediaObjectCombinationRequest}
        productEntityId={product.id}
        updateAction={updateAction}
        noSection={true}
        fieldName={"uploadedDocument" + currentFileIndex}
        uploadFileLabel={"Datei auswählen"}
        addPageLabel={"Bild als neue Seite hinzufügen"}
      />
      {/*<MessagesAttachmentUploadSection
        description={description}
        productEntity={product}
        productEntityId={product.id}
        product={product.name}
        updateAction={updateAction}
        setIsBlocked={setIsLoading}
        isBlocked={isLoading}
        fieldName={"uploadedDocument" + currentFileIndex}
        fileIndex={currentFileIndex}
        noSection={true}
        uploadFileLabel={"Datei auswählen"}
        addPageLabel={"Bild als neue Seite hinzufügen"}
        withUploadNotification={false}
        messagePath={messagePath}
      />*/}
      {firstMediaObject && isAllowMultiplePagesFileType(firstMediaObject.originalName) && (
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <InfoOutlined />
          <Typography variant={"body2"}>
            Wenn Sie mehrere Bilder zu einem Dokument zusammenfügen möchten, können Sie hierfür einfach den Button "Bild als neue Seite hinzufügen" drücken.
            Wenn Sie weitere separate Dokumente hochladen möchten, klicken Sie bitte auf “Weiter”.
          </Typography>
        </Stack>
      )}
      <Typography>2. Bitte geben Sie hier für die ausgewählte Datei eine kurze Beschreibung/einen Namen an</Typography>
      <TextField
        value={description}
        onChange={handleNoteChange}
        disabled={isLoading}
        label={"Dokumentenname / Kurzbeschreibung"}
        error={descriptionTooShort}
        helperText={"[" + description.length + "/50]" + (descriptionTooShort ? " Bitte geben Sie mindestens 5 Buchstaben ein" : "")}
        focused
      />
    </Stack>
  );
}
