import React, { ChangeEvent, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { Delete } from "@mui/icons-material";
import * as messagesActions from "../../../../store/messages/actions";
import { fileCheck } from "../../../../services/formServiceFunctions";

type LoginIndependantFileUploadProps = {
  fieldname: string;
  headline?: string;
  helperText?: string;
  files: File[];
  setFiles: (files: File[]) => void;
};

export default function LoginIndependentFileUpload({ fieldname, headline, helperText, files, setFiles }: LoginIndependantFileUploadProps) {
  const [clearInput, setClearInput] = useState(0);

  const handleFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileCheckRes = fileCheck(event.target);
    if (fileCheckRes.hasError) {
      messagesActions.addMessage({ type: "error", text: fileCheckRes.errorMsg });
      return;
    }
    if (!event.target.files) {
      return;
    }

    setFiles(Array.from(event.target.files));
  };
  return (
    <React.Fragment key={clearInput}>
      {headline && <Typography variant={"h5"}>{headline}</Typography>}
      {helperText && <p>{helperText}</p>}
      <label htmlFor={"button-file-" + fieldname}>
        <Box sx={{ display: "none" }}>
          <input accept="image/*, application/pdf" id={"button-file-" + fieldname} type="file" onChange={handleFilesChange} />
        </Box>
        <Stack direction="row" justifyContent={"flex-end"}>
          {files.length < 1 && <Button component={"span"}>Datei Auswählen</Button>}
        </Stack>
      </label>
      {files.length > 0 && (
        <Stack direction="row" justifyContent={"flex-end"} alignItems={"center"} spacing={2}>
          <Box sx={{ wordBreak: "break-all" }}>{files[0].name}</Box>
          <IconButton
            onClick={() => {
              setFiles([]);
              setClearInput(clearInput + 1);
            }}
          >
            <Delete />
          </IconButton>
        </Stack>
      )}
    </React.Fragment>
  );
}
