import React, { useState } from "react";
import navigationBarStyle from "./navigationBarStyle";
import Toolbar from "@mui/material/Toolbar";
import withScroll from "../../../services/withScroll";
import { AppBar, Button, Grid, Menu, MenuItem, Stack } from "@mui/material";
import Logo from "../Logo/Logo";
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@mui/icons-material";
import LoginIcon from "../../LoginIcon/LoginIcon";
import LoggedInMenu from "../../LoginIcon/LoggedInMenu";
import _map from "lodash/map";
import { navigationLinks } from "../../../services/layoutService";
import { Link } from "react-router-dom";
import { useCustomer } from "../../../provider/Customer/CustomerProvider";
import MessageNotifications from "./MessageNotifications";

const NavigationBar = ({ scrollPosition, minimal, clickableLogo, hideMainNavigation = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState("");
  const scrolledStyle = scrollPosition > 1;
  const { isLoggedIn } = useCustomer();

  const handleOpenMenu = (event, name) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(name);
  };

  const handleClose = () => {
    setOpenMenu("");
    setAnchorEl(null);
  };
  return (
    <AppBar position={"fixed"} color={scrolledStyle ? "inherit" : "transparent"} elevation={scrolledStyle ? 4 : 0}>
      <Toolbar sx={navigationBarStyle.toolBar}>
        <Grid container alignItems={"center"} sx={navigationBarStyle.navigationContainer} columnSpacing={5}>
          <Grid item>
            <Logo target={isLoggedIn ? "/servicewelt" : "/"} isClickable={clickableLogo}/>
          </Grid>
          {!minimal && (
            <>
              <Grid item>
                {!hideMainNavigation && (
                  <>
                    <Button
                      color="buttonText"
                      endIcon={openMenu === "areas" ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
                      onClick={(event) => handleOpenMenu(event, "areas")}
                      sx={navigationBarStyle.navMenuButton}
                    >
                      Rechtsbereiche
                    </Button>
                    <Button
                      color="buttonText"
                      endIcon={openMenu === "guidebooks" ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
                      onClick={(event) => handleOpenMenu(event, "guidebooks")}
                      sx={navigationBarStyle.navMenuButton}
                    >
                      Ratgeber
                    </Button>
                    <Button
                      color="buttonText"
                      endIcon={openMenu === "aboutUs" ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
                      onClick={(event) => handleOpenMenu(event, "aboutUs")}
                      sx={navigationBarStyle.navMenuButton}
                    >
                      Über Legalbird
                    </Button>
                  </>
                )}
                <Menu
                  open={openMenu === "areas"}
                  onClose={handleClose}
                  anchorEl={anchorEl}
                  PaperProps={{
                    style: { minWidth: 150 },
                  }}
                >
                  {_map(navigationLinks.areas, (link, index) => (
                    <MenuItem component={Link} to={link.to} key={index}>
                      {link.label}
                    </MenuItem>
                  ))}
                </Menu>
                <Menu
                  open={openMenu === "guidebooks"}
                  onClose={handleClose}
                  anchorEl={anchorEl}
                  PaperProps={{
                    style: { minWidth: 150 },
                  }}
                >
                  {_map(navigationLinks.guidebooks, (link, index) => (
                    <MenuItem component={Link} to={link.to} key={index}>
                      {link.label}
                    </MenuItem>
                  ))}
                </Menu>
                <Menu
                  open={openMenu === "aboutUs"}
                  onClose={handleClose}
                  anchorEl={anchorEl}
                  PaperProps={{
                    style: { minWidth: 150 },
                  }}
                >
                  {_map(navigationLinks.aboutUs, (link, index) => (
                    <MenuItem component={Link} to={link.to} key={index}>
                      {link.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
              <Grid item flexGrow={1}>
                <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={2}>
                  {isLoggedIn ? <MessageNotifications /> : null}
                  {isLoggedIn ? <LoggedInMenu /> : <LoginIcon />}
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

NavigationBar.propTypes = {};

NavigationBar.defaultProps = {};

export default withScroll(NavigationBar);
