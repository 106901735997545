import React from "react";
import { Box, Grid, Hidden, MenuItem } from "@mui/material";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import { translate } from "../../../../services/translations/translations";
import { dateFieldDefault, requiredValidator } from "../../../../services/validationRules";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";
import ValidatorDateField from "../../../FormFields/ValidatorDateField";
import { ContractDetailsProps } from "../../../../types/ContractDetailsProps";
import wizardStyle from "../../wizardStyle";

export default function EventContractDetails({ submitProps }: ContractDetailsProps) {
  const { values, handleSubmit } = useForm();
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Details zu Ihrem Vertrag</strong>
              </FormLegend>
            </Grid>

            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>{translate(`${values.name}.reasonForDispute.question`, values)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorSelectField
                label={translate(`${values.name}.reasonForDispute.label`, values)}
                name="reasonForDispute"
                validators={[requiredValidator("Bitte geben Sie das Problem an.")]}
              >
                <MenuItem value="cancelled">{translate(`${values.name}.reasonForDispute.options.cancelled.label`, values)}</MenuItem>
                <MenuItem value="cancellationRefused">{translate(`${values.name}.reasonForDispute.options.cancellationRefused.label`, values)}</MenuItem>
                <MenuItem value="other">{translate(`${values.name}.reasonForDispute.options.other.label`, values)}</MenuItem>
              </ValidatorSelectField>
            </Grid>

            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>{translate(`${values.name}.eventType.question`, values)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorSelectField
                label={translate(`${values.name}.eventType.label`, values)}
                name="eventType"
                helperText={translate(`${values.name}.eventType.helperText`, values)}
              >
                <MenuItem value="private">{translate(`${values.name}.eventType.options.private.label`, values)}</MenuItem>
                <MenuItem value="public">{translate(`${values.name}.eventType.options.public.label`, values)}</MenuItem>
              </ValidatorSelectField>
            </Grid>

            {values.eventType === "public" && (
              <>
                <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                  <FormLegend>{translate(`${values.name}.contractNumber.question`, values)}</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    label={translate(`${values.name}.contractNumber.label`, values)}
                    name="contractNumber"
                    helperText={translate(`${values.name}.contractNumber.helperText`, values)}
                    validators={[requiredValidator("Bitte geben Sie die Buchungs- oder Ticketnummer an")]}
                  />
                </Grid>

                <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
                  <FormLegend>{translate(`${values.name}.numberOfTickets.question`, values)}</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorSelectField label={translate(`${values.name}.numberOfTickets.label`, values)} name="numberOfTickets">
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                  </ValidatorSelectField>
                </Grid>
              </>
            )}

            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>{translate(`${values.name}.eventTitle.question`, values)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                name="eventTitle"
                label={translate(`${values.name}.eventTitle.label`, values)}
                helperText={translate(`${values.name}.eventTitle.helperText`, values)}
                validators={[requiredValidator("Bitte nennen Sie uns den Namen der Veranstaltung.")]}
              />
            </Grid>

            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>{translate(`${values.name}.eventCity.question`, values)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                name="eventCity"
                label={translate(`${values.name}.eventCity.label`, values)}
                validators={[requiredValidator("Bitte nennen Sie uns die Stadt in der das Events stattfinden soll.")]}
              />
            </Grid>

            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>{translate(`${values.name}.eventDate.question`, values)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorDateField
                label={translate(`${values.name}.eventDate.label`, values)}
                name="eventDate"
                validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault]}
              />
            </Grid>
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
