import React from "react";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import CaseDetailsItem from "../../../Common/CaseDetailsItem/CaseDetailsItem";

const SubscriptionSpecificCaseDetails = ({ contractLawCase }: any) => {
  return (
    <Grid container>
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"wasDeceived"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"salesChannel"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"subscriptionName"} />
    </Grid>
  );
};

SubscriptionSpecificCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

export default SubscriptionSpecificCaseDetails;
