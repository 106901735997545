import _replace from "lodash/replace";
import _sortBy from "lodash/sortBy";

const insurances = [
  {
    name: "Adam Riese GmbH Rechtsschutz",
    label: "Adam Riese",
    id: 1,
  },
  {
    name: "ADVOCARD Rechtsschutzversicherung AG",
    label: "ADVOCARD",
    id: 2,
  },
  {
    name: "Aeguron - CHECK24 Vergleichsportal für Sachversicherungen GmbH",
    label: "Aeguron",
    id: 3,
  },
  {
    name: "ALLRECHT Rechtsschutzversicherungen",
    label: "ALLRECHT",
    id: 4,
  },
  {
    name: "ADAC Versicherung AG",
    label: "ADAC",
    id: 5,
  },
  {
    name: "ARAG Allgemeine Versicherungs-AG",
    label: "ARAG",
    id: 6,
  },
  {
    name: "Badische Rechtsschutzversicherung AG",
    label: "Badische Rechtsschutzversicherung",
    id: 7,
  },
  {
    name: "BavariaDirekt eine Marke der OVAG - Ostdeutsche Versicherung Aktiengesellschaft",
    label: "BavariaDirekt",
    id: 8,
  },
  {
    name: "ConceptIF",
    label: "ConceptIF",
    id: 9,
  },
  {
    name: "DEURAG Deutsche Rechtsschutz-Versicherung AG",
    label: "DEURAG",
    id: 10,
  },
  {
    name: "DEVK Rechtsschutz-Versicherungs-AG",
    label: "DEVK",
    id: 11,
  },
  {
    name: "DMB Rechtsschutz-Versicherung AG",
    label: "DMB",
    id: 12,
  },
  {
    name: "ERGO Versicherung AG",
    label: "ERGO",
    id: 13,
  },
  {
    name: "IDEAL Versicherung AG",
    label: "IDEAL",
    id: 14,
  },
  {
    name: "Medien-Versicherung a.G.",
    label: "Medien-Versicherung",
    id: 15,
  },
  {
    name: "R+V Versicherung AG",
    label: "R+V Versicherung",
    id: 16,
  },
  {
    name: "ROLAND Rechtsschutz-Versicherungs-AG",
    label: "ROLAND Rechtsschutz",
    id: 17,
  },
  {
    name: "DA Deutsche Allgemeine Versicherung Aktiengesellschaft",
    label: "DA Deutsche Allgemeine Versicherung",
    id: 18,
  },
  {
    name: "Debeka Allgemeine Versicherung AG",
    label: "Debeka",
    id: 19,
  },
  {
    name: "WGV Rechtsschutz-Schadenservice GmbH",
    label: "WGV",
    id: 20,
  },
  {
    name: "VGH Versicherungen",
    label: "VGH",
    id: 21,
  },
  {
    name: "Continentale Sachversicherung AG",
    label: "Continentale",
    id: 22,
  },
  {
    name: "HDI Versicherung AG",
    label: "HDI",
    id: 23,
  },
  {
    name: "AUXILIA Rechtsschutz-Versicherungs-AG",
    label: "AUXILIA",
    id: 24,
  },
  {
    name: "Concordia Versicherungs-Gesellschaft a.G.",
    label: "Concordia",
    id: 25,
  },
  {
    name: "D.A.S. Rechtsschutz Leistungs-GmbH",
    label: "D.A.S. Rechtsschutz",
    id: 26,
  },
  {
    name: "HUK-COBURG-Rechtsschutzversicherung",
    label: "HUK-COBURG",
    id: 27,
  },
  {
    name: "Jurpartner Services, Gesellschaft für Rechtsschutz-Schadenregulierung mbH",
    label: "Jurpartner Services",
    id: 28,
  },
  {
    name: "LVM Verischerung",
    label: "LVM",
    id: 29,
  },
  {
    name: "Neue Rechtsschutz Versicherung AG",
    label: "Neue Rechtsschutz Versicherung",
    id: 30,
  },
  {
    name: "Örag Rechtsschutzversicherungs AG",
    label: "Örag",
    id: 31,
  },
  {
    name: "Rechtsschutz Union Schaden GmbH",
    label: "Rechtsschutz Union Schaden",
    id: 32,
  },
  {
    name: "RSS Rechtsschutz-Service GmbH",
    label: "RSS Rechtsschutz-Service",
    id: 33,
  },
  {
    name: "Zurich Rechtsschutz-Schadenservice GmbH",
    label: "Zurich Rechtsschutz",
    id: 34,
  },
  {
    name: "SIGNAL IDUNA Allgemeine Versicherung AG",
    label: "SIGNAL IDUNA",
    id: 35,
  },
  {
    name: "Allianz Versicherungs-AG",
    label: "Allianz",
    id: 36,
  },
  {
    name: "Württembergische Rechtsschutzversicherung",
    label: "Württembergische Rechtsschutzversicherung",
    id: 37,
  },
  {
    name: "Verti Versicherung AG",
    label: "Verti",
    id: 40,
  },
  {
    name: "Sparkassen DirektVersicherung AG",
    label: "Sparkassen DirektVersicherung",
    id: 41,
  },
  {
    name: "Mecklenburgische Versicherungsgruppe",
    label: "Mecklenburgische",
    id: 42,
  },
  {
    name: "VRK Sachversicherung AG",
    label: "VRK Versicherung / Bruderhilfe",
    id: 43,
  },
  {
    name: "Landesschadenhilfe Versicherung",
    label: "Landesschadenhilfe",
    id: 44,
  },
  {
    name: "Itzehoer Versicherung",
    label: "Itzehoer",
    id: 45,
  },
  {
    name: "Provinzial",
    label: "Provinzial",
    id: 46,
  },
  {
    name: "Nürnberger Versicherung",
    label: "Nürnberger",
    id: 48,
  },
  {
    name: "GetSafe",
    label: "GetSafe",
    id: 49,
  },
  {
    name: "DFV Deutsche Familienversicherung AG",
    label: "DFV Deutsche Familienversicherung",
    id: 50,
  },
  {
    name: "VPV Allgemeine Versicherungs-AG",
    label: "VPV Allgemeine Versicherungs-AG",
    id: 51,
  },
  {
    name: "DOMCURA AG",
    label: "DOMCURA",
    id: 52,
  },
  {
    name: "AIA AG",
    label: "AIA",
    id: 53,
  },
  {
    name: "Alte Leipziger",
    label: "Alte Leipziger",
    id: 54,
  },
];

export const PARTNER_INSURANCE = {
  ADVOCARD: 2,
  ARAG: 6,
  ROLAND: 17,
};

const sortableLabel = (label) => {
  let newLabel = label.toLowerCase();
  newLabel = _replace(newLabel, "ö", "o");
  newLabel = _replace(newLabel, "ä", "a");
  newLabel = _replace(newLabel, "ü", "u");
  return newLabel;
};

export const sortedInsurances = _sortBy(insurances, (insurance) => {
  return sortableLabel(insurance.label);
});

export default insurances;
