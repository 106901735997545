const privacyStyle = {
  privacyPage: {
    marginTop: "97px",
  },
  list: {
    listStyleType: "none",
  },
  title: {},
};

export default privacyStyle;
