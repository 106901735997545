import React from "react";
import Layout from "../../Layouts/Layout";
import ServiceWorldCase from "../../../components/Serviceworld/ServiceWorldCase";

const ServiceWorldCasePage = () => {
  return (
    <Layout active={"serviceworld"} displayBackgroundImage={true} backgroundType={"serviceworld"}>
      <ServiceWorldCase />
    </Layout>
  );
};

export default ServiceWorldCasePage;
