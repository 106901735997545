import React from "react";
import { Route, Routes } from "react-router-dom";
import GuidebookTrafficLawOverview from "../../containers/Pages/Traffic/GuidebookTrafficOverview/GuidebookTrafficLawOverview";
import GuidebookTrafficMinorOffences from "../../components/GuidebookContent/GuidebookTrafficContent/GuidebookTrafficMinorOffences";
import GuidebookTrafficDisputeAccusation from "../../components/GuidebookContent/GuidebookTrafficContent/GuidebookTrafficDisputeAccusation";

export default function GuidebookTrafficLaw() {
  return (
    <Routes>
      <Route path={`ordnungswidrigkeiten`} element={<GuidebookTrafficMinorOffences />} />
      <Route path={`bussgeldbescheid`} element={<GuidebookTrafficDisputeAccusation />} />
      <Route path={""} element={<GuidebookTrafficLawOverview />} />
    </Routes>
  );
}
