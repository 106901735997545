import personTranslations from "./personTranslations";
import addressTranslations from "./addressTranslations";
import contactTranslations from "./contactTranslations";

const divorceTranslations = {
  label: "Scheidung",
  dateMarriage: {
    label: "Datum standesamtliche Heirat",
  },
  cityMarriage: { label: "Ort standesamtliche Heirat" },
  numberChildren: {
    question: "Haben Sie gemeinsame Kinder?",
    label: "Gemeinsame Kinder unter 18 Jahren",
    options: {
      "0": { label: "keine Kinder" },
      "1": { label: "1 Kind" },
      "2": { label: "2 Kinder" },
      "3": { label: "3 Kinder" },
      "4": { label: "4 Kinder" },
      "5": { label: "5 Kinder" },
      "6": { label: "6 Kinder" },
    },
  },
  statusDivorce: {
    label: "Einvernehmliche Scheidung?",
    question: "Gibt es Streitpunkte oder ist die Scheidung einvernehmlich?",
    options: {
      mutuallyAgreed: { label: "Einvernehmliche Scheidung" },
      debatable: { label: "Offene Streitpunkte" },
      unsure: { label: "Ich bin mir nicht sicher" },
    },
  },
  dateSeparation: { label: "Datum der Trennung" },
  movedOut: {
    label: "Aktuelle Wohnsituation",
    options: {
      petitioner: { label: "Ich bin ausgezogen" },
      partner: { label: "Mein Partner ist ausgezogen" },
      both: { label: "Beide sind ausgezogen" },
      nobody: { label: "Keiner ist ausgezogen" },
    },
  },
  dateMovedOut: { label: "Datum des Auszugs" },
  // Finance
  netIncomePetitioner: {
    label: "Ihr mtl. Nettoeinkommen",
  },
  netIncomePartner: {
    label: "Mtl. Nettoeinkommen Ihres Partners",
    helperText: "Wenn Sie das Einkommen nicht genau kennen, reicht eine ungefähre Angabe aus.",
  },
  // Pensions
  pensionsExcluded: {
    label: "Versorgungsausgleich",
    question: "Möchten Sie den Versorgungsausgleich durchführen?",
    options: {
      no: { label: "Ja, wir möchten den Versorgungsausgleich durchführen" },
      yes: { label: "Nein, wir möchten den Versorgungsausgleich notariell ausschließen lassen" },
      false: { label: "Ja, wir möchten den Versorgungsausgleich durchführen" },
      true: { label: "Nein, wir möchten den Versorgungsausgleich notariell ausschließen lassen" },
    },
  },
  numberPensions: {
    label: "Ihre Rentenversicherungen",
    options: {
      "0": { label: "keine" },
      "1": { label: "1" },
      "2": { label: "2" },
      "3": { label: "3" },
      "4": { label: "4" },
      "5": { label: "5" },
    },
  },
  numberPensionsPartner: {
    label: "Rentenversicherungen Partner",
    options: {
      "0": { label: "keine" },
      "1": { label: "1" },
      "2": { label: "2" },
      "3": { label: "3" },
      "4": { label: "4" },
      "5": { label: "5" },
    },
  },
  pensionsQuestions: {
    label: "Ich habe zum Thema Versorgungsausgleich noch Fragen und möchte diese im Nachgang besprechen",
    options: {
      yes: { label: "Ja, ich habe noch Fragen zum Thema Versorgungsausgleich" },
      no: { label: "Nein, ich habe keine Fragen zum Thema Versorgungsausgleich" },
      true: { label: "Ja, ich habe noch Fragen zum Thema Versorgungsausgleich" },
      false: { label: "Nein, ich habe keine Fragen zum Thema Versorgungsausgleich" },
    },
  },
  // ProcessCostSupport
  hasProcessCostSupport: {
    label: "Verfahrenskostenhilfe",
    question: "Möchten Sie für die Scheidungskosten Verfahrenskostenhilfe beantragen?",
    options: {
      yes: { label: "Ja, ich möchte Verfahrenskostenhilfe beantragen" },
      no: { label: "Nein, ich möchte keine Verfahrenskostenhilfe beantragen" },
      true: { label: "Ja, ich möchte Verfahrenskostenhilfe beantragen" },
      false: { label: "Nein, ich möchte keine Verfahrenskostenhilfe beantragen" },
    },
  },
  // Personal
  client: {
    ...personTranslations,
    nationality: { question: "Welche Staatsangehörigkeit haben Sie?", label: "Staatsangehörigkeit" },
  },
  partner: {
    ...personTranslations,
    nationality: { question: "Welche Staatsangehörigkeit hat Ihr Ehepartner?", label: "Staatsangehörigkeit" },
  },
  commonAddressType: {
    question: "Wie lautet die letzte gemeinsame Adresse von Ihnen und Ihrem Ehepartner?",
    options: {
      client: { label: "Meine Adresse war unsere letzte gemeinsame Adresse" },
      partner: { label: "Die Adresse meines Ehepartners war unsere letzte gemeinsame Adresse" },
      commonAddress: { label: "Wir hatten zuvor eine andere gemeinsame Adresse" },
    },
  },
  commonAddress: addressTranslations,
  customer: contactTranslations,
  personalTextMessage: { label: "Haben Sie offene Fragen?" },
};

export default divorceTranslations;
