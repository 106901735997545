const pictureTeaserCardStyle = {
  image: {
    width: "100%",
    height: "auto",
    borderRadius: "25px 25px 0 0",
  },
  card: {
    width: "280px",
    height: "350px",
    margin: "0 auto",
  },
  text: {
    marginTop: ".3rem",
    textAlign: "center",
  },
};

export default pictureTeaserCardStyle;
