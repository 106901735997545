const revokePageStyle = {
  headline: {
    textAlign: "center",
    color: "primary.main",
    fontWeight: 500,
    marginBottom: "2rem",
  },
  container: {
    textAlign: "center",
    padding: { sx: "3rem 1rem", sm: "3rem" },
  },
  checkboxText: {
    textAlign: "left",
  },
};

export default revokePageStyle;
