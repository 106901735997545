import Grid from "@mui/material/Grid";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import MenuItem from "@mui/material/MenuItem";
import EmailCheck from "../../EmailCheck/EmailCheck";
import ButtonLoading from "../../Button/ButtonLoading";
import ButtonTextTermsOfServicePrivacy from "../Common/ButtonTextTermsOfServicePrivacy/ButtonTextTermsOfServicePrivacy";
import React from "react";
import { useForm } from "../../../provider/Form/FormProvider";
import contractCheckinStyle from "./contractCheckinStyle";
import { emailDefault, requiredValidator } from "../../../services/validationRules";

type ContractCheckinFormProps = {
  emailErrorText: string;
  setEmailErrorText: (emailErrorText: string) => void;
  isLoading: boolean;
};

export default function ContractCheckinForm({ emailErrorText, setEmailErrorText, isLoading }: ContractCheckinFormProps) {
  const { handleSubmit } = useForm();
  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems={"baseline"} justifyContent={"center"}>
        <Grid item xs={12} md={4} sx={contractCheckinStyle.gridSpaceMd}>
          <ValidatorSelectField label="Art des Vertrags" name={`contractType`} validators={[requiredValidator("Bitte geben Sie die Art des Vertrages an.")]}>
            <MenuItem value={"purchaseContract"}>Kaufvertrag</MenuItem>
            <MenuItem value={"vehicleContract"}>Fahrzeugkauf</MenuItem>
            <MenuItem value={"serviceContract"}>Handwerker / Dienstleistung</MenuItem>
            <MenuItem value={"membershipContract"}>Mitgliedschaft</MenuItem>
            <MenuItem value={"subscriptionContract"}>Abo / Abofalle</MenuItem>
            <MenuItem value={"providerContract"}>Handy / DSL / Strom-Vertrag</MenuItem>
            <MenuItem value={"travelContract"}>Reisevertrag</MenuItem>
            <MenuItem value={"eventContract"}>Veranstaltung</MenuItem>
            <MenuItem value={"dataLeakContract"}>Datenweitergabe</MenuItem>
            <MenuItem value={"otherContract"}>Sonstiger Vertrag</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} sm={4} sx={contractCheckinStyle.gridSpace}>
          <EmailCheck
            name="email"
            label="Ihre E-Mail-Adresse"
            autoComplete="email"
            emailErrorText={emailErrorText}
            setEmailErrorText={setEmailErrorText}
            validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={[contractCheckinStyle.submit, contractCheckinStyle.gridTopSpaced]}>
          <ButtonLoading variant="contained" color="primary" sx={contractCheckinStyle.button} type="submit" fullWidth isLoading={isLoading}>
            jetzt starten
          </ButtonLoading>
          <ButtonTextTermsOfServicePrivacy />
        </Grid>
      </Grid>
    </form>
  );
}
