/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import { Checkbox } from "@mui/material";

import React, { useEffect } from "react";
import { useForm } from "../../provider/Form/FormProvider";
import { formValue } from "../../services/formServiceFunctions";
import _get from "lodash/get";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { FormChangeEvent } from "../../types/FormDataTypes";

export type CheckboxGroupOptionProps = {
  label: string;
  value: string;
};

type CheckboxGroupProps = {
  name: string;
  options: Array<CheckboxGroupOptionProps>;
  resetOption?: string | null;
  validators?: Array<any>; // TODO: All validators should implement an interface to prevent any at this point
  dependentValidationFields?: Array<string>;
};

export default function CheckboxGroup({ name, options, resetOption = null, validators = [], dependentValidationFields = [] }: CheckboxGroupProps) {
  const { values, errors, setError, handleChange, registerValidators } = useForm();
  const isValid = !_get(errors, name);
  const value = formValue(values, name);

  useEffect(() => {
    registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators(name, [], []);
      setError(name, undefined);
    };
  }, [name]);

  const handleChangeInternal = (event: FormChangeEvent) => {
    if (resetOption !== null) {
      const checked = event.target.checked;
      const targetName = event.target.name;

      if (targetName === `${name}.${resetOption}` && checked) {
        options.forEach((option) => {
          if (option.value !== resetOption) {
            value[option.value] = false;
          }
        });
      } else if (targetName !== `${name}.${resetOption}` && checked) {
        value[resetOption] = false;
      }
    }
    setError(name, undefined);
    handleChange(event);
  };

  return (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          sx={{ alignItems: "flex-start", paddingBottom: "0.75rem" }}
          componentsProps={{ typography: { sx: { paddingTop: "3px" } } }}
          control={
            <Checkbox
              checked={!!formValue(values, `${name}.${option.value}`)}
              name={`${name}.${option.value}`}
              onChange={handleChangeInternal}
              value={option.value}
              sx={{
                marginTop: -1,
              }}
            />
          }
          label={option.label}
        />
      ))}
      {!isValid && <Typography color="error">{_get(errors, name)}</Typography>}
    </FormGroup>
  );
}
