import React from "react";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import MessageFormStep from "./MessageFormStep";
import MessageCompleted from "./MessageCompleted";
import AttachmentFormStep from "./AttachmentFormStep";
import { MessageCreateData } from "../../../types/Messaging/MessageCreateData";
import { MediaObjectCombinationRequest } from "../../../types/Entities/MediaObject/MediaObjectCombinationRequest";

type StepSwitchProps = {
  currentStep: number;
  setCurrentStep: Function;
  steps: number;
  setSteps: Function;
  setIsLoading: Function;
  product: AbstractCase;
  messageCreateData: MessageCreateData | null;
  setMessageCreateData: Function;
  mediaObjectCombinationRequestUpdateAction: (id: number, data: Partial<MediaObjectCombinationRequest>) => Promise<void>;
  mediaObjectCombinationRequest: MediaObjectCombinationRequest;
};

export default function StepSwitch({
  currentStep,
  product,
  setCurrentStep,
  steps,
  setSteps,
  setIsLoading,
  setMessageCreateData,
  messageCreateData,
  mediaObjectCombinationRequestUpdateAction,
  mediaObjectCombinationRequest,
}: StepSwitchProps) {
  switch (currentStep) {
    case 0:
      return <MessageFormStep setCurrentStep={setCurrentStep} product={product} setMessageCreateData={setMessageCreateData} />;
    case 1:
      return (
        <AttachmentFormStep
          currentStep={1}
          steps={steps}
          setSteps={setSteps}
          product={product}
          messageCreateData={messageCreateData}
          setCurrentStep={setCurrentStep}
          setIsLoading={setIsLoading}
          headline={"Dateien anhängen"}
          subHeadline={"Möchten Sie Ihrer Nachricht noch eine Datei anhängen?"}
          mediaObjectCombinationRequestUpdateAction={mediaObjectCombinationRequestUpdateAction}
          mediaObjectCombinationRequest={mediaObjectCombinationRequest}
        />
      );
    case steps - 1:
      return <MessageCompleted product={product} />;
    case steps:
      return null;
    default:
      return (
        <AttachmentFormStep
          messageCreateData={messageCreateData}
          currentStep={currentStep}
          steps={steps}
          setSteps={setSteps}
          product={product}
          setCurrentStep={setCurrentStep}
          setIsLoading={setIsLoading}
          headline={"Weitere Dateien"}
          subHeadline={"Möchten Sie der Nachricht noch eine weitere Datei anhängen?"}
          mediaObjectCombinationRequestUpdateAction={mediaObjectCombinationRequestUpdateAction}
          mediaObjectCombinationRequest={mediaObjectCombinationRequest}
        />
      );
  }
}
