/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import personTranslations from "./personTranslations";
import contactTranslations from "./contactTranslations";

const customerTranslations = {
  person: {
    ...personTranslations,
  },
  ...contactTranslations,
};

export default customerTranslations;
