import React from "react";
import summaryStyle from "./summaryStyle";
import Grid from "@mui/material/Grid";
import GridEditRow from "../../Common/GridEditRow/GridEditRow";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";

type ContractDescriptionProps = {
  formData: Record<string, any>;
  formPath: string;
  title: string;
};

const ContractDescription = ({ formData, formPath, title }: ContractDescriptionProps) => {
  const section = "contractDescription";

  return (
    <>
      <Grid item xs={12} sx={summaryStyle.subHeadline}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>
      <Grid item xs={12} sx={summaryStyle.question}>
        <Box id={"personalTextMessage"} sx={summaryStyle.scrollToAnchor} />
        Ihre Situation und Fragen
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "personalTextMessage",
          },
        }}
      >
        {formData.personalTextMessage}
      </GridEditRow>
    </>
  );
};

export default ContractDescription;
