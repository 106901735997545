//-- Ehe und Scheidung
const APPLICATION_STATUS_DIVORCE = 10;
//-- Finanzen
const APPLICATION_STATUS_FINANCIAL = 20;
const APPLICATION_STATUS_PENSIONS = 30;
const APPLICATION_STATUS_PROCESS_COST_SUPPORT = 40;
//-- Persönliche Angaben
const APPLICATION_STATUS_PARTNER = 50;
const APPLICATION_STATUS_PERSONAL = 60; // <== const APPLICATION_STATUS_CONTACT = 10;
//-- Zusammenfassung
const APPLICATION_STATUS_SUMMARY = 85;
//-- Vollmacht
const APPLICATION_STATUS_POWER_OF_ATTORNEY = 90;
//-- Dokumente
const APPLICATION_STATUS_DOCUMENTS = 100;
//-- Done
const APPLICATION_STATUS_DONE = 110;

export {
  APPLICATION_STATUS_DIVORCE,
  APPLICATION_STATUS_FINANCIAL,
  APPLICATION_STATUS_PENSIONS,
  APPLICATION_STATUS_PROCESS_COST_SUPPORT,
  APPLICATION_STATUS_PARTNER,
  APPLICATION_STATUS_PERSONAL,
  APPLICATION_STATUS_SUMMARY,
  APPLICATION_STATUS_POWER_OF_ATTORNEY,
  APPLICATION_STATUS_DOCUMENTS,
  APPLICATION_STATUS_DONE,
};