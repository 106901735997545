import React from "react";
import Grid from "@mui/material/Grid";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { isRequired, postalCodeDefault, requiredValidator, streetAddressDefault } from "../../../../services/validationRules";
import FormLegend from "../FormLegend/FormLegend";
import { translate } from "../../../../services/translations/translations";
import { FormDataTypes } from "../../../../types/FormDataTypes";
import { useForm } from "../../../../provider/Form/FormProvider";
import wizardStyle from "../../wizardStyle";

type AddressProps = {
  path: string;
  headline?: string;
  fieldsAreRequired?: boolean;
  disabled?: boolean;
};

const Address = ({ path, headline = "Wo wohnen Sie?", fieldsAreRequired = true, disabled = false }: AddressProps) => {
  const { values }: FormDataTypes = useForm();

  return (
    <>
      {headline && (
        <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
          <FormLegend>{headline}</FormLegend>
        </Grid>
      )}
      <Grid item xs={12}>
        <ValidatorTextField
          name={`${path}.streetAddress`}
          label={translate("address.streetAddress.label", values)}
          disabled={disabled}
          validators={fieldsAreRequired ? [requiredValidator("Bitte geben Sie die Straße und Hausnummer an"), streetAddressDefault] : [streetAddressDefault]}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={wizardStyle.gridSpace}>
        <ValidatorTextField
          name={`${path}.postalCode`}
          label={translate("address.postalCode.label", values)}
          disabled={disabled}
          validators={fieldsAreRequired ? [requiredValidator("Bitte geben Sie die Postleitzahl an"), postalCodeDefault] : [postalCodeDefault]}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ValidatorTextField
          name={`${path}.addressLocality`}
          label={translate("address.addressLocality.label", values)}
          disabled={disabled}
          validators={fieldsAreRequired ? [{ validator: isRequired, message: "Bitte geben Sie den Wohnort an" }] : []}
        />
      </Grid>
    </>
  );
};

export default Address;
