import { Message } from "../../../types/Entities/Messaging/Message";
import { Box, Grid, Rating, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import moment from "moment";
import messageCenterMessageStyle from "./messageCenterMessageStyle";
import DownloadTile from "../../PetitionSummary/Documents/DownloadTile";
import { useState } from "react";
import { apiPost, apiPut } from "../../../services/apiClient";
import { queryKeys } from "../../../services/reactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import { CustomerFeedback } from "../../../types/Entities/CustomerFeedback";
import { MinimalCase } from "../../../types/Entities/MinimalCase";
import { useTheme } from "@mui/material/styles";

function MessageCenterMessage({ message, product }: { message: Message; product: MinimalCase }) {
  const isCustomerMessage = message.senderType === "customer";
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const ratingDisabled = !!message.customerFeedback && moment(message.customerFeedback.feedbackDateTime).isBefore(moment().subtract(1, "day"));
  const theme = useTheme();

  return (
    <>
      <Box
        sx={Object.assign(
          {},
          messageCenterMessageStyle.messageContainer,
          isCustomerMessage ? messageCenterMessageStyle.ownMessageContainer : messageCenterMessageStyle.otherMessageContainer
        )}
      >
        <Stack display={"flex"} direction={"row"} justifyContent={"flex-end"}>
          <Typography>{moment(message.created).format("DD.MM.YYYY, HH:mm") + " Uhr"}</Typography>
        </Stack>
        <Paper
          elevation={0}
          sx={Object.assign(
            {},
            messageCenterMessageStyle.messageBubble,
            isCustomerMessage ? messageCenterMessageStyle.ownMessageBubble(theme) : messageCenterMessageStyle.otherMessageBubble(theme)
          )}
        >
          <Typography sx={{ color: "primary.main" }} fontWeight={"bold"}>
            {message.senderType === "system" ? "Legalbird" : message.sender.messagingUserFullName}
          </Typography>
          <Typography dangerouslySetInnerHTML={{ __html: message.messageText }} sx={messageCenterMessageStyle.ownMessageText} />
          {message.attachments?.length > 0 && (
            <Grid container justifyContent={"flex-start"} spacing={3}>
              {message.attachments.map((mediaObject) => (
                <Grid item key={mediaObject.id}>
                  <DownloadTile mediaObject={mediaObject} size={"small"} />
                </Grid>
              ))}
            </Grid>
          )}
        </Paper>
        {!isCustomerMessage && message.showCustomerFeedback && (
          <Box sx={messageCenterMessageStyle.customerFeedbackBox}>
            <Typography fontSize={"small"} color={"textSecondary"}>
              {ratingDisabled ? "Vielen Dank für Ihre Bewertung." : "Wie hilfreich war diese Nachricht für Sie?"}
            </Typography>
            <Rating
              name="simple-controlled"
              value={message.customerFeedback?.stars ?? 0}
              disabled={isLoading || ratingDisabled}
              onChange={async (event, stars) => {
                if (!stars) {
                  return;
                }
                setIsLoading(true);
                if (message.customerFeedback) {
                  await apiPut(`/customer_feedbacks/${message.customerFeedback.id}`, {
                    stars: stars,
                  });
                } else {
                  const feedback: CustomerFeedback = await apiPost("/customer_feedbacks", {
                    stars: stars,
                    source: "message",
                    product: product.name,
                    caseId: product.id,
                  });
                  await apiPut(`/messages/${message.id}`, { customerFeedback: `/customer_feedbacks/${feedback.id}` });
                }
                await queryClient.invalidateQueries(queryKeys.collection("/conversations"));
                setIsLoading(false);
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

export default MessageCenterMessage;
