/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { adaptV4Theme, createTheme } from "@mui/material/styles";

export const darkModePalette = {
  palette: {
    mode: "dark",
    iconColor: {
      main: "#FFFFFF",
    },
    primary: {
      light: "#4b9f9c",
      main: "#137F7B",
      dark: "#0B504C",
      contrastText: "rgb(255, 255, 255)",
    },
    secondary: {
      light: "#017b88",
      main: "#015b58",
      dark: "#013b39",
      contrastText: "rgb(255, 255, 255)",
    },
    buttonGrey: {
      main: "#e0e0e0",
      dark: "#d5d5d5",
      contrastText: "rgba(0, 0, 0, 0.86)",
    },
    buttonText: {
      main: "#f4f4f4",
    },
    text: {
      primary: "#f4f4f4",
      secondary: "#F4F4F4",
      light: "#999999",
      contrast: "#333333",
    },
    background: {
      default: "#000000",
      contrast: "#f4f4f4",
      medium: "#666666",
      light: "#333333",
    },
  },
};

export const lightModePalette = {
  palette: {
    mode: "light",
    iconColor: {
      main: "#333333",
    },
    primary: {
      light: "#4b9f9c",
      main: "#137f7b",
      dark: "#086662",
      contrastText: "rgb(255, 255, 255)",
    },
    secondary: {
      light: "#017b88",
      main: "#015b58",
      dark: "#013b39",
      contrastText: "rgb(255, 255, 255)",
    },
    buttonGrey: {
      main: "#e0e0e0",
      dark: "#d5d5d5",
      contrastText: "rgba(0, 0, 0, 0.86)",
    },
    buttonText: {
      main: "#272d4e",
    },
    text: {
      primary: "#272d4e",
      secondary: "#666666",
      light: "#999999",
      contrast: "#d0e7e6",
    },
    background: {
      default: "#fff",
      contrast: "#f4f4f4",
      medium: "#666666",
      light: "#f4f4f4",
    },
  },
};

// @ts-ignore
const theme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiTypography: {
        paragraph: {
          marginTop: "1rem",
        },
      },
      PrivatePickersYear: {
        button: {
          // fixes date picker year buttons being ugly due to having typography subtitle1 styling
          fontSize: "1rem",
          fontWeight: 400,
        },
      },
      MuiButton: {
        root: {
          padding: "8px",
        },
        label: {
          textAlign: "center",
        },
      },
      MuiFormLabel: {
        root: {
          lineHeight: "inherit",
        },
      },
      MuiFormControlLabel: {
        label: {
          fontSize: "0.875rem",
          fontWeight: 400,
          lineHeight: 1.5,
        },
      },
      MuiDialog: {
        paper: {
          margin: 24,
        },
        paperScrollPaper: {
          maxHeight: "calc(100% - 48px)",
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: 25,
        },
      },
      MuiDialogTitle: {
        root: {
          textAlign: "center",
          "& h6": {
            color: "#6ce9e4",
          },
        },
      },
      MuiSwitch: {
        colorPrimary: {
          "&$disabled": {
            color: "#bcdada",
            "& + .MuiSwitch-track": {
              backgroundColor: "#137f7b",
            },
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #E8F0FE inset",
            WebkitTextFillColor: "#000",
            transition: "background-color 5000s ease-in-out 0s",
          },
        },
      },
    },
    props: {
      MuiFormLabel: {
        required: false,
      },
      MuiPaper: {
        elevation: 8,
      },
      MuiCard: {
        elevation: 8,
      },
    },
    typography: {
      useNextVariants: true,
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
      lineHeight: "1.5",
      h1: {
        fontSize: "3.5rem",
        fontWeight: 500,
        marginTop: "1.25rem",
        marginBottom: "0.625rem",
      },
      h2: {
        fontSize: "3rem",
        fontWeight: 500,
        marginTop: "1.25rem",
        marginBottom: "0.625rem",
      },
      h3: {
        fontSize: "2.5rem",
        fontWeight: 500,
        marginTop: "1.25rem",
        marginBottom: "0.625rem",
      },
      h4: {
        fontSize: "1.75rem",
        fontWeight: 500,
        marginTop: "0.625rem",
        marginBottom: "0.625rem",
      },
      h5: {
        fontSize: "1.5rem",
        fontWeight: 500,
        marginTop: "0.625rem",
        marginBottom: "0.625rem",
      },
      h6: {
        fontSize: "1rem",
        fontWeight: 500,
      },
      subtitle1: {
        fontWeight: 300,
        fontSize: "1.75rem",
      },
      subtitle2: {
        fontWeight: 300,
        fontSize: "1.25rem",
      },
      body1: {
        fontWeight: 300,
        fontSize: "1rem",
      },
      body2: {
        lineHeight: "1.3rem",
        fontWeight: 300,
        fontSize: ".75rem",
      },
      button: {
        fontSize: "1rem",
        fontWeight: 400,
      },
    },
  })
);
//define theme depended styles from here on

theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
};

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.75rem",
  },
};

theme.typography.h3 = {
  ...theme.typography.h3,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
};

theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.25rem",
  },
};

theme.typography.h5 = {
  ...theme.typography.h5,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.125rem",
  },
};

theme.typography.h6 = {
  ...theme.typography.h6,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
};

theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.25rem",
  },
};

theme.typography.subtitle2 = {
  ...theme.typography.subtitle2,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.125rem",
  },
};

export default theme;
