import _get from "lodash/get";
import _camelCase from "lodash/camelCase";
import _findKey from "lodash/findKey";
import formStepsAlimony from "./alimony/formSteps";
import FormServiceAlimony from "./alimony/FormService";
import formStepsDivorce from "./divorce/formSteps";
import FormServiceDivorce from "./divorce/FormService";
import formStepsDeath from "./death/formSteps";
import FormServiceDeath from "./death/FormService";
import formStepsContractLaw from "./contractLaw/formSteps";
import formStepsRentalContract from "./rentalContract/formSteps";
import formStepsDataLeakContract from "./contractLaw/dataLeakContract/formSteps";
import FormServiceContractLaw from "./contractLaw/FormService";
import FormServiceRentalContract from "./rentalContract/FormService";
import FormServiceDataLeakContract from "./contractLaw/dataLeakContract/FormService";
import formStepsProcessCostSupport from "./processCostSupport/formSteps";
import FormServiceProcessCostSupport from "./processCostSupport/FormService";
import formStepsFamily from "./familyLaw/formSteps";
import FormServiceFamily from "./familyLaw/FormService";
import formStepsSettlement from "./settlement/formSteps";
import FormServiceSettlement from "./settlement/FormService";
import formStepsTraffic from "./traffic/formSteps";
import FormServiceTraffic from "./traffic/FormService";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_ALIMONY } from "./alimony/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_DIVORCE } from "./divorce/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_DEATH } from "./death/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_CONTRACT } from "./contractLaw/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_SETTLEMENT } from "./settlement/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_TRAFFIC } from "./traffic/applicationStatus";
import { APPLICATION_STATUS_SUMMARY as APPLICATION_STATUS_SUMMARY_PCS } from "./processCostSupport/applicationStatus";
import contractIcon from "../assets/icons/serviceOverview/IconVertragsrecht";
import familyIcon from "../assets/icons/familyLaw/IconFamilie";
import trafficIcon from "../assets/icons/traffic/IconVerkehrsrechtMinimal";
import employmentLawIcon from "../assets/icons/generic/IconArbeitsrecht";
import tenancyLawIcon from "../assets/icons/tenancyLaw/IconMietvertrag";
import iconDivorce from "../assets/icons/familyLaw/IconScheidungMinimal";
import iconAlimony from "../assets/icons/familyLaw/IconUnterhaltBerechnen";
import iconDeath from "../assets/icons/settlement/iconAnwaltPruefen";
import iconFamily from "../assets/icons/familyLaw/IconFamilie";
import iconContract from "../assets/icons/serviceOverview/IconVertragsrecht";
import iconDataLeakContract from "../assets/icons/serviceOverview/IconDatenweitergabe";
import _includes from "lodash/includes";
import iconShortTimeWork from "../assets/icons/shortTimeWork/IconKurzarbeitMinimal";
import iconSettlement from "../assets/icons/generic/IconArbeitsrecht";
import { STATUS_DATA_COMPLETED_LAWFIRM as STATUS_DATA_COMPLETED_LAWFIRM_CONTRACT_LAW } from "./contractLaw/leadStatus";

export const productCategoryData = {
  contractLaw: {
    label: "Vertragsrecht",
    icon: contractIcon,
  },
  familyLaw: {
    label: "Familienrecht",
    icon: familyIcon,
  },
  trafficLaw: {
    label: "Verkehrsrecht",
    icon: trafficIcon,
  },
  employmentLaw: {
    label: "Arbeitsrecht",
    icon: employmentLawIcon,
  },
  tenancyLaw: {
    label: "Mietrecht",
    icon: tenancyLawIcon,
  }
};

const productData = {
  alimony: {
    urlPart: "familienrecht/unterhalt",
    urlPartBase: "familienrecht/unterhalt",
    urlPartShort: "unterhalt",
    urlPartProductCategory: "familienrecht",
    label: "Erstberatung Unterhalt",
    apiUri: "/alimonies",
    formSteps: formStepsAlimony,
    formService: FormServiceAlimony,
    summaryStatus: APPLICATION_STATUS_SUMMARY_ALIMONY,
    productCategory: productCategoryData.familyLaw,
    productIcon: iconAlimony,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  dataLeakContract: {
    urlPart: "vertragsrecht/datenweitergabe",
    urlPartBase: "vertragsrecht",
    urlPartShort: "datenweitergabe",
    urlPartProductCategory: "vertragsrecht",
    label: "Datenweitergabe",
    apiUri: "/data_leak_contracts",
    formSteps: formStepsDataLeakContract,
    formService: FormServiceDataLeakContract,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconDataLeakContract,
    mediaObjectUploadAllowed: ({ product }) => product.leadStatus >= STATUS_DATA_COMPLETED_LAWFIRM_CONTRACT_LAW,
  },
  divorce: {
    urlPart: "familienrecht/scheidung",
    urlPartBase: "familienrecht",
    urlPartShort: "scheidung",
    urlPartProductCategory: "familienrecht",
    label: "Scheidung",
    apiUri: "/divorces",
    formSteps: formStepsDivorce,
    formService: FormServiceDivorce,
    summaryStatus: APPLICATION_STATUS_SUMMARY_DIVORCE,
    productCategory: productCategoryData.familyLaw,
    productIcon: iconDivorce,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  death: {
    urlPart: "familienrecht/todesfall",
    urlPartBase: "familienrecht/todesfall",
    urlPartShort: "todesfall",
    urlPartProductCategory: "familienrecht",
    label: "Erstberatung Erbrecht/Todesfall",
    apiUri: "/deaths",
    formSteps: formStepsDeath,
    formService: FormServiceDeath,
    summaryStatus: APPLICATION_STATUS_SUMMARY_DEATH,
    productCategory: productCategoryData.familyLaw,
    productIcon: iconDeath,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  employmentContract: {
    urlPart: "arbeitsrecht/arbeit",
    urlPartBase: "arbeitsrecht",
    urlPartShort: "arbeit",
    urlPartProductCategory: "arbeitsrecht",
    label: "Arbeitsvertrag",
    apiUri: "/employment_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.employmentLaw,
    productIcon: iconContract,
  },
  eventContract: {
    urlPart: "vertragsrecht/veranstaltung",
    urlPartBase: "vertragsrecht",
    urlPartShort: "veranstaltung",
    urlPartProductCategory: "vertragsrecht",
    label: "Veranstaltung",
    apiUri: "/event_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  familyLawCase: {
    urlPart: "familienrecht/erstberatung",
    urlPartBase: "familienrecht",
    urlPartShort: "erstberatung",
    urlPartProductCategory: "familienrecht",
    label: "Erstberatung Familienrecht",
    apiUri: "/family_law_cases",
    formSteps: formStepsFamily,
    formService: FormServiceFamily,
    summaryStatus: 1000,
    productCategory: productCategoryData.familyLaw,
    productIcon: iconFamily,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  generic: {
    urlPart: "",
  },
  legalbird: {
    urlPart: "",
  },
  membershipContract: {
    urlPart: "vertragsrecht/mitgliedschaft",
    urlPartBase: "vertragsrecht",
    urlPartShort: "mitgliedschaft",
    urlPartProductCategory: "vertragsrecht",
    label: "Mitgliedschaft",
    apiUri: "/membership_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  otherContract: {
    urlPart: "vertragsrecht/sonstige",
    urlPartBase: "vertragsrecht",
    urlPartShort: "sonstige",
    urlPartProductCategory: "vertragsrecht",
    label: "Sonstiger Vertrag",
    apiUri: "/other_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  processCostSupport: {
    apiUri: "/process_cost_supports",
    urlPart: "scheidung",
    urlPartProductCategory: "familienrecht",
    urlPartShort: "scheidung",
    formSteps: formStepsProcessCostSupport,
    formService: FormServiceProcessCostSupport,
    summaryStatus: APPLICATION_STATUS_SUMMARY_PCS,
  },
  providerContract: {
    urlPart: "vertragsrecht/versorger",
    urlPartBase: "vertragsrecht",
    urlPartShort: "versorger",
    urlPartProductCategory: "vertragsrecht",
    label: "Versorgervertrag",
    apiUri: "/provider_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  provision: {
    urlPart: "vorsorge",
  },
  purchaseContract: {
    urlPart: "vertragsrecht/kauf",
    urlPartBase: "vertragsrecht",
    urlPartShort: "kauf",
    urlPartProductCategory: "vertragsrecht",
    label: "Kaufvertrag",
    apiUri: "/purchase_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  rentalContract: {
    urlPart: "mietrecht/miete",
    urlPartBase: "mietrecht",
    urlPartShort: "miete",
    urlPartProductCategory: "mietrecht",
    label: "Mietvertrag",
    apiUri: "/rental_contracts",
    formSteps: formStepsRentalContract,
    formService: FormServiceRentalContract,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.tenancyLaw,
    productIcon: tenancyLawIcon,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  serviceContract: {
    urlPart: "vertragsrecht/dienstleistung",
    urlPartBase: "vertragsrecht",
    urlPartShort: "dienstleistung",
    urlPartProductCategory: "vertragsrecht",
    label: "Dienstleistungsvertrag",
    apiUri: "/service_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  settlement: {
    urlPart: "arbeitsrecht/kuendigung",
    urlPartBase: "kuendigung",
    urlPartShort: "kuendigung",
    urlPartProductCategory: "arbeitsrecht",
    label: "Kündigungsschutzklage",
    apiUri: "/settlements",
    formSteps: formStepsSettlement,
    formService: FormServiceSettlement,
    summaryStatus: APPLICATION_STATUS_SUMMARY_SETTLEMENT,
    productCategory: productCategoryData.employmentLaw,
    productIcon: iconSettlement,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  shortTimeWork: {
    urlPartShort: "kurzarbeit",
    urlPartProductCategory: "arbeitsrecht",
    label: "Erstberatung Kurzarbeit",
    apiUri: "/short_time_works",
    productCategory: productCategoryData.employmentLaw,
    productIcon: iconShortTimeWork,
    mediaObjectUploadAllowed: () => false,
  },
  subscriptionContract: {
    urlPart: "vertragsrecht/abonnement",
    urlPartBase: "vertragsrecht",
    urlPartShort: "abonnement",
    urlPartProductCategory: "vertragsrecht",
    label: "Abonnement",
    apiUri: "/subscription_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  traffic: {
    urlPart: "verkehrsrecht/ordnungswidrigkeit",
    urlPartBase: "ordnungswidrigkeit",
    urlPartShort: "ordnungswidrigkeit",
    urlPartProductCategory: "verkehrsrecht",
    label: "Ordnungswidrigkeit",
    apiUri: "/traffic",
    formSteps: formStepsTraffic,
    formService: FormServiceTraffic,
    summaryStatus: APPLICATION_STATUS_SUMMARY_TRAFFIC,
    productCategory: productCategoryData.trafficLaw,
    productIcon: trafficIcon,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  travelContract: {
    urlPart: "vertragsrecht/reise",
    urlPartBase: "vertragsrecht",
    urlPartShort: "reise",
    urlPartProductCategory: "vertragsrecht",
    label: "Reisevertrag",
    apiUri: "/travel_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
  vehicleContract: {
    urlPart: "vertragsrecht/fahrzeugkauf",
    urlPartBase: "vertragsrecht",
    urlPartShort: "fahrzeugkauf",
    urlPartProductCategory: "vertragsrecht",
    label: "Fahrzeugkaufvertrag",
    apiUri: "/vehicle_contracts",
    formSteps: formStepsContractLaw,
    formService: FormServiceContractLaw,
    summaryStatus: APPLICATION_STATUS_SUMMARY_CONTRACT,
    productCategory: productCategoryData.contractLaw,
    productIcon: iconContract,
    mediaObjectUploadAllowed: ({ product }) => product.isCustomerDataCompleted,
  },
};

export const getProductData = (productClassName, path, defaultValue = "") => {
  productClassName = _camelCase(productClassName);
  return _get(productData, `${productClassName}.${path}`, defaultValue);
};

export const getProductNameBy = (path, value) => {
  return _findKey(productData, [path, value]);
};

export const getCaseLink = (product) => {
  if (product.name === "processCostSupport") {
    return getCaseLink(product.divorce) + "/vkh-assistent";
  }
  return "/servicewelt/" + getProductData(product.name, "urlPartProductCategory") + "/" + getProductData(product.name, "urlPartShort") + "/" + product.id;
};

export const isInsurancePartnered = (product) => {
  return _includes(["advocard", "arag", "hukCoburg"], product.acquisitionPartner);
};
