import { AbstractCase } from "./Entities/AbstractCase";
import { Customer } from "./Entities/Customer";
import { QueryClient, UseMutationResult } from "@tanstack/react-query";
import { CreateResourceParams, DeleteResourceParams, UpdateResourceParams } from "../services/reactQuery/reactQueryService";
import { ReactNode } from "react";
import { prepareFormDataBase } from "../services/formServiceFunctions";

export type FormStep = {
  title?: string;
  stepUrl: string;
  applicationStatus: number;
  render: (props: any) => ReactNode; // any is appropriate since this can be props for dozens of components
};

export type FormSteps = { [k: number]: FormStep };

export abstract class FormServiceBase<ApplicationStatus extends number> {
  abstract dataFields: (product: AbstractCase) => { [k in ApplicationStatus]?: { [key: string]: string[] } };
  conversionFields: { path: string; convertFrom: Function; convertTo: Function }[] = [];
  //-- callback function will be called AFTER this STATUS is passed
  statusFunctions: { [k in ApplicationStatus]?: () => void } = {};

  getStep = (status: ApplicationStatus, formData: Record<string, any>, product: AbstractCase, addend?: number) => {
    const steps = this.getAvailableSteps(formData, product);
    const currentIndex = steps.findIndex((step) => step.applicationStatus === status);
    if (currentIndex === -1) {
      return steps[0];
    }
    return steps[currentIndex + (addend || 0)];
  };
  prepareFormData = (product: AbstractCase, customer: Customer): AbstractCase => {
    return prepareFormDataBase(product, customer, this.conversionFields);
  };

  abstract save: ({
    formData,
    applicationStatus,
    nextApplicationStatus,
    product,
    customer,
    updateMutation,
    queryClient,
    createMutation,
    refreshCustomer,
    deleteMutation,
  }: FormServiceSaveProps<ApplicationStatus>) => Promise<void>;
  abstract getAvailableSteps: (formData: Record<string, any>, product: AbstractCase) => FormStep[];
}

export type FormServiceSaveProps<ApplicationStatus> = {
  formData: AbstractCase;
  applicationStatus: ApplicationStatus;
  nextApplicationStatus: ApplicationStatus;
  product: AbstractCase;
  customer: Customer;
  updateMutation: UseMutationResult<any, unknown, UpdateResourceParams>;
  queryClient: QueryClient;
  createMutation: UseMutationResult<any, unknown, CreateResourceParams>;
  refreshCustomer: Function;
  deleteMutation: UseMutationResult<any, unknown, DeleteResourceParams>;
};
