export type AccordionContentWithoutLink = {
  id: number;
  heading: string;
  text: string;
};

export type AccordionContentWithLink = AccordionContentWithoutLink & {
  linkText: string;
  link: string;
};

export type AccordionContent = AccordionContentWithoutLink | AccordionContentWithLink;

export const isAccordionContentWithLink = (content: AccordionContent): content is AccordionContentWithLink => {
  return (content as AccordionContentWithLink).link !== undefined && (content as AccordionContentWithLink).linkText !== undefined;
};
