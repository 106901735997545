import React from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import { isRequired, minStringLength } from "../../../../services/validationRules";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import FormLegend from "../FormLegend/FormLegend";
import { translate } from "../../../../services/translations/translations";
import { useForm } from "../../../../provider/Form/FormProvider";
import { FormDataTypes } from "../../../../types/FormDataTypes";
import wizardStyle from "../../wizardStyle";

type PersonProps = {
  path: string;
  headline?: string;
  fieldsAreRequired?: boolean;
  showDiverseGenderOption?: boolean;
};

const Person = ({ path, headline = "Wie heißen Sie?", fieldsAreRequired = true, showDiverseGenderOption = false }: PersonProps) => {
  const { values }: FormDataTypes = useForm();

  return (
    <>
      <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
        <FormLegend>{headline}</FormLegend>
      </Grid>
      <Grid item xs={12}>
        <ValidatorSelectField
          label={translate("person.gender.label", values)}
          name={`${path}.gender`}
          validators={fieldsAreRequired ? [{ validator: isRequired, message: "Bitte geben Sie die Anrede an" }] : []}
          MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
        >
          <MenuItem value={"female"}>Frau</MenuItem>
          <MenuItem value={"male"}>Herr</MenuItem>
          {showDiverseGenderOption && <MenuItem value={"diverse"}>Divers</MenuItem>}
        </ValidatorSelectField>
      </Grid>
      <Grid item xs={12} sm={6} sx={wizardStyle.gridSpace}>
        <ValidatorTextField
          name={`${path}.givenName`}
          label={translate("person.givenName.label", values)}
          validators={
            fieldsAreRequired
              ? [
                  { validator: isRequired, message: "Bitte geben Sie den Vornamen an" },
                  { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
                ]
              : []
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ValidatorTextField
          name={`${path}.familyName`}
          label={translate("person.familyName.label", values)}
          validators={
            fieldsAreRequired
              ? [
                  { validator: isRequired, message: "Bitte geben Sie den Nachnamen an" },
                  { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
                ]
              : []
          }
        />
      </Grid>
    </>
  );
};

export default Person;
