import React from "react";
import { Grid, Stack, Tooltip } from "@mui/material";
import { Mail } from "@mui/icons-material";
import { MinimalCase } from "../../../types/Entities/MinimalCase";
import { useQuery } from "@tanstack/react-query";
import { Conversation } from "../../../types/Entities/Messaging/Conversation";
import { fetchCollection, queryKeys } from "../../../services/reactQuery/reactQueryService";
import { caseHasUnreadMessage } from "../../../services/messagingService";

type UnreadCaseMessageProps = {
  minimalCase: MinimalCase;
};

export default function UnreadCaseMessage({ minimalCase }: UnreadCaseMessageProps) {

  const { data: conversations, isLoading } = useQuery<Array<Conversation>, Error>(queryKeys.collection("/conversations"), () =>
    fetchCollection("/conversations")
  );

  if (isLoading || !conversations) {
    return null;
  }


  if (caseHasUnreadMessage(minimalCase.id, conversations)) {
    return (
      <Grid item sx={{ flexGrow: 1 }}>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Tooltip title={"Zu diesem Fall liegt eine ungelesene Nachricht in Ihrem Nachrichtenbereich vor."} placement={"right"}>
            <Mail color={"primary"} />
          </Tooltip>
        </Stack>
      </Grid>
    );
  }

  return null;
}
